//localhost:3001/bff/v1.0/getBookmarkByModule/619bec796daaa10f5658558e
export async function getBookmarkByModule(moduleId){
    
    const endpoint = process.env.REACT_APP_BFF + "/bff/v1.0/getBookmarkByModule/" + moduleId;
    const options = { 
      method: 'GET', 
      headers: new Headers({ 
        'Content-Type': 'application/json',
        'sb-application-name': 'sb-site-ui',
        'sb-application-key': 'jmd-test',
        'Access-Control-Allow-Origin': '*',
        'sessionID' : sessionStorage.getItem("sessionID"),
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
      }), 
      mode: 'cors',
      credentials: 'omit'
    };

    const getBookmarkByModuleResponse = await fetch(endpoint,options)
    .then(function(response){return response.json()});
    
    return getBookmarkByModuleResponse;

}

export async function getBookmarkByStudent(){
    
    const endpoint = process.env.REACT_APP_BFF + "/bff/v1.0/getBookmarksByStudent/";
    const options = { 
      method: 'GET', 
      headers: new Headers({ 
        'Content-Type': 'application/json',
        'sb-application-name': 'sb-site-ui',
        'sb-application-key': 'jmd-test',
        'Access-Control-Allow-Origin': '*',
        'sessionID' : sessionStorage.getItem("sessionID"),
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
      }), 
      mode: 'cors',
      credentials: 'omit'
    };

    const getBookmarkByModuleResponse = await fetch(endpoint,options)
    .then(function(response){return response.json()});
    
    return getBookmarkByModuleResponse;

}