import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";


function SleekButton({ text, link }) {
	

    useEffect(() => {

    })

	return (
		<>
            <a href={link}>
			<div className="button-container">
				<button className="sleek-button">
					<span className="circle-icon">
						<FaArrowRight style={{ marginLeft: "10px" }} />
					</span>
					<span className="button-text">{text ?? "Try Now!"}</span>
				</button>
			</div>
		</a>
       
        </>
	);
}

export default SleekButton;
