import Table from 'react-bootstrap/Table'
import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert';
import Pagination from 'react-bootstrap/Pagination'
import Datetime from 'react-datetime';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import ReactExport from "react-export-excel";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner'
import { getMyQuiz } from '../../services/quiz/getMyQuiz';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let pageSize = 10;

function MyQuizComponent(props) {

    const [numberOfPages, setNumberOfPages] = useState(1);
    const [activeDataSet, setActiveDataSet] = useState([{}]);
    const [downloadDataSet, setDownloadDataSet] = useState([]);
    const [quizList, setQuizList] = useState([]);




    const currentTableData = async (quizList, currentPage) => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        setActiveDataSet(quizList.slice(firstPageIndex, lastPageIndex));
    };

    var pageinationBar = () => {

        var html = [];

        for (var i = 1; i <= numberOfPages; i++) {
            html.push(<button class="page-link" onClick={event => updatePage(event.target.value, quizList)} value={i}> {i} </button>);
        }

        return (
            <>
                {html}
            </>
        )
    }

    var updatePage = async (num, orders) => {

        // console.log('updatePage');
        currentTableData(orders, num);
        var numPages = orders.length / 10;
        await setNumberOfPages(Math.round(numPages));
    }

    const createDownloadDataSet = async (quizLists) => {

        var downloadDataSetArray = [];

        for (var i = 0; i < quizLists.length; i++) {

            downloadDataSetArray.push({
                "Unit": quizLists[i]?.Unit,
                "Chapter": quizLists[i]?.Chapter,
                "Module": quizLists[i]?.Module,
                "Grade": quizLists[i]?.Grade,
            })
        }

        setDownloadDataSet(downloadDataSetArray);


    }

    useEffect(async () => {

        var myQuizResponse = await getMyQuiz();
        var numPages = myQuizResponse.length / 10;
        await setNumberOfPages(Math.round(numPages));
        await currentTableData(myQuizResponse, 1);

        await setQuizList(myQuizResponse);
        await createDownloadDataSet(myQuizResponse);

    }, [])

    return (
        <>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th width="25%">Unit</th>
                        <th >Chapter</th>
                        <th >Module</th>
                        <th width="10%">Grade</th>

                    </tr>
                </thead>
                <tbody>
                    {activeDataSet.map(quiz => (
                        
                        <>
                        {quiz.hasQuiz == true &&
                            <tr align="left">
                                <td>{quiz.Unit}</td>
                                <td>{quiz.Chapter}</td>
                                <td>{quiz.Module || "error"}</td>
                                {/* <td>{quiz.Grade}</td>
                 */}
                                <td align="center">{quiz.Grade === 'Not Taken' ? quiz.Grade : quiz.Grade + '%'}</td>
                            </tr>
                        }
                        </>
                    ))}
                </tbody>
            </Table>
            <div align="center">
                <Row>
                    <Col>
                        <Pagination>{pageinationBar()}</Pagination>
                    </Col>
                    <Col>
                        <div align="right">
                            <ExcelFile element={<Button>Download</Button>}>
                                <ExcelSheet data={downloadDataSet} name="My Quiz Export">
                                    <ExcelColumn label="Unit" value="Unit" />
                                    <ExcelColumn label="Chapter" value="Chapter" />
                                    <ExcelColumn label="Module" value="Module" />
                                    <ExcelColumn label="Grade" value="Grade" />
                                </ExcelSheet>
                            </ExcelFile>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )



}

export default MyQuizComponent;
