import SubmitterInfoComponenet from './submitterInfo';
import CourseSelectionMainComponenet from './courseSelectionMain';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';



function CourseBuilderMainComponenet(props) {

    return(
        <div className="ModulePage" style={{padding:"0", backgroundColor : "#f8f9fa"}}>
        <br />
        <Card style={{width:"75%", margin: "auto", backgroundColor : "white", padding: "20px"}}>
        <h2>WELCOME TO YOUR CUSTOM COURSE BUILDER</h2>
        <hr style={{margin: "auto", 'margin-bottom': "20px"}} width="75%"/>
        <SubmitterInfoComponenet />
        <hr style={{margin: "auto", 'margin-bottom': "20px", 'margin-top': "20px"}} width="75%"/>
        <CourseSelectionMainComponenet />
        <hr style={{margin: "auto", 'margin-bottom': "20px", 'margin-top': "20px"}} width="75%"/>
        <Form.Control as="textarea" type="text" autoComplete="text" placeholder="Additional Comments" /><br />
        <Button type="submit">
                    Submit
                </Button>
        </Card>

        </div>
    )
}


export default CourseBuilderMainComponenet;
