import Dropdown from 'react-bootstrap/Dropdown'
import ProductList from '../../productListapit';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import InvoiceComponent from '../bookstore/invoiceComponent';
import { getOrderByInvoice } from '../../../services/bookstore/getBookstoreDetails';
import { PaymentComponent } from './paymentComponent';
import Footer from '../../footer';


export default function BookstoreComponent(props) {
    const [invoice, setInvoice] = useState("");
    const [product, setProduct] = useState("");
    const [invoiceNum, setInvoiceNum] = useState("");
    const [searchResults, setSearchResults] = useState("");
    const [errorMsg, setErrorMsg] = useState("");


    const getInvoiceDetails = async (invoiceNum) => {
        var invoiceDetails = await getOrderByInvoice(invoiceNum);
        await setInvoice(invoiceDetails);
        await setProduct(invoiceDetails.product)

    }

    const searchForInvoice = async (invoiceValue) => {
        if(invoiceNum != ""){
            setErrorMsg("");
            var invoiceDetails = await getOrderByInvoice(invoiceValue)
            .then((payload)=> {
                setSearchResults(<InvoiceComponent invoiceDetails={payload} productDetails={payload.product} />);
            })
        }

        if(invoiceNum == ""){
            
                
            setErrorMsg(() => {
                return(<>
                <Alert variant="danger">
                Please Enter a Invoice Number
              </Alert>
              </>)})
            
        }

    };

    useEffect(() => {
        try {
            // getInvoiceDetails();
        } catch (err) {
            //console.log(err);
        }

    }, [invoice,product])

    return (
<div style={{ display: 'flex', flexDirection: 'column', minHeight: '90vh' }}>
    
    <Container style={{ flex: 1 }}>
        <br />
        <Row>
            <Container>
                {errorMsg}
                <h4>Bookstore Invoice Payment</h4>
                <hr />
                <Card style={{padding: "10px", margin: "auto"}}>
                    <Row>
                        <Col style={{margin: "auto"}}>
                            <input class="form-control form-control-sm" onChange={event => setInvoiceNum(event.target.value)} type="text" autoComplete="text" name="invoiceId" id="invoiceId" placeholder="Enter Invoice Number"/>
                        </Col>
                        <Col style={{margin: "auto"}}>
                            <Button onClick={() => searchForInvoice(invoiceNum)}>Search Invoice</Button>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </Row>
        <br />
        <Row>
            {searchResults}
        </Row>
        <br />
    </Container>
    <div class="footer">
        <Footer />
    </div>
</div>

    )

}