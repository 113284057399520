
const options = { 
    method: 'POST', 
    headers: new Headers({ 
      'Content-Type': 'application/json',
      'sb-application-name': 'sb-site-ui',
      'sb-application-key': 'jmd-test',
      'sessionID' : sessionStorage.getItem("sessionID"),
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
    }), 
    mode: 'cors',
    credentials: 'omit'
    // body: JSON.stringify(testPayload)
  };

export async function getQuizGrade(type, typeId, quizId){
    const studentProgress2 = JSON.parse(localStorage.getItem('userProgressTotal'));

    const studentShitProgress = studentProgress2['studentProgress'];
    const quizProgress = studentProgress2['quizProgress'];

    var grade = 0;
    var hasQuiz = false;
    var quizEntryId = "";
    // debugger;
    for(var q = 0; q < quizProgress.length; q++){
        if(type == "LESSON"){
        // studentProgress.quizProgress[q].lessonId == typeId;
            if(quizProgress[q].quizId == quizId){
                if(quizProgress[q].lessonId == typeId){
                    grade = grade + quizProgress[q].gradePercentage;
                    hasQuiz = true;
                }
            }
        }
        if(type == "MODULE"){
            // studentProgress.quizProgress[q].moduleId == typeId;
            if(quizProgress[q].quizId == quizId){
                if(quizProgress[q].moduleId == typeId){
                    grade = grade + quizProgress[q].gradePercentage;
                    hasQuiz = true;
                }
            }

        }
        if(type == "UNIT"){
            // studentProgress.quizProgress[q].unitId == typeId;
            if(quizProgress[q].quizId == quizId){
                if(quizProgress[q].unitId == typeId){
                    grade = grade + quizProgress[q].gradePercentage;
                    hasQuiz = true;
                }
            }

        }
    }

    var returnResponse = {
        grade,
        hasQuiz
    }
    // debugger;
    return returnResponse;
}


export async function getQuizGradeV2(quizId){

    const endpoint = process.env.REACT_APP_BFF + '/bff/v1.0/getQuizAttemptsCompleted/' + quizId;
    options.method = 'GET';
  
    let detailsResponse =
      await fetch(endpoint,options)
        .then(response => {
          return response.json();
        })
        .catch(function(err) {
          console.log(err);
        });

// debugger;
    return detailsResponse;

    // return true;
}

// module.exports = {getQuizGrade, getQuizGradeV2}