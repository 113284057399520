import {getIpAddress} from '../getIPAddress';


// const ipAddress = await getIpAddress();

var body = {
	"userId" : "New User",
	"username":"N/A",
	"email" : "N/A",
	"ipAddress" : "192.168.1.0", //get ip address later
	"validLogin" : false
}

const options = { 
    method: 'POST', 
    headers: new Headers({ 
      'Content-Type': 'application/json',
      'sb-application-name': 'sb-site-ui',
      'sb-application-key': 'jmd-test',
      'sessionID' : sessionStorage.getItem("sessionID"),
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
    }), 
    mode: 'cors',
    credentials: 'omit',
    body: JSON.stringify(body)
  };

  export async function getSession(){

    const options2 = { 
      method: 'POST', 
      headers: new Headers({ 
        'Content-Type': 'application/json',
        'sb-application-name': 'sb-site-ui',
        'sb-application-key': 'jmd-test',
        'sessionID' : sessionStorage.getItem("sessionID") || "NEW",
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
      }), 
      mode: 'cors',
      credentials: 'omit',
      body: JSON.stringify(body)
    };

    const ipAddress = await getIpAddress();

    var body = {
      "userId" : "New User",
      "username":"N/A",
      "email" : "N/A",
      "ipAddress" : ipAddress, //get ip address later
      "validLogin" : false
    }

    const endpoint = process.env.REACT_APP_BFF + '/init/';
    options2.method = 'POST';
    options2.body = JSON.stringify(body);
  
    let sessionDetailsResponse =
      await fetch(endpoint,options2)
        .then(response => {
        //   console.log(response);
          return response.json();
        })
        .catch(function(err) {
          console.log(err);
        });;

        // console.log("blah" + JSON.stringify(sessionDetailsResponse));

    return sessionDetailsResponse;

  }


  export async function getExistingSession(){

    const endpoint = process.env.REACT_APP_BFF + '/getSessionDetails';
    options.method = 'GET';
    delete options.body;
  
    let sessionDetailsResponse =
      await fetch(endpoint,options)
        .then(response => {
        //   console.log(response);
          return response.json();
        })
        .catch(function(err) {
          console.log(err);
        });;

        // console.log("blah" + JSON.stringify(sessionDetailsResponse));

    return sessionDetailsResponse;

  }

  export async function getLtiSession(){

    const endpoint = process.env.REACT_APP_BFF + '/bff/v1.0/getLtiSession/';
    options.method = 'GET';
    delete options.body;
  
    let sessionDetailsResponse =
      await fetch(endpoint,options)
        .then(response => {
        //   console.log(response);
          return response.json();
        })
        .catch(function(err) {
          console.log(err);
        });

    return sessionDetailsResponse;

  }