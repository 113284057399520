const options = { 
    method: 'POST', 
    headers: new Headers({ 
      'Content-Type': 'application/json',
      'sb-application-name': 'sb-site-ui',
      'sb-application-key': 'jmd-test',
      'sessionID' : sessionStorage.getItem("sessionID"),
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
    }), 
    mode: 'cors',
    credentials: 'omit'
    // body: JSON.stringify(testPayload)
  };

  export async function getSchools(){

    const endpoint = process.env.REACT_APP_BFF + '/bff/v1.0/getSchools/';
    options.method = 'GET';
  
    // console.log("getProductByID endpoint" + endpoint);
    let schoolDetailsResponse =
      await fetch(endpoint,options)
        .then(response => {
          // console.log('getProductByIDResponse' + JSON.stringify(response));
          return response.json();
        })
        .catch(function(err) {
          console.log(err);
        });

        // console.log("productDetailresponse >>>> " + JSON.stringify(productDetailsResponse));

    return schoolDetailsResponse;

  }
