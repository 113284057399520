import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize('G-C08SK5FN4R'); // Replace with your actual Measurement ID
};

export const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
};

export const setUserId = (userId) => {
  ReactGA.set({ userId });
};


export const logTimeOnPage = (user_id_sb) => {
  const startTime = Date.now();

  const intervalId = setInterval(() => {
    const currentTime = Date.now();
    const timeElapsed = currentTime - startTime;
    ReactGA.event({
      category: 'Time',
      action: 'Time on Page',
      label: window.location.pathname,
      value: timeElapsed,
      userProperties: {
        user_id_sb
      }
    });
  }, 5000); // Track time every 5 seconds (adjust as needed)

  return () => {
    clearInterval(intervalId); // Cleanup the interval on component unmount
  };
};


export const logEventWithUserId = (eventCategory, eventAction, eventLabel, eventValue, user_id_sb) => {
    ReactGA.send({
      hitType: 'event',
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
      user_id_sb, // Include the user ID in the event
    });
  };