import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import "../App.css";
import Popover from 'react-bootstrap/Popover'
import addToCart from '../services/bffService';
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner';
import { getSession } from "../services/bffServices/getSessionInfo";
import {delay} from '../utls/lib';
const url = require('url');
const queryParams = new URLSearchParams(window.location.search);

const getProducts = process.env.REACT_APP_BFF + "/getProducts";
const getProductsCount = process.env.REACT_APP_BFF + "/getNumOfProducts";

let numRow = 0;
let NumProduct = 0;
let content = '';
var scInfo = {};

const domainURL = "https://storage.googleapis.com/sb-general"

function ProductList(props) {
  const [showLoading, setShowLoading] = useState(false);
  const [sessionId, setSessionId] = useState(sessionStorage.getItem("sessionID"));

  const [loadingProducst, setLoadingProducts] = useState(() => {

    return (
      <>
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <br /><br />
      </>
    )
  })

  const [errorMsg, seterrorMsg] = useState("");
  const [data, setData] = useState([]);
  const [scID, setSCID] = useState();
  const addProductToCart = (id) => {
    setShowLoading(true);
    addToCart(id);
  }
  const getData = async (sessionIdv2) => {
    const options = {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'text/plain',
        'sb-application-name': 'sb-site-ui',
        'sb-application-key': 'jmd-test',
        'Access-Control-Allow-Origin': '*',
        'sessionID': sessionIdv2,
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
      }),
      mode: 'cors',
      credentials: 'omit'
    };

    await fetch(getProducts, options)
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setLoadingProducts("")
        setData(myJson);
      })
      .catch(function (err) {
        setLoadingProducts("");
        seterrorMsg(() => {
          return (
            <div style={{ width: "50%", margin: "auto" }}><br />
              <Alert key="danger" variant="danger" align="center">
                Error has occured, Please reload and retry.
              </Alert>
            </div>
          )
        })
      });
  }


  // useEffect(async () => {
  //   var currentSessionId = await sessionStorage.getItem("sessionID");
  //   if (currentSessionId == null || currentSessionId == 'error' || currentSessionId == undefined) {
  //     try {
  //       var sessionInfo = await getSession();
  //       // delay(500);
  //       await sessionStorage.setItem('sessionID', sessionInfo.sessionID);
  //       await setSessionId(sessionStorage.getItem("sessionID"));
  //       await getData(currentSessionId);
  //     } catch (err) {
  //       console.log(err)
  //     }
  //   } else {
  //     await getData(currentSessionId);
  //   }
  // }, [])

  //jmd beg replacement code
  const [sessionState, setSessionState] = useState({
    status: 'loading',
    sessionId: null,
    error: null,
  });
  useEffect(async () => {
    const fetchSession = async () => {
      try {
        const sessionInfo = await getSession();
        await sessionStorage.setItem('sessionID', sessionInfo.sessionID);
        setSessionState({ status: 'success', sessionId: sessionInfo.sessionID, error: null });
      } catch (err) {
        setSessionState({ status: 'error', sessionId: null, error: err });
      }
    };
  
    // Retrieve existing sessionID if available
    const storedSessionId = sessionStorage.getItem('sessionID');
    if (storedSessionId) {
      setSessionState({ status: 'success', sessionId: storedSessionId, error: null });
    } else {
      fetchSession(); // Fetch if no session found in storage
    }
  }, []);
  
  // Use the sessionState to decide when to call getData
  useEffect(() => {
    if (sessionState.status === 'success') {
      getData(sessionState.sessionId);
    }
  }, [sessionState]);

  //jmd end

  const [data2, setData2] = useState([]);

  const getData2 = () => {
    const options = {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'text/plain',
        'sb-application-name': 'sb-site-ui',
        'sb-application-key': 'jmd-test',
        'Access-Control-Allow-Origin': '*',
        'sessionID': sessionId,
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
      }),
      mode: 'cors',
      credentials: 'omit'
    };

    fetch(getProductsCount, options)
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        numRow = myJson.numProducts;
        NumProduct = myJson.numRows;

        if (numRow > 1) {
          for (let i = 0; i < numRow; i++) {
            content = (<Row>
              {
                data && data.length > 0 && data.map((item) =>
                  <Col md={3} lg={3} sm={3}>
                    <Card>
                      <Card.Image variant="top" src="https://www.smart-biology.com/wp-content/uploads/2022/02/Smart-Biology-Life-U1-Cover-Image-for-eBook-245x300.jpg" />
                      <Card.Body>
                        <Card.Title>{item.title}</Card.Title>
                        <Card.Text>rice: ${item.price} </Card.Text>
                        <Button>More Details</Button>{' '}
                        <Button>Add to Cart</Button>
                      </Card.Body>
                    </Card><br /><br />
                  </Col>
                )
              }
            </Row>)
          }
        }
        setData2(myJson)
      })
      .catch(function (err) {
        // console.log("ERROR HAPPEND" + err);
        seterrorMsg(() => {
          return (
            <div style={{ width: "50%", margin: "auto" }}><br />
              <Alert key="danger" variant="danger" align="center">
                Error has occured, Please reload and retry.
              </Alert>
            </div>
          )
        })
      });
  }
  useEffect(async () => {
    getData2()

  }, [])

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Popover right</Popover.Header>
      <Popover.Body>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Body>
    </Popover>
  );
  var membershipRequested = props.filter;
  var productType = props.type;
  var MemberShipAssignedTemp = ''
  // debugger;
  return (

    <div className="ProductList" style={{ 'background-color': "#f8f9fa", height: "100vh!important" }}><br />
      <div style={{ 'margin': "auto" }}>
        {loadingProducst}
      </div>
      <Container>
        <Row>
          {errorMsg}

          {props.class != "NONE" && data && data.length > 0 && data.map((item) => {
            if (item.memberships[0].membershipKey == "Individual-Access-LIFE-Unit-1-From-Atoms-to-Cells" || item.memberships[0].membershipKey == "Individual-Access-LIFE-Unit-2-Photosynthesis-and-Respiration" || item.memberships[0].membershipKey == "Individual-Access-eBook-LIFE-Unit-1-From-Atoms-to-Cells") {
              MemberShipAssignedTemp = membershipRequested //assigning genpub, move list above to strapi, maybe a button genpub
            } else {
              MemberShipAssignedTemp = item.memberships[0].membershipKey;
            }
            var parsedURL = url.parse(item?.coverImage_en);
            var updatedURL = domainURL + parsedURL.pathname;
            var searchString = new RegExp(props.class, 'i');
            if (membershipRequested == MemberShipAssignedTemp || membershipRequested == "class" && item.title.match(searchString)) {

              if (productType == item.categories[0]?.slug || productType == "ALL") {
                return (
                  <Col md={3} lg={3} sm={3}>
                    <Card style={{ width: '18rem', 'box-shadow': "5px 5px 2px #e9ecef" }}>

                      <Card.Img variant="top" style={{ padding: "10px", width: "100%", height: "350px", display: "block", 'margin-left': "auto", 'margin-right': "auto" }} src={updatedURL || 'https://www.smart-biology.com/wp-content/uploads/2022/05/Smart-Biology-Life-U4-Cover-Image-for-eBook.jpg'} />
                      <Card.Body>
                        <Card.Title style={{ 'text-align': "left", 'height': '3rem', 'padding-bottom': "5px" }}><small>{item.title}</small></Card.Title><br />
                        <Card.Text className="text-muted" style={{ 'text-align': "left" }}>Price: ${item.price} </Card.Text>

                        {item.categories[0]?.slug != 'ebooks' &&
                          <>
                            <div className="gap-2">
                              <Button variant="outline-info" href={"/product?id=" + item.id} >More Details</Button>{' '}
                              {item.ltiOnly === false || item.ltiOnly == null || item.ltiOnly == undefined || sessionStorage.getItem("isLTI") === 'Y' ? (
                                <>
                                  <Button onClick={() => addProductToCart(item.id)}>Add to Cart</Button><br /><br />
                                </>
                              ) : (
                                <>
                                  <Button disabled><strike>Add to Cart</strike></Button><br />
                                </>
                              )}
                            </div>

                          </>
                        }

                        {item.categories[0]?.slug == 'ebooks' &&
                          <>
                            <div className="d-grid gap-2">
                              <Button variant="outline-info" href={item.Custom_field[0].options} target="_blank" >Purchase</Button>{' '}
                            </div><br />
                          </>
                        }

                      </Card.Body>
                      {item.ltiOnly === true &&
                        <>
                          <Alert style={{ padding: "0px", margin: "0px" }} key="warning" variant="warning">
                            Only LTI can purchase
                          </Alert>
                        </>
                      }
                    </Card><br />
                  </Col>
                )
              }
            }
          })
          }
        </Row>
      </Container>
      <Modal
        show={showLoading}
        size="sm"
        backdrop="static"
        keyboard={false}
        class="hideBorder"
        centered
      >
        <div class="centerLoadingModel">
          <button type="submit" class="btn btn-primary mb-2" style={{ align: "center", width: "100px", height: "50px" }} disabled><Spinner animation="border" /></button>
        </div>

      </Modal>
    </div>
  );
}

export default ProductList;
