import Table from 'react-bootstrap/Table'
import React, { useState, useEffect } from "react";
// import { View, ScrollView, Text } from 'react-native';
// import {  ScrollView, Text } from 'react';
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert';
// import { getAllOrders, getOrderByID } from '../../services/bffServices/getOrderDetails';
// import OrderModelComponent from './orderModel';
import Pagination from 'react-bootstrap/Pagination'
import Datetime from 'react-datetime';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import ReactExport from "react-export-excel";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { getLTILogs } from '../../../services/userManagement/getLTILogs';
import Spinner from 'react-bootstrap/Spinner'
import Placeholder from 'react-bootstrap/Placeholder';
import { getSearchUsers } from '../../../services/search/getSearchResults';
import { getAllMemberships } from '../../../services/getAllMemberships';
import { getAllClasses } from '../../../services/getAllClasses';
import { submitUserUpdateAdmin } from '../../../../src/services/userManagement/submitUserUpdateAdmin';
import LTILogComponent from '../adminComponents/ltiLogComponent';
import UserUpdateNotesComponent from '../adminComponents/userUpdateNotesComponent';
import Accordion from 'react-bootstrap/Accordion';
import { MdOutlineCancel, MdOutlineCheck } from "react-icons/md";
import { IconContext } from "react-icons";
import { triggerRequestPassword } from '../../../services/userManagement/triggerResquestPassword';


function AdminUserSearchComponent(props) {
    const [userList, setUserList] = useState();
    const [fieldChangedDetails, setFieldChangedDetails] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [originalSelectedUser, setOriginalSelectedUser] = useState({}); // Copy of the original selectedUser
    const [showOverlay, setShowOverlay] = useState(false);
    const [showConfirmOverlay, setShowConfirmOverlay] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [activeArea, setActiveArea] = useState("Please use Search");
    const [updateUser, setUpdateUser] = useState(false);
    const [listOfMemberships, setListOfMemberships] = useState();
    const [listOfClasses, setListOfClasses] = useState();
    const [updateMsg, setUpdateMsg] = useState();
    const [ltiLogs, setLTILogs] = useState();
    const [resetPassword, setResetPassword] = useState()





    const excludedAttributes = ['ID', 'addMemberships', 'addClasses', 'removedMembership', 'removedClasses', 'membershipUpdates', 'classUpdates']; // Keep from displaying from confirmation screen



    let pageSize = 10;

    const styles = {
        // Copy and paste the CSS styles here as a JavaScript object
        input: {
            padding: '8px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            width: '100%',
            fontSize: '14px',
            height: '100%'
        },
        inputFocused: {
            outline: 'none',
            borderColor: '#007bff',
            boxShadow: '0 0 5px rgba(0, 123, 255, 0.5)',
        },
    }

    var processUpdate = async () => {
        // console.log('User has been updated2:', selectedUser);
        // console.log('Updated updateUser2:', updateUser);



        const fieldChanged = {};

        // Loop through the fields of the user object and compare their values
        for (const key in selectedUser) {
            if (selectedUser.hasOwnProperty(key) && originalSelectedUser.hasOwnProperty(key)) {
                if (selectedUser[key] !== originalSelectedUser[key]) {
                    // Create the object for the changed field with old and new values
                    fieldChanged[key] = {
                        Old: originalSelectedUser[key],
                        New: selectedUser[key],
                    };
                }
            }
        }
        if (!fieldChanged.membershipUpdates) {
            fieldChanged.membershipUpdates = {};
        }
        if (!fieldChanged.classUpdates) {
            fieldChanged.classUpdates = {};
        }

        fieldChanged.ID = selectedUser.ID;
        fieldChanged.membershipUpdates.add = selectedMemberships.join(' | ');
        fieldChanged.classUpdates.add = selectedClasses.join(' | ');
        fieldChanged.membershipUpdates.remove = removedMemberships.join(' | ');
        fieldChanged.classUpdates.remove = removedClasses.join(' | ');

        await setFieldChangedDetails(fieldChanged);
        // console.log('items changed -- fieldChanged:', fieldChanged);
        setShowConfirmOverlay(true);
        setShowOverlay(false);
    }

    var triggerPasswordReset = async (user) => {

        setResetPassword(() => {
            return (
                <Spinner size="sm" animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            )
        });

        var errorOccured = false;
        var errMsg =""
        try {
            var resetPasswordEmailResponse = await triggerRequestPassword(selectedUser?.Email)

        } catch (err) {
            errorOccured = true
            errMsg = err
        }

        if(errorOccured === false){
            setResetPassword(() => {
                return (
                    <>
                        <IconContext.Provider value={{ color: "green", className: "global-class-name", size: "1.5em" }}>
                            <MdOutlineCheck /> {' '} <span style={{ color: "green", className: "global-class-name", size: "1.5em" }}> Password Email Sent!</span> <br />
                        </IconContext.Provider>
                    </>
    
                )
            });
        }

        if(errorOccured === true){
            setResetPassword(() => {
                return (
                    <>
                        <IconContext.Provider value={{ color: "green", className: "global-class-name", size: "1.5em" }}>
                            <MdOutlineCancel /> {' '} <span style={{ color: "red", className: "global-class-name", size: "1.5em" }}> Error Occured: {errMsg}</span> <br />
                        </IconContext.Provider>
                    </>
    
                )
            });
        }



        //<MdOutlineCheck />

    }

    var submitUpdate = async () => {

        setUpdateMsg(() => {
            return (
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            )
        });
        setShowConfirmOverlay(false);
        const updateMsg = await submitUserUpdateAdmin(fieldChangedDetails);
        if (updateMsg.error) {
            setUpdateMsg(updateMsg.msg);
        }
        if (!updateMsg.error) {
            setUpdateMsg(updateMsg.msg);
        }

        getUserList(searchValue);
        cleanUp();

    }

    var getUserList = async (searchValue) => {
        var blah = await getSearchUsers(searchValue);

        // console.log("blahblah" + startDate + endDate);
        if (blah != null || blah != undefined) {
            await setUserList(blah);
            return blah;
        }
    }

    var filterUserList = async () => {
        setUpdateMsg(() => {
            return (
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            )
        });
        var list = await getUserList(searchValue);
        var numPages = list.length / 10;
        await setNumberOfPages(Math.round(numPages));
        await currentTableData(list, 1);
        setUpdateMsg("");


    }
    const currentTableData = async (theOrder, currentPage) => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        setActiveArea(theOrder.slice(firstPageIndex, lastPageIndex));
    };
    var updatePage = async (num, orders) => {
        // console.log('updatePage');
        currentTableData(orders, num);
        var numPages = orders.length / 10;
        await setNumberOfPages(Math.round(numPages));
    }

    var cleanUp = () => {
        setSelectedUser({});
        setOriginalSelectedUser({});
        setSelectedMemberships([]);
        setRemovedClasses([]);
        setRemovedMemberships([]);
        setSelectedClasses([]);
        setSearchValue("");
        setActiveArea("Please Use Search")

        setShowConfirmOverlay(false);
        setShowOverlay(false);
    }

    var pageinationBar = () => {

        var html = [];

        for (var i = 1; i <= numberOfPages; i++) {
            html.push(<button class="page-link" onClick={event => updatePage(event.target.value, userList)} value={i}> {i} </button>);
        }

        return (
            <>
                {html}
            </>
        )
    }
    useEffect(() => {
        const checkForUserUpdates = async () => {
            const isUserUpdated =
                (selectedUser && originalSelectedUser) &&
                JSON.stringify(selectedUser) !== JSON.stringify(originalSelectedUser);

            if (isUserUpdated) {
                await setUpdateUser(true);
                // console.log('Update User???: true ', updateUser);
                // console.log('User has been updated:', selectedUser);
                // console.log('User has been updated -OLD:', originalSelectedUser);
            } else {
                await setUpdateUser(false);
                // console.log('Update User???: false', updateUser);
                // console.log('User Not changed:', originalSelectedUser);
            }
        };

        checkForUserUpdates();

        setResetPassword(() => {
            return (
                <>
                    <Button onClick={() => triggerPasswordReset(selectedUser)} variant="secondary" size="sm">
                        Trigger Reset Password Email
                    </Button>
                </>
            )
        })

    }, [selectedUser]);

    useEffect(() => {
        // console.log('Updated updateUser:', updateUser);
    }, [updateUser]);

    useEffect(async () => {
        const numMemberships = await getAllMemberships();
        const numclass = await getAllClasses()
        await setListOfClasses(numclass);
        await setListOfMemberships(numMemberships);

    }, []);

    var triggeroverLay = async (studentId) => {

        const foundObject = await activeArea.find((obj) => obj.ID === studentId);
        const ltiLogging = await getLTILogs(studentId);
        // debugger;
        setLTILogs(ltiLogging);
        setOriginalSelectedUser(foundObject);

        setSelectedUser(foundObject);
        setShowOverlay(true);
    }

    const [selectedMemberships, setSelectedMemberships] = useState([]);
    const handleMembershipChange = (event) => {
        const newMembership = event.target.value;

        // Check if the newMembership is not already in the selectedMemberships array
        if (!selectedMemberships.includes(newMembership)) {
            // Set the new state using the previous state to ensure we're not overwriting any other state updates
            setSelectedMemberships(prevState => [...prevState, newMembership]);
        }
    };

    const [removedMemberships, setRemovedMemberships] = useState([]);
    const handleMembershipRemove = (event) => {
        const newMembership = event;

        // Check if the newMembership is not already in the selectedMemberships array
        if (!selectedMemberships.includes(newMembership)) {
            // Set the new state using the previous state to ensure we're not overwriting any other state updates
            setRemovedMemberships(prevState => [...prevState, newMembership]);
        }
    };

    const [selectedClasses, setSelectedClasses] = useState([]);
    const handleClassChange = (event) => {
        const newMembership = event.target.value;

        // Check if the newMembership is not already in the selectedMemberships array
        if (!selectedMemberships.includes(newMembership)) {
            // Set the new state using the previous state to ensure we're not overwriting any other state updates
            setSelectedClasses(prevState => [...prevState, newMembership]);
        }
    };

    const [removedClasses, setRemovedClasses] = useState([]);
    const handleClassRemove = (event) => {
        const newMembership = event;

        // Check if the newMembership is not already in the selectedMemberships array
        if (!selectedMemberships.includes(newMembership)) {
            // Set the new state using the previous state to ensure we're not overwriting any other state updates
            setRemovedClasses(prevState => [...prevState, newMembership]);
        }
    };

    const lookupSchoolName = (id) => {
        for (let school of listOfClasses) {
            if (school.schoolId === id) {
                return school.schoolName;
            }
        }
        return null; // or return "Not found" if you prefer
    }

    return (
        <>
            <table>
                <td style={{ padding: "10px" }}>
                    <label for="orderID"> Search Value: </label>
                </td>
                <td style={{ padding: "5px" }}>
                    <input class="form-control form-control-sm" value={searchValue} onChange={event => setSearchValue(event.target.value)} type="text" autoComplete="text" name="orderID" id="orderID" />
                </td>
                <td style={{ padding: "5px" }}>
                    <Button onClick={() => filterUserList()}>Search</Button>
                </td>
            </table>
            <hr />
            {updateMsg}
            {/* {activeArea} */}
            <hr />
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th width="15%">Student ID</th>
                        <th width="10%">Name (role)</th>
                        <th width="25%">UserName</th>
                        <th >Email</th>

                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(activeArea) ? (
                        activeArea.map(student => (
                            <tr key={student.ID}>
                                {/* <td><Alert.Link onClick={() => triggeroverLay(student.ID)}>EDIT</Alert.Link></td> */}
                                <td>{student.ID}
                                    <br />
                                    <small>
                                        <Alert.Link onClick={() => triggeroverLay(student.ID)}>Open</Alert.Link>
                                    </small>
                                </td>
                                <td>
                                    {student.Name} <br />
                                    <small>({student.Role})</small>
                                </td>
                                <td>{student.UserName}</td>
                                <td>{student.Email}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7">Please Search for a user</td>
                        </tr>
                    )}
                </tbody>
            </Table>

            <Pagination>{pageinationBar()}</Pagination>

            <Modal
                show={showOverlay}
                onHide={() => setShowOverlay(false)}
                dialogClassName="modal-90w"
                size="lg"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        User Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td>User ID:</td>
                                    <td>{selectedUser?.ID}</td>
                                </tr>
                                <tr>
                                    <td>Name:</td>
                                    <td>
                                        <input
                                            type="text" autoComplete="text"
                                            style={styles.input}
                                            value={selectedUser?.Name}
                                            onChange={(e) =>
                                                setSelectedUser({ ...selectedUser, Name: e.target.value })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '8px', verticalAlign: 'middle' }}>Email:</td>
                                    <td>
                                        <input
                                            type="text" autoComplete="text"
                                            style={styles.input}
                                            value={selectedUser?.Email}
                                            onChange={(e) =>
                                                setSelectedUser({ ...selectedUser, Email: e.target.value })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Username:</td>
                                    <td>
                                        <input
                                            type="text" autoComplete="text"
                                            style={styles.input}
                                            value={selectedUser?.UserName}
                                            onChange={(e) =>
                                                setSelectedUser({ ...selectedUser, UserName: e.target.value })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Password:</td>
                                    <td>
                                        {/* <Button variant="secondary" size="sm">
                                            Trigger Reset Password Email
                                        </Button> */}
                                        {resetPassword}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Role:</td>
                                    <td>
                                        <select
                                            style={styles.input}
                                            value={selectedUser?.Role}
                                            onChange={(e) => setSelectedUser({ ...selectedUser, Role: e.target.value })}
                                        >
                                            <option value="teacher">Teacher</option>
                                            <option value="admin">Admin</option>
                                            <option value="Student">Student</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Membership:</td>
                                    <td>
                                        <Form.Select onChange={handleMembershipChange}>
                                            <option style={{ 'fontWeight': "400", color: "#212529" }} value="">

                                            </option>
                                            {Array.isArray(listOfMemberships) && listOfMemberships.length > 0 ?
                                                listOfMemberships.map(membership => (
                                                    <option key={membership.membershipId} value={membership.membershipKey}>
                                                        {membership.membershipName}
                                                    </option>
                                                ))
                                                :
                                                <option disabled>Error retrieving memberships</option>}
                                        </Form.Select>

                                        <div>
                                            <small>
                                                Current:
                                                <ul>
                                                    {Array.isArray(selectedUser?.Membership) && selectedUser?.Membership.length > 0 ? selectedUser?.Membership.map(existinMembership => (
                                                        <li>
                                                            <small><Button variant="link" onClick={() => handleMembershipRemove(existinMembership)} size="sm"><i class="fas fa-trash"></i></Button></small> {existinMembership}
                                                        </li>
                                                    )) :
                                                        <li disabled>Error retrieving membership</li>}
                                                </ul>
                                            </small>
                                        </div>

                                        <table width="100%">
                                            <tr>
                                                <td width="50%">
                                                    <div>
                                                        <small>
                                                            Add:
                                                            <ul>
                                                                {Array.isArray(selectedMemberships) && selectedMemberships.length > 0 ? selectedMemberships.map(newMembership => (
                                                                    <li>
                                                                        {newMembership}
                                                                    </li>
                                                                )) :
                                                                    ""}
                                                            </ul>
                                                        </small>
                                                    </div>
                                                </td>
                                                <td width="50%">
                                                    <div>
                                                        <small>
                                                            Remove:
                                                            <ul>
                                                                {Array.isArray(removedMemberships) && removedMemberships.length > 0 ? removedMemberships.map(removedMembership => (
                                                                    <li>
                                                                        {removedMembership}
                                                                    </li>
                                                                )) :
                                                                    ""}
                                                            </ul>
                                                        </small>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Class:</td>
                                    <td>
                                        <Form.Select onChange={handleClassChange}>
                                            <option style={{ 'fontWeight': "400", color: "#212529" }} value="">

                                            </option>
                                            {Array.isArray(listOfClasses) && listOfClasses.length > 0 ? listOfClasses.map(classNum => (
                                                <option key={classNum.schoolId} value={classNum.schoolId}>
                                                    {classNum.schoolName}
                                                </option>
                                            )) :
                                                <option disabled>Error retrieving classes</option>}
                                        </Form.Select>

                                        <div>
                                            <small>
                                                Current:
                                                <ul>
                                                    {Array.isArray(selectedUser?.class) && selectedUser?.class.length > 0 ? selectedUser?.class.map(existingClass => (
                                                        <li>
                                                            <small><Button variant="link" onClick={() => handleClassRemove(existingClass)} size="sm"><i class="fas fa-trash"></i></Button></small> {lookupSchoolName(existingClass)}
                                                        </li>
                                                    )) :
                                                        <li disabled>Error retrieving classes</li>}
                                                </ul>
                                            </small>
                                        </div>

                                        <table width="100%">
                                            <tr>
                                                <td width="50%">
                                                    <div>
                                                        <small>
                                                            Add:
                                                            <ul>
                                                                {Array.isArray(selectedClasses) && selectedClasses.length > 0 ? selectedClasses.map(newClass => (
                                                                    <li>
                                                                        {lookupSchoolName(newClass)}
                                                                    </li>
                                                                )) :
                                                                    ""}
                                                            </ul>
                                                        </small>
                                                    </div>
                                                </td>
                                                <td width="50%">
                                                    <div>
                                                        <small>
                                                            Remove:
                                                            <ul>
                                                                {Array.isArray(removedClasses) && removedClasses.length > 0 ? removedClasses.map(removedClass => (
                                                                    <li>
                                                                        {lookupSchoolName(removedClass)}
                                                                    </li>
                                                                )) :
                                                                    ""}
                                                            </ul>
                                                        </small>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>

                                    </td>

                                </tr>
                            </tbody>
                        </table>
                        <Modal.Footer>
                            <table>
                                <tr>
                                    <td>
                                        <Button onClick={() => processUpdate()}>Confirm Changes</Button>
                                    </td>
                                    <td>
                                        <Button variant="outline-primary" onClick={() => cleanUp()}>Cancel</Button>
                                    </td>
                                </tr>
                            </table>
                        </Modal.Footer>
                    </div>
                    <hr />

                    <div>
                        <h3>General Notes</h3>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>User Update Notes</Accordion.Header>
                                <Accordion.Body>
                                    <UserUpdateNotesComponent notes={selectedUser?.notes} />
                                </Accordion.Body>
                            </Accordion.Item>
                            {Array.isArray(ltiLogs) && ltiLogs.length > 0 &&
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>LTI Sync Notes</Accordion.Header>
                                    <Accordion.Body>
                                        <LTILogComponent ltiLogs={ltiLogs} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            }
                        </Accordion>
                    </div>


                    <hr />

                </Modal.Body>
            </Modal>

            <Modal
                show={showConfirmOverlay}
                onHide={() => setShowConfirmOverlay(false)}
                dialogClassName="modal-90w"
                size="lg"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Confirm Changes for {selectedUser?.Name || ""}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {fieldChangedDetails && Object.keys(fieldChangedDetails).length > 0 ? (
                        <table style={{ width: '100%' }}>
                            <thead>
                                <strong>Customer Information Changes</strong>
                                <tr style={{ borderBottom: "1px" }}>
                                    <td>Field</td>
                                    <td>Old Value</td>
                                    <td></td>
                                    <td>New Value</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.entries(fieldChangedDetails)
                                        .filter(([attribute]) => !excludedAttributes.includes(attribute))
                                        .length > 0 ? (
                                        Object.entries(fieldChangedDetails)
                                            .filter(([attribute]) => !excludedAttributes.includes(attribute))
                                            .map(([attribute, values]) => (
                                                <tr key={attribute}>
                                                    <td>{attribute}</td>
                                                    <td>{values.Old}</td>
                                                    <td style={{ padding: '8px', verticalAlign: 'middle', textAlign: 'center' }}>
                                                        <i className="fa fa-arrow-right" aria-hidden="true" />
                                                    </td>
                                                    <td>
                                                        {values.New}
                                                    </td>
                                                </tr>
                                            ))
                                    ) : (
                                        <tr><td colSpan="4" style={{ textAlign: "center" }}><strong>No Customer Changes found</strong></td></tr>
                                    )
                                }

                                <tr>
                                    <td colSpan={4}>
                                        <hr />
                                        <strong>Membership Changes</strong>
                                        <table width="100%">
                                            <tr>
                                                <td width="50%">
                                                    <div>
                                                        <small>
                                                            Add:
                                                            <ul>
                                                                {Array.isArray(selectedMemberships) && selectedMemberships.length > 0 ? selectedMemberships.map(newMembership => (
                                                                    <li>
                                                                        {newMembership}
                                                                    </li>
                                                                )) :
                                                                    "Nothing to Add"}
                                                            </ul>
                                                        </small>
                                                    </div>
                                                </td>
                                                <td width="50%">
                                                    <div>
                                                        <small>
                                                            Remove:
                                                            <ul>
                                                                {Array.isArray(removedMemberships) && removedMemberships.length > 0 ? removedMemberships.map(removedMembership => (
                                                                    <li>
                                                                        {removedMembership}
                                                                    </li>
                                                                )) :
                                                                    "Nothing to Remove"}
                                                            </ul>
                                                        </small>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4}>
                                        <hr />
                                        <strong>Class Changes</strong>
                                        <table width="100%">
                                            <tr>
                                                <td width="50%">
                                                    <div>
                                                        <small>
                                                            Add:
                                                            <ul>
                                                                {Array.isArray(selectedClasses) && selectedClasses.length > 0 ? selectedClasses.map(newClasses => (
                                                                    <li>
                                                                        {lookupSchoolName(newClasses)}
                                                                    </li>
                                                                )) :
                                                                    "Nothing to Add"}
                                                            </ul>
                                                        </small>
                                                    </div>
                                                </td>
                                                <td width="50%">
                                                    <div>
                                                        <small>
                                                            Remove:
                                                            <ul>
                                                                {Array.isArray(removedClasses) && removedClasses.length > 0 ? removedClasses.map(removedClasses => (
                                                                    <li>
                                                                        {lookupSchoolName(removedClasses)}
                                                                    </li>
                                                                )) :
                                                                    "Nothing to Remove"}
                                                            </ul>
                                                        </small>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    ) : (
                        <p>No changes found.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <table>
                        <tr>
                            <td>
                                <Button onClick={() => submitUpdate()}>Confirm Changes</Button>
                            </td>
                            <td>
                                <Button variant="outline-primary" onClick={() => cleanUp()}>Cancel</Button>
                            </td>
                        </tr>
                    </table>
                </Modal.Footer>

            </Modal>
        </>
    )

}

export default AdminUserSearchComponent;
