import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import CustomerDetailsComponent from '../checkout/customerDetailsComponent';
import CheckoutComponent from '../checkout/checkoutComponent';
import CreditCardDetailsComponent from '../checkout/creditCardDetailsComponent';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import React, { useState, useEffect, useRef } from "react";
import Cookies from 'universal-cookie';
// import {addTax} from '../services/bffServices/updateShoppingCart';
import {addTax} from '../../services/bffServices/updateShoppingCart';
import {getShoppingCartDetails} from '../../services/bffServices/getShoppingCart';
import {updateSCCustomerInfo} from '../../services/bffServices/updateSCCustomer';
import {submitPO} from '../../services/bffServices/submitOrder';




const cookies = new Cookies();
var updateCS = false;

export function CustomToggle({ children, eventKey, payload, originator }) {

    var userInfoNew = {};
    // console.log('fnam....-->' + payload.fName);

    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState({});
    const [userName, setUserName] = useState({});
    const [password, setPassword] = useState({});
    const [email, setEmail] = useState({});

    const [userInfo, setuserInfo] = useState({});




const scURL = process.env.REACT_APP_BFF + '/getShoppingCart/' + cookies.get('sb-sc');
const optionsPOST = { 
    method: 'GET', 
    headers: new Headers({ 
      'Content-Type': 'application/json',
      'sb-application-name': 'sb-site-ui',
      'sb-application-key': 'test123',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
    }), 
    mode: 'cors',
    credentials: 'omit'
  };

    const decoratedOnClick = useAccordionButton(eventKey, async () => {
    

        if(originator == "userInfo"){
            // console.log('totally custom -- from UserInfo' + userInfo.email);
            await applyTax(cookies.get('sb-sc'),payload.province, payload.postalCode);
            // console.log('test----sent paylost---' + JSON.stringify(payload));

            await setuserInfo(JSON.stringify(payload))

            setFirstName(payload.fName);
            // console.log('firstName' + JSON.stringify(firstName));

            // console.log('test..full info object--->' + JSON.stringify(userInfo));
            // console.log('test----sent paylost---' + JSON.stringify(payload));

            localStorage.setItem("userInfo", JSON.stringify(payload));

            // userInfoNew = {
            //     "shoppingCartId" : await cookies.get('sb-sc'),
            //     "fName": payload.fName,
            //     "lName" : payload.lName,
            //     "userName" : payload.userName,
            //     "password" : payload.password,
            //     "email" : payload.email
            // }

            userInfoNew = {
                "shoppingCartId" : await cookies.get('sb-sc'),
                payload
            }

            // window.location.reload(false)
            // var scDataGrab = await grabSCData(cookies.get('sb-sc'));
            // updateCS = scDataGrab;
            //add user info to shopping cart!
        }

        if(originator == "purchaseInfo"){
            // console.log('totally custom -- from purchaseINfo');
            localStorage.setItem("purchaseInfo", JSON.stringify(payload));

            //next should do nothing on this one.  just a reveiw page
        }

        if(originator == "submitOrder"){
            // console.log('totally custom -- from submitOrder');
            // console.log('payload --> ' + JSON.stringify(payload));
            var userInfoFromStorage = await localStorage.getItem("userInfo");


            // console.log("submitPO started" + userInfoFromStorage);
            const payload = {
                "shoppingCartId" : cookies.get('sb-sc'),
                "fName": userInfoFromStorage.fName,
                "lName" : userInfoFromStorage.lName,
                "userName" : userInfoFromStorage.userName,
                "password" : userInfoFromStorage.password,
                "email" : userInfoFromStorage.email
            }
    
            await updateSCCustomerInfo(userInfoFromStorage);
            const results = await submitPO(userInfoFromStorage);
            // console.log("google;;;;--->" + JSON.stringify(results));
            cookies.remove('sb-sc');  //SC-35327796
            cookies.set('sc-orderId', results.id, { path: '/' });
    
            window.location.href = "/thankyou";

            //this shoudl be all the submit info :D 
        }

    },
    );

    // <button style={{padding: '5px, 0px 0px 0px'}} type="submit" onClick={()=> clicked()} class="btn btn-primary" >Next</button>
  
    return (
      <button
        type="button"
        style={{padding: '5px, 0px 0px 0px'}}
        onClick={decoratedOnClick}
        class="btn btn-primary"
      >
        {children}
      </button>
    );
  }

async function applyTax(scId, province, pc){
    // updateCustomerInfo();
    //Logic to get the % for taxes

    const payload = {
        "province" : province,
        "scId" : scId,
        "tax" : {
            "hst": .013
        }
    }

    // console.log('adding tax---' + payload)
    await addTax(payload);
    // setSCData();

}

export default function CheckoutMainComponent(){
    // const [show, setShow] = useState(false);
    const scID = cookies.get('sb-sc');

    const [shoppingCartData, setShoppingCartData] = useState();
    const [cartItems, setCartItems] = useState();

const setSCData = async (scid) => {

    const scInfo = await getShoppingCartDetails(scid); 
    setShoppingCartData(scInfo);
}

// useEffect(() => {
//     setSCData(scID);
//   }, []);
    
    return (
        <>
        <br />
        {/* <h2 align="left" style={{padding: '10px 20px 10px 10px'}}>Checkout</h2> */}
        <Container style={{padding: "30px 10px 0px 10px"}} fluid="sm">
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>User Information</Accordion.Header>
                    <Accordion.Body>
                        <CustomerDetailsComponent/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Purchase Breakdown</Accordion.Header>
                    <Accordion.Body>
                        <CheckoutComponent shoppingCartID={{scID}} updateCS={{updateCS}}/><br /><br /><br /><br /><br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Payment & Submit Order</Accordion.Header>
                    <Accordion.Body>
                      <CreditCardDetailsComponent />
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion>
                </Container>
        </>
    );
}

async function grabSCData (scid){

    const scInfo = await getShoppingCartDetails(scid); 
    // setShoppingCartData(scInfo);
    return scInfo;
}

