import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
	Player,
	ControlBar,
	ReplayControl,
	ForwardControl,
	CurrentTimeDisplay,
	TimeDivider,
	PlaybackRateMenuButton,
	VolumeMenuButton,
	BigPlayButton,
} from "video-react";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ChapterList from "../textbook/chapterList";
import { updatePageTitle } from "../../utls/lib";
import UNIT1 from "../../assets/images/classroom/Smart-Biology-CELLS-U1-Cover-Image.jpg";
import UNIT2 from "../../assets/images/classroom/Smart-Biology-CELLS-U2-Cover-Image.jpg";

function CellsSeriesComponent() {
	const [currentPage, setCurrentPage] = useState(0);

	const UnitCard = ({
		index,
		backgroundColor,
		title,
		id,
		description,
		imageURL,
		videoPosterURL,
		videoURL,
		chapterList,
	}) => {
		return (
			<div
				id={id}
				style={{
					"padding-bottom": "10px",
				}}
			>
				<Container>
					<Row
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "start",
						}}
					>
						<Col>
							<div>
								<br />
								<h6 style={{ "text-align": "left", "font-weight": "300" }}>
									Table of Contents
								</h6>
							</div>
							{chapterList}
						</Col>
					</Row>
				</Container>
			</div>
		);
	};

	const units = [
		<UnitCard
			index={0}
			backgroundColor="none"
			id="Unit 1"
			title="First Semester | Unit 1 | From Atoms to Cells"
			description="Students embark on one continuous journey that allows them to understand and appreciate the interconnections between the subatomic, atomic, molecular, macromolecular, and cellular worlds. We explain the underlying “why” questions so they can genuinely understand rather than memorize discrete facts."
			videoURL="https://smart-biology-molecular-dna-replication.s3-us-west-2.amazonaws.com/Life/Unit-1/Unit-1-Demo-Video-1-Bit-24.mp4?_=1"
			imageURL={UNIT1}
			videoPosterURL="https://storage.googleapis.com/sb-general/wp-content/uploads/2019/02/Life-U1-C5-TCW-2-MachinesDoWork-8-Golgi.jpg"
			chapterList=<ChapterList
				id="637c1daf943bcf4b2d1e8d01"
				module="0"
				publicSite={true}
			/>
		/>,

		<UnitCard
			index={1}
			backgroundColor="#E5E4E2"
			id="Unit 2"
			title="First Semester | Unit 2 | The Flow of Energy | Photosynthesis and Cellular Respiration"
			description="Students embark on one continuous journey that allows them to understand and appreciate the interconnections between the subatomic, atomic, molecular, macromolecular, and cellular worlds. We explain the underlying “why” questions so they can genuinely understand rather than memorize discrete facts."
			videoURL="https://smart-biology-molecular-dna-replication.s3-us-west-2.amazonaws.com/Life/Unit-2/Life-U2-Demo-Video-1-Bit-24.mp4?_=2"
			imageURL={UNIT2}
			videoPosterURL="https://storage.googleapis.com/sb-general/wp-content/uploads/2019/02/Life-U2-C8-PMG-2-ChloroSunlightHarvest-3-PhotosystemsGreatMachines.jpg"
			chapterList=<ChapterList
				id="63a9de566d27a7002f0749a1"
				module="0"
				publicSite={true}
			/>
		/>,
	];

	return (
		<div width="100%" style={{}}>
			<div style={{ "margin-top": "15px" }}>
				<Row>
					<Col md={10} sm={10} lg={10} xl={10} xxl={10}>
						<div>
							<br />
							<h2 style={{ fontSize: "42px" }}>
								Upper Year Animated Textbook Series | Cells
							</h2>
						</div>
					</Col>
				</Row>

				<Row>
					<Col>
						<hr />
					</Col>
				</Row>

				<Row
					style={{
						padding: "10px",
						width: "80vw",
						margin: "2px",
						borderRadius: "10px",
					}}
				>
					<Col style={{ textAlign: "center", color: "black" }}>
						<div
							className="grid-container-double"
							style={{ justifyContent: "center", alignContent: "center" }}
						>
							<a onClick={() => setCurrentPage(0)}>
								{" "}
								<img
									className={`product-image${
										currentPage === 0 ? "-selected" : ""
									}`}
									src={UNIT1}
								></img>
							</a>
							<a onClick={() => setCurrentPage(1)}>
								<img
									className={`product-image${
										currentPage === 1 ? "-selected" : ""
									}`}
									src={UNIT2}
								></img>
							</a>
						</div>
					</Col>
				</Row>
			</div>

			{units[currentPage]}
		</div>
	);
}

export default CellsSeriesComponent;
