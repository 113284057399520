import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Spinner';
import { getAllLTIConsumersService } from '../../../services/getAllLTIConsumers';



function AllConsumerComponent() {
    const [consumerList, setConsumerlist] = useState(null);

    useEffect(async () => {

        try {
            if (consumerList === null) {
                var consumers = await getAllLTIConsumersService();
                setConsumerlist(consumers)
            }
        } catch (err) {

        }



    }, [consumerList]);

    return (
        <>
            LTI CONSUMER LIST
            <hr width="50%" style={{ margin: "auto" }} /> <br />

            <table style={{ width: "50%", margin: "auto", boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)', borderRadius: '5px', overflow: 'hidden' }}>
                <thead style={{ backgroundColor: '#007BFF', color: '#fff', textTransform: 'uppercase', fontWeight: 'bold' }}>
                    <tr>
                        <th style={{ "padding-left": "40px", "text-align": "left", "padding-right": "62px", fontSize: '14px' }}>
                            Consumer Name
                        </th>
                        <th style={{ "padding-left": "40px", "text-align": "left", "padding-right": "62px", fontSize: '14px' }}>
                            Key
                        </th>
                        <th style={{ "padding-left": "40px", "text-align": "left", "padding-right": "62px", fontSize: '14px' }}>
                            Secret
                        </th>
                        <th style={{ "padding-left": "40px", "text-align": "left", "padding-right": "62px", fontSize: '14px' }}>
                            LTI Type
                        </th>
                        {/* <th style={{ "padding-left": "40px", "text-align": "left", "padding-right": "62px", fontSize: '14px' }}>
                            Connection Details
                        </th> */}
                    </tr>
                </thead>
                <tbody>

                    {Array.isArray(consumerList) && consumerList.length > 0 ? consumerList.map(consumer => (
                        <>
                            {/* {consumer.key && */}
                                <tr>
                                    <td style={{ "padding-bottom": "10px", "padding-top": "10px", "padding-left": "40px", "text-align": "left", "padding-right": "62px", fontSize: '12px' }}>
                                        {consumer.name}
                                    </td>
                                    <td style={{ "padding-left": "40px", "text-align": "left", "padding-right": "62px", fontSize: '12px' }}>
                                        {consumer.key}
                                    </td>
                                    <td style={{ "padding-left": "40px", "text-align": "left", "padding-right": "62px", fontSize: '12px' }}>
                                        {consumer.secret}
                                    </td>
                                    <td style={{ "padding-left": "40px", "text-align": "left", "padding-right": "62px", fontSize: '12px' }}>
                                        {consumer.type}
                                    </td>
                                    {/* <td style={{ "padding-left": "40px", "text-align": "left", "padding-right": "62px", fontSize: '12px'  }}>
                                                   TBD
                                               </td> */}
                                </tr>
                            {/* } */}
                        </>

                    )) :
                        <option disabled>Error retrieving LTI Consumers</option>}
                </tbody>
            </table>

        </>
    )

}

export default AllConsumerComponent;
