import React, { useState } from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

function CreditCardForm() {
  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [focus, setFocus] = useState("");

  const handleNumberChange = (e) => {
    setNumber(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleExpiryChange = (e) => {
    setExpiry(e.target.value);
  };

  const handleCvcChange = (e) => {
    setCvc(e.target.value);
  };

  const handleFocusChange = (e) => {
    setFocus(e.target.name);
  };

  return (
    <div className="credit-card-form">
        <table>
            <tr>
                <td>
                <Cards
        number={number}
        name={name}
        expiry={expiry}
        cvc={cvc}
        focused={focus}
      />
                </td>
                <td>
                <form>
        <div style={{'padding-bottom':"10px"}} className="form-group">
          {/* <label htmlFor="number">Card Number</label> */}
          <input
            type="tel"
            name="number"
            className="form-control"
            placeholder="1234 5678 9012 4567"
            value={number}
            onChange={handleNumberChange}
            onFocus={handleFocusChange}
          />
        </div>
        <div style={{'padding-bottom':"10px"}} className="form-group">
          {/* <label htmlFor="name">Cardholder Name</label> */}
          <input
            type="text" autoComplete="text"
            name="name"
            className="form-control"
            placeholder="Full Name"
            value={name}
            onChange={handleNameChange}
            onFocus={handleFocusChange}
          />
        </div>
        <div className="row">
          <div className="col-6">
            <div style={{'padding-bottom':"10px"}} className="form-group">
              {/* <label htmlFor="expiry">Expiry Date</label> */}
              <input
                type="tel"
                name="expiry"
                className="form-control"
                placeholder="MM/YY"
                value={expiry}
                onChange={handleExpiryChange}
                onFocus={handleFocusChange}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              {/* <label htmlFor="cvc">CVC</label> */}
              <input
                type="tel"
                name="cvc"
                className="form-control"
                placeholder="CVC"
                value={cvc}
                onChange={handleCvcChange}
                onFocus={handleFocusChange}
              />
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
                </td>
            </tr>
        </table>

      
    </div>
  );
}

export default CreditCardForm;



// import {CustomToggle} from "./checkoutMainComponent";
// import React, { useState, useEffect, useRef } from "react";


// export default function CreditCardDetailsComponent(){

//     const [ccNum, setccNum] = useState({});
//     const [expiry, setExpiry] = useState({});
//     const [cvv, setCVV] = useState({});


//     return(
//         <>
//         <table>
//             <tr>
//                 <td width="60%">
//                     <table width="80%">
//                             <tr>
//                                 <td>
//                                     <label>Credit Card Numbersssss</label>
//                                 </td>
//                                 <td>
//                                     <input onChange={event => setccNum(event.target.value)} class="form-control form-control-sm" type="text" autoComplete="text" name ="fname" id="fname" placeholder="5424000000000015" />
//                                 </td>
//                             </tr>
//                             <tr>
//                                 <td>
//                                     <label>Expiry</label>
//                                 </td>
//                                 <td>
//                                     <input onChange={event => setExpiry(event.target.value)} class="form-control form-control-sm" type="text" autoComplete="text" name ="lname" id="lname" placeholder="0822" />
//                                 </td>
//                             </tr>
//                             <tr>
//                                 <td>
//                                     <label>CVV</label>
//                                 </td>
//                                 <td>
//                                     <input onChange={event => setCVV(event.target.value)} class="form-control form-control-sm" type="text" autoComplete="text" name ="lname" id="lname" placeholder="999" />
//                                 </td>
//                             </tr>
//                         </table>
//                 </td>
//                 <td width="25%">
//                     <div align="center">
//                         This is text that we need to tell the customer <br /><br />
//                     </div>
//                     <div align="center">
//                     <CustomToggle originator = "submitOrder" payload={{
//                         "cc": ccNum,
//                         "expiry": expiry,
//                         "cvv" : cvv
//                     }}>
//                         Submit Order
//                         </CustomToggle>
//                     </div>
//                 </td>
//             </tr>
//         </table>
                    
//         </>
//     )
// }
