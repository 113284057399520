import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { updatePageTitle } from "../../utls/lib";
import CellsSeriesComponent from "./cellsInfoPage";
import LifeSeriesComponent from "./lifeInfoPage";
import FeatureImage from "../../assets/images/Features-graphic.png";
import SleekButton from "../sleekButton";
import { useLocation, Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import TestimonialCarousel from "./TestimonialCarousel";
import HighSchoolComponent from "./highschoolInfoPage";
function HighEdPageComponent(props) {
  useEffect(async () => {
    updatePageTitle("Subjects");
  }, []);

  return (
    <div width="100%" style={{}}>
      <div
        style={{
          paddingBottom: "10px",
          margin: "0 auto", // Center horizontally
          textAlign: "left",
          fontSize: "14px",
        }}
      >
        <div style={{}}>
          <Tabs />
        </div>
      </div>
    </div>
  );
}

function Tabs() {
  const tabs = [
    {
      title: "First Year | LIFE",
      url: "first-year-biology",
      content: <LifeSeriesComponent />,
    },
    {
      title: "Second Year | CELLS",
      url: "upper-year-biology",
      content: <CellsSeriesComponent />,
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].url);

  console.log(activeTab);

  useEffect(() => {}, [activeTab]);

  return (
    <div className="sub-tabs-container">
      <div className="tab-sub-buttons">
        <ul className="breadcrumb">
          {tabs.map((tab, index) => (
            <li>
              <button
                key={index}
                className={activeTab === tab.url ? "active" : ""}
                onClick={() => {
                  setActiveTab(tab.url);
                }}
              >
                {tab.title}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="tab-content" style={{ margin: "0px" }}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab-pane ${activeTab === tab.url ? "active" : ""}`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
}

export default HighEdPageComponent;
