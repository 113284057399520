import Table from 'react-bootstrap/Table'

function UserUpdateNotesComponent(props) {

    const logPaylod = props.notes;
    
    return (
        <>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                <h5>Notes:</h5>
                <small>
                    <Table striped bordered hover>
                        <thead>
                            <tr align="center">
                                <th width="15%">
                                    Date
                                </th>
                                <th width="15%">
                                    Type
                                </th>
                                <th width="15%">
                                    User
                                </th>
                                <th width="45%">
                                    Log
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(logPaylod) && logPaylod.length > 0 ? logPaylod.map(note => (
                                <tr>
                                    <td>
                                        {note.date}
                                    </td>
                                    <td>
                                        {note.type}
                                    </td>
                                    <td>
                                        {note.user}
                                    </td>
                                    <td>
                                        {Array.isArray(note?.logmsgs) && note.logmsgs.length > 0 ? note.logmsgs.map(log => (
                                            <>
                                                <span>{log}</span><br />
                                            </>
                                        )) : <p>blah</p>}
                                        { }
                                    </td>
                                </tr>

                            )) :
                                <tr><td colspan="3" align="center">No Notes on Profile</td></tr>}
                        </tbody>
                    </Table>
                </small>
            </div>
        </>
    )

}

export default UserUpdateNotesComponent;
