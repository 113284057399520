import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton,
    BigPlayButton
} from 'video-react';
import { getUnitByID } from '../../services/bffServices/textbookBFFServices/getUnitDetails';
import React, { useState, useEffect } from "react";
import ExtraContentMainComponent from './extraContentBlock/extraContentComponent';
import { updateUserProgress } from '../../services/bffServices/textbookBFFServices/updateUserProgress';
import SideNavBar from '../sideNavBar';

const url = require('url');

function TextMainPage() {
    const [unitId, setUnitId] = useState();
    const [unitInfo, setUnitInfo] = useState();
    const [coverImage, setCoverImage] = useState();
    const [content_en, setcontent_en] = useState();
    const [sampleVideoDesc, setSampleVideoDesc] = useState();
    const [sampleVideo, setSampleVideo] = useState();
    const [sampleVideoImg, setSampleVideoImg] = useState();
    const [quiz, setQuiz] = useState(false);
    const [downloadContent, setDownloadContent] = useState({
        "downloadContentsummary": "0",
        "downloadContent": "0"
    });
    const [downloadSection, setDownloadSection] = useState();
    const [downloadList2, setDownloadList2] = useState([]);
    const [additionalInfo, setAdditionalInfo] = useState();
    const [extraContentRender, setExtraContentRender] = useState();

    const unitDetails = async () => {

        const id = sessionStorage.getItem("activeUnit");
        const domainURL = "https://storage.googleapis.com/sb-general" //https://storage.googleapis.com/sb-general/
        setUnitId(id);

        const blah = await getUnitByID(id)
            .then(async (payload) => {
                var downloadSectionTemp = await downloadList(payload?.downloads)

                setDownloadSection(downloadSectionTemp);
                setcontent_en(payload.content_en); //unitCoverImage_en
                setSampleVideoDesc(payload?.sampleVideoDescription_en)
                setSampleVideo(payload.sampleVideo_en || "NONE");
                setSampleVideoImg(() => {
                    if (payload.sampleVideoPlaceholderImage_en != null || payload.sampleVideoPlaceholderImage_en != undefined) {
                        var parsedVideoURL = url.parse(payload.sampleVideoPlaceholderImage_en);
                        var updatedVideoURL = domainURL + parsedVideoURL.pathname;

                        return updatedVideoURL;
                    } else {
                        return "";
                    }

                });

                await setDownloadContent({
                    "downloadContentsummary": payload.downloadContentSummary_en,
                    "downloadContent": payload.downloads
                })

                setDownloadList2(payload.downloads);


                var parsedURL = url.parse(payload.unitCoverImage_en);
                var updatedURL = domainURL + parsedURL.pathname;
                setCoverImage(updatedURL);

                if (payload.additionaInfo_en != null || payload.additionaInfo_en != undefined) {

                    var processed = payload.additionaInfo_en.substring(
                        payload.additionaInfo_en.indexOf("**") + 2,
                        payload.additionaInfo_en.lastIndexOf("**")
                    );

                    var additionalInfo = () => {
                        const searchString = payload.additionaInfo_en;
                        const searchRegex = /<p>.*?<\/p>/;
 
                        const urlRegex = /<img src="(.*?)" title=".*?" width=".*?"\/>/;
                        const searchResult = searchString.match(urlRegex);
                        if (searchResult != null) {
                            var updatedString = searchString.replace(searchRegex, '');
                            const imageUrl = searchResult[1];
                            var updatedStringURLSrc = "";
                            if(imageUrl.includes("https://www.smart-biology.com"))
                            {
                                const updatedImageUrl = imageUrl.replace('https://www.smart-biology.com/wp-content/uploads', 'https://storage.googleapis.com/sb-general/wp-content/uploads');
                                updatedStringURLSrc = searchResult[0].replace(imageUrl, updatedImageUrl);
                            }
  
                            return (
                                <>
                                    <ReactMarkdown rehypePlugins={[rehypeRaw]} >
                                        {updatedString}

                                    </ReactMarkdown>
                                    <br />
                                    <div dangerouslySetInnerHTML={{ __html: updatedStringURLSrc }} />
                                    <div align="center" style={{ padding: "20px" }}>
                                    </div>
                                </>
                            )
                        }

                    }
                }

                try {
                    setAdditionalInfo(additionalInfo);

                } catch (err) {
                    // console.log("ln 142 -- getUnitByID: " + err);
                }

                var extraContent = () => {
                    var extraContentUI;
                    var parsedPathURL = ""
                    var parsedExtraContnetURL = "";
                    var updatedURL = "";

                    extraContentUI = payload.extraContent.map(function (ec) {
                        parsedPathURL = url.parse(ec.srcURL);
                        parsedExtraContnetURL = domainURL + parsedPathURL.pathname;
                        if (ec.srcType == "interactive") {
                            parsedExtraContnetURL = ec.srcURL;
                        }
                        return (
                            <>
                                <Col>
                                    <ExtraContentMainComponent title={ec.title_en} summary={ec.summary_en} src={parsedExtraContnetURL} srcType={ec.srcType} />
                                </Col>
                            </>
                        )
                    })

                    return extraContentUI;
                }

                setExtraContentRender(extraContent);
                updateUserProgress("UNIT", id, "IN-PROGRESS")
            });

    }

    var downloadList = async (downloadContentJSON) => {

        var uiGenerationDL;
        uiGenerationDL = downloadContentJSON.map(function (dl) {
            var parsedDownLoadPathURL = url.parse(dl?.contentLink_en);
            var parsedDownLoadURL = "https://storage.googleapis.com/sb-general" + parsedDownLoadPathURL.pathname;
            return (
                <>
                    <Col>
                        {dl?.title_en}<br />
                        <Button href={parsedDownLoadURL} target="_blank">{dl?.buttonText}</Button><br />
                    </Col>
                </>
            )
        })

        return uiGenerationDL;
    }
    useEffect(async () => {
        await unitDetails();
    }, []);


    return (
        <>
            <table width="100%" style={{ margin: "10px", 'margin-top': "30px" }}>
                <tr>
                    <td align="left" style={{ verticalAlign: "top" }}>
                        <div>
                            <SideNavBar unitId={sessionStorage.getItem("activeUnit")} />
                        </div>

                    </td>
                    <td>
                        <div>
                            <link rel="stylesheet" href="/css/video-react.css" />
                            {unitInfo}

                            <Container>
                                <Row>
                                    <Col sm={3}><img loading="lazy" title="Life | Unit 1 | From Atoms to Cells"
                                        src={coverImage}
                                        width="100%"></img>
                                    </Col>
                                    <Col sm={8} align="justify">
                                        <h3>About This Unit</h3><br />
                                        <h4>Summary</h4>
                                        <ReactMarkdown rehypePlugins={[rehypeRaw]} >{content_en}</ReactMarkdown>

                                        {sampleVideo != "NONE" &&
                                            <>
                                                <h4>Sample Video</h4> <br />

                                                <Player
                                                    playsInline
                                                    poster={sampleVideoImg}
                                                    src={sampleVideo}
                                                >
                                                    <BigPlayButton position="center" />
                                                    <ControlBar>
                                                        <ReplayControl seconds={10} order={1.1} />
                                                        <ForwardControl seconds={30} order={1.2} />
                                                        <CurrentTimeDisplay order={4.1} />
                                                        <TimeDivider order={4.2} />
                                                        <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                                                        <VolumeMenuButton disabled />
                                                    </ControlBar>
                                                </Player>
                                            </>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col align="left" sm={11}><br />
                                        {additionalInfo}
                                        <br />
                                    </Col>
                                </Row>
                                <Row style={{ 'padding-right': "50px" }}>
                                    {extraContentRender}
                                </Row>
                                <Row>
                                    <Col align="left" sm={11}>
                                        <br /><br /><ReactMarkdown rehypePlugins={[rehypeRaw]} >{downloadContent?.downloadContentsummary}</ReactMarkdown><br />
                                    </Col>
                                </Row>
                                <Row style={{ 'padding-right': "50px" }}>
                                    {downloadSection}
                                </Row><br />
                            </Container>
                        </div>
                    </td>
                </tr>
            </table>
        </>
    );
}

export default TextMainPage;
