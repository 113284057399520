import { Container } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CardGroup from 'react-bootstrap/CardGroup';
import Button from 'react-bootstrap/Button';
import { useEffect } from 'react';

export default function LandingPageComponent(props) {

  useEffect(() => {
    window.location.replace('https://smart-biology-academy.getlearnworlds.com/pages/home?site_template_id=63eda51a5734bfed94038c05');
  }, []);

  return (
    <div style={{ padding: "50px", backgroundColor: "#f8f9fa", height: "80vh" }}>


    </div>
  )
}
