import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { getQuizByID } from '../../services/bffServices/textbookBFFServices/getQuizDetails';
import { updateQuizEntries } from '../../services/bffServices/textbookBFFServices/updateUserProgress';
import { getUserProgressSummary } from '../../services/bffServices/getUserDetails';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import { MdOutlineCancel, MdOutlineCheck } from "react-icons/md";
import { IconContext } from "react-icons";
import { getQuizGradeV2 } from '../../services/getQuizGrade';
import { getQuizAttempts } from '../../services/quiz/quizAttempts';
import { syncGrades } from '../../services/quiz/ltiSync';
import { getExistingSession } from '../../services/bffServices/getSessionInfo';
import Spinner from 'react-bootstrap/Spinner';

export default function QuisPOC(props) {

	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [multiSessionUI, setMultiSessionUI] = useState(false);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);
	const [answeredQ, setAnsweredQ] = useState("");
	const [grade, setGrade] = useState(0);
	const [quizAttempts, setQuizAttempts] = useState();
	const [quizAttemptsTaken, setQuizAttemptsTaken] = useState();
	const [quizAttemptsRemaining, setQuizAttemptsRemaining] = useState();
	const [submitButton, setSubmitButton] = useState(() => {

		if (sessionStorage.getItem('lang') == "FR") {
			return "Nous faire parvenir"
		}
		if (sessionStorage.getItem('lang') != "FR") {
			return "Submit"
		}

	});

	const handleAnswerOptionClick = async (isCorrect, submittedAnswer) => {

		if (isCorrect) {
			setScore(score + 1);
			answeredQ[currentQuestion].grade = "Correct";
		} else {
			answeredQ[currentQuestion].grade = "Incorrect";
		}

		answeredQ[currentQuestion].answerSubmitted = submittedAnswer;

		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < answeredQ.length) {
			setAnsweredQ(answeredQ);
			setCurrentQuestion(nextQuestion);
		} else {
			setShowScore(true);
			var gradePercentage = ((score + 1) / answeredQ.length) * 100;
			setGrade(await gradePercentage.toFixed(0));
		}
	};

	const initialLoad = async () => {

		await setCurrentQuestion(0);
		await setShowScore(false);
		await setScore(0)
	}

	const shuffleArray = async (array) => {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			const temp = array[i];
			array[i] = array[j];
			array[j] = temp;
		}

		return array;
	}


	const shuffleAnswers = async (array) => {

		for (let i = 0; i < array.length; i++) {
			array[i].answerOptions = await shuffleArray(array[i].answerOptions);
		}

		return array;
	}

	useEffect(async () => {

		const userSession = await getExistingSession();
		var multiSesson = userSession.payload.multiSession || false;

		var attemptsTaken = await getQuizGradeV2(props.quizId)
			.then(async (payload) => {
				await setQuizAttemptsTaken(payload.payload?.attemptNum || 0);
				var quizAttempts = await getQuizAttempts(payload.payload.classid);
				await setQuizAttempts(quizAttempts)

				var newpayload = {
					"completed": payload.payload?.attemptNum || 0,
					"limit": quizAttempts
				}
				return newpayload
			})
			.then(async (payload) => {
				if (payload.limit == 100) {
					await setQuizAttemptsRemaining(101);

				}
				if (payload.limit != 100) {
					await setQuizAttemptsRemaining(payload.limit - payload.completed);

				}

			})

		var quizList = await getQuizByID(props?.quizId)
			.then(payload => shuffleArray(payload))
			.then(payload => shuffleAnswers(payload));
		await initialLoad();
		setAnsweredQ(quizList);
		setMultiSessionUI(multiSesson);
	}, [multiSessionUI])

	const submitAnswers = async (answerSubmitted) => {
		props.updateQuizUI(grade);
		setSubmitButton(() => {
			return (
				<>
					<Button onClick={() => submitAnswers(answeredQ)} type="button" disabled><Spinner animation="border" size="sm" /></Button>
				</>
			)
		});

		var gradeDetails = {
			"grade": parseFloat(grade),
			score,
			"total": answerSubmitted.length,
			"attemptNum": quizAttemptsTaken + 1
		}
		await updateQuizEntries(props.unitId, props.moduleId, props.lessonId, props.quizId, answerSubmitted, gradeDetails);

		getUserProgressSummary();
		const ltiIndicator = sessionStorage.getItem("isLTI") || "N";

		if (ltiIndicator == "Y") {
			syncGrades();
		}
	}

	return (
		<div className='QuisPOC'>
			{showScore ? (
				<>
					{multiSessionUI !== true &&
						<Alert key="warning" variant="warning">
							<strong>IMPORTANT:</strong>Your quiz has not been saved, please press submit to record your grade
						</Alert>
					}
					<div className='score-section'>
						{sessionStorage.getItem('lang') == "FR" &&
							<>
								Vous avez obtenu {score} sur {answeredQ.length} <br />{grade}%
							</>
						}
						{sessionStorage.getItem('lang') != "FR" &&
							<>
								You scored {score} out of {answeredQ.length} <br />{grade}%
							</>
						}
						<br /><div>
							{quizAttemptsRemaining == 0 &&
								<>
									<Button onClick={() => submitAnswers(answeredQ)} type="button" disabled>Submit</Button>{' '}<br /><br />

									<Alert variant="danger">
										You are unable to take this quiz as you have used up all of your attempts
									</Alert>
								</>
							}
							{quizAttemptsRemaining > 0 && quizAttemptsRemaining <= 100 &&
								<>
									{multiSessionUI !== true &&
										<>
											<Button onClick={() => submitAnswers(answeredQ)} type="button">{submitButton}</Button>{' '}
										</>

									}
									<Button variant="outline-primary" onClick={() => initialLoad()}>
										{sessionStorage.getItem('lang') == "FR" &&
											<>
												Réinitialiser
											</>
										}
										{sessionStorage.getItem('lang') != "FR" &&
											<>
												Reset
											</>
										}

									</Button><br /><br />
									<Alert variant="secondary">
										You have {quizAttemptsRemaining} attempts left
									</Alert>
								</>
							}
							{quizAttemptsRemaining == 101 &&
								<>
									{multiSessionUI !== true &&
										<>
											<Button onClick={() => submitAnswers(answeredQ)} type="button">{submitButton}</Button>{' '}
										</>
									}
									{multiSessionUI === true &&
										<>
											<Button onClick={() => props.closeQuiz()} type="button">Close</Button>{' '}
										</>
									}
									<Button variant="outline-primary" onClick={() => initialLoad()}>Reset</Button><br /><br />
								</>
							}

						</div>

						<hr />
						{sessionStorage.getItem('lang') == "FR" &&
							<>
								<h3>Réponses</h3>
							</>
						}
						{sessionStorage.getItem('lang') == "FR" &&
							<>
								<h3>Answers</h3>
							</>
						}

					</div>
					<div>
						<ul style={{'list-style-type': "none", 'padding-left': 0, 'padding-bottom': 0}}>
						{answeredQ.map((answerOption) => (
							<>
							<li>
								<Card style={{ width: "100%", margin: "10px" }}>
									<Card.Header>
										{answerOption.grade == "Incorrect" &&

											<IconContext.Provider value={{ color: "red", className: "global-class-name", size: "1.5em" }}>
												<MdOutlineCancel role='img' aria-label='Incorrect Answer'/>
											</IconContext.Provider>
										}

										{answerOption.grade == "Correct" &&

											<IconContext.Provider value={{ color: "green", className: "global-class-name", size: "1.5em" }}>
												<MdOutlineCheck role='img' aria-label='Correct Answer'/>
											</IconContext.Provider>
										}

										{sessionStorage.getItem('lang') == "FR" &&
											<>
												{answerOption.questionText_fr}
											</>
										}

										{sessionStorage.getItem('lang') != "FR" &&
											<>
												{answerOption.questionText_en}
											</>
										}
									</Card.Header>
									<Card.Body>
										<Card.Text>
											<strong>
												{sessionStorage.getItem('lang') != "FR" &&
													<>
														Answer Selected:
													</>
												}
												{sessionStorage.getItem('lang') == "FR" &&
													<>
														Réponse sélectionnée:
													</>
												}

											</strong>{" " + answerOption.answerSubmitted}
										</Card.Text>
									</Card.Body>
								</Card>
								</li>
							</>
						))}
						</ul>
						<div>
							{quizAttemptsRemaining == 0 &&
								<>
									<Button onClick={() => submitAnswers(answeredQ)} type="button" disabled>Submit</Button>{' '}<br /><br />
									<Alert variant="danger">
										You are unable to take this quiz as you have used up all of your attempts
									</Alert>
								</>
							}
							{quizAttemptsRemaining > 0 && quizAttemptsRemaining != 100 &&
								<>
									{multiSessionUI !== true &&
										<>
											<Button onClick={() => submitAnswers(answeredQ)} type="button">{submitButton}</Button>{' '}
										</>
									}
									<Button variant="outline-primary" onClick={() => initialLoad()}>
										{sessionStorage.getItem('lang') == "FR" &&
											<>
												Réinitialiser
											</>
										}
										{sessionStorage.getItem('lang') != "FR" &&
											<>
												Reset
											</>
										}

									</Button><br /><br />
									<Alert variant="secondary">
										You have {quizAttemptsRemaining} attempts left
									</Alert>
								</>
							}
							{quizAttemptsRemaining == 100 &&
								<>
									{multiSessionUI !== true &&
										<>
											<Button onClick={() => submitAnswers(answeredQ)} type="button">{submitButton}</Button>{' '}
										</>
									}
									<Button variant="outline-primary" onClick={() => initialLoad()}>Reset</Button><br /><br />
								</>
							}

						</div>
					</div>
				</>
			) : (
				<>
					<div className='question-section'>
						<div className='question-count'>

							{sessionStorage.getItem('lang') == "FR" &&
								<>
									<span>Question {currentQuestion + 1}</span> sur {answeredQ.length}
								</>
							}
							{sessionStorage.getItem('lang') != "FR" &&
								<>
									<span>Question {currentQuestion + 1}</span> of {answeredQ.length}
								</>
							}
						</div><hr align="center" width="50%" />
						{sessionStorage.getItem('lang') == "FR" &&
							<>
								<div className='question-text'>{answeredQ[currentQuestion]?.questionText_fr}</div><br />
							</>
						}
						{sessionStorage.getItem('lang') != "FR" &&
							<>
								<div className='question-text'>{answeredQ[currentQuestion]?.questionText_en}</div><br />
							</>
						}
					</div>
					<div className='answer-section' style={{ width: "100%" }}>
					<ul style={{'list-style-type': "none",'padding-left': 0,'padding-bottom': 0}}>
						
						{answeredQ[currentQuestion]?.answerOptions.map((answerOption) => (
							<li>
								{sessionStorage.getItem('lang') == "FR" &&
									<>
										<Button style={{ 'margin': "5px", width: "100%" }} onClick={() => handleAnswerOptionClick(answerOption?.isCorrect, answerOption?.answerText_fr)}>{answerOption?.answerText_fr}</Button>
									</>
								}
								{sessionStorage.getItem('lang') != "FR" &&
									<>
										<Button style={{ 'margin': "5px", width: "100%" }} onClick={() => handleAnswerOptionClick(answerOption?.isCorrect, answerOption?.answerText)}>{answerOption?.answerText}</Button>
									</>
								}
							</li>
						))}
						</ul>
					</div>
				</>
			)}
		</div>
	);
}