import Card from 'react-bootstrap/Card'
import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Card from 'react-bootstrap/Card'


export default function SchoolDataPrivacyStatementComponent(props) {

    return (
        <div style={{ backgroundColor: "#f8f9fa", justifyContent: "center", alignItems: "center"}}>
            <Card>
                <Card.Header>
                <h5>School Data Privacy Statement</h5>
                <span><small>Smart Biology Inc.</small></span>
                </Card.Header>
                <Card.Body style={{'textAlign':"left"}}>
            <Row>
                <Col>
                    <p>This privacy notice applies to the personal information pertaining to students that we receive from schools or that we maintain on behalf of schools (collectively, <strong>“School Data”</strong>), such as information about class rosters and data from students who use our learning platforms at the direction of an educational agency or school.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>Smart Biology Inc. is committed to protecting the privacy and security of all School Data that we process as a “data processor” or “service provider” to your school in order to provide the services to you and your school, pursuant to applicable laws. For example, if your school is based in: i) the United States, your student records may be protected by the Family and Educational Rights Privacy Act (FERPA) or the California Consumer Privacy Act (CCPA); the Children’s Online Privacy Protection Act (COPPA); ii) Canada, your information held by public bodies may be protected by The Freedom of Information and Protection of Privacy Act (FIPPA), or iii) the European Economic Area or the UK, your School Data may be protected by the General Data Protection Regulation (GDPR) and the UK GDPR respectively. This privacy policy explains how we do this, and how it applies to your use of Smart Biology Inc. website, products and services. For information on your school’s privacy policies, please contact the administration.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>If you use our products and platforms in your courses at your school, we only use your personal information in the School Data as needed to:</p>
                </Col>
            </Row>
            <Row>
                <Col>
                <ul>
                    <li>
                    <p>Provide you with the products, content or services selected by you, your instructors or your school and for related activities, such as customer service and “helpdesk” functions;</p>

                    </li>
                    <li>
                    <p>Understand how you use our products, content and services, including associating you with different devices that you may use to access our content, for analytics and product development purposes, and to create de-identified datasets;</p>

                    </li>
                    <li>
                    <p>Send end-of-course surveys, and;</p>

                    </li>
                    <li>
                    <p>Manage our everyday business needs, such as website administration, business continuity and disaster recovery, security and fraud prevention, corporate governance, reporting and legal compliance.</p>

                    </li>
                </ul>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>We will only use School Data for other purposes with the consent of your school and (if applicable) with your consent. Please note that we may publish reports or create products that contain de-identified, aggregated or statistical data, such as benchmarking reports that reveal how students generally engage with our platforms. These reports and products do not contain any information that would enable the recipient to contact, locate or identify you or your school.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>We do not use School Data for targeted marketing purposes. This means that we do not select or deliver ads to you in our educational products and services based on your School Data obtained or inferred over time from your use of our educational products and services. We may deliver generic or contextual ads to you while you use our educational products or platforms, but we do not target these ads based on your School Data.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>All School Data may be shared with your school (including with your instructors and school administrators). Your instructors generally have access to all School Data, including the information generated by your use of the products for a class. Your school uses the School Data in accordance with its own privacy policies.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>We may share School Data with our affiliates, which only use the School Data for the purposes listed above, and with our service providers, who are bound by law or contract to protect the School Data and only use it as needed to provide the services requested. We will also disclose School Data when required by law, such as in response to a subpoena, including to law enforcement agencies and courts in the United States and other countries where we operate.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>If you have questions about your privacy rights, please contact your school’s administration. If you believe that Smart Biology Inc. has not handled your personal information properly, you may also contact Smart Biology Inc. via email to <a href="mailto:to info@smart-biology.com">info@smart-biology.com</a></p>
                </Col>
            </Row>
                </Card.Body>
            </Card>
           
        </div>
    )
}