// import React, { useState, useEffect, useRef } from "react";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
// import Modal from "react-bootstrap/Modal";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import { createNote } from "./services/createNote";
// import { getChapterName } from "./services/lib";
// import moment from "moment";
// import EditorToolbar, { modules, formats } from "./note-toolbar";

// function CreateNoteComponent(props) {
// 	const [showCreateNote, setshowCreateNote] = useState(false);
// 	const [noteSummary, setNoteSummary] = useState("");
// 	const [notes, setNotes] = useState("");
// 	const [unit, setUnit] = useState(props?.unit || "N/A");
// 	const [date, setDate] = useState(moment().format("LLLL"));
// 	const [chapter, setChapter] = useState(props?.chapter || "N/A");
// 	const [module, setModule] = useState(props?.module || "N/A");
// 	const handleClose = () => setshowCreateNote(false);
// 	const handleShow = () => setshowCreateNote(true);
// 	const language = sessionStorage.getItem("lang") || "EN";
// 	//lang
// 	const [buttonName, setButtonName] = useState("Create Note");
// 	const [modalTitle, setModalTitle] = useState("");
// 	const [dateLabel, setDateLabel] = useState("");
// 	const [summaryLabel, setSummaryLabel] = useState("");
// 	const [closeButton, setCloseButton] = useState("");
// 	const [submitButton, setSubmitButton] = useState("");

// 	// Refs for keyboard navigation
// 	const closeButtonRef = useRef(null);
// 	const noteSummaryRef = useRef(null);
// 	const optionRef = useRef(null);



// 	const createNotesMain = async () => {
// 		handleClose();

// 		var notePayload = {
// 			unit: unit,
// 			chapter: chapter,
// 			module: module,
// 			summary: noteSummary,
// 			notes: notes,
// 			date: date,
// 		};

// 		createNote(notePayload);
// 	};

// 	useEffect(() => {
// 		// Fetch chapter name
// 		const fetchChapterName = async () => {
// 			var chapterName = await getChapterName("61788a6d23d456322035bbb2");
// 			setChapter(chapterName);
// 		};

// 		fetchChapterName();

// 		// Language handling
// 		if (language !== "FR") {
// 			setButtonName("Create Note");
// 			setModalTitle("Create a Note");
// 			setDateLabel("Date:");
// 			setSummaryLabel("Summary");
// 			setCloseButton("Close");
// 			setSubmitButton("Save Changes");
// 		} else {
// 			setButtonName("Créer une note");
// 			setModalTitle("Créer une note");
// 			setDateLabel("Date:");
// 			setSummaryLabel("Résumé");
// 			setCloseButton("proche");
// 			setSubmitButton("Sauvegarder les modifications");
// 		}
// 	}, [language]);


// const handleKeyDown = (e) => {
//     if (e.key === 'Tab' && e.shiftKey) {
//         const previousElement = document.getElementById('noteSummary');
//         if (previousElement) {
//             e.preventDefault(); // Prevent default only on Shift+Tab
//             setTimeout(() => previousElement.focus(), 0); // Use setTimeout to delay focus
//         }
//     } else if (e.key === 'Tab') {
//         const nextElement = document.getElementById('closeButton');
//         if (nextElement) {
//             e.preventDefault(); 
//             setTimeout(() => nextElement.focus(), 0);
//         }
//     }
// };


// 	return (
// 		<>
// 			<Button variant="info" onClick={handleShow}>
// 				{buttonName}
// 			</Button>

// 			<Modal
// 				show={showCreateNote}
// 				onHide={handleClose}
// 				role="dialog"
// 				aria-labelledby="dialogHeader"
// 				// ref={modalRef}
// 				tabIndex={0} // Corrected tabIndex
// 			>
// 				<Modal.Header closeButton>
// 					<h2 id="dialogHeader">{modalTitle}</h2>
// 				</Modal.Header>
// 				<Modal.Body>
// 					<Row>
// 						<Col>
// 							<Form style={{ height: "100%" }}>
// 								<Form.Group
// 									className="mb-3"
// 									controlId="exampleForm.ControlInput1"
// 								>
// 									<Form.Label htmlFor="noteSummary">{summaryLabel}</Form.Label>
// 									<Form.Control
// 										id="noteSummary"
// 										autoComplete="text"
// 										type="text"
// 										value={noteSummary}
// 										onChange={(event) => setNoteSummary(event.target.value)}
// 										autoFocus
// 									/>
// 								</Form.Group>
// 								<Form.Group
// 									className="mb-3"
// 									controlId="exampleForm.ControlTextarea1"
// 								>
// 									<Form.Label htmlFor="noteBody" for="noteBody">Notes</Form.Label>
// 									<div style={{ height: "100px" }}>
// 										<EditorToolbar />
// 										<ReactQuill
// 											id="noteBody"
// 											style={{ height: "100%" }}
// 											theme="snow"
// 											value={notes}
// 											modules={modules}
// 											formats={formats}
// 											onChange={setNotes}
// 											onKeyDown={handleKeyDown} // Handle keydown here
// 											role="textbox"
// 											type="input"
// 											aria-multiline="true"
// 											aria-labelledby="noteBody-label"
// 										>
// 											<div
// 												role="textbox"
// 												type="input"
// 												aria-multiline="true"
// 												aria-labelledby="noteBody-label"
// 												className="my-editing-area"
// 												ref={noteSummaryRef}


// 											/>
// 										</ReactQuill>
// 									</div>
// 								</Form.Group>
// 							</Form>
// 						</Col>
// 					</Row>
// 					<Row>
// 						<Col>
// 							<div style={{ display: 'flex', alignItems: 'center', paddingTop: "100px" }}>
// 								<small><h3 style={{ fontSize: "14px" }}>{dateLabel}</h3>{date}</small>
// 							</div><div>
// 								<small><h3 style={{ fontSize: "14px", paddingTop: "10px", paddingBottom: "0px" }}>Module:</h3>{module}</small>
// 							</div>
// 						</Col>
// 					</Row>
// 				</Modal.Body>
// 				<Modal.Footer>

// 					<Button id="closeButton" variant="secondary" onClick={handleClose} ref={closeButtonRef}>
// 						{closeButton}
// 					</Button>
// 					<Button variant="primary" onClick={() => createNotesMain()}>
// 						{submitButton}
// 					</Button>
// 				</Modal.Footer>
// 			</Modal>
// 		</>
// 	);
// }

// export default CreateNoteComponent;

import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { createNote } from "./services/createNote";
import { getChapterName } from "./services/lib";
import moment from "moment";
import EditorToolbar, { modules, formats } from "./note-toolbar";

function CreateNoteComponent(props) {
	const [showCreateNote, setshowCreateNote] = useState(false);
	const [noteSummary, setNoteSummary] = useState("");
	const [notes, setNotes] = useState("");
	const [unit, setUnit] = useState(props?.unit || "N/A");
	const [date, setDate] = useState(moment().format("LLLL"));
	const [chapter, setChapter] = useState(props?.chapter || "N/A");
	const [module, setModule] = useState(props?.module || "N/A");
	const handleClose = () => setshowCreateNote(false);
	const handleShow = () => setshowCreateNote(true);
	const language = sessionStorage.getItem("lang") || "EN";
	const [buttonName, setButtonName] = useState("Create Note");
	const [modalTitle, setModalTitle] = useState("");
	const [dateLabel, setDateLabel] = useState("");
	const [summaryLabel, setSummaryLabel] = useState("");
	const [closeButton, setCloseButton] = useState("");
	const [submitButton, setSubmitButton] = useState("");

	// Refs for keyboard navigation
	const closeButtonRef = useRef(null);
	const noteSummaryRef = useRef(null);
	const quillDivRef = useRef(null); // Ref for accessible div within ReactQuill

	const createNotesMain = async () => {
		handleClose();

		const notePayload = {
			unit: unit,
			chapter: chapter,
			module: module,
			summary: noteSummary,
			notes: notes,
			date: date,
		};

		createNote(notePayload);
	};

	const quillRef = useRef(null);
	useEffect(() => {
		const setAriaAttributes = () => {
			const editor = quillRef.current && quillRef.current.getEditor(); // Get the Quill editor instance
			if (editor) {
				const quillEditor = editor.root; // Access the root DOM node
				if (quillEditor) {
					quillEditor.setAttribute("role", "textbox");
					quillEditor.setAttribute("aria-multiline", "true");
					quillEditor.setAttribute("aria-labelledby", "noteBody-label");
					return; // Stop if attributes are set
				}
			}
			requestAnimationFrame(setAriaAttributes); // Keep trying until attributes are set
		};
	
		requestAnimationFrame(setAriaAttributes); // Start the loop
	
		const fetchChapterName = async () => {
			const chapterName = await getChapterName("61788a6d23d456322035bbb2");
			setChapter(chapterName);
		};

		fetchChapterName();

		if (language !== "FR") {
			setButtonName("Create Note");
			setModalTitle("Create a Note");
			setDateLabel("Date:");
			setSummaryLabel("Summary");
			setCloseButton("Close");
			setSubmitButton("Save Changes");
		} else {
			setButtonName("Créer une note");
			setModalTitle("Créer une note");
			setDateLabel("Date:");
			setSummaryLabel("Résumé");
			setCloseButton("proche");
			setSubmitButton("Sauvegarder les modifications");
		}

		return () => cancelAnimationFrame(setAriaAttributes); // Clean up on unmount
	}, [language]);

	const handleKeyDown = (e) => {
		if (e.key === "Tab") {
			e.preventDefault();
	
			if (e.shiftKey && noteSummaryRef.current) {
				noteSummaryRef.current.focus();
			} else if (closeButtonRef.current) {
				closeButtonRef.current.focus();
			}
		}
	};
	
	const handleQuillFocus = () => {
		// Logic to manage state or focus tracking if needed
	};
	
	const handleQuillBlur = () => {
		// Logic to manage state or focus tracking if needed
	};

	return (
		<>
			<Button variant="info" onClick={handleShow}>
				{buttonName}
			</Button>

			<Modal
				show={showCreateNote}
				onHide={handleClose}
				role="dialog"
				aria-labelledby="dialogHeader"
				tabIndex={0}
			>
				<Modal.Header closeButton>
					<h2 id="dialogHeader">{modalTitle}</h2>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>
							<Form style={{ height: "100%" }}>
								<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
									<Form.Label htmlFor="noteSummary">{summaryLabel}</Form.Label>
									<Form.Control
										id="noteSummary"
										ref={noteSummaryRef}
										autoComplete="text"
										type="text"
										value={noteSummary}
										onChange={(event) => setNoteSummary(event.target.value)}
										autoFocus
									/>
								</Form.Group>
								<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
									<Form.Label htmlFor="noteBody" for="noteBody">
										Notes
									</Form.Label>
									<div style={{ height: "100px" }}>
										<EditorToolbar />
										<ReactQuill
											id="noteBody"
											ref={quillRef}
											style={{ height: "100%" }}
											theme="snow"
											value={notes}
											modules={modules}
											formats={formats}
											onChange={setNotes}
											onKeyDown={handleKeyDown}
											onFocus={handleQuillFocus}    // Track focus
											onBlur={handleQuillBlur}      // Track blur to prevent unintended behavior
											role="textbox"
											aria-multiline="true"
											aria-labelledby="noteBody-label"
										/>
									</div>
								</Form.Group>
							</Form>
						</Col>
					</Row>
					<Row>
						<Col>
							<div style={{ display: "flex", alignItems: "center", paddingTop: "100px" }}>
								<small>
									<h3 style={{ fontSize: "14px" }}>{dateLabel}</h3>
									{date}
								</small>
							</div>
							<div>
								<small>
									<h3 style={{ fontSize: "14px", paddingTop: "10px", paddingBottom: "0px" }}>
										Module:
									</h3>
									{module}
								</small>
							</div>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button
						id="closeButton"
						variant="secondary"
						onClick={handleClose}
						ref={closeButtonRef}
					>
						{closeButton}
					</Button>
					<Button variant="primary" onClick={() => createNotesMain()}>
						{submitButton}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default CreateNoteComponent;

