import Form from 'react-bootstrap/Form';
import React, { useState, useEffect } from "react";
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner'
import { postDuplicateModule } from '../../../services/cmsUpdates/duplicateModule';
import { getModuleNames } from '../../../services/cmsUpdates/getModulesNames';

// import Modal from 'react-bootstrap/Modal'


function DuplicateCMSContentComponent() {

    const [moduleId, setModuleId] = useState("");
    const [moduleOldName, setModuleOldName] = useState("");
    const [moduleNewName, setModuleNewName] = useState("");
    const [showConfirmationModule, setShowConfirmationModule] = useState(false);
    const [showCompleteModule, setShowCompleteModule] = useState(false);
    const [status, setStatus] = useState();
    const [postModuleCreation, setPostModuleCreation] = useState();
    const [allModuleNames, setAllModuleNames] = useState();
    const [moduleFound, setModuleFound] = useState();

    useEffect(async () => {
        try {
            setAllModuleNames(await getModuleNames());
        } catch (err) {

        }
    }, []);

    const checkForExistingModule = (e) => {
        setModuleId(e)
        const module = allModuleNames.find((m) => m.id === e);

        if (module) {
            setModuleFound(true);
            setStatus("");
            setModuleNewName(module.title_en);
            setModuleOldName(module.title_en);
        }
        if (!module) {
            setModuleFound(false);
        }
    }
    const confirmDuplication = () => {

        if (moduleFound) {
            setShowConfirmationModule(true);
        }

        if (!moduleFound) {
            setStatus(() => {
                return (
                    <>
                        <Alert key="danger" variant="danger">
                            Module Not Found: Please Enter a Valid Module ID
                        </Alert>
                    </>
                )
            })
        }
    }

    const submitDuplication = async () => {
        var payload =
        {
            "existingModuleId": moduleId,
            "newModuleName": moduleNewName
        }

        var newModule = await postDuplicateModule(payload);

        setStatus("")//Lodaing Icon
        setPostModuleCreation(() => {
            return (
                <>

                    <h5>New Module Detials</h5>
                    <table width="40%">
                        <tr>
                            <td>
                                New Id:
                            </td>
                            <td>
                                {newModule.moduleDetailId}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Name
                            </td>
                            <td>
                                {newModule.moduleDetailName}
                            </td>
                        </tr>
                    </table>
                </>
            )
        });
        setShowCompleteModule(true);
        setShowConfirmationModule(false);
        setModuleNewName("");
        setModuleOldName("");
        setModuleId("");
    }

    return (
        <>
            <Accordion defaultActiveKey={'0'} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Duplicate Module</Accordion.Header>
                    <Accordion.Body>
                        {status}
                        <div align="left"><p>Use the below tool to duplicate a Module in the CMS. <br />Once the Module ID has been entered, the "new" name 
                        will be pre-populated with the existing module Name.  Modify as nessessary and click Duplicate Module.</p></div>
                        
                        <Col>
                            <Row>
                                <Col align="left">
                                    <Form.Control
                                        type="text" autoComplete="text"
                                        value={moduleId}
                                        onChange={event => checkForExistingModule(event.target.value)}
                                    />
                                    <Form.Text style={{ margin: "left!important" }}>
                                        Enter ID to duplicate (This can be taken from Strapi)
                                    </Form.Text>
                                </Col>
                                <Col>

                                </Col>
                            </Row>
                            <Row>
                                <Col align="left">
                                    <Form.Control
                                        type="text" autoComplete="text"
                                        value={moduleNewName}
                                        onChange={event => setModuleNewName(event.target.value)}
                                    />
                                    <Form.Text>
                                        Enter the name of the copied module
                                    </Form.Text>
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col align="left">

                                    <Button onClick={() => confirmDuplication()}>Duplicate Module</Button>
                                    {/* <Button onClick={() => filterUserList()}>Search</Button> */}
                                    <br /><span align="left"><small><strong>Note: </strong>Module will have Lesson and Quiz association, it will <u>not</u> have Chapter association</small></span>

                                </Col>
                                <Col></Col>
                            </Row>
                        </Col>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <Modal
                show={showConfirmationModule}>
                <Modal.Header>
                    <h4>Confirm Duplication of Module?</h4>
                </Modal.Header>
                <Modal.Body>
                    <strong>
                        Are you sure you want to duplicate Module:
                    </strong> <br /><br />
                    <table>
                        <tr>
                            <td>
                                Module Id:
                            </td>
                            <td>
                                {moduleId}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Module Name:
                            </td>
                            <td>
                                {moduleOldName}
                            </td>
                        </tr>
                    </table>
                    <hr />
                    <strong>New Module Title</strong>
                    <table>
                        <tr>
                            <td>
                                Module Id:
                            </td>
                            <td>
                                TBD
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Module Name:
                            </td>
                            <td>
                                {moduleNewName}
                            </td>
                        </tr>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    {status}
                    <Button onClick={() => submitDuplication()} >Confirm</Button>
                    <Button onClick={() => setShowConfirmationModule(false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showCompleteModule}>
                <Modal.Header>
                    <Alert key="success" variant="success">
                        Module has been copied!
                    </Alert>
                </Modal.Header>
                <Modal.Body>
                    {postModuleCreation}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowCompleteModule(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DuplicateCMSContentComponent;
