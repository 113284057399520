import React, { useState, useEffect } from 'react';

const testimonials = [
	{
		name: "@TheMetakraft",
		quote: "Amazing! Very helpful for understanding the tough concepts of biology."
	},
	{
		name: "@Fully_Looted",
		quote: "Wow this is incredible. The hardest part about learning biology was that it was so hard to visualize.",
	},
	{
		name: "@Inside_TheMatch",
		quote: "Smart Biology is great for helping students truly understand challenging concepts to visualize.",
	},
    {
		name: "@VK_Ulaganathan",
		quote: "SmartBiology3D is really on the path to revolutionalize effective learning, i.e, grasping concepts with minimal effort.",
	},
    {
		name: "@elonmusk",
		quote: "Wow",
	},
];

const TestimonialCarousel = () => {
    const [testimonialList, setTestimonialList] = useState(testimonials);
    const [isTransitioning, setIsTransitioning] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeout(() => {
                setTestimonialList((prevList) => {
                    const newList = [...prevList];
                    const movedItem = newList.shift();
                    newList.push(movedItem);
                    return newList;
                });
                setIsTransitioning(false);
            }, 500);
            setIsTransitioning(true);
             // Duration of the transition
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    const displayedTestimonials = testimonialList.slice(0, 4);

    return (
        <div className="carousel-container">
            <div className={`testimonial-wrapper ${isTransitioning ? 'transition' : ''}`}>
                {displayedTestimonials.map((testimonial, index) => (
                    <div key={index} className="testimonial">
                        <div className="speech-bubble">
                        <br></br>
                            <h4 style={{minHeight: '116px', justifyContent: 'center', alignContent: 'center'}}>{testimonial.quote}</h4>
                            <p className="name">- {testimonial.name}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TestimonialCarousel;
