
// {
//     "result": "FAIL",
//     "date": "2023-11-10T21:01:30-05:00",
//     "syncType": "Manual",
//     "studentId": "CX-2640103140",
//     "syncResults": "Cannot sync grades - close date has passed. Content: 63a75203d966ed1cbb5f0d12",
//     "lmsContentName": "Smart Biology: The Plasma Membrane"
//   }

export async function getLTILogs(studentId) {
    let updateResults = {};
    
    try {
      const options = {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'sb-application-name': 'sb-site-ui',
          'sb-application-key': 'jmd-test',
          'sessionID': sessionStorage.getItem("sessionID"),
        }),
        mode: 'cors',
        credentials: 'omit',
      };
        
      const endpoint = process.env.REACT_APP_LTI_SERVICE + '/sb-lti-v-1-1/getLoggingByStudentId/' + studentId;

      const response = await fetch(endpoint, options);
      if (response.ok) {
        const updateUserResults = await response.json();
        updateResults = updateUserResults;
      } else {
        throw new Error(`Failed to get user LTI logs for student ${studentId} -- ${response.status}`);
      }

    } catch (error) {
      updateResults = {
        error: true,
        errorMsg: error.message || error,
      };
    } finally {
      return updateResults;
    }
  }
  