

const options = { 
    method: 'POST', 
    headers: new Headers({ 
      'Content-Type': 'application/json',
      'sb-application-name': 'sb-site-ui',
      'sb-application-key': 'jmd-test',
      'sessionID' : sessionStorage.getItem("sessionID"),
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
    }), 
    mode: 'cors',
    credentials: 'omit',
    body: {}
  };

  export async function sendContactUsEmail(name, email, message, trial, enterpriseDetails = false){

    const endpoint = process.env.REACT_APP_BFF + '/bff/v1.0/contactUs';
    options.method = 'POST';

    const body = {
        "name": name,
        "email": email,
        "message": message, 
        "trialRequest": trial, 
        "enterpriseDetails": enterpriseDetails
    }

    options.body = JSON.stringify(body);
  
    // console.log("getProductByID endpoint" + endpoint);
    let productDetailsResponse =
      await fetch(endpoint,options)
        .then(response => {
          // console.log('getProductByIDResponse' + JSON.stringify(response));
          return response.json();
        })
        .catch(function(err) {
          console.log(err);
        });

        // console.log("productDetailresponse >>>> " + JSON.stringify(productDetailsResponse));

    return productDetailsResponse;

  }

  