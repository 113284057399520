import { useSSRSafeId } from "@react-aria/ssr";
import React, { useState, useEffect, useRef } from "react";
import Cookies from 'universal-cookie';
import {getShoppingCartDetails} from '../../services/bffServices/getShoppingCart';
import {addCouponToSC} from '../../services/bffService';
import Button from 'react-bootstrap/Button';
import { Container } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row'
import {CustomToggle} from "./checkoutMainComponent";




const cookies = new Cookies();

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // An function that increment 👆🏻 the previous state like here 
    // is better than directly setting `value + 1`
}

export default function CheckoutComponent(props){

    // useForceUpdate();
    const [shoppingCartData, setShoppingCartData] = useState();
    const [cartTotal, setCartTotal] = useState();
    const [cartTaxValue, setCartTaxValue] = useState();
    const [finisLoad, setFinishLoad] = useState(false);
    const [cartItems, setCartItems] = useState();
    const [userData, setUserData] = useState({});
    const couponForm = useRef(null);


const setSCData = async (scid) => {

    const scInfo = await getShoppingCartDetails(scid); 
    await setShoppingCartData(scInfo);
    await setCartTotal(shoppingCartData?.cartTotal);
    setFinishLoad(true);
    
    return scInfo;
}

var scd;

  useEffect(() => {
    setSCData(props.shoppingCartID.scID)
    // window.location.reload(false)
  },[]);

  const setCoupon = async () => {
    // const form = couponForm.current;
    // const couponCode = form['blah'].value;
    // console.log('coupon entered' + form['blah'].value);
    // await addCouponToSC(userData.shoppingCartID, "BIO293_C");
    // await scContents();
    const scInfo = await getShoppingCartDetails(props.shoppingCartID.scID); 
    await setShoppingCartData(scInfo);
    await setCartTotal(shoppingCartData?.cartTotal);
    setFinishLoad(true);
    // form['blah'].value = "";
}

const scContents = async () => {
    var totalDiscounts = 0;
    // const scStuff = await fetch(scURL,optionsPOST);
    const scStuff = setSCData(props.shoppingCartID.scID)

    const thatStuff = await scStuff.json();
    var productItem = await thatStuff.cartItems;
    var totals = await thatStuff.cartTotal;

    
    setUserData(thatStuff);
}

    return(
        <>
        
 <Container>
    <Row>
    <Col md={4}>
        <form class="form-inline" ref={couponForm}>
                        <div class="form-group mx-sm-3 mb-2">
                        <input type="text" autoComplete="text" class="form-control" id="blah" name="blah" placeholder="BIO293_C"/>

                    </div>
                    {/* <button type="submit" class="btn btn-primary mb-2" onClick={() => setCoupon() }>Add Coupon</button> */}
                    <Button onClick={() => setCoupon()}>Coupon</Button> 
                    <Button onClick={() => setCoupon()}>Don't have a Coupon</Button>   
        </form>
    </Col>
    <Col md={8}>
    <table align="center" width="75%">
             <tr>
                <td align="left">
                    <h6>Textbook</h6><br />
                </td>
                <td align="right">
                    <h6>Price</h6><br />
                </td>
             </tr>
             <tr>
                 <td align="left">
                    {shoppingCartData?.productName || 0}<br />
                 </td>
                 <td align="right">
                 {shoppingCartData?.productPrice}<br />
                 </td>
             </tr>
             <tr>
                 <td colspan="2">
                 <hr/>
                 </td>
             </tr>
             <tr>
                 <td align="left">
                     Subtotal
                 </td>
                 <td align="right">
                     ${shoppingCartData?.cartSubtotal || shoppingCartData?.productPrice}
                 </td>
             </tr>
             <tr>
                 <td align="left">
                 Discounts
                 </td>
                 <td align="right">
                     ${shoppingCartData?.cartTotalDiscountValue || '0'}
                 </td>
             </tr>
             <tr>
                 <td align="left">
                     Tax ({shoppingCartData?.cartTotalTaxPercent || '0'})
                 </td>
                 <td align="right">
                 ${shoppingCartData?.cartTaxValue}
                 </td>
             </tr>
             {/* <tr>
                 <td colspan="2" align="left">
                     *taxes calculated are checkout
                 </td>
             </tr> */}
             <tr>
                 <td align="left">
                     Total
                 </td>
                 <td align="right">
                 ${shoppingCartData?.cartTotal || shoppingCartData?.productPrice}
                 </td>
             </tr>
             <tr>
                 <td colspan='2' align='right'>
         {/* <br /><button type="submit" class="btn btn-primary mb-2" onClick={() => submitProductOrder()}>Checkout</button> */}
         <CustomToggle originator = "purchaseInfo" eventKey="2" payload={{}}>
                        Continue to Payment 
                        </CustomToggle>
                 </td>
             </tr>
         </table>
    </Col>
    </Row>
 </Container>
            



     
   
        </>
    )
}

