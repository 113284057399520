
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import React, { useState, useEffect } from "react";
import getFullCommentList from '../services/getFullCommentList';
import ReplyModal from '../services/replyModal';
import { getExistingSession } from '../../../services/bffServices/getSessionInfo';
const queryParams = new URLSearchParams(window.location.search);




function GetAllCommentsandReplies(props) {
    const [commentList, setCommentList] = useState([]);
    const [userRole, setUserRole] = useState([]);



    const getAllComments = async () => {
        const blah = await getFullCommentList(queryParams.get('id'));

        var ui = "";

        var newArray = [];

        newArray = blah.payload
        await setCommentList(newArray)

        for (let i = 0; i <= blah.payload.length; i++) {
            ui = + i;
        }

        // console.log("UI-jmd" + ui)
    }

    const updateCorrectAnnswer = () => {
        
    }


    useEffect(async () => {
        getAllComments();
        const userSession = await getExistingSession();
        setUserRole(userSession.payload.role);


    }, []);


    return (
        <>

            {commentList.length == 0 &&
                <>
                    <Card>
                        <Card.Body>
                            <Card.Text>
                                No questions have been asked
                            </Card.Text>
                        </Card.Body>
                    </Card>

                </>
            }
            {commentList.map(comment => (
                <>
                    <Card className="text-center">
                        <Card.Header align="left">{comment.comment}
                            <small>
                                <br />Asked By {comment.student} <br />
                                {comment.date}
                            </small>

                        </Card.Header>
                        {comment.replies != undefined &&
                            <Card.Body align="left">
                                {comment.replies.map(replies => (
                                    <>
                                        <Card.Text>
                                            {replies.comment}<br />
                                            <small>{replies.student}| {replies.date}</small>
                                        </Card.Text>
                                        {userRole == "teacher" && 
                                        <Card.Text> 
                                            <Button size="sm">Mark this as correct</Button>
                                        </Card.Text>
}
                                        <hr width="10%" />
                                    </>
                                ))}
                                <ReplyModal id={comment.id} refresh={getAllComments} />
                            </Card.Body>
                        }
                        {comment.replies == undefined &&
                            <Card.Body align="left">
                                <Card.Text>
                                    No Replies or Answers, be the first!<br />
                                </Card.Text>
                                <ReplyModal id={comment.id} refresh={getAllComments} />
                                {/* <Button>Answer Question, or ask a followup</Button> */}
                            </Card.Body>
                        }
                        {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}

                    </Card>

                    <div align="center">
                        <hr width="75%" />

                    </div>
                </>
            ))}



        </>
    );
}

export default GetAllCommentsandReplies;