import Card from 'react-bootstrap/Card'
import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function FermaPolicytComponent(props) {

    return (
        <div style={{ backgroundColor: "#f8f9fa", justifyContent: "center", alignItems: "center" }}>
            <Card>
                <Card.Header>
                    <h5>FERPA Policy</h5>
                    <span><small>Smart Biology Inc.</small></span>
                </Card.Header>
                <Card.Body style={{ 'textAlign': "left" }}>
                    <Row>
                        <Col>
                            <p>Certain information regarding students is governed by the Family Educational Rights and Privacy Act (FERPA) or other laws and agreements concerning privacy and private information. You must not submit any information to the School Data regarding another person (e.g. student) that you do not have the legal right to submit.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Smart Biology Inc. acknowledges that it is designated as a “school official” with “legitimate educational interests” in Your School Data and associated metadata, as those terms have been defined under the Family Educational Rights and Privacy Act (“FERPA”) and its implementing regulations, and Smart Biology Inc. agrees to abide by the limitations and requirements imposed by 34 CFR 99.33(a) on school officials.  Smart Biology Inc. will use Your School Data only for the purpose of fulfilling its duties under this agreement and it will not monitor or share such data with or disclose it to any third party except as required by law, or as authorized in writing by You.  </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>You have the right to consent to disclosure of your personally identifiable information contained in your educational records. Exceptions under FERPA allow Smart Biology Inc. to disclose educational records without your prior written consent to: </p>
                            <ul>
                                <li>
                                    <p>School officials with a legitimate educational interest. School officials include any staff, faculty or other person employed by your educational institution or a person or company which your educational institution has outsourced functions that otherwise would be performed by its employees.</p>
                                </li>
                                <li>
                                    <p>Authorized representatives of the following agencies: U.S. Comptroller General, the U.S. Attorney General, the U.S. Secretary of Education, or State and local educational authorities. Disclosures under this provision may be made in connection with an audit or evaluation or for the enforcement of or compliance with federal legal requirements.</p>
                                </li>
                                <li>
                                    <p>Officials associated with financial aid for which you have applied or received if the information is deemed necessary to determine eligibility, amount of aid and the conditions of aid, or to enforce terms or conditions of the aid.</p>
                                </li>
                                <li>
                                    <p>Organizations conducting studies on behalf of your educational institution to develop, validate or administer predictive tests; administer student aid programs; or improve instruction.</p>
                                </li>
                                <li>
                                    <p>Accrediting organizations to perform accrediting functions.</p>

                                </li>
                                <li>
                                    <p>Parents of an eligible student if the student is dependent for IRS tax purposes.</p>

                                </li>
                                <li>
                                    <p>Officials in compliance with a judicial order or lawfully issued subpoena.</p>

                                </li>
                                <li>
                                    <p>Appropriate officials in connection with a health or safe emergency.</p>

                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                        </Col>
                    </Row>
                    <Row>
                        <Col>

                        </Col>
                    </Row>
                    <Row>
                        <Col>

                        </Col>
                    </Row>
                    <Row>
                        <Col>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Requests for access to educational records that do not include directory information or are not an allowable exception require your consent. Smart Biology Inc. will not change how School Data and other non-public information is collected, used, or shared under the terms of this policy without advance notice and your consent.</p>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
}