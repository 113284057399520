var options = {
  method: 'POST',
  headers: new Headers({
    'Content-Type': 'application/json',
    'sb-application-name': 'sb-site-ui',
    'sb-application-key': 'jmd-test',
    'sessionID': sessionStorage.getItem("sessionID"),
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
  }),
  mode: 'cors',
  credentials: 'omit'
};

export async function getTextbookList() {

  const endpoint = process.env.REACT_APP_BFF + '/bff/v1.0/getTextbookList';
  options.method = 'GET';

  try {
    let textbookDetailsResponse =
      await fetch(endpoint, options)
        .then(response => {
          return response.json();
        })
        .catch(function (err) {
          console.log(err);
        });

    return textbookDetailsResponse[0].units[0];
  } catch (err) {
    console.log("getTextbookList" + err)
    return err
  }

}

export async function getTextbookListV2() {

  options = {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'sb-application-name': 'sb-site-ui',
      'sb-application-key': 'jmd-test',
      'sessionID': sessionStorage.getItem("sessionID"),
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
    }),
    mode: 'cors',
    credentials: 'omit'
  };
  const endpoint = process.env.REACT_APP_BFF + '/bff/v1.0/getTextbookList';
  options.method = 'GET';
  let textbookDetailsResponse =
    await fetch(endpoint, options)
      .then(response => {
        return response.json();
      })
      .catch(function (err) {
        console.log(err);
      });

  var textbookListResponseV2 = localStorage.getItem("userProgressTotal")
  textbookListResponseV2 = JSON.parse(textbookListResponseV2);

  var unitList = [];
  for (var t = 0; t < textbookDetailsResponse.length; t++) {
    for (var uu = 0; uu < textbookDetailsResponse[t].units.length; uu++) {
      for (var tt = 0; tt < textbookListResponseV2.studentProgress.payload[0].textbook.length; tt++) {
        for (var uuu = 0; uuu < textbookListResponseV2.studentProgress.payload[0].textbook[tt].unit.length; uuu++) {
          if (textbookDetailsResponse[t].units[uu].id == textbookListResponseV2.studentProgress.payload[0].textbook[tt].unit[uuu].id) {
            var newUnit = textbookDetailsResponse[t].units[uu];
            newUnit.userProgress = textbookListResponseV2.studentProgress.payload[0].textbook[tt].unit[uuu];
            if (newUnit.grade !== undefined) {
              var existingUnitIndex = unitList.findIndex(unit => unit.id === newUnit.id);
              if (existingUnitIndex !== -1) {
                unitList[existingUnitIndex] = newUnit;
              } else {
                unitList.push(newUnit);
              }
            } else {
              if (unitList.findIndex(unit => unit.id === newUnit.id) === -1) {
                unitList.push(newUnit);
              }
            }
          }
        }
      }
    }
  }

  return unitList;

}

export async function getTextbookByID(id) {

  const endpoint = process.env.REACT_APP_BFF + '/bff/v1.0/getTextbookList';
  options.method = 'GET';

  let textbookDetailsResponse =
    await fetch(endpoint, options)
      .then(response => {
        return response.json();
      })
      .catch(function (err) {
        console.log(err);
      });

  return textbookDetailsResponse;

}

