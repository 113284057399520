import Dropdown from 'react-bootstrap/Dropdown'
import ProductList from '../../productListapit';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Footer from '../../footer';
import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import PaymentComponent from '../bookstore/paymentComponent'
import addToCart from '../../../../src/services/bffService';



export default function InvoiceComponent(props) {
    const [invoice, setInvoice] = useState("");
    const [product, setProduct] = useState("");
    const [invoiceRow, setInvoiceRow] = useState("");

    useEffect(async () => {
        try {

            await setInvoice(props.invoiceDetails);
            await setProduct(props.productDetails);
            await addBookeStoreProductToCart(props.invoiceDetails)

        } catch (err) {
            console.log(err);
        }

    }, [])

    const addBookeStoreProductToCart = async (invoice) => {
        var cartInfo = {
            'description': invoice.invoiceNum,
            'productId': invoice.id,
            'price': invoice.subTotal,
            'currency': invoice?.currency.name_en || "N/A"
        }
        var cartDetails = addToCart(cartInfo, false, false);
        console.log("cartDetails == " + JSON.stringify(cartDetails))
    }


    return (
        <>
            <Container>
                <Card><br />
                    <Row>
                        <Col>
                        <strong>Invoice #:</strong> {props.invoiceDetails.invoiceNum}
                        </Col>
                        <Col>
                        </Col>
                        <Col align="left">
                            <strong>Bill To: </strong><ReactMarkdown rehypePlugins={[rehypeRaw]} >{props.invoiceDetails.billTo}</ReactMarkdown>
                        </Col>
                    </Row>

                    <Row>
                        <Table striped bordered hover style={{ width: "75%", margin: "auto" }}>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Tax Exempt</th>
                                    <th>Qty</th>
                                    <th>Rate</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{props.invoiceDetails.invoiceDate}</td>
                                    <td>{props.productDetails.title}</td>
                                    <td>{props.invoiceDetails.taxExempt ? "Yes" : "No"}</td>
                                    <td>{props.invoiceDetails.numberOfSeats}</td>
                                    <td>${props.invoiceDetails.rate}</td>
                                    <td>${props.invoiceDetails.subTotal} ({props.invoiceDetails.currency.name_en})</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                    <Row style={{ padding: "10px", margin: "auto" }}>
                        <Col style={{ padding: "10px", margin: "auto", textAlign: "right!important" }}>
                        <PaymentComponent totalAmount={props.invoiceDetails.subTotal} currency={props.invoiceDetails.currency.name_en}/>
                        
                        </Col>
                    </Row>

                    <br />

                </Card>
            </Container>
        </>
    )

}