import Table from 'react-bootstrap/Table'
import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Placeholder from 'react-bootstrap/Placeholder';
import moment from 'moment';
import { getQuizesByClass } from '../../../../services/bffServices/textbookBFFServices/getQuizDetails';
import { updateDbQuizException } from '../../../../services/bffServices/updateQuizException';
import { updateQuizAttempts, getQuizAttempts } from '../../../../services/quiz/quizAttempts';
import { Container } from 'react-bootstrap';
import { getClassListTeachers } from '../../../../services/bffServices/getClassesByTeacher';
import Spinner from 'react-bootstrap/Spinner'

function QuizDatesComponentMain(id) {

    const [classSelected, setClassSelected] = useState();
    const [quizList, setQuizList] = useState();
    const [exceptionUpdateQuizId, setExceptionUpdateQuizId] = useState();
    const [exceptionUpdateModuleId, setExceptionUpdateModuleId] = useState();
    const [exceptionUpdateChapterId, setExceptionUpdateChapterId] = useState();
    const [exceptionUpdateUnitId, setExceptionUpdateUnitId] = useState();
    const [schoolList, setSchoolList] = useState();
    const [exceptionUpdateType, setExceptionUpdateType] = useState();
    const [closeDate, setCloseDate] = useState();
    const [openDate, setOpenDate] = useState();
    const [existingDates, setExistingDates] = useState({});
    const [allQuizDates, setAllQuizDates] = useState(false);
    const [allQuiz, setAllQuiz] = useState(false);
    const [dateValidation, setDateValidation] = useState(false);
    const [dateValidationError, setDateValidationError] = useState("");
    const [quizAttemptsUI, setQuizAttemptsUI] = useState("none");
    const [quizAttempts, setQuizAttempts] = useState("");
    const [quizAttemptsUpdateResults, setQuizAttemptsUpdateResults] = useState("");
    const [currentQuizAttempts, setCurrentQuizAttempts] = useState();
    const [showLoading, setShowLoading] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const processQuizAttemptUpdates = async () => {

        var quizUpdateResults = await updateQuizAttempts(classSelected, quizAttempts);
        var quizAttemptsCurrent = await getQuizAttempts(classSelected);
        setCurrentQuizAttempts(quizAttemptsCurrent)

        if (quizUpdateResults) {
            if (quizAttempts == 100) {
                setQuizAttemptsUpdateResults(() => {
                    return (
                        <>
                            <Alert key="success" variant="success">
                                Success, Students now do not have a limit on quiz attempts
                            </Alert>
                        </>
                    )
                });
            }

            if (quizAttempts != 100) {
                setQuizAttemptsUpdateResults(() => {
                    return (
                        <>
                            <Alert key="success" variant="success">
                                Success, Students now have {quizAttempts} quiz attempts.
                            </Alert>
                        </>
                    )
                });
            }
        }

        if (!quizUpdateResults) {
            setQuizAttemptsUpdateResults(() => {
                return (
                    <>
                        <Alert key="danger" variant="danger">
                            Error, Quiz attempts not updated.  Try again, or contact support for assistance.
                        </Alert>
                    </>
                )
            });
        }
    }

    const modalChange = (exceptionDetails) => {

        setExceptionUpdateQuizId(exceptionDetails.quizId);
        setExceptionUpdateModuleId(exceptionDetails.moduleId);
        setExceptionUpdateChapterId(exceptionDetails.chapterId);
        setExceptionUpdateUnitId(exceptionDetails.unitId);
        setExceptionUpdateType(exceptionDetails.updateType);
        setExistingDates(exceptionDetails.existingQuizSettings);

        setOpenDate(exceptionDetails.existingQuizSettings.openDate);
        setCloseDate(exceptionDetails.existingQuizSettings.closeDate)
        handleShow();
    }

    const updateQuizException = async (userEntry) => {

        var closeDateInd = existingDates.closeDateInd;
        var openDateInd = existingDates.openDateInd;
        setShowLoading(true);

        if (allQuizDates == "NO" || allQuizDates == "" || allQuizDates == undefined || allQuizDates == null) {

            var payload = {
                "quizId": exceptionUpdateQuizId,
                "moduleId": exceptionUpdateModuleId,
                "chapterId": exceptionUpdateChapterId,
                "unitId": exceptionUpdateUnitId,
                "classId": classSelected,
                "membershipKey": "key-123411111",
                "closeDateInd": closeDateInd || "N",
                "closeDate": closeDate || "N",
                "openDate": openDate || "N",
                "openDateInd": openDateInd || "N"
            }

            handleClose();
            await updateDbQuizException(payload);
            setQuizList(await getQuizList());

        }

        if (allQuizDates == "ALL") {

            for (var q = 0; q < allQuiz.length; q++) {
                if (exceptionUpdateType == "REMOVE-CLOSE" || exceptionUpdateType == "ADD-CLOSE") {
                    setOpenDate(allQuiz[q].openDate);
                    openDateInd = allQuiz[q].openDateInd;
                    if (exceptionUpdateType == "ADD-CLOSE") {
                        closeDateInd = "Y";
                    }
                }

                if (exceptionUpdateType == "REMOVE-OPEN" || exceptionUpdateType == "ADD-OPEN") {
                    setCloseDate(allQuiz[q].closeDate);
                    closeDateInd = allQuiz[q].closeDateInd;
                    if (exceptionUpdateType == "ADD-OPEN") {
                        openDateInd = "Y";
                    }
                }

                var payload = {
                    "quizId": allQuiz[q].quizId,
                    "moduleId": allQuiz[q].moduleId,
                    "chapterId": allQuiz[q].chapterId,
                    "unitId": allQuiz[q].unitId,
                    "classId": classSelected,
                    "membershipKey": "key-123411111",
                    "closeDateInd": closeDateInd || "N",
                    "closeDate": closeDate || "N",
                    "openDate": openDate || "N",
                    "openDateInd": openDateInd || "N"
                }

                handleClose();
                await updateDbQuizException(payload);

            }
            setQuizList(await getQuizList());
            setAllQuizDates("");
        }
        setShowLoading(false);
    }

    const loadQuizList = async () => {
        setQuizAttemptsUpdateResults("")
        setQuizAttemptsUI("block");

        setQuizList(() => {
            return (
                <>
                    <Placeholder as="p" animation="glow">
                        <Placeholder md={12} size="lg" />
                        <Placeholder xs={12} size="lg" />
                        <Placeholder xs={12} size="lg" />
                        <Placeholder xs={12} size="lg" />
                        <Placeholder xs={12} size="lg" />
                    </Placeholder>
                </>
            )
        })

        var quizAttemptsCurrent = await getQuizAttempts(classSelected);
        setCurrentQuizAttempts(quizAttemptsCurrent)
        setQuizList(await getQuizList());
    }

    const getQuizList = async () => {

        const quizzes = await getQuizesByClass(classSelected);
        setAllQuiz(quizzes);
        return (
            <>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Unit</th>
                            <th>Chapter | Module</th>
                            <th width="10%">Open Date</th>
                            <th width="10%">Close Date</th>
                        </tr>
                    </thead>
                    <tbody style={{ verticalAlign: "middle" }}>
                        {quizzes.map(quiz => (
                            <>
                                <tr>
                                    <td>
                                        {quiz.unit.unitName_en}
                                    </td>
                                    <td>
                                        <div>
                                            {quiz.chapter.chapterName_en}
                                        </div>
                                        <div>
                                            {quiz.module.moduleName_en}
                                        </div>
                                    </td>
                                    <td>
                                        {quiz.openDateInd != "Y" &&
                                            <>
                                                <div>
                                                    <Button
                                                        className="mb-2"
                                                        type="checkbox"
                                                        variant='outline-primary'
                                                        value="1"
                                                        size="sm"
                                                        onClick={() => modalChange({
                                                            "quizId": quiz.quizId,
                                                            "updateType": "ADD-OPEN",
                                                            "moduleId": quiz.module.moduleId,
                                                            "chapterId": quiz.chapter.chapterId,
                                                            "unitId": quiz.unit.unitId,
                                                            "class": classSelected,
                                                            "existingQuizSettings": {
                                                                "openDateInd": "Y",
                                                                "openDate": quiz.openDate || null,
                                                                "closeDateInd": quiz.closeDateInd,
                                                                "closeDate": quiz.closeDate || null
                                                            }
                                                        })}
                                                    >
                                                        Add Date
                                                    </Button>

                                                </div>
                                            </>

                                        }

                                        {quiz.openDateInd == "Y" &&
                                            <>
                                            <table align="center">
                                                <tr>
                                                    <td>
                                                    <div style={{'font-size':"14px"}}>
                                                    <small>{moment(quiz.openDate).format("MMMM D YYYY")}</small>
                                                </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    <div>
                                                    <Button size="sm" onClick={() => modalChange({
                                                        "quizId": quiz.quizId,
                                                        "updateType": "REMOVE-OPEN",
                                                        "moduleId": quiz.module.moduleId,
                                                        "chapterId": quiz.chapter.chapterId,
                                                        "unitId": quiz.unit.unitId,
                                                        "class": classSelected,
                                                        "existingQuizSettings": {
                                                            "openDateInd": "N",
                                                            "openDate": "N" || null,
                                                            "closeDateInd": quiz.closeDateInd,
                                                            "closeDate": quiz.closeDate || null
                                                        }

                                                    })}>Remove Date</Button>

                                                </div>
                                                    </td>
                                                </tr>
                                            </table>
     
                         
                                            </>

                                        }
                                    </td>
                                    <td>
                                        {quiz.closeDateInd != "Y" &&
                                            <>
                                            
                                                <div>
                                                    <Button
                                                        className="mb-2"
                                                        type="checkbox"
                                                        variant='outline-primary'
                                                        value="1"
                                                        size="sm"
                                                        onClick={() => modalChange({
                                                            "quizId": quiz.quizId,
                                                            "updateType": "ADD-CLOSE",
                                                            "moduleId": quiz.module.moduleId,
                                                            "chapterId": quiz.chapter.chapterId,
                                                            "unitId": quiz.unit.unitId,
                                                            "class": classSelected,
                                                            "existingQuizSettings": {
                                                                "openDateInd": quiz.openDateInd,
                                                                "openDate": quiz.openDate || null,
                                                                "closeDateInd": "Y",
                                                                "closeDate": quiz.closeDate || null
                                                            }
                                                        })}
                                                    >
                                                        Add Date
                                                    </Button>

                                                </div>
                                            </>

                                        }

                                        {quiz.closeDateInd == "Y" &&
                                            <>
                                            <table align="center">
                                                <tr>
                                                    <td>
                                                    <div style={{'font-size':"14px"}}>
                                                    <small>{moment(quiz.closeDate).format("MMMM D YYYY")}</small>
                                                </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    <div>
                                                    <Button size="sm" onClick={() => modalChange({
                                                        "quizId": quiz.quizId,
                                                        "updateType": "REMOVE-CLOSE",
                                                        "moduleId": quiz.module.moduleId,
                                                        "chapterId": quiz.chapter.chapterId,
                                                        "unitId": quiz.unit.unitId,
                                                        "class": classSelected,
                                                        "existingQuizSettings": {
                                                            "openDateInd": quiz.openDateInd,
                                                            "openDate": quiz.openDate || null,
                                                            "closeDateInd": "N",
                                                            "closeDate": "N" || null
                                                        }

                                                    })}>Remove Date</Button>

                                                </div>
                                                    </td>
                                                </tr>
                                            </table>
                                              
                                              
                                            </>

                                        }

                                    </td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </Table>
            </>
        )
    }

    const getClassInfo = async () => {

        try {
            const schools = await getClassListTeachers();
            setSchoolList(() => {
                return (
                    <>
                        {schools.map(classSelected => (
                            <>
                                <option value={classSelected.schoolId}>{classSelected.schoolName}</option>
                            </>
                        ))}
                    </>
                );
            });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getClassInfo();

    }, []);

    return (
        <>

            {quizAttemptsUpdateResults}
            <Row style={{ width: "75%" }}>
                <Col style={{ 'text-align': "right", margin: "auto" }}>
                    Select Class:
                </Col>
                <Col >
                    <Form.Select aria-label="Default select example" id="newSchool" onChange={event => setClassSelected(event.target.value)} name="newSchool" >
                        <option value="0"></option>
                        {schoolList}
                    </Form.Select>
                </Col>
                <Col style={{ 'align-items': "left", display: "flex" }}>
                    <Button onClick={() => loadQuizList()}>Select</Button>
                </Col>
            </Row>
            <br /><hr />
            <Container style={{ display: quizAttemptsUI }}>
                <Row>
                    <Col xs={3} style={{ 'text-align': "left" }}>
                        Select Number of Quiz Attempts:
                        <Form.Select aria-label="Default select example" id="newSchool" onChange={event => setQuizAttempts(event.target.value)} name="newSchool" >
                            <option value="100">unlimited</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </Form.Select>
                        <div className="d-grid gap-2" style={{ 'padding-top': "5px" }}>
                            <Button onClick={() => processQuizAttemptUpdates()} size="sm">
                                Update Quiz Attempts
                            </Button>
                        </div>
                    </Col>
                    <Col style={{ margin: "auto", position: "relative", 'text-align': "left" }}>
                        <Form.Text className="text-muted">
                            Select the number of retries you would like the student to be allowed to do.  Once the limit has been reached, the student will not be able to retake the quiz.  Final Quiz grades will reflect the grade from the most recent attempt.
                            {currentQuizAttempts == 100 &&
                                <>
                                    <br /><br />Your class does not have a limit on the number of quiz attempts.
                                </>
                            }
                            {currentQuizAttempts != 100 &&
                                <>
                                    <br /><br />Your class is currently set for <strong>{currentQuizAttempts} </strong>attempts.
                                </>
                            }

                        </Form.Text>
                    </Col>
                </Row>
            </Container><br />
            {quizList}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {exceptionUpdateType == "ADD-CLOSE" &&
                            <>
                                Add a Close Date?
                            </>
                        }
                        {exceptionUpdateType == "ADD-OPEN" &&
                            <>
                                Add an Open Date?
                            </>
                        }
                        {exceptionUpdateType == "REMOVE-CLOSE" &&
                            <>
                                Remove a Close Date?
                            </>
                        }
                        {exceptionUpdateType == "REMOVE-OPEN" &&
                            <>
                                Remove an Open Date?
                            </>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table style={{ verticalAlign: "bottom" }}>
                        <td>
                            {exceptionUpdateType == "ADD-CLOSE" &&
                                <>
                                    Select a Date: <br />
                                    <input type="date" style={{ width: "100%" }} onChange={event => setCloseDate(event.target.value)} /> <br />
                                </>
                            }
                            {exceptionUpdateType == "REMOVE-CLOSE" &&
                                <>
                                    Remove Close Date?<br />
                                </>
                            }
                            {exceptionUpdateType == "ADD-OPEN" &&
                                <>
                                    Select a Date: <br />
                                    <input type="date" style={{ width: "100%" }} onChange={event => setOpenDate(event.target.value)} /> <br />
                                </>
                            }
                            {exceptionUpdateType == "REMOVE-OPEN" &&
                                <>
                                    Remove Open Date?<br />
                                </>
                            }
                        </td>
                        <td>
                            {allQuizDates === "ALL" &&
                                <>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label="Apply To All Quizzes"
                                        checked={allQuizDates === "ALL"}
                                        value="NO"
                                        style={{ 'margin-left': "5px" }} onChange={event => setAllQuizDates(event.target.value)}
                                    />
                                </>
                            }

                            {allQuizDates != "ALL" &&
                                <>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label="Apply To All Quizzes"
                                        checked={allQuizDates === "ALL"}
                                        value="ALL"
                                        style={{ 'margin-left': "5px" }} onChange={event => setAllQuizDates(event.target.value)}
                                    />
                                </>
                            }

                        </td>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={() => updateQuizException("blah", "blah")}>
                        Submit
                    </Button>
                </Modal.Footer>
                <div style={{ margin: "10px" }}>
                    {dateValidationError}
                </div>
            </Modal>

            <Modal
                show={showLoading}
                backdrop="static"
                centered
            >
                <div class="centerLoadingModel" >
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </Modal>
        </>
    )

}

export default QuizDatesComponentMain;