// export async function getUnitName(){
//     var tableOfContentsData = "";

//     if (localStorage.getItem('TOC') == null || localStorage.getItem("TOC") == undefined) {
//         tableOfContentsData = await transformTableOfContents(props.id);
//         // const renderStuff = await renderContent(mocUnitData3)
//         // setTableContents(renderStuff);
//     } else {
//         tableOfContentsData = JSON.parse(localStorage.getItem("TOC"));
//         // const renderStuff = await renderContent(mocUnitData3)
//         // setTableContents(renderStuff);
//     }

    
//     return "blah";

// }

export async function getModuleName(){
    

    
    return "blah";

}

export async function getChapterName(id){
    
    var tableOfContentsData = "";
    var responseData = "N/A";

    // if (localStorage.getItem('TOC') == null || localStorage.getItem("TOC") == undefined) {
        // tableOfContentsData = await transformTableOfContents(props.id);
        // const renderStuff = await renderContent(mocUnitData3)
        // setTableContents(renderStuff);
    // } else {
        tableOfContentsData = JSON.parse(localStorage.getItem("TOC"));
        // const renderStuff = await renderContent(mocUnitData3)
        // setTableContents(renderStuff);
    // }

    for(var c = 0; c < tableOfContentsData.length; c++){
        if(id == tableOfContentsData[c].id){
            responseData = tableOfContentsData[c].chapterTitle;
        }
    }

    // console.log("getChapterName" + responseData)

    
    return responseData;

}

export async function getUnitName(id){
    
    var tableOfContentsData = "";
    var responseData = "N/A";

    // if (localStorage.getItem('TOC') == null || localStorage.getItem("TOC") == undefined) {
        // tableOfContentsData = await transformTableOfContents(props.id);
        // const renderStuff = await renderContent(mocUnitData3)
        // setTableContents(renderStuff);
    // } else {
        tableOfContentsData = JSON.parse(localStorage.getItem("TOC"));
        // const renderStuff = await renderContent(mocUnitData3)
        // setTableContents(renderStuff);
    // }

    for(var c = 0; c < tableOfContentsData.length; c++){
        if(id == tableOfContentsData[c].id){
            responseData = tableOfContentsData[c].chapterTitle;
        }
    }

    // console.log("getChapterName" + responseData)

    
    return responseData;

}