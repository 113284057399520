import { getAllByPlaceholderText } from "@testing-library/react";

export function updateCustomerInfo(payload){
  //  const updatePayload = createUpdateCustiomerPayload(payload);

}

// function createUpdateCustiomerPayload(payload){
//     // const payloadDragy = {
//     //     "shoppingCartId" : "1234",
//     //     "customerDetails": {
//     //         "customerId": "fd97ccf9-9601-413b-8371-b6886105b87e",
//     //         "customerFirstName": "Prienka",
//     //         "customerLastName": "Chandrasekaran",
//     //         "customeremail": "sns69-004@email.com",
//     //         "customerType": "Student",
//     //         "customerBillingAddress": "Toronto, ON, Canada",
//     //         "existingCustomer": "N"
//     //       }
//     // }

//     return payload;
// }

export function addTax(payload){

    const updatedPayload = {
        "province" : payload.province,
        "scId" : payload.scId,
        "tax" : {
            "hst": payload.tax.hst
        }
    }

    const taxPayload = createUpdateTaxPayload(updatedPayload);
    const updateResults = updateShoppingCart(taxPayload);
    return updateResults;

}

function createUpdateTaxPayload(payload){
    const payloadDraft = {
        "shoppingCartId" : payload.scId,
        "updateType" : "tax",
        "taxProvince" : payload.province,
        "tax" : {
            "hst": payload.tax?.hst || 0,
            "gst": payload.tax?.gst || 0,
            "qst" : payload.tax?.qst || 0,
            "pst" : payload.tax?.pst || 0
        }
    }

    return payloadDraft;
}

async function updateShoppingCart(payload){
    const options = { 
        method: 'PATCH', 
        headers: new Headers({ 
          'Content-Type': 'application/json',
          'sb-application-name': 'sb-site-ui',
          'sb-application-key': 'test123',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
        }), 
        mode: 'cors',
        credentials: 'omit',
        body: JSON.stringify(payload)
      };
    
      const scURL = process.env.REACT_APP_BFF + '/updateShoppingCart';
      // let responseReturn = {};
    
      let updatedSCData =
        await fetch(scURL,options)
          .then(response => {
            // console.log(response);
            return response.json();
          })
          .catch(function(err) {
            console.log(err);
          });;

          return updatedSCData;
  
}