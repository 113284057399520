import React, { useState, useEffect } from "react";
// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
// import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookies from 'universal-cookie';
import { getOrderByID } from '../services/bffServices/getOrderDetails';
import Footer from '../components/footer';
const cookies = new Cookies();



export default function Thankyou() {

  const [orderData, setorderData] = useState({});
  const [productOrderData, setproductOrderData] = useState({});

  const orderDetails = async () => {
    // const order = await getOrderByID(cookies.get('sc-orderId'));
    const order = await getOrderByID(sessionStorage.getItem('sc-orderId'));

    // console.log("order order" + JSON.stringify(order));
    setorderData(order);
    setproductOrderData(order?.product[0]);
  }

  useEffect(() => {
    orderDetails();
  }, []);

  return (
    <div class="fill-window" style={{ 'background-color': "#f4f4f4" }}><br /><br />

      {/* <Container style={{align: "center"}} > */}
      <Col>
        <Container style={{ align: "center", width: "50%" }}>
          <Card >
            <Card.Body >
              <Card.Text>
                <h2>Welcome, and Thank You!</h2>
                <p>
                  You are all set to have access to <b>{productOrderData?.name}</b>.  You should have already been logged in.  To go to your dashboard, click <a href="/account">here</a>
                </p>
                <table align="center" width="40%" >
                  <tr align="left">
                    <td align="left">
                      <span style={{ color: "green" }}>&#9989;</span> Order #:
                    </td>
                    <td align="left">
                      {orderData?.id}
                    </td>
                  </tr>
                  <tr>
                    <td align="left">
                      <span style={{ color: "green" }}>&#9989;</span> Date:
                    </td>
                    <td align="left">
                      {orderData?.date}
                    </td>
                  </tr>
                  <tr>
                    <td align="left">
                      <span style={{ color: "green" }}>&#9989;</span> Total:
                    </td>
                    <td align="left">
                      ${orderData?.orderTotal?.Total}
                    </td>
                  </tr>
                  {orderData?.orderTotal?.Total > 0 &&
                    <tr>
                      <td align="left">
                        <span style={{ color: "green" }}>&#9989;</span> Payment Method:
                      </td>
                      <td align="left">
                        {orderData?.orderTotal?.paymentMethod}
                      </td>
                    </tr>
                  }
                </table>
              </Card.Text>
            </Card.Body>
          </Card><br />
        </Container>
      </Col>

      <Col style={{ align: "center" }}>
        <Container style={{ align: "center", width: "50%" }}>
          <Card >
            {/* <Card.Header as="h5">Purchase Information</Card.Header> */}
            <Card.Body >
              <Card.Text>
                Thank you for Purchasing! See order details below! <br /><br />
                <table align="center">
                  <tr>
                    <td align="left">
                      <strong>Item:</strong>
                    </td>
                    <td>
                      {productOrderData?.name}
                      {/* <div>
                <ul style={{width: "50%"}}>
                  <li>
                    Lesson 001
                  </li>
                  <li>
                    Lesson 002
                  </li>
                  <li>
                    Lesson 003
                  </li>
                  <li>
                    Lesson 004
                  </li>
                  <li>
                    Lesson 005
                  </li>
                </ul>
                </div> */}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td align="left">
                      <strong>Subtotal:</strong>
                    </td>
                    <td align="right">
                      ${orderData?.orderTotal?.subTotal.toFixed(2)}
                    </td>
                  </tr>
                  {orderData?.orderTotal?.totalDiscounts != 0 &&
                    <tr>
                      <td align="left">
                        <strong>Total Discounts:</strong>
                      </td>
                      <td align="right">
                        $-{orderData?.orderTotal?.totalDiscounts}
                      </td>
                    </tr>
                  }
                  <tr>
                    <td align="left">
                      <strong>Taxes:</strong>
                    </td>
                    <td align="right">
                      ${orderData?.orderTotal?.taxes.toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td align="left">
                      <strong>Total Paid:</strong>
                    </td>
                    <td align="right">
                      ${orderData?.orderTotal?.Total.toFixed(2)}
                    </td>
                  </tr>
                  {orderData?.orderTotal?.Total.toFixed(2) > 0 &&
                    <tr>
                      <td align="left">
                        <strong>Payment Type:</strong>
                      </td>
                      <td align="right">
                        {orderData?.orderTotal?.paymentMethod}
                      </td>
                    </tr>
                  }
                </table>

              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
      </Col>
      <br /><br />
      <Footer />
      {/* </Row> */}
      {/* </Container> */}
    </div>
  );
}