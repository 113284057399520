// import { useEffect, useState } from "react";

// function SmartTooltip({ children, tip, textAlign}) {

//     const [isAutomatic, setAutomatic] = useState(true);
//     const [isHovered, setHovered] = useState(false);
//     const [isFocused, setFocused] = useState(false);
//     const [isMobile, setIsMobile] = useState(false);

//     useEffect(() => {
// 			const handleResize = () => {
// 				setHovered(false);
// 				setFocused(false);
// 				setIsMobile(window.innerWidth <= 768);
// 			};

// 			handleResize(); // Check initial screen width
// 			window.addEventListener("resize", handleResize); // Update on resize

// 			return () => {
// 				window.removeEventListener("resize", handleResize); // Cleanup
// 			};
// 		}, []);

//     const handleMouseEnter = () => {
//         setHovered(true);
//     }

//     const handleMouseLeave = () => {
//         setHovered(false);
//     }

//     const handleFocus = () => {
//         setFocused(true);
//     }

//     const handleBlur = () => {
//         setFocused(false);
//     }
	
//     return !isMobile ? (
// 			<div className="field">
// 				<div
// 					style={{ alignContent: "left", textAlign: "left" }}
// 					onMouseEnter={handleMouseEnter}
// 					onMouseLeave={handleMouseLeave}
// 					onFocus={handleFocus}
// 					onBlur={handleBlur}
// 				>
// 					{children}
// 				</div>
// 				<div
// 					id="tooltip"
// 					className="automatic-tooltip"
// 					style={{
// 						display: isFocused || isHovered ? "block" : "none",
// 						textAlign: "center",
// 					}}
// 					onMouseEnter={handleMouseEnter}
// 					onMouseLeave={handleMouseLeave}
// 				>
// 					{tip}
// 				</div>
// 			</div>
// 		) : (
// 			<div style={{display: 'flex', position: 'relative'}}>
// 				<div
// 					style={{ alignContent: "left", textAlign: "left" }}>
// 					{children}
// 				</div>
// 				<div class="trigger" tabindex="0" 
//                     onMouseEnter={handleMouseEnter}
// 					onMouseLeave={handleMouseLeave}
// 					onFocus={handleFocus}
// 					onBlur={handleBlur}>
                
// 					<span class="fas fa-question" aria-hidden="true"></span>
// 					<span class="sr-only">Why do you need my email?</span>
// 				</div>
//                <div
// 					id="tooltip"
// 					className="automatic-tooltip"
// 					style={{
// 						display: isFocused || isHovered ? "block" : "none",
// 						textAlign: "center",
// 					}}
// 					onMouseEnter={handleMouseEnter}
// 					onMouseLeave={handleMouseLeave}
// 				>
// 					{tip}
// 				</div>
// 			</div>
// 		);
// }

// export default SmartTooltip;

import { useEffect, useState } from "react";

function SmartTooltip({ children, tip, textAlign }) {
    const [isHovered, setHovered] = useState(false);
    const [isFocused, setFocused] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setHovered(false);
            setFocused(false);
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize(); // Check initial screen width
        window.addEventListener("resize", handleResize); // Update on resize

        return () => {
            window.removeEventListener("resize", handleResize); // Cleanup
        };
    }, []);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };

    return !isMobile ? (
        <div className="field">
            <div
                style={{ alignContent: "left", textAlign: "left" }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onFocus={handleFocus}
                onBlur={handleBlur}
                tabIndex="0" // Make the trigger focusable
                aria-describedby="tooltip" // Associate with tooltip
            >
                {children}
            </div>
            <div
                id="tooltip"
                className="automatic-tooltip"
                style={{
                    display: isFocused || isHovered ? "block" : "none",
                    textAlign: textAlign || "center",
                }}
                role="tooltip"
            >
                {tip}
            </div>
        </div>
    ) : (
        <div style={{ display: 'flex', position: 'relative' }}>
            <div style={{ alignContent: "left", textAlign: "left" }}>
                {children}
            </div>
            <div
                className="trigger"
                tabIndex="0" // Make the trigger focusable
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onFocus={handleFocus}
                onBlur={handleBlur}
                // aria-describedby="tooltip" // Associate with tooltip
            >
                <span className="fas fa-question" aria-hidden="true"></span>
                <span className="sr-only">Why do you need my email?</span>
            </div>
            <div
                id="tooltip"
                className="automatic-tooltip"
                style={{
                    display: isFocused || isHovered ? "block" : "none",
                    textAlign: textAlign || "center",
                }}
                role="tooltip"
            >
                {tip}
            </div>
        </div>
    );
}

export default SmartTooltip;
