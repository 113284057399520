export async function postDuplicateModule(payload){

    const endpoint = process.env.REACT_APP_BFF + "/bff/v1.0/copyModule/";
    // const endpoint = "http://localhost:3001/bff/v1.0/copyModule/";


    const options = { 
      method: 'POST', 
      headers: new Headers({ 
        'Content-Type': 'application/json',
        'sb-application-name': 'sb-site-ui',
        'sb-application-key': 'jmd-test',
        'Access-Control-Allow-Origin': '*',
        'sessionID' : sessionStorage.getItem("sessionID"),
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
      }), 
      mode: 'cors',
      credentials: 'omit',
      body: JSON.stringify(payload)
    };

    var newModule = await fetch(endpoint,options)
    .then(function(response){return response.json()});;
 
    return newModule;
}