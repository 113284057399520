import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton
} from 'video-react';


export default function ExtraContentMainComponent(props) {
    // console.log("extraStuff" + JSON.stringify(props));
    // console.log("src" + props.src);
//     if(props.srcType == "interactive" ){
//         const searchString = props.src;
// const searchRegex = /^.*?(?=&)/;
// const searchResult = searchString.match(searchRegex);
// const finalString = searchResult[0].replace(/^.*?(?=\/)/, '');
//     }


    return (
        <div>
            <h3>{props.title}</h3>
            <div align="justify" style={{ padding: "20px"}}><small>{props.summary}</small></div>
            {props.srcType == "image" &&
                <><br/>
                <img src={props.src} style={{width: "95%", height: "200px", 'max-height': "200px", 'min-height': "100%" }} />
                </>
            }
            {props.srcType == "interactive" &&
                <div><br />
                    <iframe loading="lazy" style={{ visibility: "visible", opacity: "1"}} src={props.src} width="95%" height="200">
                        <script type="text/javascript" src="https://storage.googleapis.com/interactive-structures/protein-viewer/vendor.75c1a58f3a7314a88499.js"></script>
                        <script type="text/javascript" src="https://storage.googleapis.com/interactive-structures/protein-viewer/main.75c1a58f3a7314a88499.js"></script>
                        <div class="whs-app" style={{ width: "inherit", height: "inherit", position: "relative" }}>
                            <canvas style={{ width: "100%", height: "200px", 'max-height': "200px", 'min-height': "100%"  }}></canvas></div>
                    </iframe>
                </div>
            }
            {props.srcType == "video" &&
                <>
                    
                    <Player
                            playsInline
                            poster="https://storage.googleapis.com/sb-general/wp-content/uploads/2019/01/Paramecium-Poster-Image-3.jpg"
                            src={props.src}
                            
                            style={{'max-height': "200px", 'min-height': "100%" }}
                        >
                            <ControlBar>
                                <ReplayControl seconds={10} order={1.1} />
                                <ForwardControl seconds={30} order={1.2} />
                                <CurrentTimeDisplay order={4.1} />
                                <TimeDivider order={4.2} />
                                <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                                <VolumeMenuButton enabled />
                            </ControlBar>
                        </Player>
                </>
            }
        </div>
    )

}