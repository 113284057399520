import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import {
	CDBFooter,
	CDBFooterLink,
	CDBBtn,
	CDBIcon,
	CDBContainer,
	CDBBox,
} from "cdbreact";
import { ReactComponent as XIcon } from "../assets/images/x.svg";
import logo from "../assets/images/Logo-3D-White-Text-0100.png";
import ContactUsPageMainComponent from "./contactUsPage/contactUsMainComponent";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function Footer() {
	const [isMobile, setIsMobile] = useState(false);
	const [contactUsModalShow, setContactUsModalShow] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div className="footer">
			{!isMobile ? (
				<Container>
					<div
						style={{
							padding: "20px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Navbar.Brand href="/">
							<img
								src={logo}
								height="30px"
								className="d-inline-block align-top"
								alt="Smart-Biology Inc."
							/>
						</Navbar.Brand>
						<div>
							<small
								style={{
									color: "white",
									width: "200px",
									fontSize: "10px",
									textAlign: "left",
									display: "block",
								}}
							>
								&copy; 2015-2024
							</small>
							<small
								style={{
									color: "white",
									width: "200px",
									fontSize: "10px",
									textAlign: "left",
									display: "block",
								}}
							>
								Smart Biology, All rights reserved.
							</small>
							<small
								style={{
									color: "white",
									width: "200px",
									fontSize: "10px",
									textAlign: "left",
									display: "block",
								}}
							>
								Cell and Molecular Biology Animation Education.
							</small>
						</div>
						<div
							style={{
								borderLeft: "1px solid white",
								height: "50px",
								marginLeft: "0px",
								paddingLeft: "20px",
							}}
						/>
						<div>
							{" "}
							<a
								style={{
									color: "white",
									textDecoration: "none",
									fontSize: "12px",
								}}
								href="/privacy-and-terms"
							>
								Terms & Conditions | Privacy Statement
							</a>
						</div>
						<div
							style={{
								borderLeft: "1px solid white",
								height: "50px",
								marginLeft: "20px",
								paddingLeft: "20px",
							}}
						/>
						<div>
							<Nav>
								<Nav.Link
									style={{ color: 'white' }}
									onClick={() => {
										setContactUsModalShow(true);
									}}>

									Contact Us
								</Nav.Link>
							</Nav>
						</div>
						<div
							style={{
								borderLeft: "1px solid white",
								height: "50px",
								marginLeft: "20px",
								paddingLeft: "20px",
							}}
						/>
						<CDBBox
							display="flex"
							justifyContent="center"
							alignItems="center" // Vertically center icons
							gap="20px" // Adjust the gap value as needed
							style={{ width: "20%" }}
						>
							<ul style={{ 'list-style-type': "none", 'padding-left': 0, 'padding-bottom': 0,display: "flex" }}>

								<li>
									<a
										aria-label="opens facebook.com"
										href="https://www.facebook.com/smartbiology3d/"
										style={{ margin: '10px' }}
									>
										<span aria-hidden="true"><CDBIcon style={{ color: "white" }} size="2x" fab icon="facebook-f" /></span>
									</a>
								</li>
								<li>
									<a aria-label="opens x.com"
										href="https://x.com/SmartBiology3D"
										style={{ margin: '10px' }}>
										<div className="sr-only">opens x.com</div>
										<span aria-hidden="true">
											<svg viewBox="0 0 24 24" aria-hidden="true" className="x-logo" style={{ width: '30px', height: '30px' }}>
												<g>
													<path
														d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
														stroke="white"
														fill="none"
														strokeWidth="2"
													/>
												</g>
											</svg>
										</span>
									</a>
								</li>
								<li>
									<a
										aria-label="opens instagram.com"
										href="https://www.instagram.com/smartbiology3d/"
										style={{ margin: '10px' }}
									>
										<span aria-hidden="true"><CDBIcon style={{ color: "white" }} size="2x" fab icon="instagram" /></span>
									</a>
								</li>
								<li>
									<a
										aria-label="opens linked in.com"
										href="https://www.linkedin.com/company/smart-biology/mycompany/"
										style={{ margin: '10px' }}
									>
										<span aria-hidden="true"><CDBIcon style={{ color: "white" }} size="2x" fab icon="linkedin" /></span>
									</a>
								</li>
							</ul>
						</CDBBox>
					</div>
				</Container>
			) : (
				<Container
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<div
						style={{
							padding: "20px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Navbar.Brand href="/">
							<img
								src={logo}
								height="20px"
								className="d-inline-block align-top"
								alt="Smart-Biology Inc."
							/>
						</Navbar.Brand>
						<div>
							<small
								style={{
									color: "white",
									width: "200px",
									fontSize: "10px",
									textAlign: "left",
									display: "block",
								}}
							>
								&copy; 2015-2024
							</small>
							<small
								style={{
									color: "white",
									width: "200px",
									fontSize: "10px",
									textAlign: "left",
									display: "block",
								}}
							>
								Smart Biology, All rights reserved.
							</small>
							<small
								style={{
									color: "white",
									width: "200px",
									fontSize: "10px",
									textAlign: "left",
									display: "block",
								}}
							>
								Cell and Molecular Biology Animation Education.
							</small>
						</div>
					</div>
					<div>
						{" "}
						<a
							style={{
								color: "white",
								textDecoration: "none",
								fontSize: "12px",
							}}
							href="/privacy-and-terms"
						>
							Terms & Conditions | Privacy Statement
						</a>
					</div>
					<div>
						<Nav>
							<Nav.Link
								onClick={() => {
									setContactUsModalShow(true);
								}}>

								Contact Us
							</Nav.Link>
						</Nav>
					</div>
					<ul style={{ 'list-style-type': "none", 'padding-left': 0, 'padding-bottom': 0 }}>

						<CDBBox
							display="flex"
							style={{ width: "100%", justifyContent: "center" }}
							aria-hidden="true"
						>
							<li>
								<a
									aria-label="opens facebook.com"
									style={{ margin: "20px" }}
									href="https://www.facebook.com/smartbiology3d/"
								>
									<span aria-hidden="true">
										<CDBIcon
											aria-hidden="true"
											style={{ color: "white" }}
											size="2x"
											fab
											icon="facebook-f"
										/>
									</span>
								</a>
							</li>
							<li>

								<a href="https://x.com/SmartBiology3D" style={{ margin: '20px' }}>
									<div className="sr-only">opens x.com</div>
									<span aria-hidden="true">
										<svg viewBox="0 0 24 24" aria-hidden="true" className="x-logo" style={{ width: '30px', height: '30px' }}> {/* Adjust size as needed */}
											<g>
												<path
													d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
													stroke="white"
													fill="none"
													strokeWidth="2"
												/>
											</g>
										</svg>
									</span>
								</a>
							</li>
							<li>

								<a
									aria-label="opens instagram.com"
									style={{ margin: "20px" }}
									href="https://www.instagram.com/smartbiology3d/"
								>
									<span aria-hidden="true">
										<CDBIcon
											style={{ color: "white" }}
											size="2x"
											fab
											icon="instagram"
										/>
									</span>
								</a>
							</li>
							<li>

								<a
									aria-label="opens linked in.com"
									style={{ margin: "20px" }}
									href="https://www.linkedin.com/company/smart-biology/mycompany/"
								>
									<span aria-hidden="true">
										<CDBIcon
											style={{ color: "white" }}
											size="2x"
											fab
											icon="linkedin"
										/>
									</span>
								</a>
							</li>
						</CDBBox>
					</ul>
				</Container>
			)}
			<Modal
				show={contactUsModalShow}
				onHide={() => setContactUsModalShow(false)}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<ContactUsPageMainComponent
				></ContactUsPageMainComponent>
			</Modal>{" "}
		</div>
	);
}

export default Footer;
