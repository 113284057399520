import Button from 'react-bootstrap/Button';
import { updateUserProgress } from '../../services/bffServices/textbookBFFServices/updateUserProgress';
import React, { useState, useEffect } from "react";
import { getUserProgressByItemId } from '../../services/bffServices/getUserDetails';
import { isItemComplete } from '../../services/isItemComplete';
import { getExistingSession } from '../../services/bffServices/getSessionInfo';
import Modal from 'react-bootstrap/Modal';
import { IoIosAlert } from "react-icons/io";
import { IconContext } from "react-icons";
const language = sessionStorage.getItem('lang') || "EN";

function UpdateButtonComponent(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [multiSessionUI, setMultiSessionUI] = useState(false);
    const [buttonActive, setButtonActive] = useState(false);
    const [buttonText, setButtonText] = useState();
    // const [buttonText, setButtonText] = useState(() => {
    //     if (props.type == "MODULE") {

    //         if (language == "FR") {
    //             return "Module complet"
    //         }

    //         if (language != "FR") {
    //             return "Complete Module"
    //         }
    //     }

    //     if (multiSessionUI !== true) {
    //         if (props.type == "LESSON") {
    //             if (sessionStorage.getItem('lastLesson') !== "Y") {
    //                 if (language == "FR") {
    //                     return ("Terminer et passer à la leçon suivante");
    //                 }

    //                 if (language != "FR") {
    //                     return ("Complete & Move to Next Lesson");
    //                 }
    //             }

    //             if (sessionStorage.getItem('lastLesson') === "Y") {
    //                 if (language == "FR") {
    //                     return ("Terminer la leçon et passer au module suivant");
    //                 }

    //                 if (language != "FR") {
    //                     return ("Complete Lesson & Move to the Next Module");
    //                 }
    //             }
    //         }
    //     }

    //     if (multiSessionUI === true) {
    //         if (sessionStorage.getItem('lastLesson') !== "Y") {
    //             if (language == "FR") {
    //                 return ("Passer à la leçon suivante");
    //             }

    //             if (language != "FR") {
    //                 return ("Move to Next Lesson");
    //             }
    //         }

    //         if (sessionStorage.getItem('lastLesson') === "Y") {
    //             if (language == "FR") {
    //                 return ("Passer au module suivant");
    //             }

    //             if (language != "FR") {
    //                 return ("Move to the Next Module");
    //             }
    //         }
    //     }

    // });

    useEffect(async () => {
        const userSession = await getExistingSession();
        var multiSesson = userSession.payload.multiSession || false;
        setMultiSessionUI(multiSesson);

        if (multiSessionUI !== true) {
            if (props.type == "LESSON") {
                var lessonStatus = await getUserProgressByItemId("LESSON", props.id);
                if (lessonStatus == "COMPLETE") {
                    setButtonActive(true);
                    setButtonText("Lesson Completed");
                }
            }
            if (props.type == "MODULE") {
                var moduleStatus = await getUserProgressByItemId("MODULE", props.id);
                if (moduleStatus == "COMPLETE") {
                    setButtonActive(true);
                    setButtonText("Module Completed!");
                }
            }
        }

        if (multiSessionUI === true) {
            setButtonActive("");
        }

        setButtonText(() => {
            if (props.type == "MODULE") {

                if (language == "FR") {
                    return "Module complet"
                }

                if (language != "FR") {
                    return "Complete Module"
                }
            }

            if (multiSessionUI !== true) {
                if (props.type == "LESSON") {
                    if (sessionStorage.getItem('lastLesson') !== "Y") {
                        if (language == "FR") {
                            return ("Terminer et passer à la leçon suivante");
                        }

                        if (language != "FR") {
                            return ("Complete & Move to Next Lesson");
                        }
                    }

                    if (sessionStorage.getItem('lastLesson') === "Y") {
                        if (language == "FR") {
                            return ("Terminer la leçon et passer au module suivant");
                        }

                        if (language != "FR") {
                            return ("Complete Lesson & Move to the Next Module");
                        }
                    }
                }
            }

            if (multiSessionUI === true) {
                if (sessionStorage.getItem('lastLesson') !== "Y") {
                    if (language == "FR") {
                        return ("Passer à la leçon suivante");
                    }

                    if (language != "FR") {
                        return ("Move to Next Lesson");
                    }
                }

                if (sessionStorage.getItem('lastLesson') === "Y") {
                    if (language == "FR") {
                        return ("Passer au module suivant");
                    }

                    if (language != "FR") {
                        return ("Move to the Next Module");
                    }
                }
            }
        })
    }, [props.nextModuleInd, multiSessionUI]);


    const updateStatusAndButton = async () => {
        if (props.type == "LESSON") {
            setButtonActive(true);
            updateUserProgress(props.type, props.id, props.status)
            setButtonText("Lesson Completed");
            const nextTab = Number(props.nextLesson) + 1
            props.eventFunction(nextTab, props.maxKey);
            setTimeout(function () {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }, 2);
        }
        if (props.type == "MODULE") {
            var areAllLessonCompleted = await isItemComplete("MODULE", props.id);
            if (areAllLessonCompleted.total == areAllLessonCompleted.totalComplete) {
                setButtonText("Module Completed!");
                setButtonActive(true);
                updateUserProgress(props.type, props.id, props.status)
            }
            if (areAllLessonCompleted.total != areAllLessonCompleted.totalComplete) {
                setShow(true)
            }
        }
    }

    const updateStatusAndButtonMultiSession = async (multiSessionUI2) => {
        if (props.type == "LESSON") {
            const nextTab = Number(props.nextLesson) + 1
            
            props.eventFunction(nextTab, props.maxKey);
            setTimeout(function () {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }, 2);
        }

        setButtonText(() => {
            const isLastLesson = sessionStorage.getItem('lastLesson') || "N";
            if (props.type == "MODULE") {

                if (language == "FR") {
                    return "Module complet"
                }

                if (language != "FR") {
                    return "Complete Module"
                }
            }

            if (multiSessionUI2 !== true) {
                if (props.type == "LESSON") {
                    if (isLastLesson!== "Y") {
                        if (language == "FR") {
                            return ("Terminer et passer à la leçon suivante");
                        }

                        if (language != "FR") {
                            return ("Complete & Move to Next Lesson");
                        }
                    }

                    if (isLastLesson === "Y") {
                        if (language == "FR") {
                            return ("Terminer la leçon et passer au module suivant");
                        }

                        if (language != "FR") {
                            return ("Complete Lesson & Move to the Next Module");
                        }
                    }
                }
            }

            if (multiSessionUI2 === true) {
                if (isLastLesson !== "Y") {
                    if (language == "FR") {
                        return ("Passer à la leçon suivante");
                    }

                    if (language != "FR") {
                        return ("Move to Next Lesson");
                    }
                }
                if (isLastLesson === "Y") {
                    if (language == "FR") {
                        return ("Passer au module suivant");
                    }

                    if (language != "FR") {
                        return ("Move to the Next Module");
                    }
                }
            }
        })
    }

    return (
        <>
            {multiSessionUI !== true &&
                <>
                    <Button onClick={async () => {
                        updateStatusAndButton();
                    }} disabled={buttonActive}>
                        {buttonText}
                    </Button>
                </>
            }

            {multiSessionUI === true &&
                <>
                    <Button onClick={async () => {
                        updateStatusAndButtonMultiSession(multiSessionUI);
                    }}>
                        {buttonText}
                    </Button>
                </>
            }

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >

                <Modal.Body>
                    <IconContext.Provider value={{ color: "red", className: "global-class-name", size: "1.5em" }}>
                        <IoIosAlert />
                    </IconContext.Provider>
                    {language == "FR" &&
                        <>
                            Veuillez terminer toutes les leçons avant de terminer le module.

                        </>
                    }
                    {language != "FR" &&
                        <>
                            Please Complete All Lessons before completing the module.

                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UpdateButtonComponent;