import { Button, Col, Container, Row, Card, Form } from "react-bootstrap";
import { transformTableOfContents } from "../../services/unitTransformer";
import React, { useState, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion'


function CourseSelectionSingleComponenet(props) {

    var testUnitId = "61788ab423d456322035bbb3";
    const [tableContents, setTableContents] = useState("");



    useEffect(async () => {

        // console.log("payload==start");
        await transformTableOfContents(testUnitId)
            .then(payload => setTableContents(() => {

                // console.log("payload" + JSON.stringify(payload));

                return (
                    <>
                        {Object.keys(payload).map(function (key) {
                            return (
                                <>
                                <Accordion >
                                <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <div style={{ 'font-size': "14px", 'font-weight': "700", }}>
                                        <table>
                                            <tr>
                                                <td style={{ 'vertical-align': "top" }}>

                                                    <Form.Check
                                                        type="checkbox"
                                                        id={payload[key].id}
                                                    />
                                                </td>
                                                <td>
                                                {payload[key].chapterTitle}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    <div style={{ 'font-size': "12px", 'font-weight': "600" }}>
                                        {Object.keys(payload[key].module).map(function (module) {
                                            return (
                                                <>

                                                    <table>
                                                        <tr>
                                                            <td style={{ 'vertical-align': "top", 'padding-left': "15px" }}>

                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={payload[key].module[module].id}
                                                                />
                                                            </td>
                                                            <td>
                                                                {payload[key].module[module].title_en}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    {Object.keys(payload[key].module[module].lesson).map(function (lesson) {
                                                        return (
                                                            <>
                                                                <div style={{ 'font-size': "10px", 'font-weight': "400" }}>
                                                                    <table>
                                                                        <tr>
                                                                            <td style={{ 'vertical-align': "top", 'padding-left': "20px" }}>

                                                                                <Form.Check
                                                                                    type="checkbox"
                                                                                    id={payload[key].module[module].lesson[lesson].id}
                                                                                    
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                            {payload[key].module[module].lesson[lesson].title_en}
                                                                            </td>
                                                                        </tr>
                                                                    </table>

                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    )
                                                    }
                                                </>
                                            )
                                        })}

                                    </div>
                                    </Accordion.Body>
                                    </Accordion.Item>
                                    </Accordion>
                                </>
                            )
                        }
                        )}

                    </>
                )
            }));

    }, []);




    return (

        <Card style={{ width: "90%", margin: "auto", padding: "10px" }}>
            <Row >
                <Col>
                    <span>Life | Unit 1 | From Atoms to Cells | <br />Starting Small to Understand the Big</span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="d-grid gap-2">
                        <Button size="sm">Select All</Button>
                    </div>
                </Col>
                <Col>
                    <div className="d-grid gap-2">
                        <Button variant="secondary" size="sm">Clear All</Button>
                    </div>
                </Col>
            </Row><br></br>
            <Row>
                <Col style={{ 'text-align': "left" }}>

                    {tableContents}

                </Col>
            </Row>
        </Card >
    )
}


export default CourseSelectionSingleComponenet;
