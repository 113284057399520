import React, { useRef, useEffect } from "react";
import { useState } from "react";

function ParalaxImage({image}) {
	const [offsetY, setOffsetY] = useState(0);
	const handleScroll = () => setOffsetY(window.scrollY);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	return (
		<section>
			<div >
				<img src={image}
                style={{width: "110%",
						height: "600px",
						objectFit: "cover", transform: `translateY(${offsetY * 0.2}px)`,
                        position: 'center'}}></img>
			</div>
		</section>
	);
}

export default ParalaxImage;
