import React from 'react';
import { render } from 'react-dom';
import Gallery from 'react-grid-gallery';
const domainURL = "https://storage.googleapis.com/sb-general" //https://storage.googleapis.com/sb-general/

 
const IMAGES =
[{
        src: domainURL + "/wp-content/uploads/2019/02/Life-U1-C4-MMP-M3-L9-Figure4.jpg",
        thumbnail: domainURL + "/wp-content/uploads/2019/02/Life-U1-C4-MMP-M3-L9-Figure4-150x150.jpg",
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        caption: ""
},
{
        src: domainURL + "/wp-content/uploads/2019/02/Life-U1-C5-TCW-3-OtherComponents-7-CellMembrane.jpg",
        thumbnail: domainURL + "/wp-content/uploads/2019/02/Life-U1-C5-TCW-3-OtherComponents-7-CellMembrane-150x150.jpg",
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        tags: [{value: "Ocean", title: "Ocean"}, {value: "People", title: "People"}],
        caption: ""
},
 
{
        src: domainURL + "/wp-content/uploads/2019/02/Life-U1-C5-TCW-3-OtherComponents-4-MitoChloro.jpg",
        thumbnail: domainURL + "/wp-content/uploads/2019/02/Life-U1-C5-TCW-3-OtherComponents-4-MitoChloro-150x150.jpg",
        thumbnailWidth: 150,
        thumbnailHeight: 150
}]
 


function ImageGrid()
{

  return (
    <>
        <Gallery images={IMAGES}/>,
    </>
  )

}

export default ImageGrid;

