import Card from 'react-bootstrap/Card'
import React, { useState, useEffect } from "react";
import SchoolDataPrivacyStatementComponent from './schoolDataPrivacyStatementComponent';
import FermaPolicytComponent from './ferpaPolicyComponent';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function PrivacyPolicyTCMainComponent(props) {

    return (
        <div style={{'display':"block",'padding': "60px", backgroundColor: "#f8f9fa", justifyContent: "center", alignItems: "center" }}>
            <Row>
                <Col span="2">
                <div style={{ color: "#000080", 'text-align': "center" }}><h3>Terms & Conditions and Privacy Statements</h3></div>
                <hr /><br />
                </Col>
            </Row>
            <Row>
                <Col xl={6}>
                <SchoolDataPrivacyStatementComponent /><br />
                </Col>
                <Col xl={6}>
                <FermaPolicytComponent />
                </Col>
            </Row>

        </div>
    )
}