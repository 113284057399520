import React, { useState, useRef, useEffect } from 'react';

const CustomAudioPlayer = ({ audioFile, lessonAudioLabel }) => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0); // Percentage of audio progress
    const [duration, setDuration] = useState(0); // Duration in seconds

    // Toggle play/pause
    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    // Update progress as the audio plays
    const handleTimeUpdate = () => {
        const currentTime = audioRef.current.currentTime;
        const duration = audioRef.current.duration;
        setProgress((currentTime / duration) * 100);
    };

    // Set duration once the audio is loaded
    const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration);
    };

    // Seek to a different part of the audio
    const handleSeek = (event) => {
        const seekTime = (event.nativeEvent.offsetX / event.target.clientWidth) * duration;
        audioRef.current.currentTime = seekTime;
        setProgress((seekTime / duration) * 100);
    };

    // Format time in minutes and seconds
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    return (
        <div style={{ width: '100%', maxWidth: '500px' }}>
            <audio
                ref={audioRef}
                src={audioFile}
                preload="auto"
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleLoadedMetadata}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <button 
                    onClick={togglePlay} 
                    aria-label={isPlaying ? "Pause" : "Play"}
                    style={{
                        background: '#4caf50',
                        border: 'none',
                        borderRadius: '50%',
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        color: 'white',
                        fontSize: '20px',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                        transition: 'background 0.3s ease',
                    }}
                    onMouseEnter={(e) => e.currentTarget.style.background = '#45a049'}
                    onMouseLeave={(e) => e.currentTarget.style.background = '#4caf50'}
                >
                    {isPlaying ? '❚❚' : '►'}
                </button>
                {/* <div style={{ paddingLeft: '20px', paddingTop: '5px' }}>
                    <h4><strong>{lessonAudioLabel}</strong></h4>
                </div> */}
            </div>
            <div
                style={{
                    position: 'relative',
                    height: '10px',
                    background: '#ddd',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    marginTop: '10px'
                }}
                onClick={handleSeek}
            >
                <div
                    style={{
                        width: `${progress}%`,
                        height: '100%',
                        background: '#4caf50',
                        borderRadius: '5px'
                    }}
                ></div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                <span>{formatTime(audioRef.current?.currentTime || 0)}</span>
                <span>{formatTime(duration)}</span>
            </div>
        </div>
    );
};

export default CustomAudioPlayer;
