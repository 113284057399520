import {getUserProgressSummary} from '../getUserDetails';

const options = { 
    method: 'PATCH', 
    headers: new Headers({ 
      'Content-Type': 'application/json',
      'sb-application-name': 'sb-site-ui',
      'sb-application-key': 'jmd-test',
      'sessionID' : sessionStorage.getItem("sessionID"),
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
    }), 
    mode: 'cors',
    credentials: 'omit',
    body: {}
  };

  export async function updateUserProgress(type,id,status){

    const endpoint = process.env.REACT_APP_BFF + '/updateUserProgress/';

    var body = {
        "type" : type,
        "id" : id,
        "status": status
    }
    // console.log("BODY" + JSON.stringify(body))
    options.body = JSON.stringify(body);

      await fetch(endpoint,options)
        .then(response => {
          getUserProgressSummary();
          return response.json();
        })
        .catch(function(err) {
          console.log(err);
        });
  }

  export async function updateQuizEntries(unit, module, lesson, quiz, submittedAnswers, gradeDetails){

  
    const endpoint = process.env.REACT_APP_BFF + '/updateQuiz';
    var payload = {
      unit,
      module,
      lesson,
      quiz,
      submittedAnswers,
      gradeDetails
    }

    options.body = JSON.stringify(payload);

      await fetch(endpoint,options)
        .then(response => {
          return response.json();
        })
        .catch(function(err) {
          console.log(err);
        });

  }

