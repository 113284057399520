import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { updateDiscussionForumSettingsService, getDiscussionForumSettings } from '../../../../services/generalSettings/discussionForumSettings';
import { getClassListTeachers } from '../../../../services/bffServices/getClassesByTeacher';

function GeneralSettingsComponentMain() {

    const [classSelected, setClassSelected] = useState("");
    const [optionsList, setOptionsList] = useState();
    const [discussionForumIsEnabled, setDiscussionForumIsEnabled] = useState("");
    const [discussionForumIsEnabledCheckBox, setDiscussionForumIsEnabledCheckBox] = useState();
    const [showSettings, setShowSettings] = useState("none");
    const [schoolList, setSchoolList] = useState();
    const [disabledSelectButton, setDisabledSelectButton] = useState(true);

    const getClassInfo = async () => {
        try {
            const schools = await getClassListTeachers();
            if (schools.length != 0) {
                setSchoolList(() => {
                    return (
                        <>
                            {schools.map(classSelected => (
                                <>
                                    <option value={classSelected.schoolId}>{classSelected.schoolName}</option>
                                </>
                            ))}
                        </>
                    );
                });
            } else {
                setSchoolList(() => {
                    return (
                        <>

                            <>
                                <option value="N/A">No Schools Assigned</option>
                            </>

                        </>
                    );
                });
            }

        } catch (error) {
            console.error(error);
        }
    };

    const updateDiscussionForumSettings = async () => {

        var newValue = "";
        if (discussionForumIsEnabled === true) {
            setDiscussionForumIsEnabled(false);
            newValue = false;
            setDiscussionForumIsEnabledCheckBox(true);
        } else if (discussionForumIsEnabled === false) {
            setDiscussionForumIsEnabled(true);
            newValue = true;
            setDiscussionForumIsEnabledCheckBox(false);
        }

        var payload = {
            "classId": classSelected,
            "type": "discussionForum",
            "subType": "ENABLE",
            "value": newValue
        }

        updateDiscussionForumSettingsService(payload, payload.type, payload.subType);

    }

    const loadQuizList = async () => {
        setShowSettings("")
        var existingSettings = await getDiscussionForumSettings(classSelected);

        if (existingSettings == false) {
            setDiscussionForumIsEnabledCheckBox(false);
        }
        if (existingSettings[0]?.value == true) {
            setDiscussionForumIsEnabledCheckBox(false);
        }
        if (existingSettings[0]?.value == false) {
            setDiscussionForumIsEnabledCheckBox(true);
        }

        setOptionsList(() => {
            return (
                <div align="left" style={{ width: "100%" }}>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>
                                    Discussion Forums {discussionForumIsEnabled}
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            Disable Discussion:
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                type="checkbox"
                                                checked={discussionForumIsEnabledCheckBox}
                                                onChange={event => updateDiscussionForumSettings({
                                                    "type": "discussionForum",
                                                    "subType": "ENABLE",
                                                    "value": event.target.value
                                                })}
                                            />
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col>
                                            Notify Teacher when Question Submitted:
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                            />
                                            <Form.Control
                                                type="text" autoComplete="text"
                                                id="inputPassword5"
                                                aria-describedby="passwordHelpBlock"
                                                placeholder="teachers@email.com"
                                            />
                                        </Col>
                                    </Row> */}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            space for other settings
                        </Col>
                    </Row>
                    <br />
                </div>
            )
        })
    }

    useEffect(async () => {

        setDiscussionForumIsEnabled(true);
        setDiscussionForumIsEnabledCheckBox(false);
        setShowSettings("none");
        getClassInfo();

    }, []);

    return (
        <>
            General Settings
            <hr />
            <Row style={{ width: "75%" }}>
                <Col style={{ 'text-align': "right", margin: "auto" }}>
                    Select Class:
                </Col>
                <Col >
                    <Form.Select aria-label="Default select example" id="newSchool" onChange={event => setClassSelected(event.target.value)} name="newSchool" >
                        <option onClick={() => setDisabledSelectButton(true)} value="0"></option>
                        {schoolList}
                    </Form.Select>
                </Col>
                <Col style={{ 'align-items': "left", display: "flex" }}>
                    <Button onClick={() => loadQuizList()} >Select</Button>
                </Col>
            </Row>
            <br />

            <hr />

            <div align="left" style={{ width: "100%", display: showSettings }}>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                Discussion Forums {discussionForumIsEnabled}
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        Disable Discussion:
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="switch"
                                            // checked={discussionForumIsEnabled === false}
                                            checked={discussionForumIsEnabledCheckBox}
                                            onChange={event => updateDiscussionForumSettings({
                                                "type": "discussionForum",
                                                "subType": "ENABLE",
                                                "value": event.target.value
                                            })}

                                        />
                                    </Col>
                                </Row>
                                {/* <Row>
                                        <Col>
                                            Notify Teacher when Question Submitted:
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                            />
                                            <Form.Control
                                                type="text" autoComplete="text"
                                                id="inputPassword5"
                                                aria-describedby="passwordHelpBlock"
                                                placeholder="teachers@email.com"
                                            />
                                        </Col>
                                    </Row> */}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        {/* space for other settings */}
                    </Col>
                </Row>
                <br />
            </div>
        </>
    )

}

export default GeneralSettingsComponentMain;