import CourseSelectionSingleComponenet from './coutseSelectionSingleItem';

function CourseSelectionMainComponenet(props) {

    const renderSectionCustomer = () => {
        let elements = [];

        for(let i = 0; i < 5; i++) {
            elements.push(
                  <div key={i} > 
                    <CourseSelectionSingleComponenet /><br />
                  </div>
           )
   }

        return elements;
    }

    const sectionCutter = () => {
        var separateElements = [];
        var multiElements = renderSectionCustomer();

        for (var i = 0; i < multiElements.length; i += 2) {
            var oneRow = [];
            oneRow.push(multiElements.slice(i, i + 2).map(item => {
                return <div style={{ display: 'inline-block' }}>{item}</div>
            }))
            separateElements.push(oneRow.map(itm => { return <div>{itm}</div> }))
        }
        return separateElements;

    }

    return (
        <div>
            {/* CourseSelectionMainComponenet<br />
             */}
            {sectionCutter()}
        </div>
    )
}


export default CourseSelectionMainComponenet;
