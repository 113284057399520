import React, { useState, useEffect } from 'react';
import { AccessibleToolbarButton } from '../accessibility/accessibileToolbarButton';


// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar",
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};



// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block"
];

// Quill Toolbar component
export const QuillToolbar = () => {

  useEffect(() => {
    const addNewAttribute = (attribute, newAttributeName, attributeValue) => {
      const elementWithAttribute = document.querySelector(
        attribute
      );

      if (elementWithAttribute) {



        elementWithAttribute.setAttribute(newAttributeName, attributeValue);

        return elementWithAttribute;
      }
    };

    const addLabel = (attribute) => {
      if (document.getElementById('linkLabel') == null) {
        const newElement = document.createElement('label',);
        newElement.textContent = 'Enter link:';
        newElement.setAttribute("for", "linkField")
        newElement.setAttribute("id", "linkLabel")

        attribute.parentNode.insertBefore(newElement, attribute);
      }
    }

    addLabel(addNewAttribute('[data-link="https://quilljs.com"]', 'id', 'linkField'));
    addNewAttribute('[data-label="Heading"]', 'aria-label', 'Heading');
    addNewAttribute('[data-label="Subheading"]', 'aria-label', 'Subheading');
    addNewAttribute('[data-label="Normal"]', 'aria-label', 'Normal');
  }, []);



  return (
    <div id="toolbar">
      <span className="ql-formats">

          <select className="ql-header" defaultValue="3"
            aria-haspopup="listbox"
            role="listbox"
            aria-labelledby="dropdown-label"
          >

            <option aria-label="Heading" value="1">Heading</option>
            <option aria-label="Subheading" value="2">  Subheading</option>
            <option aria-label="Normal text" value="3"> Normal</option>

          </select>

      </span>
      {/* <ul style={{'list-style-type': "none", 'padding-left': 0}}>  */}
      {/* <span className="ql-formats"> */}
      {/* <AccessibleToolbarButton option={'ql-bold'} optionName={'Bold'} /> */}
      {/* <AccessibleToolbarButton option={'ql-italic'} optionName={'Italic'} /> */}
      {/* <AccessibleToolbarButton option={'ql-underline'} optionName={'Underline'} /> */}
      {/* <AccessibleToolbarButton aria-controls="linkExpand" option={'ql-link'} optionName={'Link'} /> */}
      {/* </span>
      <span className="ql-formats"> */}
      {/* <AccessibleToolbarButton option={'ql-list'} optionName={'Ordered List'} value={'ordered'} /> */}
      {/* <AccessibleToolbarButton option={'ql-list'} optionName={'Bullet List'} value={'bullet'} /> */}
      {/* </span>
      <span className="ql-formats"> */}
      {/* <AccessibleToolbarButton option={'ql-clean'} optionName={'Clear Formatting'} /> */}
      {/* </span>
      </ul> */}

    </div>
  );
};




export default QuillToolbar;
