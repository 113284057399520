import Table from 'react-bootstrap/Table'
import React, { useState, useEffect } from "react";

function LTILogComponent(props) {


    const logPaylod = props.ltiLogs;
    // debugger;
    return (
        <>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                <h5>LTI Logs</h5>
                <small>
                    <Table striped bordered hover>
                        <thead>
                            <tr align="center">
                                <th width="15%">
                                    Date
                                </th>
                                <th width="15%">
                                    Sync Type
                                </th>
                                <th width="15%">
                                    Sync Results
                                </th>
                                <th width="45%">
                                    Course / LMS assignment name
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {/* {Array.isArray(logPaylod) && logPaylod > 0 ? logPaylod.map(note => (
                                <tr>
                                    <td>
                                        {note.date}
                                    </td>
                                    <td>
                                        {note.syncType}
                                    </td>
                                    <td>
                                        {note.result}
                                    </td>
                                    <td>
                                        {note.lmsContentName}
                                    </td>
                                </tr>

                            )) :
                                <tr><td colspan="3" align="center">No Notes on Profile</td></tr>} */}

                            {logPaylod.map(note => (
                                <tr>
                                    <td>
                                        {formatDate(note.date)}
                                    </td>
                                    <td>
                                        {note.syncType}
                                    </td>
                                    <td>
                                        {note.result}
                                        {note.result !== "SUCCESS" &&

                                  
                                            <>
                                            <br />
                                                <small>{note.syncResults}</small>
                                            </>
                                       
                                        }
                                    </td>
                                    <td>
                                        {note.lmsContentName}
                                    </td>
                                </tr>

                            ))}

                        </tbody>
                    </Table>
                </small>
            </div >
        </>
    )

}

function formatDate(dateString) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${month} ${day}, ${year}, ${hours}:${minutes}`;
}

export default LTILogComponent;