import LessonComponent from "./lesson.js";
import Button from "react-bootstrap/Button";
import { getUserProgressByItemId } from "../../services/bffServices/getUserDetails";
import React, { useState, useEffect, useRef } from "react";
import { getModuleByID } from "../../services/bffServices/textbookBFFServices/getModuleDetails";
import UpdateButtonComponent from "../textbook/updateButtonComponenet";
import QuizComponent from "../textbook/quizComponent";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import DiscussionFormMainComponenet from "../discussion-forum/discussionForumMainComponent";
import SideNavBar from "../sideNavBar";
import { GrBookmark } from "react-icons/gr";
import { BsBookmarkCheckFill } from "react-icons/bs";
import {
	transformTableOfContents,
	nextLastModule,
} from "../../services/unitTransformer";
import Alert from "react-bootstrap/Alert";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { postBookmark } from "../student-notes/services/postBookmark";
import { getBookmarkByModule } from "../student-notes/services/getBookmarks";
import { patchBookmark } from "../student-notes/services/updateBookmark";
import CreateNoteComponent from "../student-notes/create-note";
import Placeholder from "react-bootstrap/Placeholder";
import Offcanvas from "react-bootstrap/Offcanvas";
import { getExistingSession } from "../../services/bffServices/getSessionInfo";
import { getDiscussionForumSettings } from "../../services/generalSettings/discussionForumSettings";
import { getMembershipKeyByUserFunction } from "../../services/getMembershipKey";
import Modal from "react-bootstrap/Modal";
import SmartTooltip from "../accessibility/tooltip.js";
const url = require("url");
const domainURL = "https://storage.googleapis.com/sb-general"; //https://storage.googleapis.com/sb-general/

const queryParams = new URLSearchParams(window.location.search);

function ModuleComponent(props) {
	const [unitId, setUnitId] = useState(sessionStorage.getItem("activeUnit"));
	const [moduleId, setModuleId] = useState(queryParams.get("id"));
	const [chapterComplete, setChapterComplete] = useState(
		queryParams.get("chapterComplete")
	);
	const [bookmarkId, setBookmarkId] = useState("");
	const [status, setStatus] = useState("blah");
	const [chapterName, setChapterName] = useState("");
	const [displayDiscussionForum, setDisplayDiscussionForum] = useState(false);
	const [show, setShow] = useState(true);
	const [showWelcomeModal, setShowWelcomeModal] = useState(true);
	const [modalShow, setModalShow] = useState(false);
	const [nextModuleId, setNextModuleId] = useState();
	const handleClose = () => setShow(false);
	const toggleShow = () => setShow((s) => !s);
	const language = sessionStorage.getItem("lang") || "EN";
	const [multiSessionUI, setMultiSessionUI] = useState(false);
	const [searchChapterId, setSearchChapterId] = useState();
	const [searchLessonId, setSearchLessonId] = useState(false);
	const [ariaPressed, setAriaPressed] = useState(false)
	const myRef = useRef(null);

	//languageVars for Buttons
	const [back, setBack] = useState("Back");
	const [completedModule, setCompleteModule] = useState(() => {
		if (language == "FR") {
			return "Module complet";
		}
		if (language != "FR") {
			return "Complete Module";
		}
	});
	const [createNote, setCreateNote] = useState("Create Note");
	const [moduleTitle, setModuleTitle] = useState(() => {
		return (
			<>
				<Placeholder as="p" animation="glow">
					<Placeholder xs={12} />
				</Placeholder>
			</>
		);
	});
	const [moduleContent, setModuleContent] = useState(() => {
		return (
			<>
				<Placeholder as="p" animation="glow">
					<Placeholder xs={12} />
					<Placeholder xs={12} />
					<Placeholder xs={12} />
					<Placeholder xs={12} />
					<Placeholder xs={12} />
				</Placeholder>
			</>
		);
	});
	const [moduleQuiz, setModuleQuiz] = useState("NONE");
	const [moduleMainImage, setModuleMainImage] = useState(() => {
		return (
			<>
				<Placeholder as="p" animation="glow">
					<Placeholder xs={12} />
				</Placeholder>
			</>
		);
	});
	const [bookmark, setBookmark] = useState(() => {
		return (
			<>
				<GrBookmark
				aria-pressed="true"
				role="img"
				aria-expanded="true"
				aria-label="Bookmark"
				/>
			</>
		);
	});

	const [bookmarkStatus, setBookmarkStatus] = useState(false);
	const [createNoteButton, setCreateNoteButton] = useState(() => {
		return (
			<>
				<Button variant="info">{createNote}</Button>
			</>
		);
	});
	const [nextModule, setNextMobulde] = useState(() => {
		return (
			<>
				<UpdateButtonComponent
					type="MODULE"
					id={queryParams.get("id")}
					status="COMPLETE"
				/>
			</>
		);
	});
	const [prevModule, setPrevMobulde] = useState(() => {
		return (
			<>
				<Button onClick={() => window.history.back()} variant="outline-primary">
					{language == "FR" && <>Dos</>}
					{language != "FR" && <>Back</>}
				</Button>
			</>
		);
	});

	const setbookMarkIcon = async () => {
		const status = bookmarkStatus;
		var payload = {
			unit: unitId,
			chapter: " ",
			module: moduleId,
		};

		if (status == false) {
			setBookmark(() => {
				return (
					<>
						<SmartTooltip
							tip={"Remove bookmark"}
							children={[
								<BsBookmarkCheckFill
									aria-pressed="true"
									role="img"
									aria-expanded="true"
									aria-label="Remove Bookmark"

								/>,
							]}
						></SmartTooltip>
					</>
				);
			});
			setBookmarkStatus(true);
			setAriaPressed(true)
			const bookmarkDetails = await postBookmark(payload);
			setBookmarkId(bookmarkDetails.id);
		}

		if (status == true) {
			setBookmark(() => {
				return (
					<div
				
					>
						<SmartTooltip
							tip={"Create Bookmark"}
							aria-expanded="true"
							children={[
								<GrBookmark
									aria-pressed="true"
									role="img"
									aria-expanded="true"
									aria-label="Create Bookmark"
								/>,
							]}
						></SmartTooltip>
					</div>
				);
			});
			setBookmarkStatus(false);
			setAriaPressed(false)
			patchBookmark(bookmarkId);
		}
	};

	const createNoteButtonRender = async (bookDetails) => {
		setCreateNoteButton(() => {
			return (
				<>
					<CreateNoteComponent
						unit={bookDetails.unit}
						chapter={bookDetails.chapter}
						module={bookDetails.module}
					/>
				</>
			);
		});
	};

	const setNavButtons = async (quizID) => {
		setNextMobulde(() => {
			return (
				<>
					<table>
						<tr>
							<td style={{ "padding-right": "10px" }}>
								{quizID != "NONE" && (
									<QuizComponent
										location="MODULE"
										moduleId={queryParams.get("id")}
										lessonId={"N/A"}
										id={quizID}
									/>
								)}
							</td>
						</tr>
					</table>
				</>
			);
		});
	};
	const welcomeModal = () => {
		return (
			<div
				className="modal show"
				style={{ display: "block", position: "initial" }}
			>
				<Modal
					show={modalShow}
					// show={show}
					onHide={() => setModalShow(false)}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Header closeButton></Modal.Header>
					{language != "FR" && (
						<Modal.Body>
							<h4>Ready for the next module?</h4>
							<p>
								To navigate between modules, simply click the button to the left
								to open the table of contents. From there, you can easily jump
								to the next module or any other module you wish to explore.
							</p>
						</Modal.Body>
					)}
					{language == "FR" && (
						<Modal.Body>
							<h4>Prêt pour le prochain module ?</h4>
							<p>
								Pour naviguer entre les modules, cliquez sur le bouton à gauche
								pour ouvrir la table des matières. De là, vous pourrez
								facilement passer au prochain module ou à tout autre module que
								vous souhaitez explorer.
							</p>
						</Modal.Body>
					)}
					<Modal.Footer>
						<Button onClick={() => setModalShow(false)}>Close</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	};

	useEffect(async () => {
		var isYorkU = false;
		try {
			const userMemberships = await getMembershipKeyByUserFunction();
			if (userMemberships && Array.isArray(userMemberships)) {
				isYorkU = userMemberships.some(
					(membership) => membership.value === "York-U-BIOL-1000-BAS-LTI"
				);
			}
		} catch (err) {
			//do Nothing
		}

		if (queryParams.get("s") && queryParams.get("c") && queryParams.get("u")) {
			var searchLessonId = queryParams.get("s");
			var searchChapterId = queryParams.get("c");

			await setSearchLessonId(queryParams.get("s"));
			await setUnitId(queryParams.get("u"));
			localStorage.setItem("activeChapter", queryParams.get("c"));
			myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
		}

		const moduleId = queryParams.get("id");
		const bookmarkCheck = await getBookmarkByModule(moduleId);
		var varNewStatus = await getUserProgressByItemId("MODULE", moduleId);
		const shouldShowModal = sessionStorage.getItem("shouldShowModal");
		if (shouldShowModal !== "false") {
			setModalShow(true);
			sessionStorage.setItem("shouldShowModal", "false");
		}
		const moduleInfo = await getModuleByID(moduleId).then(async (payload) => {
			if (isYorkU) {
				payload[0].quiz.id = "NONE";
			}
			await setModuleQuiz(payload[0]?.quiz?.id || "NONE");
			setNavButtons(payload[0]?.quiz?.id || "NONE");
			setModuleQuiz(payload[0]?.quiz?.id || "NONE");
			const text = payload[0].chapters[0].title_en;
			const match = text.match(/Chapter\s\d+\s\|\s(.*)/);
			const chapterName1 = match
				? `Chapter ${text.match(/Chapter\s(\d+)\s\|/)[1]} | ${match[1].trim()}`
				: text;
			const chapterName = chapterName1
				? chapterName1.replace(/\s*\([TS]E\)/, "")
				: "";
			setChapterName(chapterName);
			localStorage.setItem("activeChapter", payload[0].chapters[0].id);
			const payload1 = await transformTableOfContents(
				sessionStorage.getItem("activeUnit")
			);
			const payload2 = await nextLastModule(payload1, queryParams.get("id"));
			await setNextModuleId(payload2.nextModule);

			if (language != "FR") {
				setModuleTitle(payload[0].title_en);
				setModuleContent(() => {
					return (
						<>
							<ReactMarkdown rehypePlugins={[rehypeRaw]}>
								{payload[0].content_en}
							</ReactMarkdown>
						</>
					);
				});
				setModuleMainImage(() => {
					if (
						payload[0].mainImgSrc_en != null ||
						payload[0].mainImgSrc_en != undefined
					) {
						var parsedURL = url.parse(payload[0].mainImgSrc_en);
						if (parsedURL != null || parsedURL != undefined) {
							var updatedURL = domainURL + parsedURL.pathname;
							var altName = "Module " + payload[0].title_en + " Header"
							return (
								<>
									<img
										style={{ "border-radius": "25px" }}
										width="100%"
										src={updatedURL}
										loading="lazy"
										alt={altName}
									/>
								</>
							);
						}
					}
				});
			}

			if (language == "FR") {
				setModuleTitle(payload[0].title_fr);
				setModuleContent(() => {
					return (
						<>
							<ReactMarkdown rehypePlugins={[rehypeRaw]}>
								{payload[0].content_fr}
							</ReactMarkdown>
						</>
					);
				});
				setModuleMainImage(() => {
					if (
						payload[0].mainImgSrc_fr != null ||
						payload[0].mainImgSrc_fr != undefined
					) {
						var parsedURL = url.parse(payload[0].mainImgSrc_en);
						if (parsedURL != null || parsedURL != undefined) {
							var updatedURL = domainURL + parsedURL.pathname;
							return (
								<>
									<img
										style={{ "border-radius": "25px" }}
										width="100%"
										src={updatedURL}
										loading="lazy"
									/>
								</>
							);
						}
					}
				});
				setBack("Arrière");
				setCompleteModule("Module complet");
				setCreateNote("Créer une note");
				setCreateNoteButton(() => {
					return (
						<>
							<Button variant="info">Créer une note</Button>
						</>
					);
				});
			}
			createNoteButtonRender({
				unit: unitId,
				chapter: "Chapter-blah",
				module: payload[0].title_en,
			});
			return payload;
		});
		setStatus(varNewStatus);
		setShow(false);
		const cookieExists = document.cookie
			.split(";")
			.some((item) => item.trim().startsWith("sessionID="));
		if (cookieExists) {
			const cookieValue = document.cookie.replace(
				/(?:(?:^|.*;\s*)sessionID\s*\=\s*([^;]*).*$)|^.*$/,
				"$1"
			);
			sessionStorage.setItem("sessionID", cookieValue);
		}
		if (bookmarkCheck.payload.length == 0) {
			setAriaPressed(false)
			setBookmark(() => {
				return (
					<>
						<GrBookmark
						aria-pressed="true"
						role="img"
						aria-expanded="true"
						aria-label="Bookmark"
						 />
					</>
				);
			});
		} else {
			setBookmarkId(bookmarkCheck.payload[0].id);
			setBookmarkStatus(true);
			setAriaPressed(true)

			setBookmark(() => {
				return (
					<>
						<BsBookmarkCheckFill />
					</>
				);
			});
		}

		var existingSessiontInfo = await getExistingSession();
		var multiSesson = existingSessiontInfo.payload.multiSession || false;
		var validLogin = existingSessiontInfo.payload.validLogin || false;
		var discussionForumSettings = await getDiscussionForumSettings(
			existingSessiontInfo.payload.class
		);
		if (!validLogin) {
			window.location.href = "/?invalidLogin=true";
		}

		if (discussionForumSettings != false) {
			setDisplayDiscussionForum(discussionForumSettings[0].value);
		}
		if (discussionForumSettings == false) {
			setDisplayDiscussionForum(true);
		}
		setMultiSessionUI(multiSesson);
	}, [nextModuleId, multiSessionUI]);

	const dialogRef = useRef(null);
    const firstFocusableRef = useRef(null);
    const lastFocusableRef = useRef(null);
	const firstFocusableLink = useRef(null);
	useEffect(() => {
        const dialog = dialogRef.current;
        const firstFocusableElement = firstFocusableRef.current;
        const lastFocusableElement = lastFocusableRef.current;

        const handleKeyDown = (e) => {
            // Handle Shift+Tab when on the first focusable element
            if (e.target === firstFocusableElement && e.key === 'Tab' && e.shiftKey) {
                e.preventDefault();
                lastFocusableElement.focus();
            }
            // Handle Tab when on the last focusable element
            else if (e.target === lastFocusableElement && e.key === 'Tab' && !e.shiftKey) {
                e.preventDefault();
                firstFocusableElement.focus();
            }
        };

        // Attach event listener to dialog
        if (dialog) {
            dialog.addEventListener('keydown', handleKeyDown);
        }

        // Clean up event listener on unmount
        return () => {
            if (dialog) {
                dialog.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, []); // Empty dependency array ensures this runs only once when component mounts


	return (
		<div style={{ padding: "0", backgroundColor: "#f8f9fa", height: "100%" }}>
			<br />
			{welcomeModal()}
			<div style={{ paddingLeft: '2.5%', paddingRight: '2.5%' }}>
				<div
					align="left"
					style={{ width: "100%", padding: "0px", margin: "0px" }}
				>
					<table role="presentation" width="100%">
						<tr>
							<td>
								<div align="left">
									<Button variant="primary" onClick={toggleShow} className="me-2">
										{language == "FR" && <>Table des matières</>}
										{language != "FR" && <>Table Of Contents</>}
									</Button>
									<Offcanvas
										aria-labelledby="tableOFContentsHeader"
										style={{ width: "30%", position: "fixed" }}
										show={show}
										onHide={handleClose}
										tabindex={show ? 0 : -1}
										role="dialog"
										aria-modal="true"
									>
										<Offcanvas.Header closeButton></Offcanvas.Header>
										<Offcanvas.Body>
											<small>
												<SideNavBar
													module="true"
													unitId={sessionStorage.getItem("activeUnit")}
												/>
											</small>
										</Offcanvas.Body>
									</Offcanvas>
								</div>
							</td>
							{multiSessionUI !== true && (
								<>
									{/* <td align="right" style={{ padding: "5px" }}>
										{createNoteButton}
									</td> */}
									<td align="right" width="1%">

										<button
                aria-describedby="tooltip" // Associate with tooltip

											style={{
												borderColor: "transparent",
												backgroundColor: "transparent",
												'font-size': "24px",
												'font-weight': "bold"
											}}
											aria-pressed={ariaPressed}
											onClick={() => setbookMarkIcon()}
										>
											{bookmark}
										</button>

									</td>
								</>
							)}
						</tr>
					</table>
				</div>
				<div
					align="left"
					className="tocBox"
					style={{
						width: "100%",
						backgroundColor: "white",
						padding: "20px",
					}}
				>
					{chapterComplete === "true" && (
						<Alert
							style={{ "text-align": "center" }}
							key="success"
							variant="success"
						>
							Great Job! You've completed the Chapter!
						</Alert>
					)}
					<div>
						<span class="directional__nav__button_left">{prevModule}</span>
						<span class="directional__nav__button_right"> {nextModule}</span>
					</div>
					<br />
					<br />
					<div align="center">
						<h6>
							<div style={{ padding: "0px 0px 0px 10px", color: "grey" }}>
								<h5>{chapterName}</h5>
							</div>
						</h6>
						<h1>{moduleTitle}</h1>
						
					</div>
					{/* <small><a href="#mainContent" tabindex="0" className="skip-link" style={{float: "right", paddingBottom: "10px"}}>Skip To Main Content</a></small> */}

					<hr width="100%" />
					<div align="center">{moduleMainImage}</div>
					<br />
					<br />
					<div id="lesson" ref={myRef} />
					<div align="left">{moduleContent}</div>
					<LessonComponent
						nextModuleId={nextModuleId}
						moduleId={queryParams.get("id")}
						lessonId={searchLessonId}
						id={moduleQuiz}
						setNav={setNavButtons}
					/>
					<hr />
					{displayDiscussionForum == true && (
						<DiscussionFormMainComponenet
							unitId={sessionStorage.getItem("activeUnit")}
							moduleId={queryParams.get("id")}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default ModuleComponent;
