export async function updateDbQuizException(payload){
    // console.log("updateSCCustomerInfo -- payload" + JSON.stringify(payload))
  
      const options = { 
          method: 'PATCH', 
          headers: new Headers({ 
            'Content-Type': 'application/json',
            'sb-application-name': 'sb-site-ui',
            'sb-application-key': 'jmd-test',
            'sessionID' : sessionStorage.getItem("sessionID"),
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
          }), 
          mode: 'cors',
          credentials: 'omit',
          body: JSON.stringify(payload)
        };
        
        const scURL = process.env.REACT_APP_BFF + '/bff/v1.0/updateQuizException/';
        let response = await fetch(scURL,options)
        let newUserDetails = await response.json();
        // console.log('updateSCCustomerInfo:  ' + JSON.stringify(scDetails));
        
        return newUserDetails;
  }