import React, { useState, useEffect } from 'react';

function Banner(banner, onLoaded) {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [videoLoaded, setVideoLoaded] = useState(false);
// debugger
    useEffect(() => {
        if (imageLoaded && videoLoaded) {
            if (typeof onLoaded === 'function') {
                onLoaded();
            }
        }
    }, [imageLoaded, videoLoaded, onLoaded]);

    return (
        <div>
            <div className="crop-container aspect-ratio-custom">
                <video
                    style={{ width: "100%" }}
                    preload="auto"
                    autoPlay
                    playsInline
                    loop
                    muted
                    onCanPlayThrough={() => setVideoLoaded(true)} // Set videoLoaded to true when video can play through
                >
                    <source
                        src={banner.details.src}
                        type="video/mp4"
                    />
                </video>
                <div className={banner.details.cntrBnrCSS}>
                    <div
                        className={banner.details.titleFormatCSS}
                        style={{ margin: "auto", width: "50%", padding: "1%" }}
                    >
                        {banner.details.title}
                    </div>
                    <div
                        className={banner.details.descFormatCSS}
                        style={{ margin: "auto", width: "50%", padding: "1%" }}
                    >
                        {banner.details.desc}
                    </div>
                    <div className="d-none d-sm-block">
                        <img
                            loading="lazy"
                            style={{
                                width: "15%",
                                height: "15%",
                                paddingBottom: "1%",
                            }}
                            className="aligncenter wp-image-38704 size-medium"
                            src={banner.details.img}
                            alt={banner.details.imgAlt}
                            onLoad={() => setImageLoaded(true)} // Set imageLoaded to true when image is loaded
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banner;