import React, { useState, useEffect, useContext} from "react";
import { AppContext } from "../utls/AppContext";
import Card from 'react-bootstrap/Card';
import Container from "react-bootstrap/esm/Container";
import Tab from 'react-bootstrap/Tab'
import Alert from 'react-bootstrap/Alert'
import Tabs from 'react-bootstrap/Tabs'
import StudentList from './account-dashboard/student-list';
import ResetPasswordComponent from './account-dashboard/reset-password';
import CurrentCourseList from './textbook/course-list';
import OrderListComponent from './account-dashboard/orderList';
import AdminOrderListComponent from './account-dashboard/orderListAdmin';
import { getExistingSession } from '../services/bffServices/getSessionInfo';
import { getUserByEmail } from '../services/bffServices/getUserDetails';
import { logoutUser } from '../services/bffServices/logout';
import { syncGrades } from '../services/quiz/ltiSync';
import CreateUserDetailsComponent from '../components/user-creation/createUserDetailsComponent';
import AssignClassComponent from "./account-dashboard/adminComponents/assignClass";
import QuizDatesComponent from "./account-dashboard/teacher/quizDatesComponent/QuizDatesComponentMain";
import AddLTIConsumerComponent from '../components/account-dashboard/adminComponents/addLtiConsumer';
import GeneralSettingsComponentMain from '../components/account-dashboard/teacher/generalSettings/generalSettingsMainComponent'
import CouponGenerator from '../components/account-dashboard/adminComponents/batchCouponGeneration';
import MyQuizComponent from '../components/account-dashboard/myQuiz';
import UpdateUserMembershipComponent from '../components/account-dashboard/adminComponents/assignRemoveMembership';
import { getUnitByChapterLTI } from '../services/bffServices/getUnitDetailsByChapter';
import DeleteUserComponent from '../components/account-dashboard/adminComponents/deleteUser';
import AdminUserSearchComponent from '../components/account-dashboard/adminComponents/userDetails';
import DuplicateCMSContentComponent from '../components/account-dashboard/adminComponents/duplicateCourseInfo';
import { initGA, logPageView, setUserId, logTimeOnPage } from '../services/googleAnalytics/analytics';
import { updatePageTitle } from '../utls/lib';
import CreateIpListDetailsComponent from "./account-dashboard/create-ip-list-details";
import IpListDetailsMainComponent from "./account-dashboard/adminComponents/ipListManagement/ipListManagementMain"



function AccountPage() {
  const queryParams = new URLSearchParams(window.location.search);
  const { fromBookstore, setFromBookstore } = useContext(AppContext);
  const [fromLibrary, setFromLibrary ] = useState(false);
  const [userName, setUserName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userRole, setuserRole] = useState("");
  const [adminSearchRangeStart, setAdminSearchRangeStart] = useState("")
  const [adminSearchRangeEnd, setAdminSearchRangeEnd] = useState("")


  //tabs
  const [dashboard, setDashboard] = useState("Dashboard");
  const [orders, setOrders] = useState("Orders");
  const [accountDetails, setAccountDetails] = useState("Account Details");
  const [resetPassword, setRestPassword] = useState("Reset Password");
  const [greeting, setGreeting] = useState("Hi");
  const [not, setNot] = useState("not");
  const [logoutLink, setLogoutLink] = useState("logout");
  const [role, setRole] = useState("Role");
  const [myProgress, setMyProgress] = useState("My Progress");
  const [disabledStart, setDisableStart] = useState("didn't work");
  const [errorMsg, setErrorMsg] = useState();
  const [multiSession, setMultiSession] = useState(false);
  const props = {
    "studentDetails": {
      "studentId": "CX-12345"
    },
    "title": myProgress
  }

  const logout = async () => {
    var results = await logoutUser();
    window.location.href = "/";
  }

  const getUserInfo = async () => {
    const userSession = await getExistingSession()
    await setFromLibrary(userSession?.payload?.isLib || false)
    const userInfo = await getUserByEmail(userSession.payload.email);
    if (userSession.payload.userId != userInfo.ID) {

      setErrorMsg(() => {
        <>
          <Alert key="danger" variant="danger">
            Invalid Session, please retry
          </Alert>
        </>
      });

      await logoutUser();
      window.location.href = "/error?errMsg=Invalid-Session";
    }
    var firstName = userInfo.Name.substring(
      userInfo.Name.indexOf(",") + 1,
      userInfo.Name.lastIndexOf("")
    );
    var lastName = userInfo.Name.substring(
      userInfo.Name.indexOf(""),
      userInfo.Name.lastIndexOf(",")
    );
    setUserName(firstName);
    setUserLastName(lastName);
    setuserRole(userInfo.Role);
    setUserEmail(userInfo.Email);
    setUserId(userInfo.ID);
    setMultiSession(userSession.payload.multiSession);
    if (userSession.payload.multiSession === true) {
      setMyProgress("");
    }

    if (userSession.payload.class == "637c12adbd4f3a4a44f421c5") {
      if (sessionStorage.getItem("lang") == "EN" || sessionStorage.getItem("lang") == null || sessionStorage.getItem("lang") == undefined) {
        sessionStorage.setItem("lang", "FR");
        window.location.reload(false);
      }
    }

    if (userSession.payload.class == "63a7b51dd966ed1cbb5f0d5c") {
      await setDisableStart(true);
      sessionStorage.setItem("ltiLock", "Y");
    }
  }

  useEffect(async () => {
    const language = sessionStorage.getItem("lang") || "EN"
    const ltiIndicator = sessionStorage.getItem("isLTI") || "N";
    await getUserInfo()
    if (language == "FR") {
      setDashboard("Tableau de bord");
      setOrders("Ordres")
      setAccountDetails("Détails du compte")
      setRestPassword("Réinitialiser le mot de passe")
      setGreeting("Salut");
      setNot("ne pas");
      setLogoutLink("se déconnecter");
      setRole("Rôle");
      setMyProgress("Mes progrès")
    }

    if (ltiIndicator == "Y") {
      syncGrades();
    }

    var today = new Date();
    var lastMonth = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var mmStart = String(today.getMonth()).padStart(2, '0'); //January is 0!

    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + "-" + dd;
    lastMonth = yyyy + '-' + mmStart + "-" + dd;

    setAdminSearchRangeEnd(today);
    setAdminSearchRangeStart(lastMonth);

    if (queryParams.get("ltiChapter") == "true") {
      var chapter = await sessionStorage.getItem('ltiChapter');
      var unitInfo = await getUnitByChapterLTI(chapter)
        .then(payload => {
          sessionStorage.setItem("activeUnit", payload.unitId);
          return payload
        })
        .then(payload => {
          localStorage.removeItem('TOC');
          return payload
        })
        .then(payload => {
          window.location.href = "/textbook/module?id=" + payload.firstModule;
        })
    }

    updatePageTitle("Account Dashboard");

  }, []);

  const styles = {
    tabs: {
        borderBottom: '2px solid #dee2e6',
        fontSize: '14px',
    },
    tab: {
        marginBottom: '-3px', // To remove the gap caused by the border
    },
    tabLink: {
        padding: '10px 20px',
        color: '#333',
        borderRadius: '5px 5px 0 0',
        transition: 'background-color 0.3s, color 0.3s',
    },
    tabLinkHover: {
        backgroundColor: '#f8f9fa',
    },
    tabLinkActive: {
        color: '#007bff',
        backgroundColor: 'transparent',
        borderColor: '#dee2e6 #dee2e6 #fff',
    }
};

  return (
    <>
      <div style={{ 'background-color': "#f8f9fa" }}><br />

        <Container>
          <Card>
            <Card.Body  >
              <Tabs style={styles.tabs} defaultActiveKey="dashboard" id="uncontrolled-tab-example" className="mb-3" role="tab">
                <Tab id="dashboard" aria-labelledby="dashboard" style={styles.tab} eventKey="dashboard" title={dashboard}  role="tabpanel" >
                  <div align="left" style={{ fontSize: "14px" }} >
                    {greeting} <strong>{userName}</strong> ({not} <strong>{userName}</strong>? <a href="/" onClick={logout}>{logoutLink})</a>
                  </div>
                  {errorMsg}
                  <div style={{ fontSize: "12px" }} align="left">
                    <strong>{role}: </strong> {userRole}
                  </div>
                  <hr />
					{/* <small><a href="#mainContent" tabindex="-1" style={{float: "right", paddingBottom: "10px"}}>Skip To Main Content</a></small> */}
                  <CurrentCourseList isLTI={disabledStart} props={props} multiSession={multiSession} />
                </Tab>
                {(multiSession !== true && !fromLibrary) && 
                  <Tab style={styles.tab} eventKey="myQuiz" title="My Quiz" role="tabpanel">
                    <MyQuizComponent/>
                  </Tab>
                }
                {(multiSession !== true && !fromLibrary) &&
                  <Tab eventKey="orders" title={orders} role="tabpanel">
                    <OrderListComponent />
                  </Tab>
                }
                {(multiSession !== true && !fromLibrary) &&
                  <Tab role="tabpanel" eventKey="resetPassword" title={resetPassword}>
                    <ResetPasswordComponent />
                  </Tab>
                }
                {(userRole == "admin" || userRole == "teacher") &&
                  <Tab role="tabpanel" eventKey="studentList" title="Students">
                    <StudentList studentId={userId} />
                  </Tab>
                }
                {(userRole == "admin" || userRole == "teacher") &&
                  <Tab role="tabpanel" eventKey="quizDates" title="Quiz Settings">
                    <QuizDatesComponent props="jmd-1234" />
                  </Tab>
                }
                {(userRole == "admin" || userRole == "teacher") &&
                  <Tab role="tabpanel" eventKey="generalSettings" title="General Settings">
                    <GeneralSettingsComponentMain />
                  </Tab>
                }
                {userRole == "admin" &&
                  <Tab role="tabpanel" eventKey="coupons" title="Bulk Coupon Generator">
                    <CouponGenerator />
                  </Tab>
                }
                {userRole == "admin" &&
                  <Tab role="tabpanel" eventKey="Adminorders" title="View Orders">
                    <AdminOrderListComponent />
                  </Tab>
                }
                                                {userRole == "admin" &&
                  <Tab role="tabpanel" eventKey="duplicateCMS" title="Duplicate CMS Content">
                    <DuplicateCMSContentComponent />
                  </Tab>
                }
                {userRole == "admin" &&
                  <Tab role="tabpanel" eventKey="createUser" title="Create User">
                    <CreateUserDetailsComponent/>
                  </Tab>
                }
                {/* {userRole == "admin" &&
                  <Tab eventKey="reporting" title="Monthly Reporting">
                    Monthly Reporting
                  </Tab>
                } */}
                {/* {userRole == "admin" &&
                  <Tab eventKey="jameiOnly" title="Jamie Only">
                    <MigratingUsersComponent />
                  </Tab>
                } */}
                {userRole == "admin" &&
                  <Tab role="tabpanel" eventKey="deleteUser" title="DeleteUser">
                    <DeleteUserComponent />
                  </Tab>
                }
                {userRole == "admin" &&
                  <Tab role="tabpanel" eventKey="searchUsers" title="Search Users">
                    <AdminUserSearchComponent />
                  </Tab>
                }
                                {userRole == "admin" &&
                  <Tab role="tabpanel" eventKey="ltiConsumer" title="Create LTI Consumer">
                    <AddLTIConsumerComponent />
                  </Tab>
                }
                {(userRole == "admin") &&
                  <Tab role="tabpanel" eventKey="createIpList" title="IP Access Management">
                    <IpListDetailsMainComponent />
                  </Tab>
                }

              </Tabs>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default AccountPage;