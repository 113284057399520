import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../utls/AppContext";
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import CreateNoteComponent from '../student-notes/create-note';
import StudentNotesMainComponenet from '../student-notes/student-notes-main';
import LoginComponent from '../login-registration/loginComponent';
import { logoutUser } from '../../services/bffServices/logout';
import { getExistingSession } from '../../services/bffServices/getSessionInfo';
import { getUserByEmail } from '../../services/bffServices/getUserDetails';
import ForgotPasswordComponent from '../login-registration/forgotPassword';
import SearchComponent from '../search/searchComponent';

function LoginCheck(props) {
  const isLoggedIn = props.isLoggedIn;
  const [show, setShow] = useState(false);
  const [loginShow, setLoginShow] = useState();
  const [resetPasswordShow, setResetPasswordShow] = useState();
  const [userName, setUserName] = useState("");
  const [userRole, setuserRole] = useState("");
  const [dashboardLink, setDashboardLink] = useState("Dashboard");
  const [notebookLink, setNotebookLink] = useState("Notebook");
  const [logoutLink, setLogoutLink] = useState("Logout");
  const [greeting, setGreeting] = useState("Hi");
  const [multiSesson, setMultiSession] = useState(false);
  const [isLib, setIsLib] = useState(false);
  const [contactUs, setContactUs] = useState("Contact");
  const [homeLink, setHomeLink] = useState("Home");
  const [exploreLink, setExploreLink] = useState("Subjects");
  const { fromBookstore, setFromBookstore } = useContext(AppContext);

  const logout = async () => {
    var results = await logoutUser();
    window.location.href = "/";
  }
  const getUserInfo = async () => {
    const userSession = await getExistingSession()
    var multiSessonUI = userSession.payload?.multiSession || false;
    var isLibrary = userSession.payload?.isLib || false;
    const userInfo = await getUserByEmail(userSession?.payload?.email || 'NEW');
    var processed = userInfo?.Name?.substring(
      userInfo?.Name.indexOf(",") + 1,
      userInfo?.Name.lastIndexOf("")
    );
    setUserName(processed);
    setuserRole(userInfo?.Role);
    setMultiSession(multiSessonUI);
    setIsLib(isLibrary);
  }



  useEffect(async () => {
    const language = sessionStorage.getItem("lang") || "EN"

    if (language == "FR") {
      setDashboardLink("Tableau de bord");
      setNotebookLink("Mes notes");
      setLogoutLink("Fermer la session");
      setGreeting("Salut");
    }
    getUserInfo()
  }, []);

  if (isLoggedIn) {

    return (
      <>

        {isLib !== true &&
         <li> <div style={{ margin: "auto", padding: "5px" }}>{greeting}, {userName.substring(0, 10) + "\u2026"}!</div></li>
        }
        <li><Nav.Link href="/account">{dashboardLink}</Nav.Link></li>
        {multiSesson !== true || isLib !== true &&
          <li><Nav.Link onClick={() => setShow(true)}>{notebookLink}</Nav.Link></li>
        }

        <li><Nav.Link onClick={logout}>{logoutLink}</Nav.Link></li>
        <li><SearchComponent /></li>

        <Modal
          aria-labelledby="example-custom-modal-styling-title"
          show={show}
          size="lg"
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          scrollable="true"
          style={{ "max-height": "500px" }}
        >
          <Modal.Header closeButton>
            <h1 id="example-custom-modal-styling-title">My Notebook</h1>
          </Modal.Header>
          <Modal.Body >
            <StudentNotesMainComponenet />
          </Modal.Body>
        </Modal>
      </>
    );
  }

  if (!isLoggedIn) {
    return (
      <>
        <div style={{ padding: "10px" }}>
          {/* <Button variant="primary" href="/trial">Free Trial</Button> {' '} */}
          <Button backgroundColor={"#054bb4"} onClick={() => setLoginShow(true)} variant="success">Login</Button>
        </div>

        <Modal
          aria-labelledby="loginHeader"
          role="dialog"
          show={loginShow}
          size="lg"
          onHide={() => setLoginShow(false)}
          dialogClassName="modal-90w"
          scrollable="true"

        >
          <Modal.Body>
            <LoginComponent />
          </Modal.Body>
          {fromBookstore && <div style={{ "padding-bottom": "10px" }} align="right">
            <Button
              style={{ color: '#054bb4' }}
              onClick={() => {
                setLoginShow(false);
                setResetPasswordShow(true);
              }}
              variant="link"
            >
              Forgot Password?
            </Button>
            <br />
          </div>}
        </Modal>

        <Modal
          aria-labelledby="resetHeader"
          show={resetPasswordShow}
          size="lg"
          onHide={() => setResetPasswordShow(false)}
          dialogClassName="modal-90w"
          scrollable="true"
          style={{ "max-height": "500px" }}
        >
          <Modal.Body>
            <ForgotPasswordComponent />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export function NotebookModel() {
  const [show, setShow] = useState(false);

  return (
    <>
      <Modal
        show={show}
        size="lg"
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        scrollable="true"
        style={{ "max-height": "500px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            My Notebook
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <p>
            <div align="right"> <CreateNoteComponent /> </div>
            <Tabs defaultActiveKey="notes" id="uncontrolled-tab-example" className="mb-3">
              <Tab eventKey="notes" title="My Notes">
                <table>
                  <tr>
                    <td><p>
                      This is a test devtest Deplpy aug 30<br />
                      Date: 2019-08-31 Timestamp: 12:32:43am Video Name: DevTestVName <br />
                      Chapter: DevTestcourse Lesson: DevTestlesson Module: DevTestmodule Unit: DevTestunit</p>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      This is a test devtest Deplpy aug 30<br />
                      Date: 2019-08-31 Timestamp: 12:32:43am Video Name: DevTestVName <br />
                      Chapter: DevTestcourse Lesson: DevTestlesson Module: DevTestmodule Unit: DevTestunit
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      This is a test devtest Deplpy aug 30<br />
                      Date: 2019-08-31 Timestamp: 12:32:43am Video Name: DevTestVName <br />
                      Chapter: DevTestcourse Lesson: DevTestlesson Module: DevTestmodule Unit: DevTestunit
                      <hr />
                    </td>
                  </tr>
                </table>
              </Tab>

              <Tab eventKey="bookmarks" title="My Bookmarks">
                Bookmarks
              </Tab>
            </Tabs>

          </p>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default LoginCheck;
