export async function getMyQuiz() {


    const endPoint = process.env.REACT_APP_BFF + "/bff/v1.0/getQuizGradesByStudentId/";
    const options = {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            'sb-application-name': 'sb-site-ui',
            'sb-application-key': 'jmd-test',
            'Access-Control-Allow-Origin': '*',
            'sessionID': sessionStorage.getItem("sessionID"),
            'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
        }),
        mode: 'cors',
        credentials: 'omit',
        // body: JSON.stringify(theBody)
        // body: JSON.stringify(theBody)

    };

    let quizAttemptUpdate = await fetch(endPoint, options);
    let addedAttempts = await quizAttemptUpdate.json();
    
    return addedAttempts;
}