const SaleTaxList = require('./salesTax');

export async function getSalesTax(orderInfo){

    const endpoint = process.env.REACT_APP_BFF + "/bff/v1.0/getTaxInformation";
    const body = 
    {
    "country": orderInfo.country,
    "provinceOrState": orderInfo.provinceOrState,
    "postalOrZipCode": orderInfo.postalOrZipCode,
    "orderAmount": orderInfo.orderAmount,
    "shoppingCartId": orderInfo.shoppingCartId
  }
    const options = { 
      method: 'POST', 
      headers: new Headers({ 
        'Content-Type': 'application/json',
        'sb-application-name': 'sb-site-ui',
        'sb-application-key': 'jmd-test',
        'Access-Control-Allow-Origin': '*',
        'sessionID' : sessionStorage.getItem("sessionID"),
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
      }), 
      mode: 'cors',
      credentials: 'omit',
      body: JSON.stringify(body)
    };

    var salesTaxValue = await fetch(endpoint,options)
    .then(function(response){return response.json()});;
 
    return salesTaxValue;
}

//Request
// {
//     "country": "CA",
//     "provinceOrState": "ON",
//     "postalOrZipCode": "L1W1W3",
//     "orderAmount": 20.00,
//     "shoppingCartId": "SC-12345"
//   }


//Response From API
// {
//     "taxProvinceState": "ON",
//     "taxCountry": "CA",
//     "taxAmount": 2.6,
//     "taxTotalPercentage": 0.13,
//     "taxBreakdown": {
//       "gst": 1,
//       "pst": 1.6,
//       "qst": 0
//     },
//     "totalAmountWithTax": 22.6
//   }