import React, { useState, useEffect, useRef } from "react";
import {CustomToggle} from "./checkoutMainComponent";
import Form from 'react-bootstrap/Form'
import Cookies from 'universal-cookie';

import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const cookies = new Cookies();


export default function CustomerDetailsComponent(){
    const [email, setemail] = useState();
    const [userName, setUserName] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [password, setPassword] = useState();
    const [province, setProvince] = useState();  //setPostalCode
    const [postalCode, setPostalCode] = useState();  //setPostalCode



    
    return(
        <>

            <table width="80%" align="center" border="0">
            <tr>
                    <td>
                        <label>eMail</label>
                    </td>
                    <td>
                        {/* <input class="form-control form-control-sm" onChange={() => updateCustomerInfo} type="text" autoComplete="text" name ="email" id="email" placeholder="jmclean.davis+sb-test-001@gmail.com" /> */}
                        <input class="form-control form-control-sm" onChange={event => setemail(event.target.value)} type="text" autoComplete="text" name ="email1" id="email1" />
                    
                    </td>
                </tr>
            <tr>
                    <td>
                        <label>Username</label>
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="text" autoComplete="text" onChange={event => setUserName(event.target.value)} name ="username" id="username" value={email} disabled/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>Password</label>
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="password" autoComplete="password" onChange={event => setPassword(event.target.value)} name ="password" id="password" />
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <hr />
                    </td>
                </tr>
               <tr>
                    <td>
                        <label>First Name </label>
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="text" autoComplete="text" onChange={event => setFirstName(event.target.value)} name ="fname" id="fname" />
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>Last Name </label>
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="text" autoComplete="text" name ="lname" onChange={event => setLastName(event.target.value)} id="lname" />
                    </td>
                </tr>
                <tr>
                    <td>
                    Province
                    </td>
                    <td colspan="2">
                        <table width="100%" align="right">
                            <tr>
                                <td>
                                    <Form.Select aria-label="Default select example" onChange={event => setProvince(event.target.value)} id="province" name="province" >
                                        <option value="0"></option>
                                        <option value="ON">Ontario</option>
                                        <option value="AB">Alberta</option>
                                        <option value="QC">Quebec</option>
                                    </Form.Select>
                                </td>
                                <td style={{padding: "10px"}} width="20%">
                                    Postal Code
                                </td>
                                <td width="30%">
                                    <input class="form-control form-control-sm" settype="text" autoComplete="text" name ="postalCode" id="postalCode"  onChange={event => setPostalCode(event.target.value)} />
                                </td>
                            </tr>
                            </table>
                        </td>
                        </tr>
                        <tr>
                        <td colspan="2" align="right">
                        <Form.Text style={{width: "50%"}} id="passwordHelpBlock" muted>
                            Postal Code & Province are used to validate credit card payment/billing information & apply the correct taxes
                        </Form.Text>
                        </td>
                </tr>
                <tr>
                    <td colspan="2" align="right" >
                        <CustomToggle originator = "userInfo" eventKey="1" payload={{
                            "shoppingCartId" : cookies.get('sb-sc'),
                            "userName": userName,
                            "fName": firstName,
                            "lName" : lastName,
                            "email" : email,
                            "password": password,
                            "province" : province,
                            "postalCode" : postalCode
                        }}>
                        Next
                        </CustomToggle>
                    </td>
                </tr>
            </table>
        </>
    );
}