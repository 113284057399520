import Dropdown from 'react-bootstrap/Dropdown'
import ProductList from '../productListapit';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Footer from '../footer';
import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';

export default function CataloguePageComponent(props){
    const [classLookup, setClassLookup] = useState("");

    const changeClassSearchValue = (value) => {
        if(value == null || value==undefined || value == ""){
            setClassLookup("NONE");
        }else{
            setClassLookup(value);
        }
    }

    useEffect(async ()=>{
        if(props.filter == 'genpub'){
            setClassLookup("Individual-Access-LIFE-Unit-1-From-Atoms-to-Cells");
        }
        if(props.filter == "class"){
            setClassLookup("NONE");
        }


        const getShoppingCartEndPoint = process.env.REACT_APP_BFF + '/getShoppingCart/SC-00000';
            const SCrequestOptions = { 
                method: 'GET', 
                headers: new Headers({ 
                      'Content-Type': 'application/json',
                      'sb-application-name': 'sb-site-ui',
                      'sb-application-key': 'test123',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
                    }), 
                mode: 'cors',
                credentials: 'omit'
                };
                const scResponse = await fetch(getShoppingCartEndPoint,SCrequestOptions);
                // console.log("wakeupSC" + JSON.stringify(await scResponse.json()));

      },[])

const selectSchoolDropDown = () => {
    
    if(props.filter == 'class'){
        // setClassLookup("NONE");
        return(
            <><br />
            <Container align="start">

          {/* <hr /> */}
          <div align="center">
          <Card body>
            <table width="75%">
            <tr>
                <td>
                <Form.Text id="passwordHelpBlock" muted>
                    Search for your class or school name. If you don't see it listed contact us!
                </Form.Text>
                </td>
              </tr>
              <tr>
                <td>
                <Form.Control
                          type="text" autoComplete="text"
                          id="classSearch"
                          aria-describedby="passwordHelpBlock"
                          onChange={event => changeClassSearchValue(event.target.value)}
                        />
                </td>
              </tr>

            </table>
            </Card>
          </div>
          
          </Container>
          </>
        )
    }

    if(props.filter == 'genpub'){
        return(
            <> 
            <br />
            <Container>
            <Row>
            <Col sm={9} className="productSchoolDropdown">
              {props.type == "animated-textbooks" &&
              <>
              <Button href="/buy/individual/animated-textbooks">Animated Textbook</Button>{' '}
              </>
              }
              {props.type != "animated-textbooks" &&
              <>
              <Button variant="outline-primary" href="/buy/individual/animated-textbooks">Animated Textbook</Button>{' '}
              </>
              }
              {props.type == "ebooks" &&
              <>
              <Button href="/buy/individual/ebooks">eBooks</Button>{' '}
              </>
              }
              {props.type != "ebooks" &&
              <>
              <Button variant="outline-primary" href="/buy/individual/ebooks">eBooks</Button>{' '}
              </>
              }
              {props.type == "ALL" &&
              <>
              <Button href="/buy/individual">Show All</Button>{' '}
              </>
              }
              {props.type != "ALL" &&
              <>
              <Button variant="outline-primary" href="/buy/individual">Show All</Button>{' '}
              </>
              }
    <br /><br />
            </Col>
              <hr />
          </Row>
          <Row>
          <Alert style={{textAlign: "left", 'font-size': "20px"}} variant="warning"><strong>Important: </strong>Were you sent here by your instructor?<Button style={{marginLeft: '10px'}} size="sm" variant="success" href="/buy/class">Search My Class</Button></Alert>

            
          </Row>
          </Container>
          </>
        )
    }
}

return(
<div  style={{ 'background-color': "#f8f9fa", height: "100vh"  }} >
    {selectSchoolDropDown()}
    <ProductList filter={props.filter} type={props.type} class={classLookup} />


</div>
)

}