export async function getSearchResults(text) {
    const options = {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            'sb-application-name': 'sb-site-ui',
            'sb-application-key': 'jmd-test',
            'sessionID': sessionStorage.getItem("sessionID"),
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
        }),
        mode: 'cors',
        credentials: 'omit'
    };

    const endpoint = process.env.REACT_APP_BFF + '/bff/v1.0/search/' + text;

    let searchResults =
        await fetch(endpoint, options)
            .then(response => {
                return response.json();
            })
            .catch(function (err) {
                console.log(err);
            });

    return searchResults;

}

export async function getSearchUsers(value) {
    const options = {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            'sb-application-name': 'sb-site-ui',
            'sb-application-key': 'jmd-test',
            'sessionID': sessionStorage.getItem("sessionID"),
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
        }),
        mode: 'cors',
        credentials: 'omit'
    };

    const endpoint = process.env.REACT_APP_BFF + '/bff/v2.0/userSearch/' + value;

    let searchResults =
        await fetch(endpoint, options)
            .then(response => {
                return response.json();
            })
            .catch(function (err) {
                console.log(err);
            });

    return searchResults;

}