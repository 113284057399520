// import React, { useState, useEffect } from "react";
// import { CookiesProvider } from "react-cookie";
// import useCookies from "react-cookie";
import Cookies from 'universal-cookie';
import {getProductByID} from '../services/bffServices/getProducts';


var testPayload = {
    "cartItem": [
      {
        "type": "Purchase",
        "quantity": 1,
        "productName": "LIfe | Unit 1 | Atoms to Molecules",
        "productId": "619a9748fe41e87cb0b6a4b9",
        "productPrice" : 59.99,
        "productCurrency" : "USD",
        "productMembershipId" : "BIO293"
        }
    ]
  };

  // let scID = {};
  // let scInfo = {};

async function addToCart(item, trial = false, redirect = true){
  // console.log('item..' + item);
  testPayload.cartItem[0].productName = item;
  // const productDetails = await getProductByID(item);
  var orderType = "";
  // console.log("<<<<Product detilas >>>>>" + JSON.stringify(productDetails));

  if(redirect == false){
    orderType = "BOOKSTORE"
    var createCart = {
      "cartItem": [
        {
          "type": "Purchase",
          "quantity": 1,
          "productName": item.description,
          "productId": item.productId,
          "productPrice" : item.price,
          "productCurrency" : item.currency,
          "productMembershipId" : "tbd"
          }
      ],
      "orderType": orderType
    };
  }else{
  const productDetails = await getProductByID(item);
  var createCart = {
    "cartItem": [
      {
        "type": "Purchase",
        "quantity": 1,
        "productName": productDetails.description,
        "productId": productDetails.id,
        "productPrice" : productDetails.price,
        "productCurrency" : productDetails.currency,
        "productMembershipId" : "tbd"
        }
    ]
  };

  }

 
const cookies = new Cookies();
    const optionsPOST = { 
      method: 'POST', 
      headers: new Headers({ 
        'Content-Type': 'application/json',
        'sb-application-name': 'sb-site-ui',
        'sb-application-key': 'jmd-test',
        'sessionID' : sessionStorage.getItem("sessionID"),
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
      }), 
      mode: 'cors',
      credentials: 'omit',
      body: JSON.stringify(createCart)
    };
  
    const scURL = process.env.REACT_APP_BFF + '/createShoppingCart';
    // let responseReturn = {};
  
    let getSCData =
      await fetch(scURL,optionsPOST)
        .then(response => {
          // console.log(response);
        //   responseReturn = response;
          return response.json();
        })
        // .then(data => setSCIDsetSCDataa({ scId: data.shoppingCartID }))
        .catch(function(err) {
          // console.log(err);
          // setData(myJson)
        });;

  
    // return getSCData.shoppingCartID;
    // console.log(getSCData.shoppingCartID);
    // setCookie("shoppingCart",getSCData.shoppingCartID);
    cookies.set('sb-sc', getSCData.shoppingCartID, { path: '/' });  //SC-35327796
    sessionStorage.setItem('sb-sc', getSCData.shoppingCartID);
    // cookies.set('sb-sc', 'SC-35327796', { path: '/' }); 
    if(trial != true){
      if (redirect == true){
        window.location.href = "/checkout";
      }
      
    }
    return getSCData.shoppingCartID;
  }

  // async function GetShoppingCart(id){
  //   return new Promise(async function(resolve,reject){
  //   // console.log('id: ' + id);
  //   const optionsPOST = { 
  //     method: 'GET', 
  //     headers: new Headers({ 
  //       'Content-Type': 'application/json',
  //       'sb-application-name': 'sb-site-ui',
  //       'sb-application-key': 'test123',
  //       'Access-Control-Allow-Origin': '*',
  //       'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
  //     }), 
  //     mode: 'cors',
  //     credentials: 'omit'
  //   };
  
  //   const scURL = process.env.REACT_APP_BFF + '/getShoppingCart/' + id;
  //   // console.log('endpoint: ' + scURL);
  
  //   let response = await fetch(scURL,optionsPOST)
  //   let scDetails = await response.json();
  //   // console.log('scDetails:  ' + scDetails.shoppingCartID);
  //       // .then(response => async () => {
  //       //   console.log('blah blah blah: ' + response.json())
  //       //   resolve(response.json());
  //       // })
  //       // .catch(function(err) {
  //       //   console.log(err);
  //       //   reject(err);
  //       // });
  //       // console.log('bff: ' + getSCData);
  //       // return getSCData;
  //       return scDetails;
  //     });
  // }

  export async function addCouponToSC(id, couponCode){
    //validate an error isn't coming back.  if it is, return error.. if not, return SC payload
  //   {
  //     "shoppingCartId" : "SC-35327796",
  //     "updateType" : "coupon",
  //     "couponCode" : "BIO293_C"
  // }

  // console.log("jmd-blah");
  const couponPayload = {
    "shoppingCartId" : id,
    "updateType" : "coupon",
    "couponCode" : couponCode
}

const options = { 
  method: 'PATCH', 
  headers: new Headers({ 
    'Content-Type': 'application/json',
    'sb-application-name': 'sb-site-ui',
    'sb-application-key': 'test123',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
  }), 
  mode: 'cors',
  credentials: 'omit',
  body: JSON.stringify(couponPayload)
};

const scURL = process.env.REACT_APP_BFF + '/updateShoppingCart';
let response = await fetch(scURL,options)
let scDetails = await response.json();
// console.log('scDetails:  ' + scDetails.shoppingCartID);

return scDetails;


  }

  // export default {addToCart, GetShoppingCart};

  // export  default {addToCart,addCouponToSC};

  export default addToCart;