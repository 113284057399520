export async function triggerRequestPassword(email) {
    let updateResults = {};
    
    try {
      const options = {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'sb-application-name': 'sb-site-ui',
          'sb-application-key': 'jmd-test',
          'sessionID': sessionStorage.getItem("sessionID"),
        }),
        mode: 'cors',
        credentials: 'omit'
      };
      const endpoint = process.env.REACT_APP_BFF + '/bff/v1.0/requestPasswordReset/' + email;

      const response = await fetch(endpoint, options);
      if (response.ok) {
        const updateUserResults = await response.json();
        updateResults = updateUserResults;
      } else {
        throw new Error(`Failed to trigger password reset email. Status: ${response.status}`);
      }
    } catch (error) {
      updateResults = {
        error: true,
        errorMsg: error.message || error,
      };
    } finally {
      return updateResults;
    }
  }
  