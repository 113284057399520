import React, { useState, useEffect } from "react";
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Container from "react-bootstrap/esm/Container";
import Tab from 'react-bootstrap/Tab'
import Col from 'react-bootstrap/Col'
import Tabs from 'react-bootstrap/Tabs'
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Placeholder from 'react-bootstrap/Placeholder'
import ImageGrid from './imageGallary';
import ReactMarkdown from 'react-markdown';
import addToCart from '../../services/bffService';
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import { getSession } from '../../services/bffServices/getSessionInfo';
import ProductPasswordOverlay from './ProductPasswordOverlay';
const url = require('url');

const passwordData = "ribosome";
const passwordInd = false;

function ProductPage() {
  const [addToCartButton, setAddToCardButton] = useState(() => {
    return (
      <>
        <Placeholder animation="glow">
          <Placeholder.Button xs={2} />
        </Placeholder>
      </>
    )
  });
  const [productTitle, setproductTitle] = useState(() => {
    return (
      <>
        <Placeholder animation="glow">
          <Placeholder xs={6} />
        </Placeholder>
      </>
    )
  });
  const [productShortDesc, setproductShortDesc] = useState(() => {
    return (
      <>
        <Placeholder animation="glow">
          <Placeholder xs={6} />
          <Placeholder xs={6} />
          <Placeholder xs={6} />
        </Placeholder>
      </>
    )
  });
  const [productShortDescPlaceholder, setproductShortDescPlaceholder] = useState(() => {
    return (
      <>
        <Placeholder animation="glow">
          <Placeholder xs={12} />
          <Placeholder xs={12} />
          <Placeholder xs={12} />
          <Placeholder xs={12} />
          <Placeholder xs={12} /><br />
          <Placeholder xs={6} /><br />
          <Placeholder xs={6} /><br />
          <Placeholder xs={6} /><br />
          <Placeholder xs={6} /><br />
        </Placeholder>
      </>
    )
  });
  const [productInfo, setproductInfo] = useState();
  const [ltiOnly, setltiOnly] = useState(false);
  const [productExampleLesson, setproductExampleLesson] = useState();
  const [productTableOfContents, setproductTableOfContents] = useState();
  const [productExampleImages, setproductExampleImages] = useState();
  const [productCoverImage, setproductCoverImage] = useState();
  const [productPricing, setproductPricing] = useState(() => {
    return (
      <>
        <Placeholder animation="glow">
          <Placeholder xs={2} />
        </Placeholder>
      </>
    )
  });
  const [productCurrency, setproductCurrency] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const productId = queryParams.get('id');

  // const productId = id;

  const addProductToCart = (id) => {
    setShowLoading(true);
    addToCart(id);
  }

  const getData = async (sessionIdv2) => {

    const getProducts = process.env.REACT_APP_BFF + "/getProduct/";
    const options = {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'text/plain',
        'sb-application-name': 'sb-site-ui',
        'sb-application-key': 'jmd-test',
        'Access-Control-Allow-Origin': '*',
        // 'sessionID': "a5a38892-499d-4f02-96ab-9975bd105847",
        'sessionID':sessionIdv2,
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
      }),
      mode: 'cors',
      credentials: 'omit'
    };
    fetch(getProducts + productId, options)
      .then(async function (response) {

        var productInformation = await response.json();
        setproductTitle(productInformation.description);
        setproductPricing("$" + productInformation.price);
        setproductCurrency("(" + productInformation.currency + ")");
        setproductCoverImage(() => {
          const domainURL = "https://storage.googleapis.com/sb-general" //https://storage.googleapis.com/sb-general/
          var parsedURL = url.parse(productInformation.coverImage);
          var updatedURL = domainURL + parsedURL.pathname;

          return updatedURL

        });
        setproductShortDescPlaceholder("");
        setproductShortDesc(productInformation.productShortDescription);
        setltiOnly(productInformation.ltiOnly);
        setproductInfo(productInformation.productInformation);
        setproductExampleLesson(productInformation.lessonExample);
        setproductExampleImages(productInformation.exampleImages);
        setAddToCardButton(() => {
          return (
            <>
              <Button onClick={() => addProductToCart(productId)}>Add to Cart</Button>
            </>
          )
        })
        return productInformation;
      })
      .then(function (myJson) {
      });
  }

  useEffect(async () => {
    await getSession();
    // await getData(sessionStorage.getItem("sessionID") || "d726e9e9-9f85-4cfa-aaef-666e08a03355");
    const sessionId = sessionStorage.getItem("sessionID") || "d726e9e9-9f85-4cfa-aaef-666e08a03355";
    await getData(sessionId);
  }, [])


  return (
    <div style={{ 'background-color': "#f4f4f4" }}>
            {passwordInd === true &&
      <>
      <ProductPasswordOverlay password={passwordData}/>
      </>
      }
      <Container style={{ padding: "10px 0px 50px 0px", 'background-color': "#f4f4f4" }}>
        <Card style={{ padding: "20px", 'background-color': "#f4f4f4", border: "0px" }}>
          <Row>
            <Col align="center">
              <Container>
                <br /><Image width="110%" src={productCoverImage}></Image>
              </Container>
            </Col>
            <Col style={{ align: "left" }} sm={8}>
              <Card style={{ align: "left" }}>
                <Card.Body style={{ align: "left" }}>

                  <Card.Text align="left">
                  <h4>{productTitle} </h4>
                    <span style={{fontWeight: "bold"}}>{productPricing}</span> <small>{productCurrency}</small><br />

                    {ltiOnly === false || ltiOnly == null || ltiOnly == undefined || sessionStorage.getItem("isLTI") === 'Y' ? (
                      <>
                        {addToCartButton}
                      </>
                    ) : (
                      <>
                        <Alert key="warning" variant="warning">
                          <Button disabled><strike>Add to Cart</strike></Button> Only LTI can purchase
                        </Alert>
                      </>
                    )}
                  </Card.Text>

                  <Card.Text align="left">
                    {productShortDescPlaceholder}
                    <ReactMarkdown>{productShortDesc}</ReactMarkdown>
                    <div align="left" style={{ padding: "0px 0px 0px 10px", 'color': "grey" }}><small>*BEFORE purchasing, please see purchase details, sample lesson, and table of contents below.*</small></div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card>
      </Container>
      <Container>
        <Card>
          <Card.Body >
            <Tabs defaultActiveKey="productInfo" id="uncontrolled-tab-example" className="mb-3">
              <Tab eventKey="productInfo" title="Product Information">
                <table>
                  <tr>
                    <td style={{ padding: "20px", font: "10px", 'vertical-align': "top!important" }}>
                      <Image width="400px" src={productCoverImage}></Image>
                    </td>
                    <td style={{ padding: "20px", align: "left!important" }}>
                      <Card.Text align="left">
                        <small>
                          <ReactMarkdown>{productInfo}</ReactMarkdown>
                        </small>
                      </Card.Text>
                    </td>
                  </tr>
                </table>
              </Tab>
              <Tab eventKey="exampleContent" title="Example Images">
                <ImageGrid />
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </Container>
      <Modal
        show={showLoading}
        size="sm"
        backdrop="static"
        keyboard={false}
        class="hideBorder"
        centered
      >
        <div class="centerLoadingModel">
          <button type="submit" class="btn btn-primary mb-2" style={{ align: "center", width: "100px", height: "50px" }} disabled><Spinner animation="border" /></button>
        </div>
      </Modal>
      <br />
      {/* <Footer /> */}


    </div>
  )
}

export default ProductPage;
