const options = { 
    method: 'GET', 
    headers: new Headers({ 
      'Content-Type': 'application/json',
      'sb-application-name': 'sb-site-ui',
      'sb-application-key': 'jmd-test',
      'sessionID' : sessionStorage.getItem("sessionID"),
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
    }), 
    mode: 'cors',
    credentials: 'omit'
    // body: JSON.stringify(testPayload)
  };

  export async function getQuizByID(id){

    const endpoint = process.env.REACT_APP_BFF + '/bff/v1.0/getQuizDetails/' + id;
    options.method = 'GET';
  
    // console.log("getProductByID endpoint" + endpoint);
    let quizDetailsResponse =
      await fetch(endpoint,options)
        .then(response => {
          // console.log('getProductByIDResponse' + JSON.stringify(response.json));
          return response.json();
        })
        .catch(function(err) {
          console.log(err);
        });

        // console.log("productDetailresponse >>>> " + JSON.stringify(quizDetailsResponse));

        // let quizDetailsResponse2 = await transformQuisPyaload(quizDetailsResponse);
        // console.log("quizDetailsResponse2" + JSON.stringify(quizDetailsResponse2))
    return quizDetailsResponse;

  }

  async function transformQuisPyaload(quizPayload){

    // const figureList = Object.keys(quizPayload.questions).map(key => 


      
                  // <>
                  
                  // <Form.Group className="mb-3" controlId="formBasicEmail">
                  //     <Form.Label>{blah.questions[key].question_en}</Form.Label>
                  //     <div className="mb-3">
                  //       <QuizSelectionComponent answers={blah.questions[key].multiChoiceValue_en} group={blah.questions[key].id} questionType={blah.questions[key].questionType} />
                  //     </div> 
                  // </Form.Group>
                  
                  // </>
  // );

  const questionList = [];
  // console.log("quList" + JSON.stringify(quizPayload));

  for(let q = 0; q < quizPayload.questions.length; q++){
    questionList[q] = {
      quizID : quizPayload.id,
      questionId : quizPayload.questions[q].id,
      questionText_en: quizPayload.questions[q].question_en,
      questionText_fr: quizPayload.questions[q].question_fr,
      answerOptions: []
    }

    var answerOptions = quizPayload.questions[q].multiChoiceValue_en.split("\n");

    for(let a=0; a< answerOptions.length;  a++){
      let isCorrect = false;
      if (questionList[q].answerText_en == questionList[q].answerOptions[a])
      {
        isCorrect = true;
      }
      questionList[q].answerOptions[a] = {
        answerText: answerOptions[a], 
        isCorrect: isCorrect
      }
    }
    
  }

  // console.log("quList" + JSON.stringify(questionList));

  return questionList;

  // const questions = [
  //   {
  //     questionText: 'What is the capital of France?',
  //     answerOptions: [
  //       { answerText: 'New York', isCorrect: false },
  //       { answerText: 'London', isCorrect: false },
  //       { answerText: 'Paris', isCorrect: true },
  //       { answerText: 'Dublin', isCorrect: false },
  //     ],
  //   },
  //   {
  //     questionText: 'Who is CEO of Tesla?',
  //     answerOptions: [
  //       { answerText: 'Jeff Bezos', isCorrect: false },
  //       { answerText: 'Elon Musk', isCorrect: true },
  //       { answerText: 'Bill Gates', isCorrect: false },
  //       { answerText: 'Tony Stark', isCorrect: false },
  //     ],
  //   },
  //   {
  //     questionText: 'The iPhone was created by which company?',
  //     answerOptions: [
  //       { answerText: 'Apple', isCorrect: true },
  //       { answerText: 'Intel', isCorrect: false },
  //       { answerText: 'Amazon', isCorrect: false },
  //       { answerText: 'Microsoft', isCorrect: false },
  //     ],
  //   },
  //   {
  //     questionText: 'How many Harry Potter books are there?',
  //     answerOptions: [
  //       { answerText: '1', isCorrect: false },
  //       { answerText: '4', isCorrect: false },
  //       { answerText: '6', isCorrect: false },
  //       { answerText: '7', isCorrect: true },
  //     ],
  //   },
  //       {
  //     questionText: 'How many Harry Potter books are there?',
  //     answerOptions: [
  //       { answerText: '1', isCorrect: false },
  //       { answerText: '4', isCorrect: false },
  //       { answerText: '6', isCorrect: false },
  //       { answerText: '7', isCorrect: true },
  //     ],
  //   },
  // ];
}

export async function getQuizesByClass(classId){

  const endpoint = process.env.REACT_APP_BFF + '/bff/v1.0/getTextbookByClass/' + classId;

  let userList = await fetch(endpoint,options)
        .then(response => {
          return response.json();
        })
        .catch(function(err) {
          console.log(err);
        });

    return userList;
      
  }