import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import writeReplyComment from '../services/writeReplyComment';



function ReplyModal(props) {
    const [show, setShow] = useState(false);
    const [replyComment, setReplyComment] = useState();


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const writeRepluComments = async () => {
        
        handleClose();
        //write comment, then refresh main comment component

        const payload = 
        {
            "isParentComment": "N",
            "parentComment": props.id,
            "replyTo": props.replyTo || "",
            "comment": replyComment
          }

        await writeReplyComment(payload);
        props.refresh();
        // const blah = await getFullCommentList("test");

    }

    return (
        <>
        <Button onClick={handleShow}>
        Reply to Question
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Write you answer below{props.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Control onChange={event => setReplyComment(event.target.value)} as="textarea" rows="5" aria-label="With textarea"/><br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={writeRepluComments}>
            Save Answer
          </Button>
        </Modal.Footer>
      </Modal>
        </>
    )

}

export default ReplyModal;