import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert'
import { getUserByEmail } from '../../../services/bffServices/getUserDetails';
import { getSchools } from '../../../services/bffServices/getSchoolDetails';
import { getMembershipList } from '../../../services/bffServices/getMembershipList';
import { MdCropLandscape } from 'react-icons/md';
import { updateUserAddClass } from '../../../services/bffServices/updateUser';
import Placeholder from 'react-bootstrap/Placeholder';
import Card from 'react-bootstrap/Card';
import { IconContext } from "react-icons";
import { MdOutlineErrorOutline } from "react-icons/md";


function DeleteUserComponent() {

    const [email, setEmail] = useState();
    const [stepupError, setStepupError] = useState()
    const [membershipKey, setMembershipKey] = useState("");
    // const [userDetails, setUserDetails] = useState();
    const [userArea, setUserArea] = useState("");
    const [membershipList, setMembershipList] = useState();
    // const [membershipList, setSchools] = useState([]);
    const [assignClassSection, setAssignClassSection] = useState({
        display: "none"
    });
    // const testDisplay = {display: ""}

    const updateUserDelete = async () => {

        var payload = {
            "userName": email,
            "updateType": {
                "deleteUser": "Y"
            }
        }

        setUserArea(() => {
            return (
                <>
                    {/* <div className="d-flex justify-content-around"> */}
                    <Card>
                        <Card.Body>
                            <Row style={{ align: "left" }}>
                                <Col>
                                    UserID

                                </Col>
                                <Col style={{ align: "left" }}>
                                    <Placeholder animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    User Email

                                </Col>
                                <Col>
                                    <Placeholder animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    User Name

                                </Col>
                                <Col>
                                    <Placeholder animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Current Membership List

                                </Col>
                                <Col>
                                    <Placeholder animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>


                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    {/* </div> */}
                </>
            )
        });

        await updateUserAddClass(payload);
        createActiveArea();

    }

    const createActiveArea = async () => {

        setUserArea(() => {
            return (
                <>
                    <Card>
                        <Card.Body>
                            <Row style={{ align: "left" }}>
                                <Col>
                                    UserID

                                </Col>
                                <Col style={{ align: "left" }}>
                                    <Placeholder animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    User Email

                                </Col>
                                <Col>
                                    <Placeholder animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    User Name

                                </Col>
                                <Col>
                                    <Placeholder animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Current Membership List

                                </Col>
                                <Col>
                                    <Placeholder animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>


                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </>
            )
        });


        const userInfo = await getUserByEmail(email);

        // const classListDetails = 

        if (userInfo.code == 2) {
            setStepupError(() => {
                return (
                    <>
                        <Alert key="danger" variant="danger">
                            {userInfo.message}
                        </Alert>
                    </>
                )
            });

            setUserArea("");

        }

        if (userInfo.code != 2) {

            setUserArea(() => {
                return (
                    <>
                        <Card>
                            <Card.Body>
                                <Row style={{ align: "left" }}>
                                    <Col>
                                        UserID

                                    </Col>
                                    <Col style={{ align: "left" }}>
                                        {userInfo.ID}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        User Email

                                    </Col>
                                    <Col>
                                        {userInfo.Email}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        User Name

                                    </Col>
                                    <Col>
                                        {userInfo.Name}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Current Membership List

                                    </Col>
                                    <Col>
                                        <ul>

                                        </ul>
                                        {userInfo.MembershipList.map(membership => {

                                            return (
                                                <>
                                                    <li>{membership.membership}</li>
                                                </>)
                                        })}

                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </>
                )
            });

        }


        setAssignClassSection({});
    }



    useEffect(async () => {

        // await getMembershipList()
        // .then(payload => setMembershipList(payload))

        // setMembershipList([
        //     {
        //       "membership": "Individual-Access-LIFE-Unit-1-From-Atoms-to-Cells"
        //     }
        //   ]);

    }, []);

    return (
        <>

            <Row>
                <Col style={{ width: "20%" }}>
                    Enter email of user
                </Col>
                <Col>
                    <input class="form-control form-control-sm" onChange={event => setEmail(event.target.value)} type="text" autoComplete="text" name="email" id="email" />
                </Col>
                <Col>
                    <button type="submit" class="btn btn-primary mb-2" style={{ width: "100px" }} onClick={() => createActiveArea()}>Search</button>
                </Col>
            </Row>
            <div align="center"><hr width="75%" /></div>
            <div align="left">
                <Row style={{ margin: "auto" }}>
                    <Col style={{ align: "left" }}>
                        {userArea}
                    </Col>
                    <Col style={assignClassSection} >
                        <Row>
                            <Col>
                                < button type="submit" class="btn btn-primary mb-2" onClick={() => updateUserDelete()}>Delete User</button>
                            </Col>
                            <Col xs={9}>
                                {/* <div className="d-flex align-items-left justify-content-center"> */}
                                    <IconContext.Provider value={{ color: "red", className: "global-class-name", size: "1.5em" }}>
                                        <MdOutlineErrorOutline />
                                    </IconContext.Provider>
                                    <span className="ml-2 text-center">
                                        IMPORTANT: Can not be reversed! Delete with Caution!
                                    </span>
                                {/* </div> */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div><br></br>
            {stepupError}
        </>

    );
}

export default DeleteUserComponent;