import Card from 'react-bootstrap/Card'
const queryParams = new URLSearchParams(window.location.search);
// const [moduleId, setModuleId] = useState(queryParams.get('id'));

export default function ErrorPageComponent(props){


    return(
        <div style={{margin: "auto", width:"60%", padding: "100px"}}>
    <Card align="left">
      <Card.Header>Whoops, something unexpected happened! But don't fret, we're here to help.</Card.Header>
      <Card.Body>
        {/* <Card.Title>Something Went Horribly Wrong!</Card.Title> */}
        <Card.Text>
        Oopsie daisy, looks like something went wrong! But don't worry, we've got your back. Either send an email to support@smart-biology.com or give it another go. Who knows, it might just work this time. 🤞
        <br /><br /><br />
<small>
        <table>
          <tr>
          <td>
            Session ID:
          </td>
          <td>
          {sessionStorage.getItem("sessionID")}
          </td>
          </tr>
          <tr>
            <td>
              Error:
            </td>
            <td>
              {queryParams.get('errMsg')}
            </td>
            </tr>
        </table>
        </small>
        </Card.Text>
        {/* <Button>Go somewhere</Button> */}
      </Card.Body>
      {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
    </Card>
        </div>
    );

}