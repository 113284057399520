export async function getQuizCutoff(quizId, moduleId, classId) {
    const options = { 
        method: 'POST', 
        headers: new Headers({ 
          'Content-Type': 'application/json',
          'sb-application-name': 'sb-site-ui',
          'sb-application-key': 'jmd-test',
          'sessionID' : sessionStorage.getItem("sessionID"),
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
        }), 
        mode: 'cors',
        credentials: 'omit'
    }
        
    try {
        const endpoint = process.env.REACT_APP_BFF + '/bff/v1.0/getQuizCutOff/' + classId + '/' + moduleId + '/' + quizId;
        options.method = 'GET';

        let cutOffDetails = {};
        let detailsResponse =
            await fetch(endpoint, options)
                .then(response => {
                    return response.json();
                })
                .catch(function (err) {
                    console.log(err);
                });

                let openDate, closeDate;
                let isOpen = true;
                
                if (detailsResponse.payload && detailsResponse.payload.openDate && detailsResponse.payload.openDate !== "N") {
                    openDate = new Date(`${detailsResponse.payload.openDate}T00:00:00`);
                }
                
                if (detailsResponse.payload && detailsResponse.payload.closeDate && detailsResponse.payload.closeDate !== "N") {
                    closeDate = new Date(`${detailsResponse.payload.closeDate}T00:00:00`);
                }
                
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                
                if (openDate && closeDate) {
                    isOpen = (today >= openDate && today <= closeDate);
                } else if (openDate) {
                    isOpen = today >= openDate;
                } else if (closeDate) {
                    isOpen = today <= closeDate;
                }
                return {
                    open: openDate,
                    close: closeDate,
                    today: today.toJSON().split('T')[0],
                    openQuiz: isOpen
                };
    } catch (err) {
        console.log(err);
    }

}