import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';


function ProductPasswordOverlay(props) {
  const [show, setShow] = useState(true);
  const [userMessage, setUserMessage] = useState("");
  const [fadeOut, setFadeOut] = useState(false);



  // const handleClose = () => setShow(false);
  const handleClose = () => {
    setFadeOut(true);
    setTimeout(() => {
      setShow(false);
      setFadeOut(false); // Reset for the next time
    }, 1000); // Assuming 1 second for the fade-out effect
  };
  
  const handleShow = () => setShow(true);
  const [passwordEntered, setPasswordEntered] = useState();

  const checkPassword = () => {
    if (passwordEntered === props.password) {
      setUserMessage(() => {
        return (
          <>
            <br /><br /><br />
            <Alert style={{ padding: "10px", margin: "auto", width: "100%", textAlign: "center", 'font-size': "10-px", }} variant="success">
              <strong>Success!</strong>
            </Alert>
          </>
        )
      })
      setTimeout(() => {
        handleClose();
      }, 2000); // Close the modal after 2 seconds
    } else {
      setUserMessage(() => {
        return (
          <>
            <br /><br /><br />
            <Alert style={{ padding: "10px", margin: "auto", width: "100%", textAlign: "center", 'font-size': "10-px", }} variant="danger">
              <strong>Important: </strong>Password is incorrect, please retry
            </Alert>
          </>
        )
      });
      setPasswordEntered("");
    }
  }
  

  return (
    <>
      {/* <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      > */}
      <Modal
  show={show}
  onHide={handleClose}
  backdrop="static"
  keyboard={false}
  style={{ opacity: fadeOut ? 0 : 1, transition: "opacity 1s ease-out" }}
>
        <Modal.Header>
          <Modal.Title>Enter Password ({props.password})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
          autoComplete='password'
            type="password"
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            value={passwordEntered} onChange={event => setPasswordEntered(event.target.value)}
          />
          <Form.Text id="passwordHelpBlock" muted>
            Please enter password to purchase/access product for your content
          </Form.Text>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={checkPassword}>Access Product</Button>
          {userMessage}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProductPasswordOverlay;