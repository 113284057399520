// import React from "react";
import React, { useState, useEffect, useRef } from "react";
// import  GetShoppingCart  from '../services/bffService';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookies from 'universal-cookie';
import {addCouponToSC} from '../services/bffService';
import {submitPO, moveToCheckout} from '../services/bffServices/submitOrder';
const cookies = new Cookies();


function Cart(){
    const [userData, setUserData] = useState({});
    const couponForm = useRef(null);

    const [productItem, setproductItem] = useState({});
    const [cartTotal, setcartTotal] = useState({});
    const scURL = process.env.REACT_APP_BFF + '/getShoppingCart/' + cookies.get('sb-sc');
    const optionsPOST = { 
        method: 'GET', 
        headers: new Headers({ 
          'Content-Type': 'application/json',
          'sb-application-name': 'sb-site-ui',
          'sb-application-key': 'test123',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
        }), 
        mode: 'cors',
        credentials: 'omit'
      };
      useEffect(() => {
        scContents();
      }, []);
    


    
    const scContents = async () => {
        var totalDiscounts = 0;
        const scStuff = await fetch(scURL,optionsPOST);
        const thatStuff = await scStuff.json();
        var productItem = await thatStuff.cartItems;
        var totals = await thatStuff.cartTotal;
        // for (let i = 0; i < thatStuff.cartTotal.couponList.length; i++) {
        //     totalDiscounts += thatStuff.cartTotal.couponList[i].value;
        //   }
        
        setUserData(thatStuff);
        setproductItem(productItem);
        setcartTotal(totals);
        // setdiscounts(totalDiscounts);
    }

    const setCoupon = async () => {
        const form = couponForm.current;
        const couponCode = form['blah'].value;
        // console.log('coupon entered' + form['blah'].value);
        await addCouponToSC(userData.shoppingCartID, "BIO293_C");
        await scContents();
        form['blah'].value = "";
    }

    

    return(
       <div>
           <Container>
              <Row>
                  <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                        Library
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Cart</Breadcrumb.Item>
                    </Breadcrumb>
                    </Col>
                </Row>
            </Container> 

            <Container>
              <Row>
              <Col md={{ span: 5, offset: 0 }}> 
                <Container>
                <Row>
                <form class="form-inline" ref={couponForm}>
                    <Col md={{ span: 5, offset: 7 }}    >
                    <div class="form-group mx-sm-3 mb-2">
                    <input type="text" autoComplete="text" class="form-control" id="blah" name="blah" placeholder="BIO293_C"/>

                </div>
                {/* <button type="submit" class="btn btn-primary mb-2" onClick={() => setCoupon() }>Add Coupon</button> */}
                <Button onClick={() => setCoupon()}>Coupon</Button>


                    </Col>
                    <Col md={{ span: 20, offset: -10 }}>
                

                    </Col>
            </form>

                </Row>   
                </Container>
              

              </Col>
                  <Col md={{ span: 6, offset: 0 }}>
    <Card>
    <Card.Header as="h5">Cart - {userData.shoppingCartID}</Card.Header>
    <Card.Body>
      <Card.Text>

        <table align="right" width="50%">
            <tr>
                <td align="center">
                   {productItem[0]?.productName}<br />
                </td>
                <td align="right">
                {productItem[0]?.productPrice}<br />
                </td>
            </tr>
            <tr>
                <td colspan="2">
                <hr/>
                </td>
            </tr>
            <tr>
                <td align="left">
                    Subtotal
                </td>
                <td align="right">
                    ${cartTotal?.cartSubtotal || productItem[0]?.productPrice}
                </td>
            </tr>
            <tr>
                <td align="left">
                Discounts
                </td>
                <td align="right">
                    ${cartTotal?.cartTotalDiscountValue || '0'}
                </td>
            </tr>
            {/* <tr>
                <td align="right">
                    Tax ({cartTotal?.cartTotalTaxPercent || '0'})
                </td>
                <td align="right">
                ${cartTotal?.cartTaxValue || '0'}
                </td>
            </tr> */}
            <tr>
                <td colspan="2" align="left">
                    *taxes calculated are checkout
                </td>
            </tr>
            <tr>
                <td align="left">
                    Total
                </td>
                <td align="right">
                ${cartTotal?.cartTotal || productItem[0]?.productPrice}
                </td>
            </tr>
            <tr>
                <td colspan='2' align='right'>
        <br /><button type="submit" class="btn btn-primary mb-2" onClick={() => window.location.href = "/checkout"}>Checkout</button>

                </td>
            </tr>
        </table>

      </Card.Text>
    </Card.Body>
  </Card>
  </Col>
                </Row>
            </Container>


       </div>
    );
}

export default Cart;