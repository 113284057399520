
export async function createUserService(payload){
    
    const validateUserEndpoint = process.env.REACT_APP_BFF + "/bff/v1.0/createUserManually/";
    const options = { 
      method: 'POST', 
      headers: new Headers({ 
        'Content-Type': 'application/json',
        'sb-application-name': 'sb-site-ui',
        'sb-application-key': 'jmd-test',
        'Access-Control-Allow-Origin': '*',
        'sessionID' : sessionStorage.getItem("sessionID"),
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
      }), 
      mode: 'cors',
      credentials: 'omit',
      body: JSON.stringify(payload)
    };

    const createUserResponse = await fetch(validateUserEndpoint,options)
    .then(function(response){return response.json()});
    
    return createUserResponse;

}

