
import React, { useState, useEffect, useRef } from "react";
import Form from 'react-bootstrap/Form';
import { duplicateUserCheckByEmail } from '../../services/bffServices/getUserDetails';
import validator from 'validator';
import Alert from 'react-bootstrap/Alert';
import bcrypt from '../../../node_modules/bcryptjs';
import { createUserService } from '../../services/bffServices/createUser';
import Placeholder from 'react-bootstrap/Placeholder';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Papa from 'papaparse';
import { getAllMemberships } from '../../services/getAllMemberships';
import { getAllClasses } from '../../services/getAllClasses';

const saltRounds = 10;

function CreateUserDetailsComponent() {
    const [email, setemail] = useState();
    const [userName, setUserName] = useState({});
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [country, setCountry] = useState("");
    const [province, setProvince] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [duplicateMsg, setDuplicateMsg] = useState("");
    const [membership, setMembership] = useState("");
    const [classAssignment, setClass] = useState("");
    const [role, setRole] = useState("");
    const [ltiStatus, setLTIStatus] = useState("");
    const [stepupError, setStepupError] = useState("");
    const [nextButton, setNextButton] = useState("Next");
    const [file, setFile] = useState(null);
    const [listOfMemberships, setListOfMemberships] = useState();
    const [listOfClasses, setListOfClasses] = useState();

    useEffect(async () => {
        const numMemberships = await getAllMemberships();
        const numClasses = await getAllClasses();
        await setListOfMemberships(numMemberships);
        await setListOfClasses(numClasses);

    }, []);

    const processBulk = async (file) => {
        file.shift();
        var results = []
        await file.forEach(async function (user) {
            await setemail(user[0]);
            await setUserName(user[1]);
            await setPassword(user[2]);
            await setFirstName(user[3]);
            await setLastName(user[4]);
            await setCountry(user[5]);
            await setProvince(user[6]);
            await setPostalCode(user[7]);
            await setRole(user[8]);
            await setMembership(user[9]);
            await setClass(user[10]);

            // var createResults = await createUser();
            var encryptedPassword = await bcrypt.hash(user[2], saltRounds, async function (err, hash) {
                var payload = {
                    "userDetails": {
                        "firstName": user[3],
                        "lastName": user[4],
                        "username": user[1],
                        "password": hash,
                        "email": user[0],
                        "role": user[8],
                        "Class": user[10],
                        "membership": user[9],
                        "migrated": "N"
                        // "ltiStudent": "N"
                    },
                    "address": {
                        "city": "N/A",
                        "stateOrProvince": "N/A",
                        "country": "N/A",
                        "postalCode": "N/A"
                    }
                };

                var results = await createUserService(payload);

                return results;
            });

            results.push({
                "username": userName,
                "results": encryptedPassword
            });
        })

        setStepupError(JSON.stringify(results));
    }

    const handleFileChange = (event) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const csvData = e.target.result;
            const parsedData = Papa.parse(csvData).data;
            setFile(parsedData);
        };

        reader.readAsText(event.target.files[0]);

    };

    const createUser = async () => {

        var encryptedPassword = await bcrypt.hash(password, saltRounds, async function (err, hash) {
            var payload = {
                "userDetails": {
                    "firstName": firstName,
                    "lastName": lastName,
                    "username": userName,
                    "password": hash,
                    "email": email,
                    "role": role,
                    "Class": classAssignment,
                    "membership": membership,
                    "migrated": "N"
                    // "ltiStudent": "N"
                },
                "address": {
                    "city": "N/A",
                    "stateOrProvince": "N/A",
                    "country": "N/A",
                    "postalCode": "N/A"
                }
            };

            var results = await createUserService(payload);
            var resultsMess = "error101";

            if (results.status != "Success") {
                resultsMess = results.message;
            }

            if (results.status == "Success") {
                resultsMess = "Successfully Created User: " + results.email + "/" + results.id;
            }

            if (results.code !== 2) {
                setStepupError(() => {
                    return (
                        <>
                            <Alert key="success" variant="success">
                                User has been created
                            </Alert>
                        </>
                    )
                })

                setNextButton("Next");

            }
            if (results.code === 2) {

                setStepupError(() => {
                    return (
                        <>
                            <Alert key="danger" variant="danger">
                                Error has occured: {results.message}<br />
                            </Alert>
                        </>
                    )
                });
                setNextButton("Next");

            }
            return results;
        });

        return encryptedPassword;
    }

    const createUserValidation = async () => {

        if (password != "" && firstName != "" && lastName != "" && email != "") {
            var duplicateCheck = await duplicateUserCheckByEmail(email);
            if (!duplicateCheck) {
                setDuplicateMsg("")
                setStepupError("");
                if (validator.isEmail(email)) {
                    setNextButton(() => {
                        return (
                            <>
                                <Spinner animation="grow" size="sm" />
                            </>
                        )
                    })
                    setStepupError(() => {
                        return (
                            <>
                                <Placeholder xs={6} />
                            </>
                        )
                    })
                    var results = await createUser();

                } else {
                    // setDuplicateMsg("incorrect email format!")
                    setStepupError(() => {
                        return (
                            <>
                                <Alert key="danger" variant="danger">
                                    An incorrect email format has been entered, correct and resubmit.
                                </Alert>
                            </>
                        )
                    });
                }
            } else {
                setStepupError(() => {
                    return (
                        <>
                            <Alert key="danger" variant="danger">
                                Email entered is already used, please enter another email address.
                            </Alert>
                        </>
                    )
                });
            }
        } else {
            setStepupError(() => {
                return (
                    <>
                        <Alert key="danger" variant="danger">
                            Entries missing, please validate all fields have been filled out!
                        </Alert>
                    </>
                )
            });
        }
    }

    return (
        <>
            <small>
                <table width="75%" align="center" border="0">
                    <tr>
                        <td width="20%">
                            <label>Email</label>
                        </td>
                        <td>
                            <input class="form-control form-control-sm" onChange={event => setemail(event.target.value)} type="text" autoComplete="text" name="email" id="email" />
                            <div style={{ 'color': "red", 'font-size': "10px", 'text-align': "left" }}>{duplicateMsg}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Username</label>
                        </td>
                        <td>
                            <input class="form-control form-control-sm" type="text" autoComplete="text" onChange={event => setUserName(event.target.value)} name="username" id="username" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Password</label>
                        </td>
                        <td>
                            <input class="form-control form-control-sm" type="password" autoComplete="password" onChange={event => setPassword(event.target.value)} name="password" id="password" />
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <hr />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>First Name </label>
                        </td>
                        <td>
                            <input class="form-control form-control-sm" type="text" autoComplete="text" onChange={event => setFirstName(event.target.value)} name="fname" id="fname" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Last Name </label>
                        </td>
                        <td>
                            <input class="form-control form-control-sm" type="text" autoComplete="text" name="lname" onChange={event => setLastName(event.target.value)} id="lname" />
                        </td>
                    </tr>
                    {/* <tr>
                        <td>
                            <label>Country</label>
                        </td>
                        <td>
                            <Form.Select aria-label="Default select example" onChange={event => setCountry(event.target.value)} id="country" name="country" >
                                <option value="0"></option>
                                <option value="CA">Canada</option>
                                <option value="US">United States</option>
                                <option value="OTHER">Other</option>
                            </Form.Select>
                        </td>
                    </tr> */}
                    {/* <tr>
                        <td>
                            {country == "CA" &&
                                <div>Province</div>
                            }
                            {country == "US" &&
                                <div>State</div>
                            }
                            {country == "OTHER" &&
                                <div>Region</div>
                            }
                        </td>
                        <td colspan="2">
                            <table width="100%" align="right">
                                <tr>
                                    <td>
                                        {country == "CA" &&
                                            <Form.Select aria-label="Default select example" onChange={event => setProvince(event.target.value)} id="province" name="province" >
                                                <option value="0"></option>
                                                <option value="AB">Alberta</option>
                                                <option value="BC">British Columbia</option>
                                                <option value="MB">Manitoba</option>
                                                <option value="NB">New Brunswick</option>
                                                <option value="NL">Newfoundland and Labrador</option>
                                                <option value="NS">Nova Scotia</option>
                                                <option value="ON">Ontario</option>
                                                <option value="PE">Prince Edward Island</option>
                                                <option value="QC">Quebec</option>
                                                <option value="SK">Saskatchewan</option>
                                                <option value="NT">Northwest Territories</option>
                                                <option value="NU">Nunavut</option>
                                                <option value="YT">Yukon</option>
                                            </Form.Select>
                                        }
                                        {country == "US" &&
                                            <Form.Select aria-label="Default select example" onChange={event => setProvince(event.target.value)} id="province" name="province" >
                                                <option value="0"></option>
                                                <option value="AL">Alabama</option>
                                                <option value="AK">Alaska</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DE">Delaware</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>
                                                <option value="HI">Hawaii</option>
                                                <option value="ID">Idaho</option>
                                                <option value="IL">Illinois</option>
                                                <option value="IN">Indiana</option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">Kansas</option>
                                                <option value="KY">Kentucky</option>
                                                <option value="LA">Louisiana</option>
                                                <option value="ME">Maine</option>
                                                <option value="MD">Maryland</option>
                                                <option value="MA">Massachusetts</option>
                                                <option value="MI">Michigan</option>
                                                <option value="MN">Minnesota</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MT">Montana</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NH">New Hampshire</option>
                                                <option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>
                                                <option value="NY">New York</option>
                                                <option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>
                                                <option value="PA">Pennsylvania</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">Vermont</option>
                                                <option value="VA">Virginia</option>
                                                <option value="WA">Washington</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WY">Wyoming</option>
                                            </Form.Select>
                                        }
                                        {country == "OTHER" &&
                                            <input class="form-control form-control-sm" type="text" autoComplete="text" name="province" onChange={event => setProvince(event.target.value)} id="province" />
                                        }
                                    </td>

                                    {country == "CA" &&
                                        <>
                                            <td style={{ padding: "10px" }} width="20%">
                                                <div>Postal Code</div>
                                            </td>
                                            <td width="30%">
                                                <input class="form-control form-control-sm" settype="text" autoComplete="text" name="postalCode" id="postalCode" onChange={event => setPostalCode(event.target.value)} />
                                            </td>
                                        </>
                                    }
                                    {country == "US" &&
                                        <>
                                            <td style={{ padding: "10px" }} width="20%">

                                                <div>Zip Code</div>
                                            </td>
                                            <td width="30%">
                                                <input class="form-control form-control-sm" settype="text" autoComplete="text" name="postalCode" id="postalCode" onChange={event => setPostalCode(event.target.value)} />
                                            </td>
                                        </>
                                    }
                                    {country == "OTHER" &&
                                        <>
                                            <td style={{ padding: "10px" }} width="20%">
                                                <div>Zip/Postal Code</div>
                                            </td>
                                            <td width="30%">
                                                <input class="form-control form-control-sm" settype="text" autoComplete="text" name="postalCode" id="postalCode" onChange={event => setPostalCode(event.target.value)} />
                                            </td>
                                        </>
                                    }
                                </tr>

                            </table>
                        </td>
                    </tr> */}
                    <tr>
                        <td colspan="2">
                            <hr />
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <table width="100%" align="right">
                                <tr>
                                    <td>Select Membership</td>
                                    <td width="30%">
                                        {/* <input class="form-control form-control-sm" settype="text" autoComplete="text" name="memKey" id="memKey" onChange={event => setMembership(event.target.value)} /> */}
                                        <Form.Select onChange={event => setMembership(event.target.value)}>
                                            <option style={{ 'fontWeight': "400", color: "#212529" }} value="">

                                            </option>
                                            {Array.isArray(listOfMemberships) && listOfMemberships.length > 0 ?
                                                listOfMemberships.map(membership => (
                                                    <option key={membership.membershipId} value={membership.membershipKey}>
                                                        {membership.membershipName}
                                                    </option>
                                                ))
                                                :
                                                <option disabled>Error retrieving memberships</option>}
                                        </Form.Select>
                                    </td>
                                    <td>
                                        Enter Class ID (not key/name)
                                    </td>
                                    <td width="30%">
                                        {/* <input class="form-control form-control-sm" settype="text" autoComplete="text" name="classid" id="classid" onChange={event => setClass(event.target.value)} /> */}
                                        <Form.Select onChange={event => setClass(event.target.value)}>
                                            <option style={{ 'fontWeight': "400", color: "#212529" }} value="">

                                            </option>
                                            {Array.isArray(listOfClasses) && listOfClasses.length > 0 ?
                                                listOfClasses.map(singleClass => (
                                                    <option key={singleClass.schoolId} value={singleClass.schoolId}>
                                                        {singleClass.schoolName}
                                                    </option>
                                                ))
                                                :
                                                <option disabled>Error retrieving memberships</option>}
                                        </Form.Select>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Select Role
                                    </td>
                                    <td align="right">
                                        <Form.Select aria-label="Default select example" onChange={event => setRole(event.target.value)} id="role" name="role" >
                                            <option value="0"></option>
                                            <option value="None">None</option>
                                            <option value="admin">Admin</option>
                                            <option value="teacher">Teacher</option>
                                            <option value="student">Student</option>
                                        </Form.Select>
                                    </td>
                                    <td>
                                        Is LTI?
                                    </td>
                                    <td align="right">
                                        <Form.Select aria-label="Default select example" onChange={event => setLTIStatus(event.target.value)} id="role" name="role" >
                                            <option value="0"></option>
                                            <option value="Y">Yes</option>
                                            <option value="N">No</option>
                                        </Form.Select>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" align="right" >
                            <button
                                class="btn btn-primary mb-2"
                                type="button"
                                onClick={createUserValidation}
                            >
                                {nextButton}
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" align="center">
                            {stepupError}
                        </td>
                    </tr>
                </table>
            </small>

            <hr />
            <div width="75%">
                <h1>ONLY FOR JAMIE TO USE</h1>

                <Row>
                    <Col>
                        Migrate Trial Users
                    </Col>
                    <Col>
                        <input type="file" onChange={handleFileChange} />
                        <button onClick={() => processBulk(file)}>Upload</button>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default CreateUserDetailsComponent;
