import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect, useRef } from "react";
import Alert from "react-bootstrap/Alert";
import { sendContactUsEmail } from "../../services/notifications/sendContactUsEmail";
import Footer from "../footer";
import { updatePageTitle } from "../../utls/lib";

const queryParams = new URLSearchParams(window.location.search);

export default function ContactUsPageMainComponent({ type }) {
  const [email, setemail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [formValidationMsg, setFormValidationMsg] = useState("");
  const [institutionName, setInstitutionName] = useState("");
  const [institutionContact, setInstitutionContact] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [trialRequest, setTrialRequest] = useState("No");

  //Labels
  const [contactUsTitleLabel, setContactUsTitleLabel] = useState("");
  const [contactFormSubTextLabel, setContactFormSubTextLabel] = useState("");
  const [contactFormNameLabel, setContactFormNameLabel] = useState("");
  const [contactFormEmailLabel, setContactFormEmailLabel] = useState("");
  const [contactFormMessageLabel, setContactFormMessageLabel] = useState("");
  const [contactFormRequestTrialLabel, setContactFormRequestTrialLabel] =
    useState("");
  const [contactFormRequestSubmitLabel, setContactFormRequestSubmitLabel] =
    useState("");
  const [contactFormResetLabel, setContactFormResetLabel] = useState("");
  const [sideContentGenPub, setSideContentGenPub] = useState("");
  const [sideContentEnterprise, setSideContentEnterprise] = useState("");
  const [emailSentMsg, setEmailSentMsg] = useState("");
  const [institutionNameLabel, setIntitutionNameLabel] = useState("");
  const [InstContactTital, setIntContactTitle] = useState("");
  const [contactNum, setContactNum] = useState("");
  const [edLevel, setEdLevel] = useState("");
  const [countryLabel, setCountryLabel] = useState("");
  const [provinceLabel, setProvinceLabel] = useState("");
  const [mandatoryFieldsMsg, setMandatoryFieldMsg] = useState("");

  const [edHighschoolLabel, setEdHighschoolLabel] = useState("");
  const [edIndustryTrainingLabel, setIndustryTrainingLabel] = useState("");
  const [edHigherEdLabel, setEdHigherEdLabel] = useState("");
  const [edFirstYear, setEdFirstYear] = useState("");
  const [edUpperYear, serEdUpperYear] = useState("");
  const [shareEmailLabel, setshareEmailLabel] = useState("");

  useEffect(async () => {
    const language = sessionStorage.getItem("lang") || "EN";

    if (language == "EN") {
      setContactUsTitleLabel("Contact Us!");
      setContactFormSubTextLabel(
        "Please be aware that we do not provide answers to content-related questions. We will however be happy to help with any technical issues."
      );
      setContactFormNameLabel("First & Last Name");
      setContactFormEmailLabel("Email");
      setContactFormMessageLabel("Message");
      setContactFormRequestTrialLabel("Request Class Trial");
      setContactFormRequestSubmitLabel("Submit");
      setContactFormResetLabel("Reset");
      setEmailSentMsg("Email has been sent");
      setMandatoryFieldMsg("Please ensure all mandatory fields are entered");
      setIntitutionNameLabel("Institution Name");
      setIntContactTitle("Institution Contact & Job Title");
      setContactNum("Contact Number");
      setEdLevel("Education Level Interested");
      setCountryLabel("Country");
      setProvinceLabel("Province / State / Territory");
      setEdHighschoolLabel("High School");
      setEdHigherEdLabel("Higher Education");
      setEdFirstYear("First Year Biology");
      serEdUpperYear("Second Year Biology");
      setshareEmailLabel("We will never share your email address");
      setIndustryTrainingLabel("Industry Training");

      setSideContentGenPub(() => {
        return (
          <>
            <div>
              <h3>Instructor</h3>
              <div>
                Are you a college, university, or high school instructor
                interested in using Animated Textbooks for your course? If so,
                please click "Request Class Trial" in the form.
              </div>
              <br />
            </div>
            <div>
              <div>
                <h3>Students</h3>
                Are you a college, university, or high school student with a
                question or comment about Smart Biology animations, your order,
                or the website? Please fill out the contact form and a member of
                our team would be happy to get back to you.
              </div>
              <br />
            </div>
            <div>
              <div>
                <h3>Industry</h3>
                Are you interested in using Smart Biology animations for your
                pharmaceutical or biotech company? Please fill out the
                information in the form on the right and a member of our team
                would be happy to get back to you.
              </div>
              <br />
            </div>
            <div>
              <div>
                <strong>General inquiries:</strong>{" "}
                <a href="mailto:info@smart-biology.com">
                  info@smart-biology.com
                </a>
              </div>
              <div>
                <strong>Order inquiries: </strong>{" "}
                <a href="mailto:orders@smart-biology.com">
                  orders@smart-biology.com
                </a>
              </div>
            </div>
          </>
        );
      });
      setSideContentEnterprise(() => {
        return (
          <>
            <div align="center">
              <h3>
                Let Us Help You Set Your <br />
                Organization Apart
                <hr />
              </h3>
            </div>
            <div>
              <div>
                <p>
                  Set your organization apart by providing a biology learning
                  experience that will engage and amaze at scale. Smart Biology
                  content solutions offer flexible and affordable solutions that
                  are tailored by organization size and purchasing preference
                  for K-12 school districts, libraries, academic departments,
                  business or industry.
                </p>
              </div>
              <div>
                <p>
                  Our customized solutions allow you to bring the amazing world
                  of Smart Biology to anyone in your organization for training,
                  professional development, career study and more.
                </p>
              </div>
              <hr />
              <div align="center">
                <p>
                  <h5>
                    <strong>
                      Incredibly detailed, high-quality, narrated lesson
                      animations
                    </strong>
                  </h5>
                  <img
                    src="https://storage.googleapis.com/sb-general/contactPage/enterprise/animations.gif"
                    width="400px"
                  />
                </p>
              </div>
              <div align="center">
                <p>
                  <h5>
                    <strong>Interactive Structures</strong>
                  </h5>
                  <img
                    src="https://storage.googleapis.com/sb-general/contactPage/enterprise/structures.gif"
                    width="400px"
                  />
                </p>
              </div>
              <div align="center">
                <p>
                  <h5>
                    <strong>Just-in-Time quizzing</strong>
                    <br />
                  </h5>
                  <img
                    src="https://storage.googleapis.com/sb-general/contactPage/enterprise/justInTimeQuiz.png"
                    width="400px"
                  />
                </p>
              </div>
              <div align="right">
                <p>
                  <strong>…and so much more!</strong>
                </p>
              </div>
            </div>
            <br />
          </>
        );
      });
    }

    if (language == "FR") {
      setContactUsTitleLabel("Contact Us!");
      setContactFormSubTextLabel(
        "Veuillez noter que nous ne fournissons pas de réponses aux questions liées au contenu. Nous serons cependant heureux de vous aider pour tout problème technique."
      );
      setContactFormNameLabel("Premier nom de famille");
      setContactFormEmailLabel("e-mail");
      setContactFormMessageLabel("Message");
      setContactFormRequestTrialLabel("Demander un essai en classe");
      setContactFormRequestSubmitLabel("Soumettre");
      setContactFormResetLabel("Réinitialiser");
      setEmailSentMsg("L'email a été envoyé");
      setMandatoryFieldMsg(
        "Veuillez vous assurer que tous les champs obligatoires sont remplis"
      );
      setIntitutionNameLabel("Nom de l'institution");
      setIntContactTitle("Contact de l'établissement et titre du poste");
      setContactNum("Contact Number");
      setEdLevel("Niveau d'éducation Intéressé");
      setCountryLabel("Pays");
      setProvinceLabel("Province / État / Territoire");
      setEdHighschoolLabel("Lycée");
      setEdHigherEdLabel("l'enseignement supérieur");
      setEdFirstYear("Biologie première année");
      serEdUpperYear("Biologie de deuxième année");
      setshareEmailLabel("Nous ne partagerons jamais votre adresse e-mail");
      setIndustryTrainingLabel("Industry Training");

      setSideContentGenPub(() => {
        return (
          <>
            <Col align="left">
              <Container>
                <h3>Instructeur</h3>
                <div>
                  Êtes-vous un enseignant d'un collège, d'une université ou d'un
                  lycée intéressé par l'utilisation de manuels animés pour votre
                  cours ? Si oui, veuillez cliquer sur "Demander un essai de
                  classe" dans le formulaire.
                </div>
                <br />
              </Container>
              <Container>
                <div>
                  <h3>Étudiants</h3>
                  Êtes-vous un étudiant de niveau collégial, universitaire ou
                  secondaire avec une question ou un commentaire sur les
                  animations Smart Biology, votre commande ou le site Web ?
                  Veuillez remplir le formulaire de contact et un membre de
                  notre équipe se fera un plaisir de vous répondre.
                </div>
                <br />
              </Container>
              <Container>
                <div>
                  <h3>Industrie</h3>
                  Vous souhaitez utiliser les animations Smart Biology pour
                  votre entreprise pharmaceutique ou biotechnologique ? Veuillez
                  remplir les informations dans le formulaire à droite et un
                  membre de notre équipe se fera un plaisir de vous répondre.
                </div>
                <br />
              </Container>
              <Container>
                <div>
                  <strong>Renseignements généraux:</strong>{" "}
                  <a href="mailto:info@smart-biology.com">
                    info@smart-biology.com
                  </a>
                </div>
                <div>
                  <strong>Demandes de commandes :</strong>{" "}
                  <a href="mailto:orders@smart-biology.com">
                    orders@smart-biology.com
                  </a>
                </div>
              </Container>
            </Col>
          </>
        );
      });

      setSideContentEnterprise(() => {
        return (
          <>
            <div align="center">
              <h3>
                Laissez-nous vous aider à définir votre
                <br />
                Organisation à part
                <hr />
              </h3>
            </div>
            <div>
              <div>
                <p>
                  Démarquez votre organisation en offrant une expérience
                  d'apprentissage de la biologie qui engagera et étonnera à
                  grande échelle. Les solutions de contenu d'entreprise Smart
                  Biology offrent des solutions flexibles et abordables adaptées
                  à la taille de l'organisation et aux préférences d'achat pour
                  les districts scolaires de la maternelle à la 12e année, les
                  bibliothèques, les départements universitaires et les
                  organisations à but non lucratif.
                </p>
              </div>
              <div>
                <p>
                  Nos solutions d'entreprise personnalisées vous permettent
                  d'offrir le monde incroyable de la biologie intelligente à
                  tous les membres de votre organisation pour la formation, le
                  développement professionnel, l'étude de carrière et plus
                  encore.
                </p>
              </div>
              <hr />
              <div align="center">
                <p>
                  <h5>
                    <strong>
                      Des animations de cours incroyablement détaillées et de
                      haute qualité
                    </strong>
                  </h5>
                  <img
                    src="https://storage.googleapis.com/sb-general/contactPage/enterprise/animations.gif"
                    width="400px"
                  />
                </p>
              </div>
              <div align="center">
                <p>
                  <h5>
                    <strong>Structures interactives</strong>
                  </h5>
                  <img
                    src="https://storage.googleapis.com/sb-general/contactPage/enterprise/structures.gif"
                    width="400px"
                  />
                </p>
              </div>
              <div align="center">
                <p>
                  <h5>
                    <strong>Quiz juste à temps</strong>
                    <br />
                  </h5>
                  <img
                    src="https://storage.googleapis.com/sb-general/contactPage/enterprise/justInTimeQuiz.png"
                    width="400px"
                  />
                </p>
              </div>
              <div align="right">
                <p>
                  <strong>…et bien plus!</strong>
                </p>
              </div>
            </div>
            <br />
          </>
        );
      });
    }
  }, []);

  const contactFormType = type || "";

  const sendContactEmail = async () => {
    if (contactFormType == "enterprise") {
      if (
        email != "" &&
        name != "" &&
        message != "" &&
        institutionName != "" &&
        institutionContact != "" &&
        contactNumber != "" &&
        educationLevel != "" &&
        country != "" &&
        province != ""
      ) {
        var enterpriseDetails = {
          institutionName,
          institutionContact,
          contactNumber,
          educationLevel,
          country,
          province,
        };

        var emailSendResults = await sendContactUsEmail(
          name,
          email,
          message,
          trialRequest,
          enterpriseDetails
        );

        if (emailSendResults.Message == "SUCCESS") {
          setFormValidationMsg(() => {
            return (
              <>
                <Alert key="success" variant="success">
                  {emailSentMsg}
                </Alert>
              </>
            );
          });
        }
      } else {
        setFormValidationMsg(() => {
          return (
            <>
              <Alert key="danger" variant="danger">
                {mandatoryFieldsMsg}
              </Alert>
            </>
          );
        });
      }
    }

    if (contactFormType != "enterprise") {
      if (email != "" && name != "" && message != "") {
        var emailSendResults = await sendContactUsEmail(
          name,
          email,
          message,
          trialRequest
        );

        if (emailSendResults.Message == "SUCCESS") {
          setFormValidationMsg(() => {
            return (
              <>
                <Alert key="success" variant="success">
                  {emailSentMsg}
                </Alert>
              </>
            );
          });
        }
      } else {
        setFormValidationMsg(() => {
          return (
            <>
              <Alert key="danger" variant="danger">
                {mandatoryFieldsMsg}
              </Alert>
            </>
          );
        });
      }
    }
  };

  return (
    <div style={{ height: "100vh!important" }}>
      <Container
        style={{
          padding: "40px",
          "background-color": "#f8f9fa",
          height: "100vh!important",
          width: "100%",
        }}
      >
        <Row>
          {type === "enterprise" ? (
            <>
              <h2 style={{ textAlign: "center", padding: "4%" }}>
                Let Us Help You Set Your Organization Apart
              </h2>
            </>
          ) : (
            <>
              <h2 style={{ textAlign: "center", padding: "4%" }}>Contact Us</h2>
            </>
          )}
        </Row>
        <Row>
          <Col style={{ align: "left" }}></Col>
        </Row>
        <Form style={{ align: "left" }}>
          <Form.Group className="mb-3" controlId="contactForm">
            <Form.Control
              onChange={(event) => setName(event.target.value)}
              type="text"
              autoComplete="text"
              placeholder={contactFormNameLabel}
            />{" "}
            <br />
            {contactFormType == "enterprise" && (
              <>
                <Form.Control
                  onChange={(event) => setInstitutionName(event.target.value)}
                  type="text"
                  autoComplete="text"
                  placeholder={institutionNameLabel}
                />{" "}
                <br />
                <Form.Control
                  onChange={(event) =>
                    setInstitutionContact(event.target.value)
                  }
                  type="text"
                  autoComplete="text"
                  placeholder={InstContactTital}
                />{" "}
                <br />
                <Form.Control
                  onChange={(event) => setContactNumber(event.target.value)}
                  type="text"
                  autoComplete="text"
                  placeholder={contactNum}
                />{" "}
                <br />
                <Form.Select
                  style={{ "font-weight": "400" }}
                  aria-label="Default select example"
                  onChange={(event) => setEducationLevel(event.target.value)}
                  name="country"
                >
                  <option
                    style={{
                      "font-weight": "400",
                      color: "#212529",
                      "font-size": "1rem",
                    }}
                    value="0"
                  >
                    {edLevel}*
                  </option>
                  <option value="HS">{edHighschoolLabel}</option>
                  <option value="HEHS">{edHigherEdLabel}</option>
                  <option value="FYB">{edFirstYear}</option>
                  <option value="UYB">{edUpperYear}</option>
                  <option value="IT">{edIndustryTrainingLabel}</option>
                </Form.Select>
                <br />
                <Form.Control
                  onChange={(event) => setCountry(event.target.value)}
                  type="text"
                  autoComplete="text"
                  placeholder={countryLabel}
                />{" "}
                <br />
                <Form.Control
                  onChange={(event) => setProvince(event.target.value)}
                  type="text"
                  autoComplete="text"
                  placeholder={provinceLabel}
                />{" "}
                <br />
              </>
            )}
            <Form.Control
              onChange={(event) => setemail(event.target.value)}
              type="email"
              autoComplete="email"
              placeholder={contactFormEmailLabel}
            />
            {contactFormType != "enterprise" && (
              <div
                align="left"
                style={{ padding: "0px 0px 0px 10px", color: "grey" }}
              >
                <small>{shareEmailLabel}</small>
              </div>
            )}
            <br />
            <Form.Control
              onChange={(event) => setMessage(event.target.value)}
              as="textarea"
              rows={5}
              placeholder={contactFormMessageLabel}
            />
          </Form.Group>
          <div className="d-grid gap-2">
            <div align="left" color="red">
              {formValidationMsg}
            </div>
            <Button onClick={() => sendContactEmail()}>
              {contactFormRequestSubmitLabel}
            </Button>
            <Button variant="secondary" type="reset">
              {contactFormResetLabel}
            </Button>
          </div>
        </Form>
        <br />
      </Container>
    </div>
  );
}
