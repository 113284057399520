// import {encryptPassword} from '../encryptPassword';
import bcrypt from '../../../node_modules/bcryptjs';
const saltRounds = 10;

export async function resetPassword(newPassword, id = null, requested=false, existingEmail = null){
  var body = {}
    // console.log("login payloat" + JSON.stringify(newPassword));

    var encryptedPassword = await bcrypt.hash(newPassword, saltRounds, async function(err, passwordHash) {
        // console.log('hash' + passwordHash);
        
    const validateUserEndpoint = process.env.REACT_APP_BFF + "/bff/v1.0/resetPassword";

    if(requested == false){
      body = {
        "newToken" : passwordHash
      }
    }

    if(requested == true){
      body = {
        "newToken" : passwordHash,
        "passwordRequestId": id,
        "passwordRequested": requested,
        "emailAddressEntered": existingEmail
      }
    }

    const options = { 
      method: 'PATCH', 
      headers: new Headers({ 
        'Content-Type': 'application/json',
        'sb-application-name': 'sb-site-ui',
        'sb-application-key': 'jmd-test',
        'Access-Control-Allow-Origin': '*',
        'sessionID' : sessionStorage.getItem("sessionID"),
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
      }), 
      mode: 'cors',
      credentials: 'omit',
      body: JSON.stringify(body)
    };

    const resetPasswordResponse = await fetch(validateUserEndpoint,options)
    .then(function(response){return response.json()});
    return resetPasswordResponse;
    
});
    return encryptedPassword;
}
