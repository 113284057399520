
export async function loginCheck(){

    const getSessionEndpoint = process.env.REACT_APP_BFF + "/getSessionDetails";
const options = { 
  method: 'GET', 
  headers: new Headers({ 
    'Content-Type': 'text/plain',
    'sb-application-name': 'sb-site-ui',
    'sb-application-key': 'jmd-test',
    'Access-Control-Allow-Origin': '*',
    'sessionID' : sessionStorage.getItem("sessionID"),
    'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
  }), 
  mode: 'cors',
  credentials: 'omit'
};

let results = {};

const loggedInDetails = await fetch(getSessionEndpoint,options)
.then(function(response){return response.json()});

// console.log(JSON.stringify(loggedInDetails));

      if(loggedInDetails.payload.validLogin){
          results = {
              validLogin: true,
              userId: "CX-123456-1",
              username: "NewUserName-1"
          }
        }
        
        if(!loggedInDetails.payload.validLogin){
            results = {
                validLogin: false,
                userId: "N/A",
                username: "N/A"
            }
          }

    return results;
}