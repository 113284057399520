import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import QuisPOC from './quizPOC';
import { getQuizGrade, getQuizGradeV2 } from '../../services/getQuizGrade';
import { MdOutlineCancel, MdOutlineCheck, MdKeyboardArrowRight } from "react-icons/md";
import { IconContext } from "react-icons";
import { getQuizAttempts } from '../../services/quiz/quizAttempts';
import Alert from 'react-bootstrap/Alert';
import { getQuizCutoff } from '../../services/getQuizCutoff';

export default function QuizComponent(props) {
  const language = sessionStorage.getItem("lang") || "EN";
  const [quiz, setQuiz] = useState(false);
  const [disabledQuizButton, setDisabledQuizButton] = useState(false);
  const [quizButtonText, setQuizButtonText] = useState(() => {
    if (language == "FR") {
      return "Répondez au questionnaire"
    }
    if (language != "FR") {
      return "Take Quiz"
    }
  });
  const [existingAnswers, setExistingAnswers] = useState(false);
  const [existingAnswersRender, setExistingAnswersRender] = useState(false);
  const [quizAttemptsTaken, setQuizAttemptsTaken] = useState(false);
  const [quizAttempts, setQuizAttempts] = useState(false);
  const [quizAttemptsRemaining, setQuizAttemptsRemaining] = useState(false);
  const [quizCutoffDates, setquizCutoffDates] = useState();
  const updateQuizUI = (grade) => {
    setQuiz(false);
    setQuizButtonText("Quiz Complete: " + grade + "%");
  }
  const closeQuiz = () => {
    setQuiz(false);
  }

  useEffect(async () => {
    if (props.location == "MODULE") {
      const hasGade = await getQuizGrade("MODULE", props.moduleId, props.id);
      var attemptsTaken = await getQuizGradeV2(props.id)
        .then(async (payload) => {
          await setQuizAttemptsTaken(payload.payload?.attemptNum || 0);
          var quizAttempts = await getQuizAttempts(payload.payload.classid);
          await setQuizAttempts(quizAttempts)
          var quizCutOffResults = await getQuizCutoff(props.id, props.moduleId, payload.payload.classid);

          if (quizCutOffResults.openQuiz) {
            setDisabledQuizButton(false);
            setquizCutoffDates("");
          }
          if (!quizCutOffResults.openQuiz) {
            setDisabledQuizButton(true);
            setQuizButtonText(() => {
              return (
                <>
                  <s>{quizButtonText}</s>
                </>
              )
            })
            setquizCutoffDates(() => {

              return (

                <Alert style={{ 'font-size': '11px', padding: "3px" }} key="warning" variant="warning">
                  {/* <Alert.Heading style={{ 'font-size': "14px" }}>You can not take this quiz</Alert.Heading> */}
                  You are outside of the open/close date range set by your instructor.
                  <br />
                  Please check with your instructor for applicable open/close date.
                </Alert>
              )
            }
            );
          }

          var newpayload = {
            "completed": payload.payload?.attemptNum || 0,
            "limit": quizAttempts
          }
          return newpayload
        })
        .then(async (payload) => {
          if (payload.limit == 100) {
            await setQuizAttemptsRemaining(101);

          }
          if (payload.limit != 100) {
            await setQuizAttemptsRemaining(payload.limit - payload.completed);
          }
        })

      if (hasGade.hasQuiz) {
        setQuizButtonText("Quiz Complete: " + hasGade.grade + "%");
        setExistingAnswers(true);
        var existingQuizList = await localStorage.getItem("userProgressTotal");
        existingQuizList = JSON.parse(existingQuizList);
        for (var q = 0; q < existingQuizList.quizProgress.length; q++) {
          if (existingQuizList.quizProgress[q].quizId == props.id) {
            setExistingAnswersRender(() => {
              return (
                <>
                  {existingQuizList.quizProgress[q].questions.map(question => (
                    <>
                      <Card style={{ width: "100%", margin: "10px" }}>
                        <Card.Header>

                          {question.grade == "Incorrect" &&
                            <IconContext.Provider value={{ color: "red", className: "global-class-name", size: "1.5em" }}>
                              <MdOutlineCancel />
                            </IconContext.Provider>
                          }
                          {question.grade == "Correct" &&
                            <IconContext.Provider value={{ color: "green", className: "global-class-name", size: "1.5em" }}>
                              <MdOutlineCheck />
                            </IconContext.Provider>
                          }
                          {question.questionText_en}
                        </Card.Header>
                        <Card.Body>
                          <Card.Text>
                            <strong>Answer Selected: </strong>{question.answerSubmitted}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </>
                  ))}
                </>
              )
            })
          }
        }
      }
    }

  }, [])

  return (
    <>
      {/* <table>

          <tr>
            <td> */}
           
            {/* </td>
          <td style={{'vertical-align': "top", align:"right", padding:"5px"}} align="right"> */}
           <Button variant="outline-primary" onClick={() => { setQuiz(true) }} disabled={disabledQuizButton}>{quizButtonText}</Button><br />
           {quizCutoffDates}
          {/* </td>
        </tr>
      </table> */}

      <Modal
        show={quiz}
        size="lg"
        onHide={() => setQuiz(false)}
        dialogClassName="modal-90w"
        scrollable="true"
        backdrop="static"
        style={{ "max-height": "600px" }}
        role="dialog"
        aria-modal="true"
        aria-label="quiz model"

      >
        <Modal.Body aria-labelledby="quizHeader">
          <h1 id="quizHeader">Quiz</h1>
          <Card>
            {existingAnswers == true &&
              <>
                <Card.Header as="h5">{quizButtonText} - Previous Answers</Card.Header>
              </>
            }
            <Card.Body>
              <Card.Text>
                {existingAnswers == true &&
                  <>
                    {existingAnswersRender}
                    {/* <Button variant="outline-primary" onClick={() => { setExistingAnswers(false) }}>Retake</Button>{' '}
                    <Button variant="outline-primary" onClick={() => { setQuiz(false) }}>Cancel</Button><br /> */}
                    <div align="left" style={{ padding: "0px 0px 0px 0px", 'color': "grey" }}><small>Retaking the quiz will erase your previous grade</small></div>
                    <div>
                      {quizAttemptsRemaining <= 0 &&
                        <>
                          <Button variant="outline-primary" onClick={() => { setExistingAnswers(false) }} disabled>Retake</Button>{' '}
                          <Button variant="outline-primary" onClick={() => { setQuiz(false) }}>Cancel</Button><br /><br />
                          <Alert variant="danger">
                            You are unable to take this quiz as you have used up all of your attempts
                          </Alert>
                        </>
                      }
                      {quizAttemptsRemaining > 0 && quizAttemptsRemaining <= 100 &&
                        <>
                          <Button variant="outline-primary" onClick={() => { setExistingAnswers(false) }}>Retake</Button>{' '}
                          <Button variant="outline-primary" onClick={() => { setQuiz(false) }}>Cancel</Button><br /><br />
                          <Alert variant="secondary">
                            You have {quizAttemptsRemaining} attempt(s) left
                          </Alert>
                        </>
                      }

                      {quizAttemptsRemaining == 101 &&
                        <>
                          <Button variant="outline-primary" onClick={() => { setExistingAnswers(false) }}>Retake</Button>{' '}
                          <Button variant="outline-primary" onClick={() => { setQuiz(false) }}>Cancel</Button><br />
                        </>
                      }
                    </div>
                  </>
                }
                {existingAnswers == false &&
                  <>
                    <QuisPOC closeQuiz={closeQuiz} updateQuizUI={updateQuizUI} moduleId={props.moduleId} lessonId={props.lessonId} quizId={props.id} unitId={sessionStorage.getItem("activeUnit")} setNav={props?.setNav || null} />
                  </>
                }
              </Card.Text>
            </Card.Body>
          </Card><br />
        </Modal.Body>
      </Modal>
    </>
  )
}