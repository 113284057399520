export async function submitUserUpdateAdmin(payload) {
    let updateResults = {};
    
    try {
      const options = {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'sb-application-name': 'sb-site-ui',
          'sb-application-key': 'jmd-test',
          'sessionID': sessionStorage.getItem("sessionID"),
        }),
        mode: 'cors',
        credentials: 'omit',
        body: JSON.stringify(payload),
      };
      const endpoint = process.env.REACT_APP_BFF + '/bff/v1.0/updateUserAdmin/';
      
      updateResults = "Worked";
      const response = await fetch(endpoint, options);
      if (response.ok) {
        const updateUserResults = await response.json();
        updateResults = updateUserResults;
      } else {
        throw new Error(`Failed to update user. Status: ${response.status}`);
      }
    } catch (error) {
      updateResults = {
        error: true,
        errorMsg: error.message || error,
      };
    } finally {
      return updateResults;
    }
  }
  