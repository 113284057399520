


export async function getShoppingCartDetails(scId){

    const getShoppingCartEndPoint = process.env.REACT_APP_BFF + '/getShoppingCart/' + scId;
    const requestOptions = { 
        method: 'GET', 
        headers: new Headers({ 
              'Content-Type': 'application/json',
              'sb-application-name': 'sb-site-ui',
              'sb-application-key': 'test123',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
            }), 
        mode: 'cors',
        credentials: 'omit'
        };
        const scResponse = await fetch(getShoppingCartEndPoint,requestOptions);
        const scData = await scResponse.json();

        // console.log('SC dadta' + JSON.stringify(scData));

        // {
        //     "shoppingCartID": "SC-34315118",
        //     "cartItems": [
        //       {
        //         "quantity": 1,
        //         "productName": "LIFE | From Atoms to Cells",
        //         "productId": "619a9748fe41e87cb0b6a4b9",
        //         "productPrice": 59.99,
        //         "productCurrency": "USD"
        //       }
        //     ],
        //     "cartTotal": {
        //       "cartSubtotal": 59.99,
        //       "cartTaxValue": 7.8,
        //       "taxBreakdown": {
        //         "gst": 0,
        //         "pst": 0,
        //         "hst": 0.13,
        //         "qst": 0
        //       },
        //       "cartTotalTaxPercent": 0.13,
        //       "cartTotal": 67.79,
        //       "cartCurrency": "USD"
        //     }
        //   }

        var cartDiscountedvalue = 0;
        var cartTotalTaxPercent = 0;
        var cartTaxValue = 0;
        var cartTotal = 0;


        try{
            if(scData.cartTotal.cartDiscountedvalue){
                cartDiscountedvalue = scData.cartTotal.cartDiscountedvalue;
            }
            if(scData.cartTotal.cartTotalTaxPercent){
                cartTotalTaxPercent = scData.cartTotal.cartTotalTaxPercent;
            }
            // if(scData.cartTotal.cartTotalTaxPercent){
            //     cartTotalTaxPercent = scData.cartTotal.cartTotalTaxPercent;
            // }
            if(scData.cartTotal.cartTaxValue){
                cartTaxValue = scData.cartTotal.cartTaxValue;
            }
            if(scData.cartTotal.cartTaxValue){
                cartTotal = scData.cartTotal.cartTotal;
            }
        
        }catch(err){}


        const cartPayload = {
            "productName" : scData.cartItems[0].productName,
            "productPrice" : scData.cartItems[0].productPrice,
            "cartSubtotal" : scData.cartItems[0].productPrice,
            "productCurrency" : scData.cartItems[0].productCurrency,
            "cartTotalDiscountValue" : cartDiscountedvalue,
            "cartTotalTaxPercent" : cartTotalTaxPercent,
            "cartTaxValue" : cartTaxValue.toFixed(2),
            "cartTotal" : cartTotal,
        }

        // console.log('cartPayload' + JSON.stringify(cartPayload));

        return cartPayload;
      
        }