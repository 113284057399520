
const options = {
  method: 'GET',
  headers: new Headers({
    'Content-Type': 'application/json',
    'sb-application-name': 'sb-site-ui',
    'sb-application-key': 'jmd-test',
    'Access-Control-Allow-Origin': '*',
    'sessionID': sessionStorage.getItem("sessionID"),
    'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
  }),
  mode: 'cors',
  credentials: 'omit'
  // body: JSON.stringify(testPayload)
};


export async function duplicateUserCheckByEmail(email) {

  const endpoint = process.env.REACT_APP_BFF + '/getUserByEmail/' + email;
  var result = true;

  let duplciateUserCheckResponse = await fetch(endpoint, options)
    .then(response => {
      return response.json();
    })
    .catch(function (err) {
      console.log(err);
    });

  // console.log('duplciateUserCheckResponse -->' + JSON.stringify(duplciateUserCheckResponse));

  if (duplciateUserCheckResponse.code != null || duplciateUserCheckResponse.code != undefined) {
    if (duplciateUserCheckResponse.code == "2") {
      result = false;
    }
  }

  return result;

}

export async function getUsersByClass(classId) {

  const endpoint = process.env.REACT_APP_BFF + '/bff/v1.0/getUsersByClass/' + classId;

  let userList = await fetch(endpoint, options)
    .then(response => {
      return response.json();
    })
    .catch(function (err) {
      console.log(err);
    });

  const splitName = async (name) => {
    var firstName = name.substring(
      name.indexOf(",") + 1,
      name.lastIndexOf("")
    );
    var lastName = name.substring(
      name.indexOf(""),
      name.lastIndexOf(",")
    );

    return { "fn": firstName, "ln": lastName }
  }

  for (var s = 0; s < userList.length; s++) {
    var splitNameR = await splitName(userList[s].Name);
    userList[s].fName = splitNameR.fn;
    userList[s].lName = splitNameR.ln;

  }

  let sortedUserList = sortPayload(userList);

  return sortedUserList;

}


export async function getUserByEmail(email) {

  const endpoint = process.env.REACT_APP_BFF + '/getUserByEmail/' + email;

  if (email != "NEW" && email != "N/A") {
    let UserResponse = await fetch(endpoint, options)
      .then(response => {
        return response.json();
      })
      .catch(function (err) {
        console.log(err);
      });

    return UserResponse;
  }
}

export async function getUserProgressSummary() {
  var progressReponse = {};
  const endpoint = process.env.REACT_APP_BFF + '/fullUserProgress/';

  let userResponse = await fetch(endpoint, options)
    .then(response => {
      return response.json();
    })
    .catch(function (err) {
      console.log(err);
    });

  if (userResponse.studentProgress.result == "NOT FOUND") {
    progressReponse = {
      "percentCompleted": "0",
      "lessonsCompleted": "0",
      "totalLessons": "16",
      "unit": {
        "id": 0,
        "status": "n/a"
      }
    }
  }


  if (userResponse.studentProgress.result != "NOT FOUND") {


    for (let t = 0; t < userResponse.studentProgress.payload[0].textbook.length; t++) {
      if (userResponse.studentProgress.payload[0].textbook.length === 0) { break; }
      for (let u = 0; u < userResponse.studentProgress.payload[0].textbook[t].unit.length; u++) {
        var lessonCompleted = 0;
        var lessonsInprogress = 0;
        var lessonsNotStarted = 0;
        var lessonTotal = 0;
        for (let c = 0; c < userResponse.studentProgress.payload[0].textbook[t].unit[u].chapter.length; c++) {
          for (let m = 0; m < userResponse.studentProgress.payload[0].textbook[t].unit[u].chapter[c].module.length; m++) {
            lessonTotal = lessonTotal + userResponse.studentProgress.payload[0].textbook[t].unit[u].chapter[c].module[m].lesson.length;
            for (let l = 0; l < userResponse.studentProgress.payload[0].textbook[t].unit[u].chapter[c].module[m].lesson.length; l++) {
              if (userResponse.studentProgress.payload[0].textbook[t].unit[u].chapter[c].module[m].lesson[l].status == "IN-PROGRESS") {
                lessonsInprogress = lessonsInprogress + 1;
              }
              if (userResponse.studentProgress.payload[0].textbook[t].unit[u].chapter[c].module[m].lesson[l].status == "Not Started") {
                lessonsNotStarted = lessonsNotStarted + 1;
              }
              if (userResponse.studentProgress.payload[0].textbook[t].unit[u].chapter[c].module[m].lesson[l].status == "COMPLETE") {
                lessonCompleted = lessonCompleted + 1;
              }
            }
          }
        }

        userResponse.studentProgress.payload[0].textbook[t].unit[u].lessonsCompleted = lessonCompleted
        userResponse.studentProgress.payload[0].textbook[t].unit[u].lessonsInprogress = lessonsInprogress
        userResponse.studentProgress.payload[0].textbook[t].unit[u].lessonsNotStarted = lessonsNotStarted
        userResponse.studentProgress.payload[0].textbook[t].unit[u].lessonsTotal = lessonCompleted + lessonsInprogress + lessonsNotStarted
        userResponse.studentProgress.payload[0].textbook[t].unit[u].percentCompleted = ((100 * userResponse.studentProgress.payload[0].textbook[t].unit[u].lessonsCompleted) / userResponse.studentProgress.payload[0].textbook[t].unit[u].lessonsTotal).toFixed(2)

      }

    }

    lessonTotal = lessonCompleted + lessonsInprogress + lessonsNotStarted;


    progressReponse = {
      "percentCompleted": ((100 * lessonCompleted) / lessonTotal).toFixed(2),
      "lessonsCompleted": lessonCompleted,
      "totalLessons": lessonTotal,
      "unit": {
        "id": userResponse.studentProgress.payload[0].textbook[0].unit[0].id,
        "status": userResponse.studentProgress.payload[0].textbook[0].unit[0].status,
        "grade": userResponse.studentProgress.payload[0].textbook[0].unit[0].grade
      }
    }
  }

  localStorage.setItem('userProgressTotal', JSON.stringify(userResponse));
  localStorage.setItem('userProgressSummary', JSON.stringify(progressReponse));
  return progressReponse;

}

export async function getUserProgressByItemId(type, id) {
  var progressReponse = {};
  var userResponse = localStorage.getItem('userProgressTotal');

  userResponse = JSON.parse(userResponse);

  if (userResponse == undefined || userResponse == null) {
    await getUserProgressSummary();

  }

  var returnValue = "NOT STARTED";

  if (userResponse.studentProgress.result != "NOT FOUND") {

    for (let t = 0; t < userResponse.studentProgress.payload[0].textbook.length; t++) {
      if (userResponse.studentProgress.payload[0].textbook.length === 0) { break; }

      for (let u = 0; u < userResponse.studentProgress.payload[0].textbook[t].unit.length; u++) {
        if (type == "UNIT") {
          returnValue = userResponse.studentProgress.payload[0].textbook[t].unit[u].status;
          break;
        }
        for (let c = 0; c < userResponse.studentProgress.payload[0].textbook[t].unit[u].chapter.length; c++) {
          if (type == "CHAPTER") {
            returnValue = userResponse.studentProgress.payload[0].textbook[t].unit[u].chapter[c].status;
            break;
          }
          for (let m = 0; m < userResponse.studentProgress.payload[0].textbook[t].unit[u].chapter[c].module.length; m++) {
            if (type == "MODULE") {
              if (id == userResponse.studentProgress.payload[0].textbook[t].unit[u].chapter[c].module[m].id) {
                returnValue = userResponse.studentProgress.payload[0].textbook[t].unit[u].chapter[c].module[m].status;
                break;
              }
            }
            for (let l = 0; l < userResponse.studentProgress.payload[0].textbook[t].unit[u].chapter[c].module[m].lesson.length; l++) {
              if (type == "LESSON") {
                if (id == userResponse.studentProgress.payload[0].textbook[t].unit[u].chapter[c].module[m].lesson[l].id) {
                  returnValue = userResponse.studentProgress.payload[0].textbook[t].unit[u].chapter[c].module[m].lesson[l].status;
                  break;
                }
              }
            }
          }
        }
      }

    }

  }

  if (type == "QUIZ") {
    for (let q = 0; q < userResponse.quizProgress.length; q++) {
      if (userResponse.quizProgress != null || userResponse.quizProgress != null) {
        if (id == userResponse.quizProgress[q].moduleId) {
          returnValue = userResponse.quizProgress[q].gradePercentage;
        } else {
          returnValue = 0;
        }
      } else {
        returnValue = 0;
      }
    }
  }

  localStorage.setItem('userProgressTotal', JSON.stringify(userResponse));
  localStorage.setItem('userProgressSummary', JSON.stringify(progressReponse));

  return returnValue;

}

function sortPayload(payload) {
  payload.sort((a, b) => {
    if (a.fName === b.fName) {
      return a.lName.localeCompare(b.lName);
    }
    return a.fName.localeCompare(b.fName);
  });
  payload.forEach(user => {
    if (user.grades) { // Check if grades is defined
      user.grades.sort((a, b) => {
        if (!a || !b) return 0; // handle null or undefined values
        if (a.unitName < b.unitName) return -1;
        if (a.unitName > b.unitName) return 1;
        return 0;
      });
      user.grades.forEach(grade => {
        if (grade.chapters) { // Check if chapters is defined
          grade.chapters.sort((a, b) => {
            if (!a || !b) return 0; // handle null or undefined values
            if (a.chapterName < b.chapterName) return -1;
            if (a.chapterName > b.chapterName) return 1;
            return 0;
          });

          grade.chapters.forEach(chapter => {
            if (chapter.modules) { // Check if modules is defined
              chapter.modules.sort((a, b) => {
                if (!a || !b) return 0; // handle null or undefined values
                if (a.moduleName < b.moduleName) return -1;
                if (a.moduleName > b.moduleName) return 1;
                return 0;
              });
            }
          });
        }
      });
    }

    return user;
  })

  return payload;
}
