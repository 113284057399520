import React, { useState, useEffect } from "react";
import Nav from 'react-bootstrap/Nav';
// import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';
// import Text from 'react-bootstrap/Text';
import "../App.css";

const getLesson = process.env.REACT_APP_BFF + "/getLessonByID/6178898723d456322035bba8";
// const getProductsCount = process.env.REACT_APP_BFF + "/getNumOfProducts";
const options = { 
  method: 'GET', 
  headers: new Headers({ 
    'Content-Type': 'text/plain',
    'sb-application-name': 'sb-site-ui',
    'sb-application-key': 'test123',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
  }), 
  mode: 'cors',
  credentials: 'omit'
};
let numRow = 0;
let NumProduct = 0;
let content = '';

function ProductList() {
  const [data,setData]=useState([]);
  const getData=()=>{
    fetch(getLesson,options)
      .then(function(response){
        return response.json();
      })
      .then(function(myJson) {
        // console.log(myJson);
        setData(myJson)
      });
  }
  useEffect(()=>{
    getData()
  },[])

  
  return (
    <div className="ProductList">
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Buy</Breadcrumb.Item>
        </Breadcrumb>
        <Container>
        <h2>{data.title_en}</h2>
          <Row>
            <Col>
              
              <div className="display-linebreak">{data.lessonText_en}</div>
            </Col>
            <Col>
              Lesson Video
            </Col>
          </Row>
        </Container>
        
    </div>
  );
}

export default ProductList;

          {/* {
            data && data.length>0 && data.map((item)=><Nav.Link href={item.linkURL}>{item.linkName}</Nav.Link>)
          } */}