import * as jose from 'jose'

export async function encryptPaymentDetails(payload) {

    const jwt = new jose.UnsecuredJWT(payload)
        .encode()

    // console.log("encryptPaymentDetails -->  " + jwt);

    return jwt

}

export async function encryptTransactionDetails(payload) {

    const unsecuredJwt = new jose.UnsecuredJWT(payload)
        .encode()

    // console.log("encryptTransactionDetails -->  " + unsecuredJwt);

    return unsecuredJwt

}

export async function encryptFullDetails(ccDetails, transactionDetails) {

    const ccDetailsEnc = await encryptPaymentDetails(ccDetails);
    const transactionDetailsEnc = await encryptTransactionDetails(transactionDetails);

    var payload = {
        "ccDetails": ccDetailsEnc,
        "transactionDetails": transactionDetailsEnc
    }

    const paymentDetailsEnc = new jose.UnsecuredJWT(payload)
        .encode()

    // console.log("encryptFullDetails -->  " + paymentDetailsEnc);

    const returnDetails = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9" + paymentDetailsEnc + "C-dl7yjBWHIoalRxgouRoRC-W8Vpc_j_q_hOmrr-gYs"

    return returnDetails;

}