import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton,
  BigPlayButton,
} from "video-react";
import ChapterList from "../textbook/chapterList";
import Gallery from "react-grid-gallery";
import { updatePageTitle } from "../../utls/lib";
import UNIT_1_IMAGE from "../../assets/images/classroom/SB-BIOLOGY-HIGH-SCHOOL-AP-U1.jpg";
import UNIT_2_IMAGE from "../../assets/images/classroom/SB-BIOLOGY-HIGH-SCHOOL-AP-U2.jpg";
import UNIT_3_IMAGE from "../../assets/images/classroom/SB-BIOLOGY-HIGH-SCHOOL-AP-U3.jpg";
import UNIT_4_IMAGE from "../../assets/images/classroom/SB-BIOLOGY-HIGH-SCHOOL-AP-U4.jpg";
import UNIT_5_IMAGE from "../../assets/images/classroom/SB-BIOLOGY-HIGH-SCHOOL-AP-U5.jpg";

function HighSchoolComponent() {
  const [currentPage, setCurrentPage] = useState(0);

  const UnitCard = ({
    index,
    backgroundColor,
    title,
    id,
    description,
    imageURL,
    videoPosterURL,
    videoURL,
    chapterList,
  }) => {
    return (
      <div
        id={id}
        style={{
          "margin-top": "15px",
          "padding-top": "10px",
          "padding-bottom": "10px",
        }}
      >
        <Container>
          <div className="module-container">
            <div>
              <h3 style={{ "text-align": "left" }}>{title}</h3>

              <p style={{ "font-size": "19px", "text-align": "justify" }}>
                {description}
              </p>
              <br />
            </div>
            <Player playsInline src={videoURL} poster={videoPosterURL}>
              <BigPlayButton position="center" />
              <ControlBar>
                <ReplayControl seconds={10} order={1.1} />
                <ForwardControl seconds={30} order={1.2} />
                <CurrentTimeDisplay order={4.1} />
                <TimeDivider order={4.2} />
                <PlaybackRateMenuButton
                  rates={[5, 2, 1, 0.5, 0.1]}
                  order={7.1}
                />
                <VolumeMenuButton />
              </ControlBar>
            </Player>
          </div>
          <Row>
            <Col>
              <div>
                <br />
                <h6 style={{ "text-align": "left", "font-weight": "300" }}>
                  Table of Contents
                </h6>
              </div>
              {chapterList}
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const units = [
    <UnitCard
      index={0}
      backgroundColor="none"
      id="Unit 1"
      title="Unit 1 | From Atoms to Cells"
      description="In Unit 1, students embark on a continuous journey through a cell, allowing them to understand and
appreciate the interconnections between the atomic, molecular, and cellular worlds. Most importantly,
we explain the underlying “why” questions so they can genuinely understand, rather than simply
memorize."
      videoURL="https://smart-biology-molecular-dna-replication.s3-us-west-2.amazonaws.com/Life/Unit-1/Unit-1-Demo-Video-1-Bit-24.mp4?_=1"
      imageURL={UNIT_1_IMAGE}
      videoPosterURL="https://storage.googleapis.com/sb-general/wp-content/uploads/2019/02/Life-U1-C5-TCW-2-MachinesDoWork-8-Golgi.jpg"
      chapterList=<ChapterList
        id="61788ab423d456322035bbb3"
        module="0"
        publicSite={true}
      />
    />,

    <UnitCard
      index={1}
      backgroundColor="#E5E4E2"
      id="Unit 2"
      title="Unit 2 | The Flow of Energy | Photosynthesis and Cellular Respiration"
      description="Macromolecular machines come to life in Unit 2, as we learn about the flow of energy. In fact, the same
electrons and protons we learned about in Unit 1 power the photosynthesis and cellular respiration
machinery here in Unit 2! Accurate visualizations allow students to understand chemical energy in a
whole new way."
      videoURL="https://smart-biology-molecular-dna-replication.s3-us-west-2.amazonaws.com/Life/Unit-2/Life-U2-Demo-Video-1-Bit-24.mp4?_=2"
      imageURL={UNIT_2_IMAGE}
      videoPosterURL="https://storage.googleapis.com/sb-general/wp-content/uploads/2019/02/Life-U2-C8-PMG-2-ChloroSunlightHarvest-3-PhotosystemsGreatMachines.jpg"
      chapterList=<ChapterList
        id="63992d209483af58e1b0c145"
        module="0"
        publicSite={true}
      />
    />,

    <UnitCard
      index={2}
      backgroundColor="none"
      id="Unit 3"
      title="Unit 3 | Genetics | The Cycle of Life"
      description="Unlike any other genetics resource, this unit ties together all of the content from Units 1 and 2 into one
single story. Unit 3 begins as we press the play button on life, and students then embark on one
continuous journey beginning with the structure of DNA and ending with patterns of inheritance."
      imageURL={UNIT_3_IMAGE}
      chapterList=<ChapterList
        id="639b49be9483af58e1b0c23e"
        module="0"
        publicSite={true}
      />
      videoPosterURL="https://storage.googleapis.com/sb-general/wp-content/uploads/2020/06/Life-U3-C10-DIV-3-Mitosis-3-ProphaseChromoCondense.jpg"
      videoURL="https://smart-biology-molecular-dna-replication.s3-us-west-2.amazonaws.com/Life/Unit-3/Life-U3-Demo-Video-1-Bit-24.mp4?_=3"
    />,

    <UnitCard
      index={3}
      backgroundColor="#E5E4E2"
      id="Unit 4"
      title="Unit 4 | Evolution | Putting Life in Perspective"
      description="From homology morphs, to accurate evo/devo animations, to a 4.5 km real-time hike through the 4.5
billion year Earth history, Unit 4 is truly unique. Students can visualize so many amazing processes
including micro/macroevolution, evo/devo, fossils, homology, mutations, and so much more… the Smart
Biology way!"
      imageURL={UNIT_4_IMAGE}
      chapterList=<ChapterList
        id="63a0b633369ecf65fe1e61bc"
        module="0"
        publicSite={true}
      />
      videoPosterURL="https://storage.googleapis.com/sb-general/wp-content/uploads/2020/12/Life-Unit-4-Demo-Video-Poster-Image-1.jpg"
      videoURL="https://smart-biology-molecular-dna-replication.s3-us-west-2.amazonaws.com/Life/Unit-4/Life-Unit-4-Demo-Video-1-Evolution-Smart-Biology-Animations-Bit-28P.mp4?_=4"
    />,

    <UnitCard
      index={4}
      id="Unit 5"
      backgroundColor="none"
      title="Unit 5 | Ecology | The Symphony of Life"
      description="Everything we’ve learned so far has been leading up to this: Ecology, the story of how everything fits
together to create the symphony of life! Ecology takes place at a planetary level, and in Unit 5, we
present this in a way that promotes understanding. By the end, we come full circle with the flow of
energy and cycling of atoms."
      imageURL={UNIT_5_IMAGE}
      chapterList=<ChapterList
        id="63a33f58369ecf65fe1e649f"
        module="0"
        publicSite={true}
      />
      videoPosterURL="https://storage.googleapis.com/sb-general/wp-content/uploads/2020/06/Ecology-Demo-Video-Poster-Image-Fly-1.jpg"
      videoURL="https://smart-biology-molecular-dna-replication.s3-us-west-2.amazonaws.com/Life/Unit-5/Life-Unit-5-Ecology-Demo-Video-Smart-Biology-Animations-2.mp4"
    />,
  ];

  return (
    <div width="100%" style={{}}>
      <div style={{ "margin-top": "15px" }}>
        <Row>
          <Col md={10} sm={10} lg={10} xl={10} xxl={10}>
            <div>
              <br />
              <h2 style={{ fontSize: "42px" }}>
                High School Animated Textbook Series
              </h2>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <hr />
          </Col>
        </Row>

        <Row
          style={{
            padding: "10px",
            margin: "2px",
            borderRadius: "10px",
          }}
        >
          <Col style={{ textAlign: "center", color: "black" }}>
            <div
              className="grid-container-double"
              style={{ justifyContent: "center", alignContent: "center" }}
            >
              <a onClick={() => setCurrentPage(0)}>
                {" "}
                <img
                  className={`product-image${
                    currentPage === 0 ? "-selected" : ""
                  }`}
                  src={UNIT_1_IMAGE}
                ></img>
              </a>
              <a onClick={() => setCurrentPage(1)}>
                <img
                  className={`product-image${
                    currentPage === 1 ? "-selected" : ""
                  }`}
                  src={UNIT_2_IMAGE}
                ></img>
              </a>
              <a onClick={() => setCurrentPage(2)}>
                <img
                  className={`product-image${
                    currentPage === 2 ? "-selected" : ""
                  }`}
                  src={UNIT_3_IMAGE}
                ></img>
              </a>
              <a onClick={() => setCurrentPage(3)}>
                <img
                  className={`product-image${
                    currentPage === 3 ? "-selected" : ""
                  }`}
                  src={UNIT_4_IMAGE}
                ></img>
              </a>
              <a onClick={() => setCurrentPage(4)}>
                {" "}
                <img
                  className={`product-image${
                    currentPage === 4 ? "-selected" : ""
                  }`}
                  src={UNIT_5_IMAGE}
                ></img>
              </a>
            </div>
          </Col>
        </Row>
        <hr></hr>
      </div>

      {units[currentPage]}

      <div
        style={{
          "margin-top": "15px",
          "padding-top": "10px",
          "padding-bottom": "10px",
        }}
      ></div>
    </div>
  );
}

export default HighSchoolComponent;
