export async function getDiscussionForumSettings(classId){

    const endPoint = process.env.REACT_APP_BFF + "/bff/v1.0/getDiscussionForumSettings/" + classId;
    const options = {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            'sb-application-name': 'sb-site-ui',
            'sb-application-key': 'jmd-test',
            'Access-Control-Allow-Origin': '*',
            'sessionID': sessionStorage.getItem("sessionID"),
            'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
        }),
        mode: 'cors',
        credentials: 'omit',
        // body: JSON.stringify(theBody)
        // body: JSON.stringify(theBody)
    };

    try{
       var response =  await fetch(endPoint, options);
       return response.json();
    }catch(err){
        console.log(err);
    }
    
}

export async function updateDiscussionForumSettingsService(payload, updateType, updateSubType){

    //type == what is being updated
    //sub-type == add or remove



    const endPoint = process.env.REACT_APP_BFF + "/bff/v1.0/updateDiscussionForumSettings/";
    const options = {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            'sb-application-name': 'sb-site-ui',
            'sb-application-key': 'jmd-test',
            'Access-Control-Allow-Origin': '*',
            'sessionID': sessionStorage.getItem("sessionID"),
            'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
        }),
        mode: 'cors',
        credentials: 'omit',
        body: JSON.stringify(payload)
        // body: JSON.stringify(theBody)
    };

    try{
        await fetch(endPoint, options);
    }catch(err){
        console.log(err);
    }
    
}