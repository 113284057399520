import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import AllConsumerComponent from './ltiConsumerList';
import { getAllClasses } from '../../../services/getAllClasses';
import { getAllMemberships } from '../../../services/getAllMemberships';
import { createLTIConsumerService } from '../../../services/createLTIConsumer';
import { getAllProduct } from '../../../services/bffServices/getProducts';



function AddLTIConsumerComponent() {

    const [consumerName, setConsumerName] = useState("");
    const [consumerKey, setConsumerKey] = useState("");
    const [consumerSecret, setConsumerSecret] = useState("");
    const [ltiVersion, setLTIVersion] = useState("LTI-1p0");
    const [ltiType, setLTIType] = useState("");
    const [classSelected, setClassSelected] = useState("");
    const [membership, setMembership] = useState("");
    const [product, setProduct] = useState("");
    const [listOfClasses, setListOfClasses] = useState();
    const [listOfMemberships, setListOfMemberships] = useState();
    const [listOfProducts, setListOfProducts] = useState();

    const [validationAlert, setValidationAlert] = useState("");
    const [validationAlertCreate, setValidationAlertCreate] = useState("");
    const [disabledButtons, setDisableButtons] = useState(false);
    const [loading, setLoading] = useState("");
    const [downloadFile, setDownloadFile] = useState();
    const [displayArea, setDisplayArea] = useState();



    //Result Details
    const [connectionDetails, setConnectionDetails] = useState([{}])


    const displatConnectionDetails = (consumerDetails) => {
        return (
            <div style={{
                height: '300px',  // You can set this to any desired height
                overflowY: 'auto',
                border: '1px solid #ccc', // Optional: for visual separation
                padding: '10px', // Optional: for some space inside the box
                textAlign: "left"
            }}>
                <h4>Connection Details</h4>
                {consumerDetails.map((item, index) => (
                    <div key={index} style={{ marginBottom: '20px' }}>
                        <div><strong>Connection URL:</strong> {item.connectionURL}</div>
                        <div><strong>Key:</strong> {item.key}</div>
                        <div><strong>Security:</strong> {item.security}</div>
                        <div>
                            <strong>Custom Parameters:</strong>
                            <div style={{ marginLeft: '20px' }}>classKey={item.customParameters?.classKey || ""}</div>
                            <div style={{ marginLeft: '20px' }}>level={item.customParameters?.level || ""}</div>
                            <div style={{ marginLeft: '20px' }}>contentId={item.customParameters?.contentId || ""}</div>
                        </div>
                        <div><strong>Description (<span style={{ color: "red" }}>not added to LMS</span>)</strong> {item.description}</div>

                    </div>
                ))}
            </div>
        );
    }




    //tempDelete
    const [tempDisplay, setTempDisplay] = useState({});

    //ConfirmationOverlay
    const [showOverlay, setShowOverlay] = useState(false);
    const [showConfirmOverlay, setShowConfirmOverlay] = useState(false);

    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    const createLTIConsumer = async () => {
        var results = {};
        setDisableButtons(true);
        setLoading(() => {
            return (
                <>
                    <Spinner animation="border" size="sm" />
                </>
            )
        })

        const ltiObject = {
            consumerName: consumerName,
            consumerKey: consumerKey,
            consumerSecret: consumerSecret,
            ltiVersion: ltiVersion,
            ltiType: ltiType,
            classSelected: classSelected,
            membership: membership,
            sessionID: sessionStorage.getItem("sessionID"),
            productId: product
        };

        try {
            results = await createLTIConsumerService(ltiObject);
            await setConnectionDetails(results);

            if (results.success) {
                var formattedData = await createFormattedText(results.consumerDetails);
                var processedData = formattedData.replace(/\n/g, '<br />')
                setDisplayArea(() => {
                    return (
                        <>
                            <h5>Connection Details</h5>
                            <hr style={{ margin: "auto" }} width="100%" /><br />
                            <div style={{
                                height: '300px',  // You can set this to any desired height
                                overflowY: 'auto',
                                border: '1px solid #ccc', // Optional: for visual separation
                                padding: '10px', // Optional: for some space inside the box
                                textAlign: "left"
                            }}>


                                <div dangerouslySetInnerHTML={{ __html: processedData }} />

                            </div>
                        </>
                    );
                });
                setShowConfirmOverlay(false)
                setDisableButtons(false)
                let result = '';

                setValidationAlertCreate(() => {
                    return (<>
                        <Alert key="success" variant="success">
                            LTI Consumer Created <br></br><Button onClick={() => downloadFormattedTextFile(results.consumerDetails)}>Download Connection Details</Button>
                        </Alert>
                    </>)
                })

            }
        } catch (err) {
            setValidationAlertCreate(() => {
                return (<>
                    <Alert key="danger" variant="danger">
                        An error has occured - Please Retry
                    </Alert>
                </>)
            })
            setShowConfirmOverlay(false)
            setDisableButtons(false)
            setLoading("");
        }


        if (!results.success) {
            setValidationAlertCreate(() => {
                return (<>
                    <Alert key="danger" variant="danger">
                        {results.errorMsg} + Please Retry
                    </Alert>
                </>)
            })
            setShowConfirmOverlay(false)
            setDisableButtons(false)
            setLoading("");

        }


        // setTempDisplay(ltiObject);

    }

    const isValidValue = value => {
        return value !== undefined && value !== null && value !== '' && value !== ' ';
    };

    const areAllFieldsValid = () => {
        return isValidValue(consumerName) &&
            isValidValue(consumerKey) &&
            isValidValue(consumerSecret) &&
            isValidValue(ltiVersion) &&
            isValidValue(classSelected) &&
            isValidValue(membership) &&
            isValidValue(ltiType);
    };

    const triggeroverLay = () => {
        setValidationAlertCreate("");

        if (areAllFieldsValid()) {
            setShowConfirmOverlay(true);
        } else {
            setValidationAlert(() => {
                return (<>
                    <Alert key="danger" variant="danger">
                        Ensure all fields are completed
                    </Alert>
                </>)
            })
        }
    };

    useEffect(async () => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < 30; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        setConsumerSecret(result);
        const numclass = await getAllClasses()
        const numMemberships = await getAllMemberships();
        const numProducts = await getAllProduct();
        await setListOfClasses(numclass);
        await setListOfMemberships(numMemberships);
        await setListOfProducts(numProducts);

    }, []);

    return (
        <div width="75%">
            {validationAlertCreate}
            <Row>
                <Col xs={5}>
                    <h5>Enter New LTI Consumer Information Below</h5>
                    <hr style={{ margin: "auto" }} width="75%" /><br />
                    <Form>
                        <Row>
                            <Col style={{ padding: "10px" }} align="left">
                                <Form.Label>Consumer Name</Form.Label>
                            </Col>
                            <Col xs={8}>
                                <Form.Control  autoComplete='name' onChange={event => setConsumerName(event.target.value)} type="text" />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ padding: "10px" }} align="left">
                                <Form.Label>Consumer Key</Form.Label>
                            </Col>
                            <Col xs={8} >
                                <Form.Control
                                autoComplete='name'
                                    value={consumerKey}
                                    onChange={event => {
                                        // Remove spaces from the input
                                        const newValue = event.target.value.replace(/\s+/g, '.');
                                        setConsumerKey(newValue);
                                    }}
                                    type="text"
                                />
                                <Form.Text className="text-muted">
                                    No Spaces, they will be replaced with .
                                </Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ padding: "10px" }} align="left">
                                <Form.Label>Consumer Secret</Form.Label>
                            </Col>
                            <Col xs={8}>
                                <Form.Control autoComplete='password' value={consumerSecret} onChange={event => setConsumerSecret(event.target.value)} type="text" disabled />
                                <Form.Text className="text-muted">
                                    Auto-Generated
                                </Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ padding: "10px" }} align="left">
                                <Form.Label>LTI Version</Form.Label>
                            </Col>
                            <Col xs={8}>
                                <Form.Select onChange={event => setLTIVersion(event.target.value)}>
                                    <option value="LTI-1p0">LTI-1p0</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ padding: "10px" }} align="left">
                                <Form.Label>Class</Form.Label>
                            </Col>
                            <Col xs={8}>
                                <Form.Select onChange={event => setClassSelected(event.target.value)}>
                                    <option style={{ 'fontWeight': "400", color: "#212529" }} value="">

                                    </option>
                                    {Array.isArray(listOfClasses) && listOfClasses.length > 0 ? listOfClasses.map(classNum => (
                                        <option key={classNum.schoolId} value={classNum.schoolId}>
                                            {classNum.schoolName}
                                        </option>
                                    )) :
                                        <option disabled>Error retrieving classes</option>}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ padding: "10px" }} align="left">
                                <Form.Label>Membership</Form.Label>
                            </Col>
                            <Col xs={8}>
                                <Form.Select onChange={event => setMembership(event.target.value)}>
                                    <option style={{ 'fontWeight': "400", color: "#212529" }} value="">

                                    </option>
                                    {Array.isArray(listOfMemberships) && listOfMemberships.length > 0 ?
                                        listOfMemberships.map(membership => (
                                            <option key={membership.membershipId} value={membership.membershipKey}>
                                                {membership.membershipName}
                                            </option>
                                        ))
                                        :
                                        <option disabled>Error retrieving memberships</option>}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ padding: "10px" }} align="left">
                                <Form.Label>Product</Form.Label>
                            </Col>
                            <Col xs={8}>
                                <Form.Select onChange={event => setProduct(event.target.value)}>
                                    <option style={{ 'fontWeight': "400", color: "#212529" }} value="">

                                    </option>
                                    {Array.isArray(listOfProducts) && listOfProducts.length > 0 ?
                                        listOfProducts.map(product => (
                                            <option key={product.id} value={product.id}>
                                                {product.title}
                                            </option>
                                        ))
                                        :
                                        <option disabled>Error retrieving memberships</option>}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ padding: "10px" }} align="left">
                                <Form.Label>Setup Type</Form.Label>
                            </Col>
                            <Col xs={8}>
                                <Form.Select onChange={event => setLTIType(event.target.value)}>
                                    <option style={{ 'fontWeight': "400", color: "#212529" }} value="">

                                    </option>
                                    <option value="unit">Basic LTI Integration</option>
                                    <option value="chapter">Advanced LTI Integration</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col align="right">

                            </Col>
                            <Col xs={8} align="right">
                                <Button onClick={() => triggeroverLay()} type="button">Submit</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={2}>
                            </Col>
                            <Col xl={8}>
                                <br />{validationAlert}
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col xs={6}>
                    {displayArea}
                </Col>
            </Row>
            <hr />
            <AllConsumerComponent/>

            <Modal
                show={showConfirmOverlay}
                onHide={() => setShowConfirmOverlay(false)}
                dialogClassName="modal-90w"
                size="lg"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Confirm Values
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table style={{ width: '80%' }}>
                        <thead>
                            <tr>
                                <td>
                                    Consumer Name:
                                </td>
                                <td>
                                    {consumerName}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Consumer Key:
                                </td>
                                <td>
                                    {consumerKey}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Consumer Secret:
                                </td>
                                <td>
                                    {consumerSecret}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    LTI Version:
                                </td>
                                <td>
                                    {ltiVersion}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Class:
                                </td>
                                <td>
                                    {classSelected}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Membership:
                                </td>
                                <td>
                                    {membership}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Product Selected:
                                </td>
                                <td>
                                    {product}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    LTI Type:
                                </td>
                                <td>
                                    {ltiType === "unit" ? "Basic LTI Integration" : ltiType === "chapter" ? "Advanced LTI Integration" : ltiType}
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <table>
                        <tr>
                            <td>{loading}</td>
                            <td>
                                <Button onClick={() => createLTIConsumer()} disabled={disabledButtons}>Confirm Info & Create LTI Consumer</Button>
                            </td>
                            <td>
                                <Button variant="outline-primary" onClick={() => setShowConfirmOverlay(false)} disabled={disabledButtons}>Cancel</Button>
                            </td>
                        </tr>
                        <tr>
                            <td>

                            </td>
                            <td colspan="2">
                                <br />{validationAlertCreate}
                            </td>
                        </tr>
                    </table>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

async function downloadFormattedTextFile(data) {
    const formattedText = await createFormattedText(data);
    const blob = new Blob([formattedText], { type: "text/plain" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "connectionDetails.txt"; // This will be the name of the downloaded file
    a.click();
    URL.revokeObjectURL(url);
}

async function createFormattedText(data) {
    let result = '';
    data.forEach(item => {
        // result += `Start---${item.description}\n\n`;
        result += `Connection URL: ${item.connectionURL}\n`;
        result += `Key: ${item.key}\n`;
        result += `Security: ${item.security}\n`;
        result += `Custom Parameters:\n`;
        result += `  classKey=${item.customParameters.classKey}\n`;
        result += `  level=${item.customParameters.level}\n`;
        result += `  contentId=${item.customParameters.contentId}\n`;
        result += `Description (NOT TO BE ADDED TO LMS)=${item.description}\n\n`;
        result += `---\n\n`;
        // result += `END---${item.description}\n\n`;
    });

    return result;
}

export default AddLTIConsumerComponent;