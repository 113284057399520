import React, { useState, useEffect } from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import LoginCheck from '../components/header/student-view';
import NotebookModel from '../components/header/student-view';
import {loginCheck} from '../services/bffServices/isLoggedIn';
import Button from 'react-bootstrap/Button'
import NavDropdown from 'react-bootstrap/NavDropdown'
import AlertSB from '../components/alertSB';
import ChapterList from '../components/textbook/chapterList';



function SideNavBar(props) {

  return (
    <div style={{'max-width': "500px"}}>
<small>
    <ChapterList id={props.unitId} module={props?.module || "0"} />
    </small>

   </div>
  );
}

export default SideNavBar;

