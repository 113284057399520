export async function updateSCCustomerInfo(payload){
  // console.log("updateSCCustomerInfo -- payload" + JSON.stringify(payload))
    // const payload_test = {
    //     "shoppingCartId" : payload.shoppingCartId,
    //     "customerDetails": {
    //         "customerId": "TBD",
    //         "customerFirstName": payload.firstname,
    //         "customerLastName": payload.lastName,
    //         "customeremail": payload.email,
    //         "customerType": "Student",
    //         "customerBillingAddress": "TBD",
    //         "existingCustomer": "Y"
    //       }
    // };

    const payload_test = {
      "shoppingCartId" : payload.shoppingCartId,
      "customerDetails": {
          "customerId": "fd97ccf9-9601-413b-8371-b6886105b87e",
          "customerFirstName": "Prienka",
          "customerLastName": "Chandrasekaran",
          "customeremail": "sns69-004@email.com",
          "customerType": "Student",
          "customerBillingAddress": "Toronto, ON, Canada",
          "existingCustomer": "Y"
        }
  };

    const options = { 
        method: 'PATCH', 
        headers: new Headers({ 
          'Content-Type': 'application/json',
          'sb-application-name': 'sb-site-ui',
          'sb-application-key': 'jmd-test',
          'sessionID' : sessionStorage.getItem("sessionID"),
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
        }), 
        mode: 'cors',
        credentials: 'omit',
        body: JSON.stringify(payload_test)
      };
      
      const scURL = process.env.REACT_APP_BFF + '/updateShoppingCart';
      let response = await fetch(scURL,options)
      let scDetails = await response.json();
      // console.log('updateSCCustomerInfo:  ' + JSON.stringify(scDetails));
      
      return scDetails;
}