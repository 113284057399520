import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


function SubmitterInfoComponenet(props) {

    return (
        <>
                <Row>
                    <Col style={{margin: "auto"}} xs={3}>
                        Professor Details
                    </Col>
                    <Col style={{margin: "auto"}}>
                        <Row>
                            <Col>
                                <Form.Control autoComplete='text' type="text" autoComplete="text" placeholder="First Name" />

                            </Col>
                            <Col>
                                <Form.Control autoComplete='text' type="text" autoComplete="text" placeholder="Last Name" />
                            </Col>
                        </Row><br />
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control autoComplete='email' type="email" autoComplete="email" placeholder="Email" />
                                    <Form.Text className="text-muted">
                                        We'll never share your email with anyone else.
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        Institution Information
                    </Col>
                    <Col >
                        <Row>
                            <Col>
                                <Form.Control autoComplete='text' type="text" autoComplete="text" placeholder="Institution Name" />
                            </Col>
                            <Col>
                                <Form.Control autoComplete='text' type="text" autoComplete="text" placeholder="Course Code & Title" />
                            </Col>
                        </Row><br />

                    </Col>
                </Row>



        </>
    )
}


export default SubmitterInfoComponenet;
