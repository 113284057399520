


  export async function generateBulkCoupons(payload){

    const options = { 
      method: 'POST', 
      headers: new Headers({ 
        'Content-Type': 'application/json',
        'sb-application-name': 'sb-site-ui',
        'sb-application-key': 'jmd-test',
        'sessionID' : sessionStorage.getItem("sessionID"),
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
      }), 
      mode: 'cors',
      credentials: 'omit',
      body: JSON.stringify(payload)
    };
    const endpoint = process.env.REACT_APP_BFF + '/bff/v1.0/generateBulkCoupons/'
  
    let couponList =
      await fetch(endpoint,options)
        .then(response => {
          return response.json();
        })
        .catch(function(err) {
          console.log(err);
        });

    return couponList;

  }

  