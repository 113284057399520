import Card from 'react-bootstrap/Card'
import React, { useState, useEffect } from "react";

const queryParams = new URLSearchParams(window.location.search);
// const [moduleId, setModuleId] = useState(queryParams.get('id'));

export default function NotFoundComponent(props) {
    // var url = "";

    return (

        <div style={{ display: "flex", backgroundColor: "#f8f9fa", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <div style={{ width: "60%", padding: "100px" }}>
              
                <div class="planet">
                    <div class="crater"></div>
                    <div class="crater"></div>
                    <div class="crater"></div>
                    <div class="crater"></div>
                    <div class="crater"></div>
                    <div class="rover">
                        <div class="body"></div>
                        <div class="wheels"></div>
                        <div class="trace"></div>
                    </div>
                    <div class="flag">
                        404 - No Life Here
                    </div>
                </div>
                <Card align="left">
                    <Card.Header><h7>Whoops, can't find what you are looking for!</h7></Card.Header>
                    <Card.Body>
                        <Card.Text>
                            Oopsie daisy, looks like something went wrong! Page you are trying to reach doens't exist! Please verify the URL and page you are trying to reach.
                            If you feel this is an error, please email support@smart-biology.com
                            <br />
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </div>

    );

}