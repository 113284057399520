// import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Alert from 'react-bootstrap/Alert'
import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

const gitHubUrl = process.env.REACT_APP_BFF + "/getBanner";
const options = { 
  method: 'GET', 
  headers: new Headers({ 
    'Content-Type': 'text/plain',
    'sb-application-name': 'sb-site-ui',
    'sb-application-key': 'jmd-test',
    'sessionID' : sessionStorage.getItem("sessionID"),
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
  }), 
  mode: 'cors',
  credentials: 'omit'
};

// componentDidMount() {
//   this.setState({ ruinPerformance: true });
// }

function AlertBanner() {
  // console.log("Start Banner");
  const language = sessionStorage.getItem("lang") || "EN"
  let loading = true;
  const [data,setData]=useState([]);
  const [bannerType,setbannerType]=useState([]);
  const [readBannerText,setreadBannerText]=useState([]);
  const [disabledButtom,setdisabledButtom]=useState([]);
  const [overlayTitle,setoverlayTitle]=useState([]);
  const [overlayContent,setoverlayContent]=useState([]);
  const [overlayURL,setoverlayURL]=useState([]);
  const [showOverlay,setshowOverlay]=useState([]);
  const [isActive,setisActive]=useState([]);
  const [bannerText,setBannerText]=useState();

  // var isActive = false;


  const getData=()=>{
    fetch(gitHubUrl,options)
      .then(function(response){
        // console.log(response)
        loading = false;
        return response.json();
      })
      .then(function(myJson) {
        // console.log(myJson);
        setData(myJson)

        if(language =="EN"){
          setBannerText(myJson.en_text)
        }
        if(language =="FR"){
          setBannerText(myJson.fr_text)
        }
        
        if(myJson.type == "sales"){
          // console.log(JSON.stringify(myJson));dd
          setbannerType("success");
          
          if(myJson.includeOverlay)
          {
            setreadBannerText(myJson.buttonText);
            setoverlayContent(myJson.overlayContent);
            setoverlayTitle(myJson.overlayTitle);
            setoverlayURL(myJson.overlayLink);
            setisActive(myJson.activateBanner);
            // componentDidMount();
          }

          if(!myJson.includeOverlay){
            setshowOverlay(false);
            setdisabledButtom("disabled");
            
          }

        }
        if(myJson.type == "warning"){
          setbannerType("warning");
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  useEffect(()=>{
    getData()
    
  },[])

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if(isActive){
  
  return (
    <div className="AlertBanner">
          <Alert variant={bannerType} className="removePadding advert" >
            {bannerText}{' '}
            <Alert.Link onClick={() => setShow(showOverlay)}>{readBannerText}</Alert.Link>
          {/* </Button> */}
          </Alert>
          <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {overlayTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {overlayContent}
          </p>
          <p>
            <a href={overlayURL} target="_blank">Click to Purchase</a>
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
  }else{
    return null;
  }
}

export default AlertBanner;