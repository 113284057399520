import { QRCodeSVG } from 'qrcode.react'

function QRComponent() {

    return (
        <>
            <QRCodeSVG
                value={"redirecrUrl"}
                title={"title"}
                size={128}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"H"}
                marginSize={0}
                imageSettings={{
                    src: "https://storage.googleapis.com/sb-general/sb_test_001.png",
                    x: undefined,
                    y: undefined,
                    height: 30,
                    width: 40,
                    opacity: 1,
                    excavate: true,
                }}
            />

        </>
    )
}

export default QRComponent;