import { Container } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import { sendContactUsEmail } from '../../services/notifications/sendContactUsEmail';
import { sendContactUsEmailHS } from '../../services/notifications/sendContactEmailHS';
// import Footer from '../footer';
import { updatePageTitle } from '../../utls/lib';
import texasProducts from '../contactUsPage/texasProducts.json';
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton,
    BigPlayButton
} from 'video-react';

const queryParams = new URLSearchParams(window.location.search);

export default function ContactTexasOrderFormComponent(props) {

    //Validation Labels
    const [formValidationMsg, setFormValidationMsg] = useState("");
    const [formValidationMsgTC, setFormValidationMsgTC] = useState("");
    const [emailValidationLabel, setEmailValidationLabel] = useState(false);
    const [emailSchoolValidationLabel, setEmailSchoolValidationLabel] = useState(false);
    const [qtyCalculationValue, setQtyCalculationValue] = useState("$20");


    //T&C Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //Password Overlay
    const [showPassword, setShowPassword] = useState(true);
    const handlPasswordClose = () => setShowPassword(false);
    const handlePasswordShow = () => setShowPassword(true);
    const [enteredPassword, setEnteredPassword] = useState("");
    const [passwordValidationError, setPasswordValidationError] = useState("");



    const [sideContentEnterprise, setSideContentEnterprise] = useState("");

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        district: '',
        school: '',
        contactStreetNum: '',
        contactStreetName: '',
        contactCity: '',
        contactStateProv: '',
        contactCountry: '',
        contactPostZipCode: '',
        contactPhoneNum: '',
        contactSchoolEmail: '',
        billingAttn: '',
        billingPO: '',
        billingCompanyName: '',
        billingStreetNum: '',
        billingStreetName: '',
        billingCity: '',
        billingStateProv: '',
        billingCountry: '',
        billingPostZipCode: '',
        billingPhoneNum: '',
        productOrdered: '',
        productQty: '',
        termConditionsAccepted: false, 
        additionalComments: ''
    });

    function validateEmail(email) {
        // A simple regex for email validation
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,6}$/;
        return regex.test(email);
    }

    const handleQtyChange = event => {
        const productQty = event.target.value;

        if(formData.productOrdered !== "0"){
            const foundProduct = texasProducts.find(product => product.name === formData.productOrdered);

            setQtyCalculationValue(productQty*foundProduct?.Price || 0);
        }
      

        setFormData({ ...formData, productQty });

    };

    const handleProductChange = event => {
        var productOrdered = event.target.value;

        if(productOrdered !== "0"){
            const foundProduct = texasProducts.find(product => product.name === productOrdered);

            setQtyCalculationValue(formData.productQty*foundProduct?.Price || 0);
        }

        if(productOrdered === "0"){
            productOrdered = "";
            setFormData({ ...formData, productOrdered });
        }


        setFormData({ ...formData, productOrdered });

    };

    const handleEmailChange = event => {
        setEmailValidationLabel("");
        const email = event.target.value;
        if (!validateEmail(email)) {
            setEmailValidationLabel("Please Enter Correct Format");
            // Optionally: Provide visual feedback or set an error state
        }
        if (email === '') {
            setEmailValidationLabel("");
            // Optionally: Provide visual feedback or set an error state
        }
        setFormData({ ...formData, email });
    };

    const handleSchoolEmailChange = event => {
        setEmailSchoolValidationLabel("");
        const contactSchoolEmail = event.target.value;
        if (!validateEmail(contactSchoolEmail)) {
            setEmailSchoolValidationLabel("Please Enter Correct Format");
            // Optionally: Provide visual feedback or set an error state
        }
        if (contactSchoolEmail === '') {
            setEmailSchoolValidationLabel("");
            // Optionally: Provide visual feedback or set an error state
        }
        setFormData({ ...formData, contactSchoolEmail });
    };

    const passwordValidation = () => {
        setPasswordValidationError("");
        if (enteredPassword == "ribosome") {
            handlPasswordClose();
        }

        if (enteredPassword !== "ribosome") {
            setPasswordValidationError("Incorrect Access Code");
        }


        if (enteredPassword === "") {
            setPasswordValidationError("Please Enter An Access Code");
        }
    };

    function isFormValid(form) {

        // Check other fields
        for (let key in form) {
            if (form.hasOwnProperty(key) && key !== "termConditionsAccepted") {
                let value = form[key];

                if (value === null || value === '' || value === ' ') {
                    return false;
                }
            }
        }

        return true;
    }
    function isTandCValid(form) {
        // Ensure termConditionsAccepted is true
        if (!form.termConditionsAccepted) {
            return false;
        }

        return true;
    }
    function resetForm() {
        setFormValidationMsg("");
        setFormValidationMsgTC("");
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            district: '',
            school: '',
            contactStreetNum: '',
            contactStreetName: '',
            contactCity: '',
            contactStateProv: '',
            contactCountry: '',
            contactPostZipCode: '',
            contactPhoneNum: '',
            contactSchoolEmail: '',
            billingAttn: '',
            billingPO: '',
            billingCompanyName: '',
            billingStreetNum: '',
            billingStreetName: '',
            billingCity: '',
            billingStateProv: '',
            billingCountry: '',
            billingPostZipCode: '',
            billingPhoneNum: '',
            productOrdered: '',
            productQty: '',
            termConditionsAccepted: false,
            type: "HIGHSCHOOL"
        });
    }


    useEffect(async () => {
        const language = sessionStorage.getItem("lang") || "EN"
        updatePageTitle("Contact Us");

        if (language == "EN") {

            setSideContentEnterprise(() => {
                return (
                    <>
                        <div align="center" style={{ color: "#000080", 'text-align': "center" }}>
                            <h4>
                                Teach Your Students the Smart Biology Way
                                <hr />
                            </h4>
                        </div>
                        <div>
                            <div>
                                <p>
                                    Set your school apart by providing a biology learning experience that will engage and amaze at scale. Our courseware allows you to bring biology to life for your students. Simply fill out the order form and a member of our team will get back to you.                              </p>
                            </div>
                            <hr />
                            <div align="center">
                                <Player
                                    playsInline
                                    poster="https://storage.googleapis.com/sb-general/wp-content/uploads/2021/03/SB-Instructor-Resources-3.jpg"
                                    src="https://storage.googleapis.com/sb-general/Smart-Biology-90-Sec-Explainer-Video-Bit-30vs.mp4"
                                >
                                    <BigPlayButton position="center" />
                                    <ControlBar>
                                        <ReplayControl seconds={10} order={1.1} />
                                        <ForwardControl seconds={30} order={1.2} />
                                        <CurrentTimeDisplay order={4.1} />
                                        <TimeDivider order={4.2} />
                                        <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                                        <VolumeMenuButton />
                                    </ControlBar>
                                </Player>
                            </div>
                        </div><br />
                    </>
                )
            })
        }
    }, []);

    const contactFormType = "enterprise";

    const sendContactEmail = async () => {

        setFormValidationMsg("");

        setFormValidationMsgTC("");

        if (contactFormType == "enterprise") {

            // var emailSendResults = await sendContactUsEmail(name, email, message, trialRequest, enterpriseDetails);

           
            var validForm = await isFormValid(formData);
            var validTandC = await isTandCValid(formData)

            if (!validForm) {
                setFormValidationMsg(() => {
                    return (
                        <>
                            <Alert key="danger" variant="danger">
                                Please ensure all fields are completed
                            </Alert>
                        </>
                    )
                })
            }

            if (!validTandC) {
                setFormValidationMsgTC(() => {
                    return (
                        <>
                            <Alert key="danger" variant="danger">
                                Please accept Terms and Conditions
                            </Alert>
                        </>
                    )
                })
            }

            if (validForm && validTandC) {

                try{

                    let contactSubmissionResults = await sendContactUsEmailHS(formData);

                    if(contactSubmissionResults.status === "SUCCESS"){
                        setFormValidationMsg(() => {
                            return (
                                <>
                                    <Alert key="success" variant="success">
                                        Order successfully created, you will recieve a response shortly
                                    </Alert>
                                </>
                            )
                        });
                    }

                    if(contactSubmissionResults.status === "ERROR"){
                        setFormValidationMsg(() => {
                            return (
                                <>
                                    <Alert key="danger" variant="danger">
                                        Error submitting order, please retry or contact support@smart-biology.com
                                    </Alert>
                                </>
                            )
                        });
                    }
    

                }catch(err){
                    setFormValidationMsg(() => {
                        return (
                            <>
                                <Alert key="danger" variant="danger">
                                    Error submitting order, please retry or contact support@smart-biology.com
                                </Alert>
                            </>
                        )
                    });
                }
               


            }

            //Need to put logic if there is an error
            // setFormValidationMsg(() => {
            //     return (
            //         <>
            // <Alert key="danger" variant="danger">
            //     An Error has occured, please retry or email orders@smart-biology.com for assistance
            // </Alert>
            //         </>
            //     )
            // })
        }
    }

    return (
        <div style={{ 'background-color': "#f8f9fa", height: "100vh!important" }}>
            <Container style={{ padding: "40px", 'background-color': "#f8f9fa", height: "100vh!important", width: "100%" }}>
                <Card style={{ padding: "50px", width: "100%", boxShadow: "0 4px 8px rgba(0,0,0,0.1), 0 6px 20px rgba(0,0,0,0.1)" }}>
                    <Row >
                        <Col align="left" className="d-none d-lg-block" >
                            <Container>
                                {sideContentEnterprise}
                            </Container>
                        </Col>
                        <Col style={{ align: "left" }}>
                            <Card style={{ align: "left" }}>
                                <Card.Header style={{ color: "#000080", 'fontWeight': "600", 'fontSize': "20px" }}>
                                    Create Your Order
                                </Card.Header>
                                <Card.Body style={{ align: "left" }}>
                                    <Form style={{ align: "left" }}>
                                        <Form.Group className="mb-3" controlId="contactForm">
                                            <Row>
                                                <Col>
                                                    <Form.Control 
                                                     className="placeholder-color"
                                                     value={formData.firstName}
                                                        onChange={event => setFormData({ ...formData, firstName: event.target.value })}
                                                        type="text" autoComplete="text" placeholder="First Name" />
                                                </Col>
                                                <Col>
                                                    <Form.Control 
                                                     className="placeholder-color"
                                                     value={formData.lastName}
                                                        onChange={event => setFormData({ ...formData, lastName: event.target.value })} type="text" autoComplete="text" placeholder="Last Name" /> <br />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col colspan="2">
                                                    <Form.Control 
                                                     className="placeholder-color"
                                                     value={formData.email}
                                                        onChange={handleEmailChange} type="email" autoComplete="email" placeholder="Email Address" />
                                                    {!emailValidationLabel && <br />}
                                                    {emailValidationLabel &&
                                                        <span className="d-block text-left text-danger" style={{ textAlign: 'left', 'fontSize': "12px", 'padding-bottom': "7px" }}>
                                                            {emailValidationLabel}
                                                        </span>
                                                    }

                                                </Col>
                                            </Row>


                                            <Row>
                                                <Col>
                                                    <Form.Control
                                                     className="placeholder-color"
                                                      value={formData.district}
                                                        onChange={event => setFormData({ ...formData, district: event.target.value })} type="text" autoComplete="text" placeholder="District" />
                                                </Col>
                                                <Col>
                                                    <Form.Control 
                                                     className="placeholder-color"
                                                     value={formData.school}
                                                        onChange={event => setFormData({ ...formData, school: event.target.value })} type="text" autoComplete="text" placeholder="School" />
                                                </Col>
                                            </Row>
                                            <hr />
                                            <div style={{ color: "#000080", 'text-align': "left" }}><h6>Contact Information</h6></div>
                                            <Row>
                                                <Col xs={3}>
                                                    <Form.Control 
                                                     className="placeholder-color"
                                                     value={formData.contactStreetNum}
                                                        onChange={event => setFormData({ ...formData, contactStreetNum: event.target.value })} type="text" autoComplete="text" placeholder="Street Num" /> <br />
                                                </Col>
                                                <Col>
                                                    <Form.Control 
                                                     className="placeholder-color"
                                                     value={formData.contactStreetName}
                                                        onChange={event => setFormData({ ...formData, contactStreetName: event.target.value })} type="text" autoComplete="text" placeholder="Street Name" /> <br />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col >
                                                    <Form.Control 
                                                     className="placeholder-color"
                                                     value={formData.contactCity}
                                                        onChange={event => setFormData({ ...formData, contactCity: event.target.value })} type="text" autoComplete="text" placeholder="City" /> <br />
                                                </Col>
                                                <Col>
                                                    <Form.Control  className="placeholder-color"
                                                    value={formData.contactStateProv}
                                                        onChange={event => setFormData({ ...formData, contactStateProv: event.target.value })} type="text" autoComplete="text" placeholder="State / Province" /> <br />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col >
                                                    <Form.Control  className="placeholder-color"
                                                    value={formData.contactCountry}
                                                        onChange={event => setFormData({ ...formData, contactCountry: event.target.value })} type="text" autoComplete="text" placeholder="Country" /> <br />
                                                </Col>
                                                <Col>
                                                    <Form.Control  className="placeholder-color"
                                                    value={formData.contactPostZipCode}
                                                        onChange={event => setFormData({ ...formData, contactPostZipCode: event.target.value })} type="text" autoComplete="text" placeholder="Postal/Zip Code" /> <br />
                                                </Col>
                                            </Row>
                                            <Form.Control  className="placeholder-color"
                                            value={formData.contactPhoneNum}
                                                onChange={event => setFormData({ ...formData, contactPhoneNum: event.target.value })} type="text" autoComplete="text" placeholder="Phone Number" /> <br />
                                            <Form.Control  className="placeholder-color"
                                            value={formData.contactSchoolEmail}
                                                onChange={handleSchoolEmailChange} type="text" autoComplete="text" placeholder="School Contact Email Address" />
                                            {!emailSchoolValidationLabel && <br />}
                                            {emailSchoolValidationLabel &&
                                                <span className="d-block text-left text-danger" style={{ textAlign: 'left', 'fontSize': "12px", 'padding-bottom': "7px" }}>
                                                    {emailSchoolValidationLabel}
                                                </span>
                                            }

                                            <hr />
                                            <div style={{ color: "#000080", 'text-align': "left" }}><h6>Billing Information</h6></div>
                                            <Row>
                                                <Col>
                                                    <Form.Control 
                                                     className="placeholder-color"
                                                     value={formData.billingAttn}
                                                        onChange={event => setFormData({ ...formData, billingAttn: event.target.value })} type="text" autoComplete="text" placeholder="ATTN: Name / Dept" /> <br />
                                                </Col>
                                                <Col>
                                                    <Form.Control  className="placeholder-color"
                                                    value={formData.billingPO}
                                                        onChange={event => setFormData({ ...formData, billingPO: event.target.value })} type="text" autoComplete="text" placeholder="Invoice / PO ID" /> <br />
                                                </Col>
                                            </Row>
                                            <Form.Control  className="placeholder-color"
                                            value={formData.billingCompanyName}
                                                onChange={event => setFormData({ ...formData, billingCompanyName: event.target.value })} type="text" autoComplete="text" placeholder="Company Name" /> <br />
                                            <Row>
                                                <Col xs={3}>
                                                    <Form.Control  className="placeholder-color"
                                                    value={formData.billingStreetNum}
                                                        onChange={event => setFormData({ ...formData, billingStreetNum: event.target.value })} type="text" autoComplete="text" placeholder="Street Num" /> <br />
                                                </Col>
                                                <Col>
                                                    <Form.Control  className="placeholder-color"
                                                    value={formData.billingStreetName}
                                                        onChange={event => setFormData({ ...formData, billingStreetName: event.target.value })} type="text" autoComplete="text" placeholder="Street Name" /> <br />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col >
                                                    <Form.Control  className="placeholder-color"
                                                    value={formData.billingCity}
                                                        onChange={event => setFormData({ ...formData, billingCity: event.target.value })} type="text" autoComplete="text" placeholder="City" /> <br />
                                                </Col>
                                                <Col>
                                                    <Form.Control  className="placeholder-color"
                                                    value={formData.billingStateProv}
                                                        onChange={event => setFormData({ ...formData, billingStateProv: event.target.value })} type="text" autoComplete="text" placeholder="State / Province" /> <br />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col >
                                                    <Form.Control  className="placeholder-color"
                                                    value={formData.billingCountry}
                                                        onChange={event => setFormData({ ...formData, billingCountry: event.target.value })} type="text" autoComplete="text" placeholder="Country" /> <br />
                                                </Col>
                                                <Col>
                                                    <Form.Control  className="placeholder-color"
                                                    value={formData.billingPostZipCode}
                                                        onChange={event => setFormData({ ...formData, billingPostZipCode: event.target.value })} type="text" autoComplete="text" placeholder="Postal/Zip Code" /> <br />
                                                </Col>
                                            </Row>
                                            <Form.Control className="placeholder-color"
                                             value={formData.billingPhoneNum}
                                                onChange={event => setFormData({ ...formData, billingPhoneNum: event.target.value })} type="text" autoComplete="text" placeholder="Phone Number" /> <br />
                                            <hr />
                                            <div style={{ color: "#000080", 'text-align': "left" }}><h6>Product Details</h6></div>


                                            {contactFormType == 'enterprise' &&
                                                <>
                                                    <Row>
                                                        <Col xl={10}>
                                                            <Form.Select
                                                                style={{ 'fontWeight': "400", color: "#212529" }}
                                                                aria-label="Default select example"
                                                                value={formData.productOrdered}
                                                                onChange={handleProductChange}
                                                                name="product"
                                                            >
                                                                <option style={{ 'fontWeight': "400", color: "#212529" }} value="0">
                                                                    Select Your BIOLOGY Texas Courseware (TE included)
                                                                </option>

                                                                {texasProducts.map(product => (
                                                                    <option key={product.ISBN} value={product.name}>
                                                                        {product.dropDownDescription}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control value={formData.productQty}
                                                                onChange={handleQtyChange} type="text" autoComplete="text" placeholder="Qty" /> <br />

                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        
                                                        <Col>
                                                            {formData.productQty && formData.productOrdered &&
                                                            <Card bg="light">
                                                                <Card.Body>
                                                                <span style={{ textAlign: 'left', 'fontSize': "12px", 'padding-bottom': "7px", display:"block" }}>
                                                                    <Row>
                                                                        <Col xl={3}>
                                                                        <strong>Product: </strong>
                                                                        </Col>
                                                                        <Col>
                                                                        {formData.productOrdered}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xl={3}>
                                                                        <strong># of Students: </strong>
                                                                        </Col>
                                                                        <Col>
                                                                        {formData.productQty}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xl={3}>
                                                                        <strong>Total (USD):</strong>
                                                                        </Col>
                                                                        <Col>
                                                                        ${qtyCalculationValue} + TAX
                                                                        </Col>
                                                                    </Row>
                                                                </span>
                                                                </Card.Body>
                                                                </Card>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                    
                                            <hr />
                                            <Row>
                                                <Col>
                                                <Form.Control 
                                                 className="placeholder-color"
                                                 value={formData.additionalComments} rows={5}
                                                onChange={event => setFormData({ ...formData, additionalComments: event.target.value })} as="textarea" placeholder="Additional Comments" /> <br />
                                            
                                                </Col>
                                            </Row>
                                            <small>
                                                <Row>
                                                    <Col className="d-flex align-items-center">
                                                        <Form.Check
                                                            type="switch"
                                                            id="custom-switch"
                                                            label="I accept terms and conditions"
                                                            checked={formData.termConditionsAccepted}
                                                            onChange={event => setFormData({ ...formData, termConditionsAccepted: event.target.checked })}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Button variant="link" onClick={handleShow} size="sm">
                                                            Click to view Terms & Conditions
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </small>
                    
                                        </Form.Group>
                                        <div className="d-grid gap-2">
                                            <div align="left" color="red">{formValidationMsg} {formValidationMsgTC}</div>
                                            <Button onClick={() => sendContactEmail()}>Submit Order</Button>
                                            <Button variant="secondary" onClick={resetForm}>Reset Form</Button>
                                        </div> <br />
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card>
                <br />
            </Container>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Terms and Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ 'max-height': "400px", 'overflow-y': "auto" }}>
                    <p><strong>Last updated:</strong> September 07, 2015</p>

                    <p>These Terms and Conditions (“Terms”, “Terms and Conditions”) govern your relationship with <a href="https://www.smart-biology.com" target="_blank">https://www.smart-biology.com</a> website (the “Service”) operated by Smart Biology (“us”, “we”, or “our”).</p>

                    <h5>Acceptance of Terms</h5>
                    <p>Please read these Terms and Conditions carefully before using the Service.</p>
                    <p>Your access to and use of the Service is based on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.</p>
                    <p>By accessing or using the Service you agree to be bound by these Terms and accept all legal consequences. If you do not agree to these terms and conditions, in whole or in part, please do not use the Service.</p>

                    <h5>Purchases</h5>
                    <p>If you wish to purchase any product or service made available through the Service (“Purchase”), you may be asked to supply certain information relevant to your Purchase, including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.</p>
                    <p>You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct, and complete.</p>
                    <p>By submitting such information, you grant us the right to provide the information to third parties for purposes of facilitating the completion of Purchases.</p>

                    <h5>Discussion Forums</h5>
                    <p>Posting inappropriate comments on the discussion forums will result in termination of your account and termination of your subscription to all animation packages you have purchased. You will not be issued any refund. Examples of inappropriate comments include, but are not limited to, profanity/offensive language, derogatory remarks about others, racism, prejudice, sex, and religion.</p>

                    <h5>Availability, Errors and Inaccuracies</h5>
                    <p>In order to provide exceptional service, and accuracy, we regularly update the products and services on the Service.</p>
                    <p>We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.</p>
                    <p>Despite our best efforts, the products or services available on our Service may have an error regarding the price, be inaccurately described, or be unavailable. We may experience delays in updating information on the Service and in our advertising on other websites.</p>

                    <h5>Accounts</h5>
                    <p>When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</p>
                    <p>You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.</p>
                    <p>You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
                    <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trade mark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>

                    <h5>Intellectual Property</h5>
                    <p>The Service and all contents, including but not limited to text, images, graphics or code are the property of Smart Biology and are protected by copyright, trademarks, database and other intellectual property rights. You may display and copy, download or print portions of the material from the different areas of the Service only for your own non-commercial use, or to place an order with Smart Biology. Any other use is strictly prohibited and may violate copyright, trademark and other laws. These Terms do not grant you a license to use any trademark of Smart Biology or its affiliates. You further agree not to use, change or delete any proprietary notices from materials downloaded from the Service.</p>

                    <h5>Links To Other Web Sites</h5>
                    <p>The Service may contain links to third-party web sites or services that are not owned or controlled by Smart Biology. Smart Biology has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that Smart Biology shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services.</p>
                    <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>

                    <h5>Termination</h5>
                    <p>We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms.</p>
                    <p>All provisions of the Terms shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
                    <p>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.</p>

                    <h5>Governing Law</h5>
                    <p>These Terms shall be governed by, and interpreted and enforced in accordance with, the laws in the Province of Ontario and the laws of Canada, as applicable.</p>
                    <p>If any provision of these Terms is held to be invalid or unenforceable by a court of competent jurisdiction, then any remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements, oral or otherwise, regarding the Service.</p>

                    <h5>Changes</h5>
                    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will make reasonable efforts to provide at least 30 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
                    <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showPassword}
                onHide={handlPasswordClose}
                backdrop="static"
                keyboard={false}
                style={{ 'backdrop-filter': "blur(10px)" }}
            >
                <Modal.Header >
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form style={{ align: "left" }}>
                        <Form.Group className="mb-3" controlId="contactForm">
                            <Row>
                                <Col>
                                    Enter Access Code:
                                </Col>
                                <Col>
                                    <Form.Control className="placeholder-color"
                                     value={enteredPassword}
                                        onChange={event => setEnteredPassword(event.target.value)} type="text" autoComplete="text" placeholder="" /> <br />

                                </Col>
                            </Row>
                            {passwordValidationError &&
                                <Alert key="danger" variant="danger">
                                    {passwordValidationError}
                                </Alert>
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => passwordValidation()}>Enter</Button><br />
                </Modal.Footer>
            </Modal>
        </div>
    );
}