import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert'
import { generateBulkCoupons } from '../../../services/bulkCoupons';
import ReactExport from "react-export-excel";
import { getAllMemberships } from '../../../services/getAllMemberships';
import { getAllProduct } from '../../../services/bffServices/getProducts';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function CouponGenerator() {

    const [classID, setClassId] = useState("");
    const [couponDesc, setCouponDesc] = useState("");
    const [product, setProduct] = useState("");
    const [discountValue, setDiscountValue] = useState("");
    const [numCoupons, setNumCoupons] = useState("");
    const [exportDataSet, setExportDataSet] = useState("");
    const [statusBanner, setStatusBanner] = useState("");
    const [enabledDownloadButton, setDownLoadButton] = useState("none");
    const [listOfMemberships, setListOfMemberships] = useState();
    const [listOfProducts, setListOfProducts] = useState();
    const [submitButtonStatus, setSubmitButtonStatus] = useState("");

    const [membership, setMembership] = useState("");



    const submitCoupons = async () => {
        var payload = {
            "classKey": membership,//actually membership key, mislabled
            "membershipKey": membership,//actually membership key, mislabled
            "couponDesc": couponDesc,
            "productId": product,
            "couponDollarValue": discountValue,
            "numberOfCoupons": numCoupons
        }
        try {
            setSubmitButtonStatus(() => {
                return (
                    <>
                        {/* <Button disabled> */}
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            
                        {/* </Button> */}
                    </>
                )
            });
            var downloadData = await generateBulkCoupons(payload)
                .then(payload => setExportDataSet(payload))
            // .then(payload => setDownLoadButton(""));


            if (!exportDataSet.errorId) {
                setDownLoadButton("")
                setStatusBanner(() => {
                    return (
                        <>
                            <Alert key="success" variant="success">
                                Coupons have been created.  Download Coupons below
                            </Alert>

                        </>
                    )
                })
            }

            if (exportDataSet.errorId == "E001") {
                setStatusBanner(() => {
                    return (
                        <>
                            <Alert key="danger" variant="danger">
                                Error creating Coupons: {downloadData.errorID + ": " + downloadData.errorMsg}
                            </Alert>
                        </>
                    )
                })
            }
            // if (exportDataSet == "") {
            //     setStatusBanner(() => {
            //         return (
            //             <>
            //                 <Alert key="danger" variant="danger">
            //                     Error creating Coupons: Unknown System Error
            //                 </Alert>
            //             </>
            //         )
            //     })
            // }

            setSubmitButtonStatus("");
        } catch (err) {
            setStatusBanner(() => {
                return (
                    <>
                        <Alert key="danger" variant="danger">
                            Error creating Coupons: {err}
                        </Alert>
                    </>
                )
            })
        }



    }
    useEffect(async () => {
        const numMemberships = await getAllMemberships();
        const numProducts = await getAllProduct();
        await setListOfMemberships(numMemberships);
        await setListOfProducts(numProducts);


    }, []);

    return (
        <div width="75%">
            <h5>Enter details below to create coupons</h5>
                    <hr style={{ margin: "auto" }} width="75%" /><br />
            <Form>
                <Row>
                    <Col xs={2} style={{ padding: "10px" }} align="right">
                        <Form.Label>Membership </Form.Label>
                    </Col>
                    <Col xs={5} align="left">
                        {/* <Form.Control onChange={event => setClassId(event.target.value)} type="text" autoComplete="text" /> */}
                        <Form.Select onChange={event => setMembership(event.target.value)}>
                            <option style={{ 'fontWeight': "400", color: "#212529" }} value="">

                            </option>
                            {Array.isArray(listOfMemberships) && listOfMemberships.length > 0 ?
                                listOfMemberships.map(membership => (
                                    <option key={membership.membershipId} value={membership.membershipKey}>
                                        {membership.membershipName}
                                    </option>
                                ))
                                :
                                <option disabled>Error retrieving memberships</option>}
                        </Form.Select>
                        {/* <Form.Text className="text-muted">
                            i.e. BIO293, no spaces
                        </Form.Text> */}
                    </Col>
                </Row>
                <Row>
                    <Col xs={2} style={{ padding: "10px" }} align="right">
                        <Form.Label>Coupon Description</Form.Label>
                    </Col>
                    <Col xs={5} align="left">
                        <Form.Control onChange={event => setCouponDesc(event.target.value)} type="text" autoComplete="text" />
                        <Form.Text className="text-muted">
                            Details on who this coupon is for
                        </Form.Text>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2} style={{ padding: "10px" }} align="right">
                        <Form.Label>What Product?</Form.Label>
                    </Col>
                    <Col xs={5} align="left">
                        <Form.Select onChange={event => setProduct(event.target.value)}>

                            {/* <Form.Control onChange={event => setProduct(event.target.value)} type="text" autoComplete="text" /> */}
                            <option style={{ 'fontWeight': "400", color: "#212529" }} value="">

                            </option>
                            {Array.isArray(listOfProducts) && listOfProducts.length > 0 ?
                                listOfProducts.map(product => (
                                    <option key={product.id} value={product.id}>
                                        {product.title} (${product.price})
                                    </option>
                                ))
                                :
                                <option disabled>Error retrieving memberships</option>}
                            {/* <Form.Text className="text-muted">
                            Enter Product ID (From Strapi)
                        </Form.Text> */}
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2} style={{ padding: "10px" }} align="right">
                        <Form.Label>Dollar Value of Discount</Form.Label>
                    </Col>
                    <Col xs={5} align="left">
                        <Form.Control onChange={event => setDiscountValue(event.target.value)} type="text" autoComplete="text" />
                        <Form.Text className="text-muted">
                            $$ value to remove from price
                        </Form.Text>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2} style={{ padding: "10px" }} align="right">
                        <Form.Label>Coupons Needed</Form.Label>
                    </Col>
                    <Col xs={5} align="left">
                        <Form.Control onChange={event => setNumCoupons(event.target.value)} type="text" autoComplete="text" />
                        <Form.Text className="text-muted">
                            # of coupons required
                        </Form.Text>
                    </Col>
                </Row>

                <Row>
                    <Col xs={2} align="right">
                    </Col>
                    <Col xs={5} align="right">
                        {submitButtonStatus} {' '}<Button onClick={() => submitCoupons()}>Submit</Button>
<br /><br />
                    </Col>
                </Row>
      
                {statusBanner}
                <hr />
                <div style={{ 'textAlign':"right", display: enabledDownloadButton }}>
                    <ExcelFile element={<Button>Download</Button>}>
                        <ExcelSheet data={exportDataSet} name="Bulk Generated Coupons">
                            <ExcelColumn label="Coupon Code" value="couponCode" />
                        </ExcelSheet>
                    </ExcelFile>
                </div>
            </Form>

            {/* {JSON.stringify(listOfProducts)} */}
        </div>
    )
}
export default CouponGenerator;