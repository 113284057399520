import React, { useState, useEffect } from "react";
import Nav from 'react-bootstrap/Nav';
// import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';
import { useParams } from "react-router";
// import Text from 'react-bootstrap/Text';
import "../App.css";

// const getProducts = process.env.REACT_APP_BFF + "/getLessons/617888c023d456322035bba5";
const getProducts = process.env.REACT_APP_BFF + "/getLessons/";
const getModule = process.env.REACT_APP_BFF + "/getModule/";

// const getProductsCount = process.env.REACT_APP_BFF + "/getNumOfProducts";
const options = { 
  method: 'GET', 
  headers: new Headers({ 
    'Content-Type': 'text/plain',
    'sb-application-name': 'sb-site-ui',
    'sb-application-key': 'test123',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
  }), 
  mode: 'cors',
  credentials: 'omit'
};


function DisplayLessonContent() {
  let moduleID = useParams();
  // console.log(getProducts + moduleID.id);
  
  const [data,setData]=useState([]);
  const getData=()=>{
    fetch(getProducts + moduleID.id,options)
      .then(function(response){
        return response.json();
      })
      .then(function(myJson) {
        // console.log(myJson);
       const test =  myJson.sort(function(a, b){
          return a.order - b.order;
      });
      // console.log("testing");
      // console.log(test);
        setData(myJson)
      });
  }
  useEffect(()=>{
    getData()
  },[])

  //----------------------

  const [module,setData2]=useState([]);
  const getData2=()=>{
    fetch(getModule + moduleID.id,options)
      .then(function(response){
        return response.json();
      })
      .then(function(moduleJson) {
        setData2(moduleJson)
      });
  }
  const sessionID = sessionStorage.getItem("sessionID");
  const cookieExists = document.cookie.split(";").some((item) => item.trim().startsWith("sessionID="));

  useEffect(()=>{
    getData2();
    if (cookieExists) {
      const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)sessionID\s*\=\s*([^;]*).*$)|^.*$/, "$1");
      sessionStorage.setItem("sessionID", cookieValue);
    }

  },[cookieExists])

  //----------------------

  return (
    <div className="ModulePage" style={{padding:"0", backgroundColor : "#f8f9fa", height: "100vh"}}>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Buy</Breadcrumb.Item>
        </Breadcrumb>
        <Container>
        <div className='moduleTitle'>{module.title_en}</div><br/>
          <Row>
            <Col sm={12} className="moduleContent">
            {module.content_en}
            </Col>
          </Row>
          </Container>
          <hr />
          <img className='moduleImage' src='https://www.smart-biology.com/wp-content/uploads/2019/01/Life-U1-C1-TSW-M1-TitleImage.jpg' />
          <Container>
          {
            data && data.length>0 && data.map((item)=>
            <Container>
            <div className="lessonTitle">{item.title_en}</div>
            <Row>
              <Col sm={5} >
                <div className="lessonText">Lesson Text</div>
                <div className="display-linebreak">{item.lessonText_en}</div>
              </Col>
              <Col>
              <Row>
                <Col>
                <video width="100%" height="auto" preload="auto" controls autoplay>
                  <source src="https://www.smart-biology.com/wp-content/uploads/2020/08/Smart-Biology-Animated-Textbooks-Smaller-Bit-32-2.mp4" type="video/mp4"/>
                  </video>
                </Col>
              </Row>
              <Row >
                <Col >
                {
                  item.lessonFigures && item.lessonFigures.length>0 && item.lessonFigures.map((figures)=>
                  <div className='figures'>{figures.content_en}<br />
                  <img className='moduleImage figureImage' src="https://storage.googleapis.com/sb-test-storage/Life-U1-C1-TSW-M1-L1-Figure1-2%20(1).jpg"/>
                  </div>
                  )
                }
                </Col>
              </Row>
              </Col>
            </Row>
            </Container>
            // <Nav.Link href={item.linkURL}>{item.linkName}</Nav.Link>
 
            )
          }
          </Container>
        
        
    </div>
  );
}

export default DisplayLessonContent;
