import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert'
import { getUserByEmail } from '../../../services/bffServices/getUserDetails';
import { getSchools } from '../../../services/bffServices/getSchoolDetails';
import { MdCropLandscape } from 'react-icons/md';
import { updateUserAddClass } from '../../../services/bffServices/updateUser';


function AssignClassComponent() {

    const [email, setEmail] = useState();
    const [classKey, setClassKey] = useState("empty");
    const [stepupError, setStepupError] = useState()

    // const [userDetails, setUserDetails] = useState();
    const [userArea, setUserArea] = useState("EMPTY");
    const [classList, setClassList] = useState();
    const [schools, setSchools] = useState([]);
    const [assignClassSection, setAssignClassSection] = useState({
        display: "none"
    });
    // const testDisplay = {display: ""}

    const updateUserWithClass = async () => {

        var payload = {
            "userName": email,
            "updateType": {
                "classChange": "ADD"
            },
            "newClass": classKey
        }

        // console.log("updateUserWithClass" + JSON.stringify(payload))

        await updateUserAddClass(payload);
        createActiveArea();

    }

    const createActiveArea = async () => {

        const userInfo = await getUserByEmail(email);

        if (userInfo.code == 2) {
            setStepupError(() => {
                return (
                    <>
                        <Alert key="danger" variant="danger">
                            {userInfo.message}
                        </Alert>
                    </>
                )
            });

            setUserArea("");

        }


        if (userInfo.code != 2) {

            setUserArea(() => {
                return (
                    <>
                        <Row style={{ align: "left" }}>
                            <Col>
                                UserID

                            </Col>
                            <Col style={{ align: "left" }}>
                                {userInfo.ID}

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                User Email

                            </Col>
                            <Col>
                                {userInfo.Email}

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                User Name

                            </Col>
                            <Col>
                                {userInfo.Name}

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Current Classes assigned

                            </Col>
                            <Col>
                                <ul>

                                </ul>
                                {userInfo.classList.map(school => {

                                    return (
                                        <>
                                            <li>{school.className}({school.classKey})</li>
                                        </>)
                                })}

                            </Col>
                        </Row>
                    </>
                )
            });
        }

        setAssignClassSection({});

        // setClassList(() => {
        //     return (
        //         <>
        //             <Row>
        //                 <Col>
        //                     Select Class To Assign {classKey}
        //                 </Col>
        //                 <Col>
        //                     {/* <Form.Select aria-label="Default select example" onChange={event => setProvince(event.target.value)} id="province" name="province" ></Form.Select> */}
        //                     <Form.Select aria-label="Default select example" onChange={event => setClassKey(event.target.value)} id="newSchool" name="newSchool" >
        //                     <option value="0"></option>
        //                         {schools.map(school => (
        //                             <>
        //                                 <option value={school.schoolId}>{school.schoolName} / {school.className} ({school.schoolId})</option>
        //                             </>

        //                         ))}
        //                     </Form.Select>
        //                 </Col>
        //                 <Col>
        //                     <button type="submit" class="btn btn-primary mb-2" onClick={() => updateUserWithClass()}style={{ width: "100px" }} >Assign</button>

        //                 </Col>
        //             </Row>
        //         </>
        //     )
        // });
    }



    useEffect(async () => {
        var schoolListDetails = await getSchools()
            .then(payload => setSchools(payload))
        // console.log("schoolListDetails " + JSON.stringify(schoolListDetails));
        //    await setSchools(schoolListDetails);

    }, []);

    return (
        <>
            <Row>
                <Col>
                    Enter email of user
                </Col>
                <Col>
                    <input class="form-control form-control-sm" onChange={event => setEmail(event.target.value)} type="text" autoComplete="text" name="email" id="email" />
                </Col>
                <Col>
                    <button type="submit" class="btn btn-primary mb-2" style={{ width: "100px" }} onClick={() => createActiveArea()}>Search</button>
                </Col>
            </Row>
            <div align="center"><hr width="75%" /></div>
            <div align="left">
                <Row style={{ margin: "auto" }}>
                    <Col style={{ align: "left" }}>
                        {userArea}
                    </Col>
                    <Col style={assignClassSection} >
                        {classList}
                        <Row>
                            <Col>
                                Select Class To Assign {classKey}
                            </Col>
                            <Col>
                                {/* <Form.Select aria-label="Default select example" onChange={event => setProvince(event.target.value)} id="province" name="province" ></Form.Select> */}
                                <Form.Select aria-label="Default select example" onChange={event => setClassKey(event.target.value)} id="newSchool" name="newSchool" >
                                    <option value="0"></option>
                                    {schools.map(school => (
                                        <>
                                            <option value={school.schoolId}>{school.schoolName} / {school.className} ({school.schoolId})</option>
                                        </>

                                    ))}

                                </Form.Select>
                            </Col>
                            <Col>
                                < button type="submit" class="btn btn-primary mb-2" onClick={() => updateUserWithClass()}>Assign</button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div><br />
            {stepupError}
        </>

    );
}

export default AssignClassComponent;