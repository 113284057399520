



  export async function getOrderByInvoice(id){

    const options = { 
        method: 'GET', 
        headers: new Headers({ 
          'Content-Type': 'application/json',
          'sb-application-name': 'sb-site-ui',
          'sb-application-key': 'jmd-test',
          'Access-Control-Allow-Origin': '*',
          'sessionID' : sessionStorage.getItem("sessionID"),
          'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
        }), 
        mode: 'cors',
        credentials: 'omit'
        // body: JSON.stringify(testPayload)
      };
    const endpoint = process.env.REACT_APP_BFF + '/bff/v1.0/getBookstoreInvoiceById/' + id;
    options.method = 'GET';
  
    let invoiceDetailsResponse =
      await fetch(endpoint,options)
        .then(response => {
          return response.json();
        })
        .catch(function(err) {
          console.log(err);
        });

    return invoiceDetailsResponse;

  }





//   export async function getOrderByUserID(){

//     const endpoint = process.env.REACT_APP_BFF + '/getOrderByCustomer/';
//     options.method = 'GET';
  
//     let productDetailsResponse =
//       await fetch(endpoint,options)
//         .then(response => {
//           // console.log("getOrderByUserID" + JSON.stringify(response));
//           return response.json();
//         })
//         .catch(function(err) {
//           console.log(err);
//         });;
//         // console.log('getOrderByUserID --- ln 51' + JSON.stringify(productDetailsResponse));
//     return productDetailsResponse;

//   }

//   export async function getAllOrders(start,end,status = null){

//     const endpoint = process.env.REACT_APP_BFF + '/bff/v1.0/getOrders/?start=' + start + '&end=' + end + '&status=' + status;
//     options.method = 'GET';
  
//     console.log("endpoint " + endpoint);
//     let productDetailsResponse =
//       await fetch(endpoint,options)
//         .then(response => {
//           // console.log("getOrderByUserID" + JSON.stringify(response));
//           return response.json();
//         })
//         .catch(function(err) {
//           console.log(err);
//         });;
//         console.log('getOrderByUserID --- ln 69' + JSON.stringify(productDetailsResponse));
//     return productDetailsResponse;

//   }
  