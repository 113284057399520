import CurrentCourseList from '../textbook/course-list';
import Card from 'react-bootstrap/Card'
import StudentNotesMainComponenet from '../student-notes/student-notes-main'

function MyCoursePageComponent(){
    const props = {
        "studentDetails": {
          "studentId":"CX-12345"
        },
        "title": ""
      }
    return(
        <div style={{ width: '100rem', align: 'center',padding: '50px' }}>
            <table>
                <tr>
                    <td width="60%">
                    <Card style={{ padding: '20px' }}>
                        <Card.Body>
                            <Card.Title>My Textbooks</Card.Title>
                            <Card.Text>
                                <CurrentCourseList props={props}/>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </td>
                    <td align="center" style={{'vertical-align': "top",padding: "50px"}}>
                        <StudentNotesMainComponenet />
                    </td>
                </tr>
            </table>
 
        </div>
    );
}

export default MyCoursePageComponent;
