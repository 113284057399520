export async function isItemComplete(type, typeId) {
    const studentProgress2 = JSON.parse(localStorage.getItem('userProgressTotal'));
    const studentProgress = studentProgress2['studentProgress'];

    var grade = 0;
    var hasQuiz = false;

    var completePayload = {
        "total": 0,
        "totalComplete": 0,
        "totalNotComplete": 0
    }

    if (type == "MODULE") {
        for (var p = 0; p < studentProgress.payload.length; p++) {
            for (var t = 0; t < studentProgress.payload[p].textbook.length; t++) {
            for (var u = 0; u < studentProgress.payload[p].textbook[t].unit.length; u++) {
                for (var c = 0; c < studentProgress.payload[p].textbook[t].unit[u].chapter.length; c++) {
                    for (var m = 0; m < studentProgress.payload[p].textbook[t].unit[u].chapter[c].module.length; m++) {
                        if (typeId == studentProgress.payload[p].textbook[t].unit[u].chapter[c].module[m].id) {
                            completePayload.total = studentProgress.payload[p].textbook[t].unit[u].chapter[c].module[m].lesson.length;
                            for (var l = 0; l < studentProgress.payload[p].textbook[t].unit[u].chapter[c].module[m].lesson.length; l++) {
                                if (studentProgress.payload[p].textbook[t].unit[u].chapter[c].module[m].lesson[l].status == "COMPLETE") {
                                    completePayload.totalComplete++;
                                }
                                if (studentProgress.payload[p].textbook[t].unit[u].chapter[c].module[m].lesson[l].status == "IN-PROGRESS") {
                                    completePayload.totalNotComplete++;
                                }
                                if (studentProgress.payload[p].textbook[t].unit[u].chapter[c].module[m].lesson[l].status == "Not Started") {
                                    completePayload.totalNotComplete++;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    }

    return completePayload;
}
