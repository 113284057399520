export async function getAllLTIConsumersService(payload){

    const options = { 
      method: 'GET', 
      headers: new Headers({ 
        'Content-Type': 'application/json',
        'sb-application-name': 'sb-site-ui',
        'sb-application-key': 'jmd-test',
        'sessionID' : sessionStorage.getItem("sessionID"),
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
      }), 
      mode: 'cors',
      credentials: 'omit',
      body: JSON.stringify(payload)
    };
    // const endpoint = "http://localhost:8080/sb-lti-v-1-1/allConsumers";process.env.LTI_SERVICE 
    // const endpoint = "https://sb-lti-service-ycgnbf2s4a-uc.a.run.app/sb-lti-v-1-1/allConsumers";
    //                   https://sb-lti-service-ycgnbf2s4a-uc.a.run.app/sb-lti-v-1-1/allConsumers
    const endpoint = process.env.REACT_APP_LTI_SERVICE + "/sb-lti-v-1-1/allConsumers";

  
    let couponList =
      await fetch(endpoint,options)
        .then(response => {
          return response.json();
        })
        .catch(function(err) {
          console.log(err);
        });

    return couponList;

  }

  