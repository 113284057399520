import {encryptFullDetails } from '../jwtPaymentTransformer';

export async function submitPO(orderDetails, trial = false, trialInfoSubmitted = false) {

  const orderPayload = await generaterPayload(orderDetails, trial);

  if (trial == true) {
    orderPayload.trialOrder = true;
    orderPayload.trialInfoSubmitted = trialInfoSubmitted;
  }

  const options = {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'sb-application-name': 'sb-site-ui',
      'sb-application-key': 'jmd-test',
      'sessionID': sessionStorage.getItem("sessionID"),
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
    }),
    mode: 'cors',
    credentials: 'omit',
    body: JSON.stringify(orderPayload)
  };

  const scURL = process.env.REACT_APP_BFF + '/createOrder';

  let orderResponse =
    await fetch(scURL, options)
      .then(response => {
        return response.json();
      })
      .catch(function (err) {
        //console.log(err + "req: " + JSON.stringify(orderPayload) + " Session: " + sessionStorage.getItem("sessionID"));
      });;

  return orderResponse;
}

export async function submitBookstorePO(orderDetails, trial = false, trialInfoSubmitted = false) {

  const orderPayload = await generaterPayload(orderDetails, trial);

  if (trial == true) {
    orderPayload.trialOrder = true;
    orderPayload.trialInfoSubmitted = trialInfoSubmitted;
  }

  const options = {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'sb-application-name': 'sb-site-ui',
      'sb-application-key': 'jmd-test',
      'sessionID': sessionStorage.getItem("sessionID"),
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
    }),
    mode: 'cors',
    credentials: 'omit',
    body: JSON.stringify(orderPayload)
  };

  const scURL = process.env.REACT_APP_BFF + '/bff/v1.0/submitBookStoreOrder/';

  let orderResponse =
    await fetch(scURL, options)
      .then(response => {
        return response.json();
      })
      .catch(function (err) {
        //console.log(err);
      });;

  return orderResponse;
}

export function moveToCheckout(id) {
  window.location.href = "/checkout?" + id;
}

async function generaterPayload(orderDetails, trial = false) {
  var paymentDetails;
  if (trial != true) {
    paymentDetails = await encryptFullDetails(orderDetails.ccDetails, orderDetails.transactionDetails);
  }
  if (trial == true) {
    paymentDetails = "N/A";
  }

  const orderPayload = {
    "shoppingCartID": orderDetails.shoppingCartId,
    "paymentDetails": paymentDetails,
    "existingUser": orderDetails.existingUser,
    "userDetails": {
      "firstName": orderDetails.fName,
      "lastName": orderDetails.lName,
      "username": orderDetails.userName,
      "password": orderDetails.password,
      "email": orderDetails.email,
      "role": "Student",
      "ltiStudent": "N",
      "address": {
        "streetNum": "N/A",
        "street": "N/A",
        "city": "N/A",
        "stateOrProvince": orderDetails.addressInfo.province,
        "country": orderDetails.addressInfo.country,
        "postalCode": orderDetails.addressInfo.postalCode,
        "emailAddress": orderDetails.email
      }
    }
  }

  return orderPayload;

}